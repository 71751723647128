import React, { ReactElement } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type Props = {
  toolTipMsg: string
  children?: ReactElement
}

const TooltipComponent = ({ toolTipMsg, children }: Props) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {toolTipMsg}
    </Tooltip>
  )
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {children || <span className='mdi mdi-information ms-2' />
      }
    </OverlayTrigger>
  )
}

export default TooltipComponent
