export const ENGLISH_TRANSLATION = {
  CHANGE_THEME: 'Change Theme',
  INPUT_FIELD_ENUMS: {
    FIRST_NAME: {
      PLACEHOLDER: 'First Name',
      LABEL_NAME: 'Firs Name'
    },
    LAST_NAME: {
      PLACEHOLDER: 'Last Name',
      LABEL_NAME: 'Last Name'
    },
    MIDDLE_NAME: {
      PLACEHOLDER: 'Middle Name',
      LABEL_NAME: 'Middle Name'
    },
    COUNTRY: {
      PLACEHOLDER: 'Country',
      LABEL_NAME: 'Country'
    },
    STATE: {
      PLACEHOLDER: 'State',
      LABEL_NAME: 'State'
    },
    CITY: {
      PLACEHOLDER: 'City',
      LABEL_NAME: 'City'
    },
    ADDRESS: {
      PLACEHOLDER: 'Enter Your Address',
      LABEL_NAME: 'Address'
    },
    EMAIL_ADDRESS: {
      PLACEHOLDER: 'Email Address',
      LABEL_NAME: 'Email Address'
    },
    COUNTRY_CODE: {
      PLACEHOLDER: '+91',
      LABEL_NAME: 'Code'
    },
    PHONE_NUMBER: {
      PLACEHOLDER: 'Phone Number',
      LABEL_NAME: 'Phone Number'
    },
    DATE_OF_BIRTH: {
      PLACEHOLDER: 'Date Of Birth',
      LABEL_NAME: 'Date Of Birth'
    },
    PASSWORD: {
      PLACEHOLDER: 'Set Password',
      LABEL_NAME: 'Password'
    },
    CONFIRM_PASSWORD: {
      PLACEHOLDER: 'Confirm Password',
      LABEL_NAME: 'Confirm Password'
    },
    LOGIN_EMAIL_OR_PHONE_NUMBER: {
      PLACEHOLDER: 'Enter your email or phone number',
      LABEL_NAME: 'Email or Phone Number'
    },
    LOGIN_PASSWORD: {
      PLACEHOLDER: 'Enter your password',
      LABEL_NAME: 'Password'
    },
    ADD_DESIGNATION: {
      PLACEHOLDER: 'Designation',
      LABEL_NAME: 'Enter the designation'
    },
    ADD_DUTY: {
      PLACEHOLDER: 'Duty',
      LABEL_NAME: 'Assign Duty of above designation'
    },
    DOCUMENT_NAME: {
      PLACEHOLDER: 'Enter Name Of Document',
      LABEL_NAME: 'Document Name'
    },
    DOCUMENT_DESCRIPTION: {
      PLACEHOLDER: 'Enter Document Description',
      LABEL_NAME: 'Document Description'
    },
    DOCUMENT_FORMAT: {
      PLACEHOLDER: 'Document Format',
      LABEL_NAME: 'Document Format'
    },
    DOCUMENT_SIZE: {
      PLACEHOLDER: 'Max Size Of Document',
      LABEL_NAME: 'Document Size'
    },
    DOCUMENT_DO_SHOW: {
      PLACEHOLDER: 'Document Name',
      LABEL_NAME: 'Enter Name Of Document'
    },
    DOCUMENT_EXPIRY: {
      PLACEHOLDER: 'Document Name',
      LABEL_NAME: 'Enter Name Of Document'
    },
    DOCUMENT_PLACE_HOLDING_IMAGE: {
      PLACEHOLDER: 'Enter Image url',
      LABEL_NAME: 'Document Place Holder'
    },
    IS_CAPTURE_ONLY: {
      PLACEHOLDER: 'Document Name',
      LABEL_NAME: 'Enter Name Of Document'
    },
    DOCUMENT_REQUIRED: {
      PLACEHOLDER: 'Document Name',
      LABEL_NAME: 'Enter Name Of Document'
    },
    VEHICLE_NAME: {
      PLACEHOLDER: 'Enter Name Of Vehicle',
      LABEL_NAME: 'Vehicle Name'
    },
    VEHICLE_DESCRIPTION: {
      LABEL_NAME: 'Vehicle Description',
      PLACEHOLDER: 'Describe The Vehicle'
    },
    VEHICLE_IMAGE: {
      LABEL_NAME: 'Vehicle Image',
      PLACEHOLDER: 'Enter Image Url Of Vehicle'
    },
    VEHICLE_ICON_IMAGE: {
      LABEL_NAME: 'Vehicle Icon Image',
      PLACEHOLDER: 'Enter Image Url Of Vehicle Icon'
    },
    VEHICLE_IS_ACTIVE: {
      LABEL_NAME: 'Active/Inactive'
    },
    VEHICLE_TYPE: {
      LABEL_NAME: 'Vehicle Type',
      PLACEHOLDER: 'Enter Type Of Vehicle'
    },
    VEHICLE_CAPACITY: {
      PLACEHOLDER: 'Please Enter The Seating Capacity Of Vehicle',
      LABEL_NAME: 'Vehicle Capacity'
    },
    VEHICLE_AVG_SPEED: {
      PLACEHOLDER: 'Vehicle Avg Speed According Meter/Minutes',
      LABEL_NAME: 'Vehicle Average Speed'
    },
    DRIVER_NAME: {
      PLACEHOLDER: 'Please Enter Name',
      LABEL_NAME: 'Driver Name'
    },
    DRIVER_GENDER: {
      PLACEHOLDER: 'Please Select Gender',
      LABEL_NAME: 'Gender'
    },
    DRIVER_DOB: {
      PLACEHOLDER: 'Enter Date of Birth ',
      LABEL_NAME: 'Date of Birth'
    },
    DRIVER_EMAIL: {
      PLACEHOLDER: 'Please Enter Email Address',
      LABEL_NAME: 'Email Address'
    },
    DRIVER_MOBILE: {
      PLACEHOLDER: 'Enter Your MObile No.',
      LABEL_NAME: 'MObile No.'
    },
    DRIVER_CREATED: {
      PLACEHOLDER: 'Driver Registered At',
      LABEL_NAME: 'Driver Registered Date'
    },
    DRIVER_ADDRESS: {
      PLACEHOLDER: 'Please Entre Your Address',
      LABEL_NAME: 'Address'
    },
    DRIVER_CITY: {
      PLACEHOLDER: 'Enter Your City',
      LABEL_NAME: 'City Name'
    },
    DRIVER_STATUS: {
      LABEL_NAME: 'Active/Inactive'
    },
    VEHICLE_BRAND: {
      PLACEHOLDER: 'Enter Brand Name of Vehicle',
      LABEL_NAME: 'Vehicle Brand'
    },
    VEHICLE_MODEL: {
      PLACEHOLDER: 'Enter Model of Vehicle',
      LABEL_NAME: 'Vehicle Model'
    },
    VEHICLE_PLATE: {
      PLACEHOLDER: 'Enter Vehicle No. Plate',
      LABEL_NAME: 'Vehicle Plate'
    },
    VEHICLE_SEAT_CAPACITY: {
      PLACEHOLDER: 'Vehicle Seat Capacity',
      LABEL_NAME: 'Vehicle Seat Capacity'
    },
    VEHICLE_MAKE_YEAR: {
      PLACEHOLDER: 'Making Year Of Vehicle',
      LABEL_NAME: 'Vehicle Made Year'
    },
    VEHICLE_COLOR: {
      PLACEHOLDER: 'Vehicle Color',
      LABEL_NAME: 'Vehicle Color'
    },
    BRAND_NAME: {
      PLACEHOLDER: 'Please Enter the brand name of Vehicle',
      LABEL_NAME: 'Vehicle Brand'
    },
    BANNER_TITLE: {
      PLACEHOLDER: 'Please Enter a Title',
      LABEL_NAME: 'Banner Title'
    },
    BANNER_TYPE: {
      PLACEHOLDER: 'Please Select Type of Banner',
      LABEL_NAME: 'Please Select Type'
    },
    BANNER_DESCRIPTION: {
      PLACEHOLDER: 'Please Enter Description',
      LABEL_NAME: 'Banner Description'
    },
    BANNER_ICON: {
      PLACEHOLDER: 'Please Enter URL',
      LABEL_NAME: 'Banner Icon'
    },
    BANNER_REDIRECT_LINK: {
      PLACEHOLDER: 'Please Enter URL for Redirect',
      LABEL_NAME: 'Banner Redirect Link'
    },
    USER_NAME: {
      PLACEHOLDER: 'Please Enter Name',
      LABEL_NAME: 'User Name'
    },
    USER_DOB: {
      PLACEHOLDER: 'Date of Birth',
      LABEL_NAME: 'Date of Birth'
    },
    USER_EMAIL: {
      PLACEHOLDER: 'Please Enter User Email Address',
      LABEL_NAME: 'Email Address'
    },
    USER_MOBILE: {
      PLACEHOLDER: 'Please Enter Users Mobile Number',
      LABEL_NAME: 'Mobile No.'
    },
    USER_STATUS: {
      PLACEHOLDER: '',
      LABEL_NAME: 'User Account Status'
    },
    USER_COUNTRY: {
      PLACEHOLDER: 'Please Enter you County Code',
      LABEL_NAME: 'Country Code'
    },
    USER_CITY: {
      PLACEHOLDER: 'Please Enter Name of the City',
      LABEL_NAME: 'City Name'
    },
    SHIFT_TITLE: {
      PLACEHOLDER: 'Please Enter Title Of Shift',
      LABEL_NAME: 'Shift Title'
    },
    SHIFT_START_TIME: {
      PLACEHOLDER: 'Please Enter Start Time of Shift',
      LABEL_NAME: 'Start Time'
    },
    SHIFT_END_TIME: {
      PLACEHOLDER: 'Please Enter End Time of Shift',
      LABEL_NAME: 'End Time'
    },
    SHIFT_IS_ACTIVE: {
      LABEL_NAME: 'Make Shift Active/Inactive'
    },
    ADDITIONAL_PERSON_CHARGE: {
      PLACEHOLDER: 'Additional Person Charge',
      LABEL_NAME: 'Additional Person Charge'
    },
    BASE_RATE: {
      PLACEHOLDER: 'Base Rate',
      LABEL_NAME: 'Base Rate'
    },
    CANCEL_RATE: {
      PLACEHOLDER: 'Cancel Rate',
      LABEL_NAME: 'Cancel Rate'
    },
    DISTANCE_RATE: {
      PLACEHOLDER: 'Distance Rate',
      LABEL_NAME: 'Distance Rate'
    },
    DURATION_RATE: {
      PLACEHOLDER: 'Duration Rate',
      LABEL_NAME: 'Duration Rate'
    },
    INITIAL_DISTANCE: {
      PLACEHOLDER: 'Initial Distance',
      LABEL_NAME: 'Initial Distance'
    },
    INITIAL_DURATION: {
      PLACEHOLDER: 'Initial Duration',
      LABEL_NAME: 'Initial Duration'
    },
    INITIAL_PICKUP_DISTANCE: {
      PLACEHOLDER: 'Initial Pickup Distance',
      LABEL_NAME: 'Initial Pickup Distance'
    },
    INITIAL_WAIT_TIME: {
      PLACEHOLDER: 'Initial Wait Time',
      LABEL_NAME: 'Initial Wait Time'
    },
    LUGGAGE_CHARGE: {
      PLACEHOLDER: 'Luggage Charge',
      LABEL_NAME: 'Luggage Charge'
    },
    PICKUP_DISTANCE_RATE: {
      PLACEHOLDER: 'Pickup Distance Rate',
      LABEL_NAME: 'Pickup Distance Rate'
    },
    STOP_RATE: {
      PLACEHOLDER: 'Stop Rate',
      LABEL_NAME: 'Stop Rate'
    },
    SUR_CHARGE: {
      PLACEHOLDER: 'Sur Charge',
      LABEL_NAME: 'Sur Charge'
    },
    WAIT_CHARGE: {
      PLACEHOLDER: 'Wait Rate',
      LABEL_NAME: 'Wait Rate'
    }
  },

  FORM_PAGE_CONSTANTS: {
    GREETING_TITLE: {
      REGISTER_PAGE: 'Hello!',
      LOGIN_PAGE: 'Welcome Back!'
    },
    GREETING_LINE: {
      REGISTER_PAGE: 'Welcome!',
      LOGIN_PAGE: 'Happy to see you again!'
    },
    CHECKBOX_CONDITION: {
      REGISTER_PAGE: 'I agree to all ',
      LOGIN_PAGE: 'Keep me sign in'
    },
    BUTTON_NAME: {
      REGISTER_PAGE: 'SING UP',
      LOGIN_PAGE: 'LOGIN',
      REQUEST_RESET_PASSWORD: 'Request to reset password',
      RESET_PASSWORD_PAGE: 'Reset Password'
    },
    BOTTOM_LINE: {
      REGISTER_PAGE: 'Already have an account?',
      LOGIN_PAGE: "Don't have an account? ",
      REQUEST_RESET_PASSWORD: 'Go back to',
      RESET_PASSWORD_PAGE: 'Go back to',
      REGISTER_PAGE_SUBMITTED: 'You have registered successfully click here to'
    },
    REDIRECT_LINK: {
      TERMS_CONDITION: 'Terms & Conditions',
      FORGOT_PASSWORD: 'Forgot password',
      REQUEST_RESET_PASSWORD: 'Reset password',
      RESET_PASSWORD_PAGE: 'Login'
    }
  },
  PROJECT_BUTTON: {
    ADD: 'Add',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
    ENTER: 'Enter',
    DELETE: 'Delete',
    SUBMIT: 'Submit',
    EDIT: 'Edit',
    VIEW: 'View',
    ASSIGN: 'Assign',
    DEACTIVATE: 'Deactivate',
    INSERT: 'Insert',
    UPDATE: 'Update'
  },
  DESIGNATION_RIGHT_PAGE: {
    TITLE: 'Designation Rights',
    NO_DESIGNATION: 'No Rights to show for this designation.',
    RIGHTS_NAME: {
      CREATE: 'Create :',
      READ: 'Read :',
      UPDATE: 'Update :',
      DELETE: 'Delete :'
    },
    RIGHTS_NAME_MOBILE_VIEW: {
      CREATE: 'C :',
      READ: 'R :',
      UPDATE: 'U :',
      DELETE: 'D :'
    }
  },
  PAGE_TITLE: {
    DESIGNATION: 'Designation Table',
    ADD_DESIGNATION: 'Add Designation',
    EDIT_DESIGNATION: 'Edit Designation',
    VIEW_DESIGNATION: 'View Designation',
    DESIGNATION_RIGHTS: 'Designation Rights',
    ADD_VEHICLE: 'Add Vehicle',
    EDIT_VEHICLE: 'Edit Vehicle',
    VIEW_VEHICLE: 'View Vehicle'
  },
  PAGE_NAME: {
    HOME: 'Home',
    DESIGNATION: 'Designation',
    ADD_DESIGNATION: 'Add Designation',
    EDIT_DESIGNATION: 'Edit Designation',
    VIEW_DESIGNATION: 'View Designation',
    DESIGNATION_RIGHTS: 'Designation Rights',
    ADMIN_LIST: 'Admin List',
    ADMIN_DETAILS: 'Admin Detail',
    USER_LIST: 'User List',
    VIEW_USER: 'View User Details',
    EDIT_USER: 'Edit User',
    DRIVER_LIST: 'Driver List',
    VIEW_DRIVER: 'View Driver Details',
    EDIT_DRIVER: 'Edit Driver Details',
    ADD_DRIVER: 'Add Driver Details',
    DRIVER_DOCUMENTS: 'Driver Documents',
    DRIVER_DOCUMENT_VIEW: 'Driver Document View',
    DRIVER_DOCUMENT_ADD: 'Driver Document Add',
    DRIVER_DOCUMENT_EDIT: 'Driver Document Edit',
    DRIVER_VERIFICATION: 'Driver Verification',
    VEHICLE_LIST: 'Vehicle List',
    VEHICLE_LIST_VIEW: 'View Vehicle',
    VEHICLE_LIST_ADD: 'Add Vehicle',
    VEHICLE_LIST_EDIT: 'Edit Vehicle',
    VEHICLE_DOCUMENT: 'Vehicle Document',
    VEHICLE_DOCUMENT_VIEW: 'View Vehicle Document',
    VEHICLE_DOCUMENT_EDIT: 'Edit Vehicle Document',
    VEHICLE_DOCUMENT_ADD: 'Add Vehicle Document',
    MEDIA: 'Media',
    VEHICLE_BRAND: 'Vehicle Brand',
    SHIFT_SETTINGS: 'Shift Settings',
    ADD_SHIFT: 'Add Shift',
    ORGANIZATION_SHIFT_PRICE_SETTINGS: 'Shift price Settings',
    ORGANIZATION_SETTINGS: 'Organization Settings',
    BOOKINGS: 'Bookings',
    BOOKING_DETAILS: 'Bookings Details'

  },
  TOAST_ERROR: {
    HEADER_TYPE: {
      ERROR: 'Error',
      SUCCESS: 'Success'
    },
    DESIGNATION: {
      FILED_TO_LOAD: 'Can not fetch designation details.',
      FILED_TO_DELETE: 'Entry has not deleted yet. Please try agin.',
      DELETE_SUCCESS: 'Entry deleted successfully.'
    },
    ADD_DESIGNATION: {
      FIELD_TO_LOAD: 'Did not get the designation you trying to access.',
      FIELD_TO_EDIT: 'Entry not Added.',
      EDIT_SUCCESS: 'Entry Added successfully.'
    },
    EDIT_DESIGNATION: {
      FIELD_TO_LOAD: 'Did not get the designation you trying to access.',
      FIELD_TO_EDIT: 'Entry not Edited.',
      EDIT_SUCCESS: 'Entry edited successfully.'
    },
    VIEW_DESIGNATION: {
      FIELD_TO_LOAD: 'Did not get the designation you trying to access.'
    },
    DESIGNATION_RIGHTS: {
      FIELD_TO_LOAD_DESIGNATION_RIGHTS: 'Could not fetch the designation rights data.',
      FIELD_TO_LOAD_DESIGNATION_MENU: 'Could not fetch the designation data for select menu.'
    },
    REGISTER_FORM: {
      SUBMIT_SUCCESS: 'you have registered successfully',
      SUBMIT_FIELD: 'something went wrong',
      COUNTRY_FETCH_FIELD: 'can not fetch country',
      STATE_FETCH_FIELD: 'Can not fetch state',
      CITY_FETCH_FIELD: 'can not fetch city'
    },
    LOGIN_FORM: {
      LOGIN_FIELD: 'Something went wrong. Please try again.',
      LOGIN_SUCCESS: 'Logged in  successfully.'
    },
    ADMIN_LIST: {
      FILED_TO_LOAD: 'Can not fetch Admin list.'
    },
    USER_LIST: {
      FILED_TO_LOAD: 'Can not fetch User list.'
    },
    DRIVER_LIST: {
      FILED_TO_LOAD: 'Can not fetch Driver list.'
    },
    REQUEST_RESET_PASSWORD: {
      REQUEST_FILED: 'Something went wrong!',
      REQUEST_SUCCESS: 'Your request has been send successfully please check you mail.'
    },
    RESET_PASSWORD: {
      LINK_EXPIRED: 'Your password reset link has expired',
      FIELD_TO_RESET: 'Your password has been not reset please try again.',
      RESET_SUCCESS: 'Your request has been reset successfully.'
    },
    AUTH_ERROR: {
      EMAIL_EXIST: 'This email id is already in use.',
      LOGIN_SUCCESS: 'You have logged in successfully.',
      ACCOUNT_CREATED: 'Your account has been created successfully.',
      INVALID_PASSWORD: 'Please make sure you have entered a valid password.',
      ACCOUNT_NOT_FOUND: 'There is no account with this email address. create an account first.',
      PASSWORD_RESET_SUCCESS: 'Your password has been reset successfully.',
      LOGOUT_SUCCESS: 'You have logged out successfully.',
      LOGOUT_FAILED: 'Logout failed Please try again.',
      RESET_PASSWORD_LINK_EXPIRED: 'This link has expired. Please try again to reset you password.',
      RESET_PASSWORD_LINK_SENT: 'Please check your mail to reset your password.'
    },
    ADMIN_MENUS_ERROR: {
      ADMIN_LIST_FETCH_SUCCESS: 'Admin list is fetched successfully',
      ADMIN_DETAIL_FETCH_SUCCESS: 'Admin details fetched successfully',
      ADMIN_UPDATE_SUCCESS: 'Admin details updated successfully',
      ADMIN_STATUS_UPDATE_SUCCESS: 'Admin status updated successfully'
    },
    USER_MENU_ERROR: {
      USER_LIST_FETCH_SUCCESS: 'User list is fetched successfully'
    },
    DRIVER_MENU_ERROR: {
      DRIVER_DOC_CREATED: 'Driver document created successfully',
      DRIVER_DOC_UPDATED: 'Driver document edit successfully',
      DRIVER_LIST_FETCH_SUCCESS: 'Driver list is fetched successfully'
    },
    MEDIA: {
      UPLOAD_IMAGE_SUCCESS: 'All Files are uploaded successfully'
    },
    DEFAULT_ERROR: 'Something went wrong. Please try again.'
  },
  SIDEBAR: {
    LOGO_TAG_LINE: '',
    MENU: {
      MAIN_MENU: 'Main Menu',
      DASHBOARD: 'Dashboard',
      ORGANIZATION_MENU: 'Organization',
      BOOKING_MENU: 'Booking',
      WIDGETS: 'Widgets',
      APP_PAGE: {
        MENU_TITLE: 'App Pages',
        PAGE: 'Pages'
      },
      USER_MENU: 'User Menu',
      ADMIN: {
        MENU_TITLE: 'Admin',
        DESIGNATION: 'Designation',
        CREATE_DESIGNATION: 'Create designation',
        DESIGNATION_RIGHTS: 'Designation Rights',
        ADMIN_LIST: 'List'
      },
      USERS: {
        MENU_TITLE: 'User',
        USER_LIST: 'List'
      },
      DRIVER: {
        MENU_TITLE: 'Driver',
        DRIVER_LIST: 'List',
        DRIVER_DOCUMENT_LIST: 'Document',
        CREATE_DRIVER_DOCUMENT: 'Create Document'
      },
      VEHICLE: {
        MENU_TITLE: 'Vehicle',
        VEHICLE_LIST: 'List',
        VEHICLE_BRAND: 'Brand',
        VEHICLE_DOCUMENT: 'DocumentS'
      },
      ORGANIZATION: {
        MENU_TITLE: 'Organization',
        APP_SETTINGS: 'App Settings',
        MEDIA: 'Media',
        SHIFT_SETTING: 'Shift Settings'
      },
      BOOKING: {
        MENU_TITLE: 'Booking',
        DETAILS: 'Details'
      }
    }
  },
  NAVBAR: {
    GREETING_LINE: 'Hi! welcome back!',
    SEARCH_PLACEHOLDER: 'Search',
    NOTIFICATION_MENU: {
      MENU_TITLE: 'Notifications',
      EVENT_TODAY: {
        MENU_TITLE: 'Event Today',
        MENU_DETAIL: 'Just a reminder that you have an event today'
      },
      SETTING: {
        MENU_TITLE: 'Setting',
        MENU_DETAIL: 'Update dashboard'
      },
      NOTIFICATION: {
        MENU_TITLE: 'Notification',
        MENU_DETAIL: 'New admin wow!'
      }
    },
    USER_MENU: {
      MENU_TITLE: 'User',
      LOGOUT: {
        MENU_TITLE: 'Logout',
        MENU_DETAIL: ''
      }
    }
  },
  DESIGNATION_PAGE: {
    TABLE_TITLE: 'Designation Table',
    ADD_DESIGNATION_BUTTON: 'Add Designation',
    SEARCH_BAR_PLACEHOLDER: 'Search',
    IS_DEFAULT_CHECKBOX: 'Set To Default',
    DESIGNATION_TABLE: {
      ORDER: 'Order',
      DESIGNATION: 'Designation',
      DUTY: 'Duty',
      ACTION: 'Action',
      DEFAULT: 'Default',
      NO_DESIGNATION: 'No Designation To show',
      TABLE: {
        ROW_PER_PAGE: 'Row per page',
        RANGE_SELECTION: 'Per'
      },
      ACTION_DROPDOWN: {
        VIEW: 'View',
        EDIT: 'Edit',
        DELETE: 'Delete',
        RIGHTS: 'Rights',
        BLOCK: 'Block',
        ACTIVE: 'Active'
      }
    },
    MODAL: {
      MODAL_TITLE: 'Delete Designation',
      MODAL_MESSAGE: 'Are you sure you want to delete the designation field - '
    }
  },
  ADMIN_LIST_PAGE: {
    TABLE_TITLE: 'Admin List',
    ADD_DESIGNATION_BUTTON: 'Add Designation',
    SEARCH_BAR_PLACEHOLDER: 'Search',
    ADMIN_TABLE: {
      ORDER: 'Order',
      NAME: 'Name',
      EMAIL: 'Email',
      STATUS: 'Status',
      DETAILS: 'Details',
      DESIGNATION_NAME: 'Designation',
      NO_ADMIN: 'No Admin To show'
    },
    MODAL: {
      TITLE_ASSIGN_DESIGNATION: 'Assign Designation',
      MODAL_MESSAGE: 'Are you sure you want to delete this designation field?',
      SELECT_LABEL: 'Select Designation',
      SELECT_PLACEHOLDER: 'Please Select Designation you want to assign'
    }
  },
  VEHICLE_LIST_PAGE: {
    TITLE: 'Vehicle List',
    ADD_VEHICLE_BUTTON: 'Add Vehicle',
    NO_VEHICLE: 'No Vehicle Data Found',
    DOCUMENTS_BUTTON: 'Documents'
  },
  VEHICLE_DOCUMENTS_PAGE: {
    TITLE: 'Vehicle Documents',
    NO_DOCUMENTS: 'No Documents To Show',
    ADD_DOCUMENTS: 'Add Documents',
    DOCUMENT_BUTTON: 'Document',
    TABLE: {
      NAME: 'Document Name',
      ACTION: 'Action'
    }
  },
  ADD_VEHICLE_DOCUMENT_PAGE: {
    TITLE: 'Add Vehicle Document'
  },
  EDIT_VEHICLE_DOCUMENT_PAGE: {
    TITLE: 'Edit Vehicle Document'
  },
  ADMIN_DETAILS_PAGE: {
    TITLE: 'Admin Details'
  },
  USER_LIST_PAGE: {
    TABLE_TITLE: 'User List',
    ADD_DESIGNATION_BUTTON: 'Add Designation',
    SEARCH_BAR_PLACEHOLDER: 'Search',
    USER_TABLE: {
      ORDER: 'Order',
      STATUS: 'Status',
      NAME: 'Name',
      EMAIL: 'Email',
      ACTION: 'Action',
      AVAILABILITY: 'Availability',
      NO_USER: 'No User To Show'
    },
    MODAL: {
      MODAL_TITLE: 'Delete Designation Field',
      MODAL_MESSAGE: 'Are you sure you want to delete this designation field?'
    }
  },
  DRIVER_LIST_PAGE: {
    TABLE_TITLE: 'Driver List',
    ADD_DESIGNATION_BUTTON: 'Add Designation',
    SEARCH_BAR_PLACEHOLDER: 'Search',
    DRIVER_TABLE: {
      ORDER: 'Order',
      ID: 'Id',
      NAME: 'Name',
      EMAIL: 'Email',
      MOBILE_NO: 'Mobile No.',
      DESIGNATION: 'Designation',
      NO_DRIVER: 'No Driver To Show'
    },
    MODAL: {
      MODAL_TITLE: 'Delete Designation Field',
      MODAL_MESSAGE: 'Are you sure you want to delete this designation field?'
    }
  },
  BOOKING_PAGE: {
    NO_DATA_FOUND: 'No Booking Details Found'
  },
  DRIVER_DOCUMENT_VERIFICATION_PAGE: {
    TITLE: 'Driver Verification',
    TITLE_DRIVERS_DOCUMENT: 'Driver Documents',
    TITLE_VEHICLE_DOCUMENT: 'Drivers Vehicle Documents',
    DRIVER_NAME: 'Driver Name ',
    APPROVED_DOCUMENT: 'Approved Document',
    REJECTED_DOCUMENT: 'Rejected Document',
    PENDING_FOR__APPROVAL: 'Pending For Approval',
    NO_SUBMITTED: 'Not Submitted Document',
    VEHICLE_MODEL: 'Model',
    VEHICLE_PLATE: 'No. plate',
    VEHICLE_BRAND_NAME: 'Brand ',
    VEHICLE_TYPE: 'Type ',
    APPROVE: 'Approve',
    REJECT: 'Reject',
    NO_DOCUMENT_FOUND: 'No Document Found',
    NO_VEHICLE: 'No Vehicle Found',
    MENTION_REASON: 'Please Mention Reason*'
  },
  DRIVER_DOCUMENT_PAGE: {
    TABLE_TITLE: 'Documents List',
    CREATE_DOCUMENT: 'Create Document',
    DRIVER_DOCUMENT_LIST: {
      Document_NAME: 'Documents',
      EDIT: 'Edit',
      DELETE: 'Delete',
      VIEW: 'View',
      NO_DOCUMENT: 'No Documents found'
    },
    DOCUMENT_ADD_PAGE: {
      TITLE: 'Add Document',
      HAS_NUMBER: 'Doc number ',
      HAS_EXPIRY: 'Doc expiry ',
      IS_REQUIRED: 'Required ',
      IS_CAPTURE_ONLY: 'Captured only '
    },
    DOCUMENT_EDIT_PAGE: {
      TITLE: 'Edit Document'
    },
    DOCUMENT_VIEW_PAGE: {
      TITLE: 'View Document'
    }
  },
  RESET_PASSWORD_PAGE: {
    LINK_EXPIRED: 'Link is expired',
    REDIRECT_LINK_LINE: 'to request again for change password',
    REDIRECT_LINK: 'Click here '
  },
  MEDIA_PAGE: {
    TITLE: 'Media',
    DROP_AREA_TITLE1: 'Drag and drop file here',
    DROP_AREA_TITLE2: 'Or click here to select files',
    ATTACHMENT_DETAILS: 'Attachment Details',
    IMAGE_TYPE: 'Image Type',
    CATEGORY: {
      GENERAL: 'General',
      VEHICLE: 'Vehicle',
      DRIVER: 'Driver',
      USER: 'User',
      ORGANIZATION: 'Organization',
      ADMIN: 'Admin',
      DISPATCHER: 'Dispatcher'
    }
  },
  ORGANIZATION_SHIFT_SETTINGS_PAGE: {
    TITLE: 'Drivers Shift Settings',
    ADD_SHIFT: 'Add Shift',
    ASSIGN_PRICE: 'Set Price',
    TABLE: {
      TITLE: 'Shift',
      START_TIME: 'Start Time',
      END_TIME: 'End Time',
      ACTIVE_STATUS: 'Active Status',
      NO_DATA_FOUND: 'No Shift to Show'
    },
    MODAL_TITLE: 'Pick Shift Hours'
  },
  ORGANIZATION_SHIFT_PRICE_SETTINGS_PAGE: {
    TITLE: 'Set Shift Price'
  },
  ORGANIZATION_SETTINGS: {
    TITLE: 'Organization Settings',
    BOOKING_FOR_OTHER: 'For Other',
    HAS_COUPON_CODE: 'Coupon Code',
    HAS_OUTSTATIONS: 'Out Stations',
    HAS_POOL: 'Pools',
    HAS_RENTAL: 'Rental',
    HAS_MULTIPLE_STOP: 'Multiple Stops',
    HAS_SCHEDULE_BOOKINGS: 'Schedule Bookings',
    MULTIPLE_BOOKINGS: 'Multiples Bookings',
    SWITCH_DELAY: 'Switch Delay',
    HOME_BANNER_IS_CLICKABLE: 'Home Banner Is Clickable',
    PICK_BACKGROUND_COLOR: 'Banner Background Color',
    PICK_TEXT_COLOR: 'Banner Text Color'

  },
  ACTION_DROPDOWN: {
    TITLE: 'Action',
    VIEW: 'View',
    EDIT: 'Edit',
    DELETE: 'Delete',
    RIGHTS: 'Rights',
    BLOCK: 'Block',
    VERIFICATION: 'Verification',
    DESIGNATION: 'Designation',
    ACTIVE: 'Active',
    SHIFT_PRICE_SETTINGS: 'Price Settings'
  },
  FORMIK_ERROR: {
    ADD_DESIGNATION: {
      DESIGNATION_REQUIRED: 'Please enter Designation you want to assign',
      DUTY_REQUIRED: 'Please assign the Duty to the Designation'
    },
    AUTH_VALIDATION: {
      EMAIL_REQUIRED: 'Email is required',
      EMAIL_MAX_LENGTH: 'Email is required',
      EMAIL_INVALID: 'Please enter a valid email address',
      EMAIL_LOWER_CASE: 'only lowercase letters allowed',
      PASSWORD_REQUIRED: 'Please set your password',
      CONFIRM_PASSWORD_REQUIRED: 'Please confirm your password',
      PASSWORD_MAX_LENGTH: 'Password must not have more than 18 characters',
      PASSWORD_MIN_LENGTH: 'Provide least 6 characters',
      PASSWORD_SHOULD_MATCHED: 'Password must match',
      REGISTER_FORM: {
        FIRST_NAME_REQUIRED: 'First name is required',
        FIRST_NAME_MAX_LENGTH: 'First name must be at most 15 characters',
        MIDDLE_NAME_MAX_LENGTH: 'Middle name must be at most 15 characters',
        LAST_NAME_REQUIRED: 'Last name is required',
        LAST_NAME_MAX_LENGTH: 'Last name must be at most 15 characters',
        SELECT_COUNTRY: 'Please select a country',
        SELECT_STATE: 'Please select a state',
        SELECT_CITY: 'Please select a city',
        SELECT_COUNTRY_CODE: 'Country code',
        ADDRESS_MAX_LENGTH: 'Address must be at most 500 characters',
        DATE_OF_BIRTH_INVALID: 'Date is invalid',
        MOBIL_NO_REQUIRED: 'Mobile number is required',
        MOBILE_NO_TYPE: 'Mobile should be a number value.',
        MOBILE_NO_MIN_LENGTH: 'number should be at least 6 characters long',
        MOBILE_NO_MAX_LENGTH: 'number should be at most 12 characters long'
      }
    },
    DRIVER_DOCUMENT: {
      DOC_NAME_REQUIRED: 'Document name is required',
      DOC_NAME_MAX_LENGTH: 'Max length should not exceed 50 words',
      DOC_DISC_REQUIRED: 'Please Provide short description',
      DOC_DISC_MAX_LENGTH: 'Max length should not exceed 250 words',
      DOC_SIZE_REQUIRED: 'Give max size of document should be',
      DOC_MAX_SIZE: 'Document size should not exceed to 30MB',
      DOC_FORMAT_REQUIRED: 'Please select the type of document'

    },
    VEHICLE_ADD: {
      VEHICLE_NAME_REQUIRED: 'Vehicle name is required',
      VEHICLE_NAME_LENGTH: 'Max length should not exceed 50 words',
      VEHICLE_DESCRIPTION_LENGTH: 'Max length should not exceed 150 words',
      VEHICLE_CAPACITY_REQUIRED: 'Please enter seating capacity of vehicle',
      VEHICLE_CAPACITY_MAX: 'Max capacity should not be greater then 10',
      VEHICLE_CAPACITY_MIN: 'Minimum length should not be less then 1',
      VEHICLE_TYPE_REQUIRED: 'Please enter vehicle type',
      VEHICLE_TYPE_LENGTH: 'Max length should not exceed 50 words',
      VEHICLE_IMAGE_VALID_URL: 'Please enter a valid image URL'
    },
    DRIVER_ADD: {
      CITY_REQUIRED: 'Please enter a city name',
      GENDER_REQUIRED: 'Please enter a gender'
    },
    GENERAL_FORMIK_ERROR: {
      NAME_REQUIRED: 'Name is required',
      NAME_MAX_LENGTH: 'Max length should not exceed 50 words',
      DESCRIPTION_REQUIRED: 'Description is required',
      DESCRIPTION_MAX_LENGTH: 'You have exceed to max limits of words',
      EMAIL_REQUIRED: 'Email is required',
      EMAIL_MAX_LENGTH: 'Email is required',
      EMAIL_INVALID: 'Please enter a valid email address',
      DATE_OF_BIRTH_INVALID: 'Date is invalid',
      DATE_OF_BIRTH_REQUIRED: 'Please enter your birth date',
      MOBILE_NO_REQUIRED: 'Mobile is required',
      MOBILE_NO_MAX_LENGTH: 'Invalid Mobile Number',
      MOBILE_NO_MIN_LENGTH: 'Invalid Mobile Number'
    }
  },
  ACTIVATION_STATUS: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    PENDING: 'Pending',
    BLOCKED: 'Blocked'
  },
  FOOTER_LINE: `Copyright © ${new Date().getFullYear()} All rights reserved.`
}
