import React, { useEffect, useContext } from 'react'
import UserContext from '../../../Context/UserContext'
import { PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { useTranslation } from 'react-i18next'
import ListMedia from '../../../Components/Media/ListMedia'

type Props = {}

const Media = (props: Props) => {
  const { setArrayOfBreadCrumb } = useContext(UserContext)
  const { t } = useTranslation()
  useEffect(() => {
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.MEDIA')}`, url: '' }
    ])
  }, [])
  return (
    <ListMedia />
  )
}

export default Media
