/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Card, Col, Form, FormGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import EditVehicleDetailsModal, { VehicleAllDetailsModel } from '../../../../Components/Common/EditVehicleDetailsModal'
import GetSingleVehicleDocuments, { DocumentDetail } from '../../../../Components/Common/GetSingleVehicleDocuments'
import InnerLoader from '../../../../Components/Common/InnerLoader'
import UserContext from '../../../../Context/UserContext'
import { getDriversDocument, putUpdateDriverStatus } from '../../../../Services/DriverService'
import { getVehicleDetails, patchEditVehicleDetails, putUpdateVehicleStatus } from '../../../../Services/VehicleService'
import { DRIVER_ALL_DOCUMENT_STATUS, DRIVER_VEHICLE_STATUS, PROJECT_ROUTES_ENUMS } from '../../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../../utils/utils'
import { VehicleDetailsModel } from '../../../Models/CommonModel'

type Props = {}

const DriverDocumentVerification = (props: Props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { setArrayOfBreadCrumb, setIsFetching, isFetching } = useContext(UserContext)
  const [driverDocumentList, setDriverDocumentList] = useState([] as DocumentDetail[])
  const [driverVehicle, setDriverVehicle] = useState<Array<VehicleDetailsModel>>([])
  const [driverName, setDriverName] = useState('')
  const [driverId, setDriverId] = useState('')
  const [driverDocStatus, setDriverDocStatus] = useState<Number>()

  // const [modalBody, setModalBody] = useState({} as { imageUrl: string })

  // ******** Edit Modal
  const [showEditModal, setShowEditModal] = useState(false)
  const [vehicleIdState, setVehicleIdState] = useState('')
  const [EditModalBody, setEditModalBody] = useState({} as VehicleAllDetailsModel)

  /**
         * @function getAllDocuments
         * @return Promise<void>
         * @description This function fetch all the documents of  driver and vehicle.
         */
  const getAllDocuments = async () => {
    setIsFetching(true)
    try {
      if (!location.state) {
        // will redirect on refresh of page
        navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE)
      } else {
        // @ts-ignore
        const driverId = location.state.id
        setDriverId(driverId)
        // @ts-ignore
        setDriverName(location.state.name)
        // @ts-ignore
        const response = await getDriversDocument(driverId)
        if (response.data) {
          setDriverDocStatus(response.data.documentStatus)
          setDriverDocumentList(response.data.driverDocuments)
          setDriverVehicle(response.data.vehicleDocuments)
          setIsFetching(false)
        } else {
          throw response
        }
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  useEffect(() => {
    getAllDocuments()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.DRIVER_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE },
      { name: `${t('PAGE_NAME.DRIVER_VERIFICATION')}`, url: '' }
    ])
    return () => { }
  }, [])
  // To close the Modal and update the location state so we will not redirect to previous pages
  const closeModal = () => {
    setShowEditModal(false)
    location.state = { id: driverId, name: driverName, docVerificationStatus: driverDocStatus }
    // setModalBody({} as { imageUrl: string, documentNumber: string })
    setEditModalBody({} as VehicleAllDetailsModel)
  }

  /**
     * @function editVehicleDetails
     * @return Promise<void>
     * @param vehicleI
     * @description This function will open the modal while clicking on Edit button and fetch the vehicle details to show in modal for edit it.
     */
  const editVehicleDetails = async (vehicleId: string) => {
    setIsFetching(true)
    try {
      const response = await getVehicleDetails(vehicleId)
      if (response.data) {
        setEditModalBody(response.data)
        setIsFetching(false)
        setVehicleIdState(vehicleId)
        setShowEditModal(true)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ editVehicleDetails ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }
  /**
   * @function handleEditVehicleDetailsSubmit
   * @return Promise<void>
   * @param vehicleI
   * @param vehicleDetails
   * @description This function will update the vehicle details after clicking on submit button placed in modal which will open after clicking on edit button in vehicle details.
   */

  const handleEditVehicleDetailsSubmit = async (vehicleId: string, vehicleDetails: VehicleAllDetailsModel) => {
    setIsFetching(true)
    try {
      const response = await patchEditVehicleDetails(vehicleId, vehicleDetails)
      if (response.statusCode === 200) {
        setIsFetching(false)
        setShowEditModal(false)
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
        location.state = { id: driverId, name: driverName, docVerificationStatus: driverDocStatus }
        getAllDocuments()
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ handleEditVehicleDetailsSubmit ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  const handleVehicleActiveSwitch = (switchState: boolean, vehicleId: string) => {
    if (switchState) {
      const updatedVehicle = driverVehicle.map(vehicle => {
        if (vehicle.vehicleId === vehicleId && vehicle.documents.length > 0) {
          let requiredDocuments = 0
          const filteredDocuments = vehicle.documents.map(document => {
            if (document.status.toString() === '1') {
              requiredDocuments++
              return vehicle
            } else if (document.isRequired) {
              requiredDocuments++
              return null
            } else {
              return null
            }
          })
          if (_.compact(filteredDocuments).length === requiredDocuments && _.compact(filteredDocuments).length > 0) {
            vehicle.vehicleStatus = DRIVER_VEHICLE_STATUS.APPROVED
            updateStatus(vehicleId, DRIVER_VEHICLE_STATUS.APPROVED, 'vehicle')
          }
        }
        return vehicle
      })
      setDriverVehicle(updatedVehicle)
    } else {
      const updatedVehicle = driverVehicle.map(vehicle => vehicle.vehicleId === vehicleId ? { ...vehicle, vehicleStatus: DRIVER_VEHICLE_STATUS.SUBMITTED } : vehicle)
      updateStatus(vehicleId, DRIVER_VEHICLE_STATUS.SUBMITTED, 'vehicle')
      setDriverVehicle(updatedVehicle)
    }
  }

  const handleDriverActiveSwitch = (switchState: boolean, driverId: string) => {
    console.log('🚀 ~ file: DriverDocumentVerification.tsx:188 ~ handleDriverActiveSwitch ~ driverId', driverId, switchState)
    const switchStatus = switchState ? DRIVER_ALL_DOCUMENT_STATUS.APPROVED : DRIVER_ALL_DOCUMENT_STATUS.PENDING_VERIFICATION
    setDriverDocStatus(switchStatus)
    // @ts-ignore
    location.state.docVerificationStatus = switchStatus
    updateStatus(driverId, switchStatus, 'driver')
    // return true
    /* if (switchState) {
      let requiredDocuments = 0
      const updatedDriverDoc = driverDocumentList.map(document => {
        if (document.status === DRIVER_ALL_DOCUMENT_STATUS.APPROVED && document.isRequired) {
          requiredDocuments++
          return document
        } else if (document.isRequired) {
          requiredDocuments++
          return null
        } else {
          return null
        }
      })
      if (_.compact(updatedDriverDoc).length === requiredDocuments && _.compact(updatedDriverDoc).length > 0) {
        // @ts-ignore
        location.state.docVerificationStatus = DRIVER_ALL_DOCUMENT_STATUS.APPROVED
        setDriverDocStatus(DRIVER_ALL_DOCUMENT_STATUS.APPROVED)
        // @ts-ignore
        setDriverDocumentList(updatedDriverDoc)
        updateStatus(driverId, DRIVER_ALL_DOCUMENT_STATUS.APPROVED, 'driver')
      }
    } else {
      const updatedDriverDoc = driverDocumentList.map(driver => driver.driverDocId === driverId ? { ...driver, status: DRIVER_ALL_DOCUMENT_STATUS.PENDING_VERIFICATION } : driver)
      // @ts-ignore
      location.state.docVerificationStatus = DRIVER_ALL_DOCUMENT_STATUS.PENDING_VERIFICATION
      setDriverDocumentList(updatedDriverDoc)
      updateStatus(driverId, DRIVER_ALL_DOCUMENT_STATUS.PENDING_VERIFICATION, 'driver')
      setDriverDocStatus(DRIVER_ALL_DOCUMENT_STATUS.PENDING_VERIFICATION)
    } */
  }

  const updateStatus = async (vehicleId: string, status: number, typeOfSwitch: string) => {
    setIsFetching(true)
    try {
      let response
      if (typeOfSwitch === 'vehicle') {
        response = await putUpdateVehicleStatus(vehicleId, status)
      } else {
        response = await putUpdateDriverStatus(vehicleId, status)
      }
      if (response.statusCode === 200) {
        setIsFetching(false)
        const responseMessage = fetchResponseMessage(response.responseCode)
        // getAllDocuments()
        toast.success(responseMessage)
      } else {
        throw response
      }
    } catch (error) {
      console.error('🔥 ~ updateStatus ~ error', error)
      setIsFetching(false)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  return (
    <>
      <EditVehicleDetailsModal
        show={showEditModal}
        // TODO: from translation
        modalTitle={'Edit Vehicle Details'}
        onHide={closeModal}
        vehicleId={vehicleIdState}
        handleSubmit={handleEditVehicleDetailsSubmit}
        modalBodyData={EditModalBody}
        deActiveVehicle={(vehicleId, status) => {
          updateStatus(vehicleId, status, 'vehicle')
          setShowEditModal(false)
        }}
      />
      {isFetching ?
        <InnerLoader /> :
        <>
          <Row>
            <Col lg={6} >
              <Col sm={12} className='card form-card'>
                <div className='mt-2'>
                  <FormGroup className='mb-5 mt-4 px-4 switch-wrapper d-flex justify-content-between'>
                    <h3 className='align-self-center mb-0 mt-1'>{`${driverName.split(' ')[0]}'s Documents`}</h3>
                    <Form.Check type="switch" name="isDefault" className="switch ms-3" checked={driverDocStatus === DRIVER_ALL_DOCUMENT_STATUS.APPROVED} onChange={(event) => handleDriverActiveSwitch(event.target.checked, driverId)} />
                  </FormGroup>
                  {driverDocumentList.length > 0 ?
                    <GetSingleVehicleDocuments documents={driverDocumentList} documentType='driverDocument' classes={'px-4'} rerenderAllDocument={getAllDocuments} />
                    : <h6 className='w-100 text-center'>{`${t('DRIVER_DOCUMENT_VERIFICATION_PAGE.NO_DOCUMENT_FOUND')}`}</h6>}
                </div>
              </Col>
            </Col>
            <Col lg={6}>
              <Col sm={12} className='card form-card mt-5 mt-lg-0' >
                <div className='mt-2'>
                  {/* TODO: form translation */}
                  <h3 className='d-flex mb-5 mt-4 px-4'>
                    {/* TODO: from translation */}
                    {`${driverName.split(' ')[0]}'s Vehicles`}
                  </h3>
                  <div className='mt-3 mb-3 row'>
                    {driverVehicle.length > 0 ? driverVehicle.map((car: any, carIndex: number) => {
                      return (
                        <React.Fragment key={carIndex}>
                          <Card.Body className='h-100 px-4 py-0'>
                            <Accordion className={'mb-3  mx-2 document-accordion'}>
                              <Accordion.Item eventKey={`${carIndex + car.vehicleBrand}`}>
                                <Accordion.Header className='justify-content-between px-2'>
                                  <p className='mb-0 text-overflow-none d-flex'>{car.vehicleBrand + '  ' + car.vehicleModel}
                                  </p>
                                  <p className={`ms-auto mb-0 font-weight-bold ${car.vehicleStatus === DRIVER_VEHICLE_STATUS.APPROVED ? 'text-success' : 'text-muted'}`}>{car.vehicleStatus === DRIVER_VEHICLE_STATUS.APPROVED ? 'Approved' : 'Pending'}</p>
                                </Accordion.Header>
                                <Accordion.Body className='pt-3'>
                                  <div className='d-flex flex-row justify-content-between px-2'>
                                    {/* <Card.Img className='mb-3' src={car.vehicleIcon} style={{ width: '10rem', cursor: 'pointer' }} onClick={() => editVehicleDetails(car.vehicleId)}></Card.Img> */}
                                    <div className='d-flex'>
                                      {/* <div className='d-flex w-100 mb-2'>
                                      <h6>{`${t('DRIVER_DOCUMENT_VERIFICATION_PAGE.VEHICLE_BRAND_NAME')} :   `}</h6>
                                      <p>{car.vehicleBrand}</p>
                                    </div> */}
                                      <div className='d-flex flex-column w-100 mb-2'>
                                        <p className='m-0 text-overflow-none title-name'>{`${t('DRIVER_DOCUMENT_VERIFICATION_PAGE.VEHICLE_PLATE')} :   `}</p>
                                        <p className='mb-0 mt-1 font-weight-bold text-secondary title-value text-overflow-none'>{car.vehiclePlate}</p>
                                      </div>
                                      {/* <div className='ms-5 d-flex w-100 mb-2 flex-column'>
                                      <p className='m-0 text-overflow-none title-name'>{`${t('DRIVER_DOCUMENT_VERIFICATION_PAGE.VEHICLE_TYPE')} :   `}</p>
                                      <p className='mb-0 mt-1 font-weight-bold text-secondary title-value text-overflow-none'>{car.vehicleBrand}</p>
                                    </div> */}
                                    </div>
                                    <div className='d-flex flex-column align-items-end justify-content-between'>
                                      <FormGroup>
                                        <Form.Check type="switch" name="isDefault" className="switch ms-3" checked={car.vehicleStatus === DRIVER_VEHICLE_STATUS.APPROVED} onChange={(event) => handleVehicleActiveSwitch(event.target.checked, car.vehicleId)} />
                                      </FormGroup>
                                      <span className='mdi mdi-pencil ms-3 mt-4 cursor-p' onClick={() => editVehicleDetails(car.vehicleId)}></span>
                                    </div>

                                  </div>
                                  <GetSingleVehicleDocuments documents={car.documents} documentType='vehicleDocument' classes={'p-0'} rerenderAllDocument={getAllDocuments} />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Card.Body>
                        </React.Fragment>)
                    }) : <h6 className='w-100 text-center my-5'>{`${t('DRIVER_DOCUMENT_VERIFICATION_PAGE.NO_VEHICLE')}`}</h6>}
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </>
      }
    </>
  )
}

export default DriverDocumentVerification
