/* eslint-disable indent */
import { DesignationResponseObject, PermissionsObjectModel, SidebarMenuModel } from '../Pages/Models/CommonModel'
import { DROPDOWN_OPTIONS_ID, MODULE_ID, PROJECT_ROUTES_ENUMS } from './enums/enums'
import i18n from '../i18n'
import _ from 'lodash'
import Designation from '../Pages/AdminMenu/Designation/Designation'
import React from 'react'
import AdminDetails from '../Pages/AdminMenu/AdminList/AdminDetails'
import AdminList from '../Pages/AdminMenu/AdminList/AdminList'
import AddDesignation from '../Pages/AdminMenu/Designation/AddDesignation/AddDesignation'
import EditDesignation from '../Pages/AdminMenu/Designation/EditDesignation/EditDesignation'
import ViewDesignation from '../Pages/AdminMenu/Designation/ViewDesignation/ViewDesignation'
import DesignationRights from '../Pages/AdminMenu/DesignationRights'
import AddDriverDocument from '../Pages/DriverMenu/DriverDocument/AddDriverDocument'
import DriverDocument from '../Pages/DriverMenu/DriverDocument/DriverDocument'
import EditDriverDocument from '../Pages/DriverMenu/DriverDocument/EditDriverDocument'
import AddDriver from '../Pages/DriverMenu/DriverList/AddDriver'
import DriverDocumentVerification from '../Pages/DriverMenu/DriverList/DriverDocumentVerification/DriverDocumentVerification'
import DriverList from '../Pages/DriverMenu/DriverList/DriverList'
import EditDriver from '../Pages/DriverMenu/DriverList/EditDriver'
import ViewDriverDetails from '../Pages/DriverMenu/DriverList/ViewDriver'
import AddShift from '../Pages/OrganizationMenu/ShiftSettings/AddShift'
import OrganizationShiftPrice from '../Pages/OrganizationMenu/ShiftSettings/OrganizationShiftPrice/OrganizationShiftPrice'
import ShiftSettings from '../Pages/OrganizationMenu/ShiftSettings/ShiftSettings'
import EditUser from '../Pages/UserMenu/UserList/EditUser'
import UserList from '../Pages/UserMenu/UserList/UserList'
import ViewUser from '../Pages/UserMenu/UserList/ViewUser'
import AddVehicleDocument from '../Pages/VehicleMenu.tsx/VehicleDocument/AddVehicleDocument'
import EditVehicleDocument from '../Pages/VehicleMenu.tsx/VehicleDocument/EditVehicleDocument'
import VehicleDocument from '../Pages/VehicleMenu.tsx/VehicleDocument/VehicleDocument'
import ViewVehicleDocument from '../Pages/VehicleMenu.tsx/VehicleDocument/ViewVehicleDocument'
import AddVehicle from '../Pages/VehicleMenu.tsx/VehicleList/AddVehicle'
import EditVehicle from '../Pages/VehicleMenu.tsx/VehicleList/EditVehicle'
import VehicleBrand from '../Pages/VehicleMenu.tsx/VehicleList/VehicleBrand'
import VehicleList from '../Pages/VehicleMenu.tsx/VehicleList/VehicleList'
import ViewVehicle from '../Pages/VehicleMenu.tsx/VehicleList/ViewVehicle'
import OrganizationsAppSettings from '../Pages/OrganizationMenu/OrganizationsAppSettings/OrganizationsAppSettings'
import Media from '../Pages/OrganizationMenu/Media/Media'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserNurse } from '@fortawesome/free-solid-svg-icons'
import Bookings from '../Pages/Bookings/Bookings'
import BookingDetails from '../Pages/Bookings/BookingDetails'

/**
 * @function getTreeFromFlatData
 * @return object
 * @param flatArray [flat array of object]
 * @description this function will tack the array of object which is single dimension of you can say it is a flat and convert it in to Tree structure of parent child according to id and parent id.
 */
export const getTreeFromFlatData = (flatArray: DesignationResponseObject) => {
  const obj = flatArray
  // TODO: need to Remove any
  function mapParentChild (parent: any, child: any) {
    if ('children' in parent && parent.children) {
      parent.children.push(child)
    } else {
      parent.children = [child]
    }
  }

  for (let i = 0; i < obj.length - 1; i++) {
    const arr1 = obj[i]
    for (let j = i + 1; j < obj.length; j++) {
      const arr2 = obj[j]
      if (arr1.moduleId === arr2.parentModuleId) {
        mapParentChild(arr1, arr2)
      } else if (arr2.moduleId === arr1.parentModuleId) {
        mapParentChild(arr2, arr1)
      }
    }
  }
  const done = obj.filter((module: any) => module.parentModuleId === null)
  return done
}

export const isAuthRoutes = (url: string) => {
  return Object.values(PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES).includes(url)
}

export const RateMetersToKm = (rate: number) => rate * 1000
export const RateKmToMeters = (rate: number) => rate / 1000

export const RateMetersToMiles = (rate: number) => rate * 1610
export const RateMilesToMeters = (rate: number) => rate / 1610

export const fetchResponseMessage = (responseCode: number) => {
  switch (responseCode) {
    // ************************* Auth ****************************************** //
    case 2001:
      return `${i18n.t('TOAST_ERROR.AUTH_ERROR.LOGIN_SUCCESS')}`
    case 2003:
      return `${i18n.t('TOAST_ERROR.AUTH_ERROR.ACCOUNT_CREATED')}`
    case 2004:
      return `${i18n.t('TOAST_ERROR.AUTH_ERROR.INVALID_PASSWORD')}`
    case 2006:
      return `${i18n.t('TOAST_ERROR.AUTH_ERROR.ACCOUNT_NOT_FOUND')}`
    case 2007:
      return `${i18n.t('TOAST_ERROR.AUTH_ERROR.PASSWORD_RESET_SUCCESS')}`
    case 2008:
      return `${i18n.t('TOAST_ERROR.AUTH_ERROR.LOGOUT_SUCCESS')}`
    case 2009:
      return `${i18n.t('TOAST_ERROR.AUTH_ERROR.LOGOUT_FAILED')}`
    case 2011:
      return `${i18n.t('TOAST_ERROR.AUTH_ERROR.RESET_PASSWORD_LINK_EXPIRED')}`
    case 2013:
      return `${i18n.t('TOAST_ERROR.AUTH_ERROR.RESET_PASSWORD_LINK_SENT')}`
    case 2015:
      return `${i18n.t('TOAST_ERROR.AUTH_ERROR.EMAIL_EXIST')}`
    case 2016:
      return 'Mobile No. already exists.'

    /* // ************************* Admin  Menu's ****************************************** // */
    case 6010:
      return `${i18n.t('TOAST_ERROR.ADMIN_MENUS_ERROR.ADMIN_LIST_FETCH_SUCCESS')}`
    case 6011:
      return `${i18n.t('TOAST_ERROR.ADMIN_MENUS_ERROR.ADMIN_DETAIL_FETCH_SUCCESS')}`
    case 6012:
      return `${i18n.t('TOAST_ERROR.ADMIN_MENUS_ERROR.ADMIN_UPDATE_SUCCESS')}`
    case 6013:
      return `${i18n.t('TOAST_ERROR.ADMIN_MENUS_ERROR.ADMIN_STATUS_UPDATE_SUCCESS')}`

    // ************************* Driver Menu's ****************************************** //

    case 6021:
      return `${i18n.t('TOAST_ERROR.DRIVER_MENU_ERROR.DRIVER_DOC_CREATED')}`
    case 6022:
      return `${i18n.t('TOAST_ERROR.DRIVER_MENU_ERROR.DRIVER_DOC_UPDATED')}`
    case 6024:
      return `${i18n.t('TOAST_ERROR.DRIVER_MENU_ERROR.DRIVER_DOC_UPDATED')}`
    case 6040:
      return `${i18n.t('TOAST_ERROR.DRIVER_MENU_ERROR.DRIVER_LIST_FETCH_SUCCESS')}`
    case 6025:
      return 'Driver Document Status Update Successfully'
    case 6026:
      return 'Driver Status Update Successfully'
    case 6041:
      return 'Driver Document fetched Successfully'

    // ************************* User Menu's ****************************************** //
    case 6032:
      return 'User Status Update Failed'
    case 6030:
      return `${i18n.t('TOAST_ERROR.USER_MENU_ERROR.USER_LIST_FETCH_SUCCESS')}`
    case 6031:
      return 'User  Status Update Successfully'

    // ************************* Vehicle Menu's ****************************************** //
    // TODO: from translation
    case 6056:
      return 'Vehicle Document Update Failed'
    case 6058:
      return 'Vehicle Document updated Failed'
    case 6042:
      return 'Driver Vehicle Update Failed'
    case 6044:
      return 'Vehicle Status updated Failed'
    case 6043:
      return 'Vehicle Status updated successfully'
    case 6050:
      return 'Organization Vehicle Created Successfully'
    case 6051:
      return 'Organization Vehicle Document Created Successfully'
    case 6052:
      return 'Vehicle List Fetched Successfully'
    case 6053:
      return 'Vehicle Detail Fetched Successfully'
    case 6054:
      return 'Vehicle Updated Successfully'
    case 6055:
      return 'Vehicle Deleted Successfully'
    case 6057:
      return 'Vehicle Document Updated Successfully'
    case 6059:
      return 'Vehicle Document List Fetched Successfully'
    case 6060:
      return 'Vehicle Document Detail Fetched Successfully'

    // *************************Organization Menu's Success****************************************** //
    case 6061:
      return 'Organization Shift Details Fetched Successfully'
    case 6063:
      return 'Organization Shift Created Successfully'
    case 6064:
      return 'Organization Shift Updated Successfully'
    case 6065:
      return 'Organization Shift Updated Failed'
    case 6066:
      return 'Organization Shift Deleted Successfully'
    case 6068:
      return 'Vehicle Shift Price Fetched Successfully'
    case 6069:
      return 'Vehicle Shift Price Updated Successfully'
    case 6070:
      return 'Organization Shift price Update Failed'
    case 6071:
      return 'Driver Document Update Successfully'
    case 6072:
      return 'Vehicle Document Status Update Failed'
    case 6073:
      return 'Organization Shift Deleted Successfully'
    case 6074:
      return '?????????????????'
    case 6075:
      return '??????????????????'
    case 6076:
      return 'Shift Status Update Successfully'
    case 6077:
      return 'Shift  Status Update Failed'
    case 6078:
      return '??????????????????'

    // *************************Default response code****************************************** //
    case 1000:
      return 'Success'
    case 1001:
      return 'Failed'
    default:
      if (responseCode > 200) {
        return 'Success'
      } else {
        return `${i18n.t('TOAST_ERROR.DEFAULT_ERROR')}`
      }
  }
}

export const getStatus = (statusCode: number) => {
  switch (statusCode) {
    case -1:
      return `${i18n.t('ACTIVATION_STATUS.BLOCKED')}`
    case 1:
      return `${i18n.t('ACTIVATION_STATUS.ACTIVE')}`
    case 0:
      return `${i18n.t('ACTIVATION_STATUS.INACTIVE')}`
    case -2:
      return `${i18n.t('ACTIVATION_STATUS.PENDING')}`
  }
}

export const sidebarComponents: SidebarMenuModel = [
  {
    menuName: `${i18n.t('SIDEBAR.MENU.MAIN_MENU')}`,
    arrayOfChildrenModuleId: [MODULE_ID.DEFAULT_ACCESS],
    menuData: [
      {
        name: `${i18n.t('SIDEBAR.MENU.DASHBOARD')}`,
        url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE,
        icon: <i className='menu-icon mdi mdi-view-dashboard' />,
        accessType: 'read',
        moduleId: MODULE_ID.DEFAULT_ACCESS,
        arrayOfChildrenModuleId: [MODULE_ID.DEFAULT_ACCESS]
      }
    ]
  },
  {
    menuName: `${i18n.t('SIDEBAR.MENU.USER_MENU')}`,
    arrayOfChildrenModuleId: [MODULE_ID.ADMIN_MENU, MODULE_ID.USER_MENU, MODULE_ID.DRIVER_MENU, MODULE_ID.VEHICLE_MENU],
    menuData: [
      {
        name: `${i18n.t('SIDEBAR.MENU.ADMIN.MENU_TITLE')}`,
        url: '',
        icon: <i className='menu-icon mdi mdi-account-supervisor' />,
        accessType: 'read',
        moduleId: MODULE_ID.ADMIN_MENU,
        arrayOfChildrenModuleId: [MODULE_ID.ADMIN_DESIGNATION, MODULE_ID.ADMIN_DESIGNATION_RIGHTS, MODULE_ID.ADMIN_LIST],
        children: [
          {
            name: `${i18n.t('SIDEBAR.MENU.ADMIN.ADMIN_LIST')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ADMIN_LIST_PAGE,
            accessType: 'read',
            moduleId: MODULE_ID.ADMIN_LIST
          },
          {
            name: `${i18n.t('SIDEBAR.MENU.ADMIN.DESIGNATION')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE,
            accessType: 'read',
            moduleId: MODULE_ID.ADMIN_DESIGNATION
          },
          {
            name: `${i18n.t('SIDEBAR.MENU.ADMIN.CREATE_DESIGNATION')}`,
            url: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE}/add`,
            accessType: 'create',
            moduleId: MODULE_ID.ADMIN_DESIGNATION
          },
          {
            name: `${i18n.t('SIDEBAR.MENU.ADMIN.DESIGNATION_RIGHTS')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_RIGHT_PAGE,
            accessType: 'read',
            moduleId: MODULE_ID.ADMIN_DESIGNATION_RIGHTS
          }
        ]
      },
      {
        name: `${i18n.t('SIDEBAR.MENU.USERS.MENU_TITLE')}`,
        url: '',
        icon: <i className='menu-icon mdi mdi-account' />,
        accessType: 'read',
        moduleId: MODULE_ID.USER_MENU,
        arrayOfChildrenModuleId: [MODULE_ID.USER_LIST],
        children: [
          {
            name: `${i18n.t('SIDEBAR.MENU.USERS.USER_LIST')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.USER_LIST_PAGE,
            accessType: 'read',
            moduleId: MODULE_ID.USER_LIST
          }
        ]
      },
      {
        name: `${i18n.t('SIDEBAR.MENU.DRIVER.MENU_TITLE')}`,
        url: '',
        icon: <FontAwesomeIcon className={'menu-icon'} icon={faUserNurse} />,
        accessType: 'read',
        moduleId: MODULE_ID.DRIVER_MENU,
        arrayOfChildrenModuleId: [MODULE_ID.DRIVER_LIST, MODULE_ID.DRIVER_DOCUMENT],
        children: [
          {
            name: `${i18n.t('SIDEBAR.MENU.DRIVER.DRIVER_LIST')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE,
            accessType: 'read',
            moduleId: MODULE_ID.DRIVER_LIST
          },
          {
            name: `${i18n.t('SIDEBAR.MENU.DRIVER.DRIVER_DOCUMENT_LIST')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_DOCUMENT_PAGE,
            accessType: 'read',
            moduleId: MODULE_ID.DRIVER_DOCUMENT
          },
          {
            name: `${i18n.t('SIDEBAR.MENU.DRIVER.CREATE_DRIVER_DOCUMENT')}`,
            url: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_DOCUMENT_PAGE}/add`,
            accessType: 'create',
            moduleId: MODULE_ID.DRIVER_DOCUMENT
          }
        ]
      },
      {
        name: `${i18n.t('SIDEBAR.MENU.VEHICLE.MENU_TITLE')}`,
        url: '',
        icon: <i className='menu-icon mdi mdi-car' />,
        accessType: 'read',
        moduleId: MODULE_ID.VEHICLE_MENU,
        arrayOfChildrenModuleId: [MODULE_ID.VEHICLE_LIST, 101],
        children: [
          {
            name: `${i18n.t('SIDEBAR.MENU.VEHICLE.VEHICLE_LIST')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE,
            accessType: 'read',
            moduleId: MODULE_ID.VEHICLE_LIST
          },
          {
            name: 'Create Vehicle',
            url: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE}/add`,
            accessType: 'create',
            moduleId: MODULE_ID.VEHICLE_LIST
          },
          {
            name: `${i18n.t('SIDEBAR.MENU.VEHICLE.VEHICLE_BRAND')}`,
            url: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_BRAND_PAGE}`,
            accessType: 'read',
            moduleId: MODULE_ID.DEFAULT_ACCESS
          }
        ]
      }
    ]
  },
  {
    menuName: `${i18n.t('SIDEBAR.MENU.ORGANIZATION_MENU')}`,
    arrayOfChildrenModuleId: [MODULE_ID.ORGANIZATION],
    menuData: [
      {
        name: `${i18n.t('SIDEBAR.MENU.ORGANIZATION.MENU_TITLE')}`,
        url: '',
        icon: <i className='menu-icon mdi mdi-office-building' />,
        accessType: 'read',
        moduleId: MODULE_ID.ORGANIZATION,
        arrayOfChildrenModuleId: [MODULE_ID.ORGANIZATION_APP_SETTINGS, MODULE_ID.SHIFT, MODULE_ID.MEDIA],
        children: [
          {
            name: `${i18n.t('SIDEBAR.MENU.ORGANIZATION.APP_SETTINGS')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_APP_SETTINGS_PAGE,
            accessType: 'read',
            moduleId: MODULE_ID.ORGANIZATION_APP_SETTINGS
          },
          {
            name: `${i18n.t('SIDEBAR.MENU.ORGANIZATION.SHIFT_SETTING')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_SHIFT_SETTINGS,
            accessType: 'read',
            moduleId: MODULE_ID.SHIFT
          },
          {
            // TODO: from translation
            name: 'Create Shift',
            url: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_SHIFT_SETTINGS}/add`,
            accessType: 'create',
            moduleId: MODULE_ID.SHIFT
          },
          {
            name: `${i18n.t('SIDEBAR.MENU.ORGANIZATION.MEDIA')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_MEDIA,
            accessType: 'read',
            moduleId: MODULE_ID.MEDIA
          }
        ]
      }
    ]
  },
  {
    menuName: `${i18n.t('SIDEBAR.MENU.BOOKING_MENU')}`,
    arrayOfChildrenModuleId: [MODULE_ID.BOOKING],
    menuData: [
      {
        name: `${i18n.t('SIDEBAR.MENU.BOOKING.MENU_TITLE')}`,
        url: '',
        icon: <i className='menu-icon mdi mdi-office-building' />,
        accessType: 'read',
        moduleId: MODULE_ID.BOOKING,
        arrayOfChildrenModuleId: [MODULE_ID.BOOKING],
        children: [
          {
            name: `${i18n.t('SIDEBAR.MENU.BOOKING.DETAILS')}`,
            url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.BOOKING_PAGE,
            accessType: 'read',
            moduleId: MODULE_ID.BOOKING
          }
        ]
      }
    ]
  }
]

export const getDropdownTitle = (dropDownTitle: string, moduleID: number, permissionsObject: PermissionsObjectModel[]) => {
  switch (dropDownTitle) {
    case 'view':
      if (_.find(permissionsObject, { moduleId: moduleID })?.read) {
        return { dropDownTitle: `${i18n.t('ACTION_DROPDOWN.VIEW')}`, titleId: DROPDOWN_OPTIONS_ID.VIEW }
      } else {
        return false
      }
    case 'edit':
      if (_.find(permissionsObject, { moduleId: moduleID })?.update) {
        return { dropDownTitle: `${i18n.t('ACTION_DROPDOWN.EDIT')}`, titleId: DROPDOWN_OPTIONS_ID.EDIT }
      } else {
        return false
      }
    case 'delete':
      if (_.find(permissionsObject, { moduleId: moduleID })?.delete) {
        return { dropDownTitle: `${i18n.t('ACTION_DROPDOWN.DELETE')}`, titleId: DROPDOWN_OPTIONS_ID.DELETE }
      } else {
        return false
      }
    case 'rights':
      if (_.find(permissionsObject, { moduleId: moduleID })?.update) {
        return { dropDownTitle: `${i18n.t('ACTION_DROPDOWN.RIGHTS')}`, titleId: DROPDOWN_OPTIONS_ID.RIGHTS }
      } else {
        return false
      }
    case 'block':
      if (_.find(permissionsObject, { moduleId: moduleID })?.delete) {
        return { dropDownTitle: `${i18n.t('ACTION_DROPDOWN.BLOCK')}`, titleId: DROPDOWN_OPTIONS_ID.BLOCK }
      } else {
        return false
      }
    case 'active':
      if (_.find(permissionsObject, { moduleId: moduleID })?.delete) {
        return { dropDownTitle: `${i18n.t('ACTION_DROPDOWN.ACTIVE')}`, titleId: DROPDOWN_OPTIONS_ID.ACTIVE }
      } else {
        return false
      }
    case 'verification':
      if (_.find(permissionsObject, { moduleId: moduleID })?.read) {
        return { dropDownTitle: `${i18n.t('ACTION_DROPDOWN.VERIFICATION')}`, titleId: DROPDOWN_OPTIONS_ID.VERIFICATION }
      } else {
        return false
      }
    case 'designation':
      if (_.find(permissionsObject, { moduleId: moduleID })?.update) {
        return { dropDownTitle: `${i18n.t('ACTION_DROPDOWN.DESIGNATION')}`, titleId: DROPDOWN_OPTIONS_ID.DESIGNATION }
      } else {
        return false
      }
    case 'price settings':
      if (_.find(permissionsObject, { moduleId: moduleID })?.update) {
        return { dropDownTitle: `${i18n.t('ACTION_DROPDOWN.SHIFT_PRICE_SETTINGS')}`, titleId: DROPDOWN_OPTIONS_ID.PRICE_SETTINGS }
      } else {
        return false
      }
  }
}

type RoutesArray = {
  PATH: string
  PERMISSION_ARRAY: ('create' | 'read' | 'update' | 'delete')[]
  PERMISSION_MODULE_ID: number
  COMPONENT: React.ReactNode
}[]
export const ROUTES_ARRAY: RoutesArray = [
  { PATH: '/admin/designation-create', PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.ADMIN_DESIGNATION, COMPONENT: <Designation /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.ADMIN_DESIGNATION, COMPONENT: <Designation /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE}/add`, PERMISSION_ARRAY: ['create'], PERMISSION_MODULE_ID: MODULE_ID.ADMIN_DESIGNATION, COMPONENT: <AddDesignation /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE}/edit/:itemId`, PERMISSION_ARRAY: ['update'], PERMISSION_MODULE_ID: MODULE_ID.ADMIN_DESIGNATION, COMPONENT: <EditDesignation /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE}/view/:itemId`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.ADMIN_DESIGNATION, COMPONENT: <ViewDesignation /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_RIGHT_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.ADMIN_DESIGNATION_RIGHTS, COMPONENT: <DesignationRights /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ADMIN_LIST_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.ADMIN_LIST, COMPONENT: <AdminList /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ADMIN_LIST_PAGE}/view/:itemId`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.ADMIN_LIST, COMPONENT: <AdminDetails /> },

  // **************User menu **************** //

  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.USER_LIST_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.USER_LIST, COMPONENT: <UserList /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.USER_LIST_PAGE}/view/:itemId`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.USER_LIST, COMPONENT: <ViewUser /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.USER_LIST_PAGE}/edit/:itemId`, PERMISSION_ARRAY: ['update'], PERMISSION_MODULE_ID: MODULE_ID.USER_LIST, COMPONENT: <EditUser /> },

  // **************Driver menu **************** //

  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.DRIVER_LIST, COMPONENT: <DriverList /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE}/add`, PERMISSION_ARRAY: ['create'], PERMISSION_MODULE_ID: MODULE_ID.DRIVER_LIST, COMPONENT: <AddDriver /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE}/view/:itemId`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.DRIVER_LIST, COMPONENT: <ViewDriverDetails /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE}/edit/:itemId`, PERMISSION_ARRAY: ['update'], PERMISSION_MODULE_ID: MODULE_ID.DRIVER_LIST, COMPONENT: <EditDriver /> },

  // **************Driver Document menu **************** //

  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_DOCUMENT_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.DRIVER_DOCUMENT, COMPONENT: <DriverDocument /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_DOCUMENT_PAGE}/add`, PERMISSION_ARRAY: ['create'], PERMISSION_MODULE_ID: MODULE_ID.DRIVER_DOCUMENT, COMPONENT: <AddDriverDocument /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_DOCUMENT_PAGE}/edit/:itemId`, PERMISSION_ARRAY: ['update'], PERMISSION_MODULE_ID: MODULE_ID.DRIVER_DOCUMENT, COMPONENT: <EditDriverDocument /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_SUBMITTED_DOCUMENT_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.DRIVER_DOCUMENTS_VERIFICATION, COMPONENT: <DriverDocumentVerification /> },

  // **************Vehicle menu **************** //

  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_BRAND_PAGE}`, PERMISSION_ARRAY: ['read', 'update'], PERMISSION_MODULE_ID: MODULE_ID.VEHICLE_MENU, COMPONENT: <VehicleBrand /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.VEHICLE_LIST, COMPONENT: <VehicleList /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE}/add`, PERMISSION_ARRAY: ['create'], PERMISSION_MODULE_ID: MODULE_ID.VEHICLE_LIST, COMPONENT: <AddVehicle /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE}/edit/:itemId`, PERMISSION_ARRAY: ['update'], PERMISSION_MODULE_ID: MODULE_ID.VEHICLE_LIST, COMPONENT: <EditVehicle /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE}/view/:itemId`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.VEHICLE_LIST, COMPONENT: <ViewVehicle /> },

  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.VEHICLE_DOCUMENT, COMPONENT: <VehicleDocument /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE}/add`, PERMISSION_ARRAY: ['create'], PERMISSION_MODULE_ID: MODULE_ID.VEHICLE_DOCUMENT, COMPONENT: <AddVehicleDocument /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE}/edit/:itemId`, PERMISSION_ARRAY: ['update'], PERMISSION_MODULE_ID: MODULE_ID.VEHICLE_DOCUMENT, COMPONENT: <EditVehicleDocument /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE}/view/:itemId`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.VEHICLE_DOCUMENT, COMPONENT: <ViewVehicleDocument /> },

  // **************Organization menu **************** //

  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_APP_SETTINGS_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.ORGANIZATION_APP_SETTINGS, COMPONENT: <OrganizationsAppSettings /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_SHIFT_SETTINGS}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.SHIFT, COMPONENT: <ShiftSettings /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_SHIFT_SETTINGS}/add`, PERMISSION_ARRAY: ['create'], PERMISSION_MODULE_ID: MODULE_ID.SHIFT, COMPONENT: <AddShift /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_MEDIA}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.MEDIA, COMPONENT: <Media /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_SHIFT_PRICE_PAGE}`, PERMISSION_ARRAY: ['read', 'update'], PERMISSION_MODULE_ID: MODULE_ID.ORGANIZATION_SHIFT_PRICE, COMPONENT: <OrganizationShiftPrice /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.BOOKING_PAGE}`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.BOOKING, COMPONENT: <Bookings /> },
  { PATH: `${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.BOOKING_PAGE}/view/:itemId`, PERMISSION_ARRAY: ['read'], PERMISSION_MODULE_ID: MODULE_ID.BOOKING, COMPONENT: <BookingDetails /> }
]
