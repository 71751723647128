import React, { useContext, useEffect, useState } from 'react'
import { Button, FormGroup, FormLabel, Modal, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import UserContext from '../../Context/UserContext'
// import { DRIVER_VEHICLE_STATUS } from '../../utils/enums/enums'
import InnerLoader from './InnerLoader'

export type VehicleAllDetailsModel = {
  colorName: string
  driverId?: string
  isCurrentCar?: Boolean
  make: string
  model: string
  seatCapacity: number
  status?: number
  vehicleBrandName: string
  vehiclePlate: string
}
type Props = {
  show: boolean
  modalTitle: string
  onHide: () => void
  vehicleId: string
  handleSubmit: (documentId: string, updatedVehicleDetails: VehicleAllDetailsModel) => void
  modalBodyData: VehicleAllDetailsModel
  deActiveVehicle: (vehicleId: string, status: number) => void
}

const EditVehicleDetailsModal = (props: Props) => {
  const { t } = useTranslation()
  const { darkMode, isFetching } = useContext(UserContext)
  const [vehicleBrandNameState, setVehicleBrandNameState] = useState('')
  const [vehicleModelState, setVehicleModelState] = useState('')
  const [vehiclePlateState, setVehiclePlateState] = useState('')
  const [vehicleSeatCapacity, setVehicleSeatCapacity] = useState(0)
  const [vehicleMakeYear, setVehicleMakeYear] = useState('')
  const [vehicleColor, setVehicleColorState] = useState('')

  useEffect(() => {
    setVehicleBrandNameState(props.modalBodyData.vehicleBrandName)
    setVehicleModelState(props.modalBodyData.model)
    setVehiclePlateState(props.modalBodyData.vehiclePlate)
    setVehicleSeatCapacity(props.modalBodyData.seatCapacity)
    setVehicleMakeYear(props.modalBodyData.make)
    setVehicleColorState(props.modalBodyData.colorName)

    return () => { }
  }, [props.modalBodyData, props.vehicleId])

  const submitUpdatedDetails = (vehicleId: string) => {
    const updatedVehicleDetails = {
      vehiclePlate: vehiclePlateState,
      colorName: vehicleColor,
      model: vehicleModelState,
      make: vehicleMakeYear,
      seatCapacity: vehicleSeatCapacity,
      vehicleBrandName: vehicleBrandNameState
    }
    props.handleSubmit(vehicleId, updatedVehicleDetails)
  }
  return (
    <>
      <Modal show={props.show} aria-labelledby="contained-modal-title-vcenter" className={darkMode ? 'dark-theme' : ''} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center flex-column align-items-center'>
          {props.modalBodyData ?
            <>
              <FormGroup className='w-100 my-2'>
                <FormLabel>{`${t('INPUT_FIELD_ENUMS.VEHICLE_BRAND.LABEL_NAME')}`}</FormLabel>
                <input type="text" value={vehicleBrandNameState} name={'brand'} className='form-control form-control-lg border-left-0 w-100 mb-1' placeholder={`${t('INPUT_FIELD_ENUMS.VEHICLE_BRAND.PLACEHOLDER')}`}
                  onChange={(event) => setVehicleBrandNameState(event.target.value)} />
              </FormGroup>
              <FormGroup className='w-100 my-2'>
                <FormLabel>{`${t('INPUT_FIELD_ENUMS.VEHICLE_MODEL.LABEL_NAME')}`}</FormLabel>
                <input type="text" value={vehicleModelState} name={'model'} className='form-control form-control-lg border-left-0 w-100 mb-1' placeholder={`${t('INPUT_FIELD_ENUMS.VEHICLE_MODEL.PLACEHOLDER')}`}
                  onChange={(event) => setVehicleModelState(event.target.value)} />
              </FormGroup>
              <FormGroup className='w-100 my-2'>
                <FormLabel>{`${t('INPUT_FIELD_ENUMS.VEHICLE_PLATE.LABEL_NAME')}`}</FormLabel>
                <input type="text" value={vehiclePlateState} name={'plate'} className='form-control form-control-lg border-left-0 w-100 mb-1' placeholder={`${t('INPUT_FIELD_ENUMS.VEHICLE_PLATE.PLACEHOLDER')}`}
                  onChange={(event) => setVehiclePlateState(event.target.value)} />
              </FormGroup>
              <FormGroup className='w-100 my-2'>
                <FormLabel>{`${t('INPUT_FIELD_ENUMS.VEHICLE_SEAT_CAPACITY.LABEL_NAME')}`} </FormLabel>
                <input type="text" value={vehicleSeatCapacity} name={'capacity'} className='form-control form-control-lg border-left-0 w-100 mb-1' placeholder={`${t('INPUT_FIELD_ENUMS.VEHICLE_SEAT_CAPACITY.PLACEHOLDER')}`}
                  onChange={(event) => setVehicleSeatCapacity(+event.target.value)} />
              </FormGroup>
              <FormGroup className='w-100 my-2'>
                <FormLabel>{`${t('INPUT_FIELD_ENUMS.VEHICLE_MAKE_YEAR.LABEL_NAME')}`} </FormLabel>
                <input type="text" value={vehicleMakeYear} name={'makeYear'} className='form-control form-control-lg border-left-0 w-100 mb-1' placeholder={`${t('INPUT_FIELD_ENUMS.VEHICLE_MAKE_YEAR.PLACEHOLDER')}`}
                  onChange={(event) => setVehicleMakeYear(event.target.value)} />
              </FormGroup>
              <FormGroup className='w-100 my-2'>
                <FormLabel>{`${t('INPUT_FIELD_ENUMS.VEHICLE_COLOR.LABEL_NAME')}`}</FormLabel>
                <input type="text" value={vehicleColor} name={'color'} className='form-control form-control-lg border-left-0 w-100 mb-1' placeholder={`${t('INPUT_FIELD_ENUMS.VEHICLE_COLOR.PLACEHOLDER')}`}
                  onChange={(event) => setVehicleColorState(event.target.value)} />
              </FormGroup>
              {/* <Button onClick={() => props.deActiveVehicle(props.vehicleId, DRIVER_VEHICLE_STATUS.DEACTIVATED)} variant='danger'>
                {isFetching ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> :
                  `${t('PROJECT_BUTTON.DEACTIVATE')}`
                }
              </Button> */}
            </>
            : <InnerLoader />}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.onHide} variant={'light'}>{`${t('PROJECT_BUTTON.CANCEL')}`}</Button> */}
          <Button onClick={() => submitUpdatedDetails(props.vehicleId)} className='me-auto'>
            {isFetching ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> :
              `${t('PROJECT_BUTTON.SUBMIT')}`
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditVehicleDetailsModal
