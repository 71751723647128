import i18n from '../../../i18n'
import * as yup from 'yup'
export const VehicleListSchema = yup.object().shape({
  vehicleName: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.VEHICLE_ADD.VEHICLE_NAME_REQUIRED')}`)
    .max(50, `${i18n.t('FORMIK_ERROR.VEHICLE_ADD.VEHICLE_NAME_LENGTH')}`),
  vehicleDescription: yup.string().max(250, `${i18n.t('FORMIK_ERROR.VEHICLE_ADD.VEHICLE_DESCRIPTION_LENGTH')}`),
  seatingCapacity: yup.number().required(`${i18n.t('FORMIK_ERROR.VEHICLE_ADD.VEHICLE_CAPACITY_REQUIRED')}`).max(10, `${i18n.t('FORMIK_ERROR.VEHICLE_ADD.VEHICLE_CAPACITY_MAX')}`)
    .min(1, `${i18n.t('FORMIK_ERROR.VEHICLE_ADD.VEHICLE_CAPACITY_MIN')}`),
  vehicleImage: yup.string().url(`${i18n.t('FORMIK_ERROR.VEHICLE_ADD.VEHICLE_IMAGE_VALID_URL')}`),
  vehicleType: yup.object().required(`${i18n.t('FORMIK_ERROR.VEHICLE_ADD.VEHICLE_TYPE_REQUIRED')}`)
})
