import React, { useContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ReactSelect, { SingleValue } from 'react-select'
import UserContext from '../../Context/UserContext'

type Props = {
  show: boolean
  onHide: () => void
  modalMessage: string
  modalTitle: string
  confirmDeleteHandle: (designationId: string, selectedChoice: { value: string, label: string, id: string }) => void
  designationId: string
  options?: { value: string, label: string, id: string }[]
}

const ModalComponent = (props: Props) => {
  const [selectedChoice, setSelectedChoice] = useState<SingleValue<{ value: string, label: string, id: string }>>()
  const { t } = useTranslation()
  const { darkMode } = useContext(UserContext)

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className={darkMode ? 'dark-theme' : ''}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.modalMessage}</p>
        {props.options?.length ? (
          <ReactSelect
            name={'designation'}
            placeholder={'designation'}
            options={props.options}
            value={selectedChoice}
            className="form-control form-control-lg p-0 mb-1 react-select-menu"
            classNamePrefix={'react-select'}
            captureMenuScroll={true}
            onChange={(value) => setSelectedChoice((selectedChoice) => value)}
          />
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button className={'d-block me-auto'} onClick={() => props.confirmDeleteHandle(props.designationId, selectedChoice!)} variant={'danger'}>
          {`${t('PROJECT_BUTTON.DELETE')}`}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalComponent
