import { FormikValues } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SingleValue } from 'react-select'
import VehicleCreateViewEdit from '../../../Components/Common/VehicleCreateViewEdit'
import UserContext from '../../../Context/UserContext'
import { getSingleVehicle } from '../../../Services/VehicleService'
import { PROJECT_ROUTES_ENUMS, VEHICLE_TYPE } from '../../../utils/enums/enums'
import { VehiclePriceModel } from '../../Models/CommonModel'
import { OptionDataModel, SelectDropdownModel } from '../../Register/UserRegistrationModel'

type Props = {}
const ViewVehicle = (props: Props) => {
  const { t } = useTranslation()
  const params = useParams()
  const { setArrayOfBreadCrumb, setIsFetching } = useContext(UserContext)
  const [vehicleIsActiveState, setVehicleIsActiveState] = useState(false)
  const [vehicleNameState, setVehicleNameState] = useState('')
  const [vehicleDescriptionState, setVehicleDescriptionState] = useState('')
  const [vehicleTypeState, setVehicleTypeState] = useState<SingleValue<SelectDropdownModel>>()
  const [seatingCapacityState, seSeatingCapacityState] = useState<number>(0)
  const [vehicleImageState, setVehicleImageState] = useState('')
  const [vehicleMapIconState, setVehicleMapIconState] = useState('')
  const [averageSpeedState, setAverageSpeedState] = useState(500)
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([] as OptionDataModel)
  const [vehicleDefaultPrice, setVehicleDefaultPrice] = useState({} as VehiclePriceModel)

  useEffect(() => {
    (async () => {
      setIsFetching(true)
      try {
        const response = await getSingleVehicle(params.itemId!)
        if (response.data.vehicle) {
          console.log('🔥 ~ response.data', response.data)
          setIsFetching(false)
          setVehicleIsActiveState(response.data.vehicle.isActive)
          setVehicleNameState(response.data.vehicle.name)
          setVehicleDescriptionState(response.data.vehicle.description)
          seSeatingCapacityState(response.data.vehicle.seatCapacity)
          setVehicleImageState(response.data.vehicle.vehicleIcon)
          setVehicleMapIconState(response.data.vehicle.vehicleMapIcon)
          setAverageSpeedState(response.data.vehicle.averageSpeed)
          setVehicleTypeOptions(Object.keys(VEHICLE_TYPE).map((vehicleType: any) => ({ value: vehicleType, label: vehicleType })))
          const vehicleType = Object.keys(VEHICLE_TYPE).find((type: string) => VEHICLE_TYPE[type as keyof typeof VEHICLE_TYPE] === response.data.vehicle.vehicleType)
          if (vehicleType) {
            setVehicleTypeState({ value: vehicleType, label: vehicleType })
          }
          setVehicleDefaultPrice(response.data.vehicleDefaultPrice)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🔥 ~ error', error)
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST_VIEW')}`, url: '' }
    ])
    return () => { }
  }, [])

  const setFieldValue = (formik: FormikValues) => {
    setVehicleIsActiveState(formik.values.vehicleIsActive)
    setVehicleNameState(formik.values.vehicleName)
    setVehicleDescriptionState(formik.values.vehicleDescription)
    setVehicleTypeState(formik.values.vehicleType)
    seSeatingCapacityState(formik.values.seatingCapacity)
    setVehicleMapIconState(formik.values.vehicleMapIcon)
  }

  return (
    <VehicleCreateViewEdit
      field={{
        vehicleName: vehicleNameState,
        vehicleDescription: vehicleDescriptionState,
        vehicleType: vehicleTypeState,
        seatingCapacity: seatingCapacityState,
        vehicleIsActive: vehicleIsActiveState,
        vehicleImage: vehicleImageState,
        vehicleMapIcon: vehicleMapIconState,
        averageSpeed: averageSpeedState,
        option: vehicleTypeOptions,
        setVehicleIsActiveState
      }}
      isDisabled={true}
      cardTitle={vehicleNameState || `${t('PAGE_TITLE.VIEW_VEHICLE')}`}
      submitButtonName="EDIT"
      setFieldValue={setFieldValue}
      pageName="view-vehicle"
      paramId={params.itemId!}
      vehiclePrice={vehicleDefaultPrice}
    />
  )
}

export default ViewVehicle
