import { FormikValues } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DriverDocumentCreateViewEdit from '../../../Components/Common/DriverDocumentCreateViewEdit'
import UserContext from '../../../Context/UserContext'
import { PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'

type Props = {}

const AddDriverDocument = (props: Props) => {
  const { t } = useTranslation()
  const [selectedFormat, setSelectedFormat] = useState('' as string | [] as any)
  const [doShowState, setDoShow] = useState(false)
  const [doseExpiryState, setDoseExpiry] = useState(false)
  const [isRequiredStateState, setIsRequiredState] = useState(true)
  const [isCaptureOnlyStateState, setIsCaptureOnlyState] = useState(false)
  const [documentNameState, setDocumentNameState] = useState('')
  const [documentDescriptionState, setDocumentDescriptionState] = useState('')
  const [documentSizeState, setDocumentSizeState] = useState(1)
  const [documentImageState, setDocumentImageState] = useState('')

  const { setArrayOfBreadCrumb } = useContext(UserContext)

  useEffect(() => {
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.DRIVER_DOCUMENTS')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_DOCUMENT_PAGE },
      { name: `${t('PAGE_NAME.DRIVER_DOCUMENT_ADD')}`, url: '' }
    ])
    setDocumentNameState('')
    setDocumentDescriptionState('')
    setDocumentSizeState(1)
    setDocumentImageState('')
    setSelectedFormat('')
    return () => { }
  }, [])

  const setFieldValue = (formik: FormikValues) => {
    setSelectedFormat(formik.values.documentFormat)
    setDocumentNameState(formik.values.documentName)
    setDocumentDescriptionState(formik.values.documentDescription)
    setDocumentSizeState(formik.values.documentSize)
    setDocumentImageState(formik.values.documentImage)
  }
  return (
    <DriverDocumentCreateViewEdit
      field={{
        documentName: documentNameState,
        documentDescription: documentDescriptionState,
        documentSize: documentSizeState,
        documentImage: documentImageState,
        documentFormat: selectedFormat,
        setDoShow,
        setDoseExpiry,
        setIsRequiredState,
        setIsCaptureOnlyState,
        setSelectedFormat,
        setDocumentSizeState,
        doShow: doShowState,
        doseExpiry: doseExpiryState,
        isRequiredState: isRequiredStateState,
        isCaptureOnlyState: isCaptureOnlyStateState
      }}
      isDisabled={false}
      cardTitle={`${t('DRIVER_DOCUMENT_PAGE.DOCUMENT_ADD_PAGE.TITLE')}`}
      submitButtonName="ADD"
      setFieldValue={setFieldValue}
      pageName="create-driver-document"
    />
  )
}

export default AddDriverDocument
