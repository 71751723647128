import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import ShitPriceAccordion from '../../../../Components/Common/ShitPriceAccordion'
import UserContext from '../../../../Context/UserContext'
import { getShiftPrice, patchUpdateVehicleShiftPrice } from '../../../../Services/OrganizationServices'
import { getVehicleList } from '../../../../Services/VehicleService'
import { PROJECT_ROUTES_ENUMS } from '../../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../../utils/utils'
import { VehiclePriceModel } from '../../../Models/CommonModel'

type Props = {}

const OrganizationShiftPrice = (props: Props) => {
  const { setIsFetching, setArrayOfBreadCrumb } = useContext(UserContext)
  const [vehicleList, setVehicleList] = useState([] as { vehicleImage: string, name: string, id: string }[])
  const [shiftPrice, setShiftPrice] = useState([] as VehiclePriceModel[])
  const location = useLocation()
  const { t } = useTranslation()
  useEffect(() => {
    (async () => {
      setIsFetching(true)
      try {
        const response = await getVehicleList()
        if (response.data) {
          console.log('🔥 ~ response.data', response.data)
          setIsFetching(false)
          setVehicleList(response.data)
          getVehicleShiftPrice()
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🔥 ~ useEffect ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.SHIFT_SETTINGS')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_SHIFT_SETTINGS },
      // @ts-ignore
      { name: `${location?.state?.shiftName} ${t('PAGE_NAME.ORGANIZATION_SHIFT_PRICE_SETTINGS')}`, url: '' }
    ])
  }, [])

  const getVehicleShiftPrice = async () => {
    console.log('🚀 ~ file: OrganizationShiftPrice.tsx:56 ~ getVehicleShiftPrice ~ location.state', location.state)
    setIsFetching(true)
    try {
      // @ts-ignore
      const response = await getShiftPrice(location.state.shiftId as string)
      if (response.data) {
        setIsFetching(false)
        setShiftPrice(response.data)
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ getVehicleShiftPrice ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  const handleSubmit = async (values: VehiclePriceModel) => {
    console.log('🚀 ~ file: OrganizationShiftPrice.tsx:72 ~ handleSubmit ~ values', values)
    setIsFetching(true)
    try {
      const priceDetails = {
        orgVehicleDetailId: values.orgVehicleDetailId,
        orgShiftId: values.shiftId,
        initialDistance: values.initialDistance,
        baseRate: values.baseRate,
        distanceRate: values.distanceRate,
        durationRate: values.durationRate,
        waitRate: values.waitRate,
        stopRate: values.stopRate,
        surCharge: values.surCharge,
        cancelRate: values.cancelRate,
        pickupDistanceRate: values.pickupDistanceRate,
        initialWaitTime: values.initialWaitTime,
        initialPickupDistance: values.initialPickupDistance,
        initialDuration: values.initialDuration,
        luggageCharge: values.luggageCharge,
        additionalPersonCharge: values.additionalPersonCharge
      }

      console.log('🔥 ~ handleUpdate ~ priceDetails', priceDetails)
      const response = await patchUpdateVehicleShiftPrice(`${values.id}`, priceDetails)
      console.log('🚀 ~ file: OrganizationShiftPrice.tsx:96 ~ handleSubmit ~ response', response)
      if (response.statusCode === 200) {
        setIsFetching(false)
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
        getVehicleShiftPrice()
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ handleUpdate ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  return (
    <>
      {/* @ts-ignore */}
      {/* <h3 className='ms-1'>{location?.state?.shiftName} Vehicle Prices</h3> */}
      {/* <hr /> */}
      {vehicleList && shiftPrice ? vehicleList.map((vehicle: { vehicleImage: string, name: string, id: string }, VehicleIndex) => (
        <ShitPriceAccordion
          key={VehicleIndex}
          vehicleImage={vehicle.vehicleImage}
          vehicleName={vehicle.name}
          eventKey={VehicleIndex}
          vehiclePrice={shiftPrice.find(vehiclePriceObject => vehiclePriceObject.orgVehicleDetailId === vehicle.id) || {} as VehiclePriceModel}
          vehicleId={vehicle.id}
          // @ts-ignore
          handleSubmit={handleSubmit}
          // handleSubmit={(vehiclePriceId, formValue) => handleSubmit(vehiclePriceId, formValue, location.state)}
        />
      )) : <h4>No Data To Show</h4>}
    </>
  )
}

export default OrganizationShiftPrice
