import { FormikValues } from 'formik'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SingleValue } from 'react-select'
import { toast } from 'react-toastify'
import DriverCreateViewEdit from '../../../Components/Common/DriverCreateViewEdit'
import UserContext from '../../../Context/UserContext'
import { getDriverDetails } from '../../../Services/DriverService'
import { GENDER_OPTION, PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../utils/utils'
import { SelectDropdownModel } from '../../Register/UserRegistrationModel'

type Props = {}

const ViewDriverDetails = (props: Props) => {
  const { setIsFetching, setArrayOfBreadCrumb } = useContext(UserContext)
  const params = useParams()
  const { t } = useTranslation()

  const [driverName, setDriverName] = useState('')
  const [driverGender, setDriverGender] = useState<SingleValue<SelectDropdownModel>>()
  const [driverBirthDate, setDriverBirthDate] = useState('')
  const [driverEmail, setDriverEmail] = useState('')
  const [driverMobile, setDriverMobile] = useState('')
  const [driverStatus, setDriverStatus] = useState(false)
  const [driverCreatedAt, setDriverCreatedAt] = useState('')
  const [driverAddress, setDriverAddress] = useState('')
  const [driverCity, setDriverCity] = useState('')
  const [noDataFetched, setNoDataFetched] = useState(true)

  useEffect(() => {
    (async () => {
      setIsFetching(true)
      try {
        // @ts-ignore
        const response = await getDriverDetails(params.itemId!)
        if (response.statusCode === 200) {
          setDriverName(response.data.name)
          const gender = GENDER_OPTION.find((genderObject: any) => genderObject.id === response.data.gender)
          setDriverGender(gender!)
          setDriverBirthDate(response.data.dob)
          setDriverEmail(response.data.email)
          setDriverMobile(response.data.mobile)
          setDriverStatus(!!response.data.status)
          setDriverCreatedAt(response.data.createdAt)
          setDriverAddress(response.data.address)
          setDriverCity(response.data.cityName)
          setIsFetching(false)
          setNoDataFetched(false)
        } else {
          throw response
        }
      } catch (error) {
        console.error('🔥 ~ error', error)
        setIsFetching(false)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.DRIVER_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE },
      { name: `${t('PAGE_NAME.VIEW_DRIVER')}`, url: '' }
    ])
    return () => { }
  }, [])

  const setFieldValue = (formik: FormikValues) => {
    setDriverName(formik.values.name)
    setDriverGender(formik.values.gender)
    setDriverBirthDate(formik.values.dob)
    setDriverEmail(formik.values.email)
    setDriverMobile(formik.values.mobile)
    setDriverCreatedAt(formik.values.createdAt)
    setDriverAddress(formik.values.address)
  }
  return (
    <DriverCreateViewEdit
      field={{
        name: driverName,
        gender: driverGender,
        dob: driverBirthDate,
        email: driverEmail,
        mobile: driverMobile,
        status: driverStatus,
        createdAt: driverCreatedAt,
        address: driverAddress,
        city: driverCity,
        setStatus: setDriverStatus
      }}
      isDisabled={true}
      cardTitle={`${t('PAGE_NAME.VIEW_DRIVER')}`}
      submitButtonName="EDIT"
      setFieldValue={setFieldValue}
      pageName="view-driver"
      paramId={params.itemId!}
      noDataFetched={noDataFetched}
    />
  )
}

export default ViewDriverDetails
