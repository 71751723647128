import React, { useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import UserContext from '../Context/UserContext'
import AppLayout from '../Layout/AppLayout'
import SidePanel from '../Layout/SidePanel'
import Login from '../Pages/Login/Login'
import PageNotFound from '../Pages/Others/pageNotFound'
import Register from '../Pages/Register/Register'
import RequestResetPassword from '../Pages/RequestResetPassword/RequestResetPassword'
import ResetPassword from '../Pages/ResetPassword/ResetPassword'
import TermsCondition from '../Pages/TermsConditions/TermsCondition'
import { PROJECT_ROUTES_ENUMS } from '../utils/enums/enums'
import PrivateRoute from '../Components/Routes/PrivateRoute'
import PublicRoute from '../Components/Routes/PublicRoute'
import UnderMaintenance from '../Pages/Others/UnderMaintenance'
import UnAuthorized from '../Pages/Others/UnAuthorized'
import AccessControl from '../Components/Routes/AccessControl'
import { ROUTES_ARRAY } from '../utils/utils'
import Dashboard from '../Pages/Dashboard/Dashboard'

type Props = {}

const ProjectRoutes = (props: Props) => {
  const { userPermissions } = useContext(UserContext)

  return (
    <BrowserRouter>
      <Routes>
        {/* Auth Routes */}
        <Route path="/auth" element={<PublicRoute />} >
          <Route path={PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.LOGIN_PAGE} element={<SidePanel><Login /></SidePanel>} />
          <Route path={PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.SIGN_UP_PAGE} element={<SidePanel><Register /></SidePanel>} />
          <Route path={PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.REQUEST_RESET_PASSWORD_PAGE} element={<SidePanel><RequestResetPassword /></SidePanel>} />
          <Route path={`${PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.RESET_PASSWORD_PAGE}/:token`} element={<SidePanel><ResetPassword /></SidePanel>} />
        </Route>

        {/* Private Routes */}
        <Route path="/" element={<PrivateRoute />} >
          {ROUTES_ARRAY.map((route, index) => {
            return (<Route path={route.PATH} key={index} element={
              <AccessControl allowedPermissionKey={route.PERMISSION_ARRAY} userPermissions={userPermissions} moduleId={route.PERMISSION_MODULE_ID}>
                <>
                  {route.COMPONENT}
                </>
              </AccessControl>} >
            </Route>)
          })}
          <Route path={PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE} element={<AppLayout><Dashboard /></AppLayout>} />
          <Route path={PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.REGISTRATION_TERMS_CONDITIONS_PAGE} element={<AppLayout><TermsCondition /></AppLayout>} />
        </Route>

        {/* 404 Page */}
        <Route path='*' element={<PageNotFound />} />
        <Route path='/unauthorized' element={<AppLayout><UnAuthorized /></AppLayout>} />
        <Route path={PROJECT_ROUTES_ENUMS.OTHER_ROUTES.UNDER_MAINTENANCE} element={<UnderMaintenance />} />
      </Routes>
    </BrowserRouter>
  )
}

export default ProjectRoutes
