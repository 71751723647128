// UI Change for edit and delete links
// Remove Button which are disabled by default
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import DesignationEditView from '../../../../Components/Common/DesignationEditView'
import UserContext from '../../../../Context/UserContext'
import { getEditDesignation } from '../../../../Services/DesignationService'
import { PROJECT_ROUTES_ENUMS } from '../../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../../utils/utils'

type Props = {}

const ViewDesignation = (props: Props) => {
  const [designationInput, setDesignationInput] = useState('')
  const [dutyInput, setDutyInput] = useState('')
  const [isDefaultState, setDefaultState] = useState(false)
  const params = useParams()
  const { t } = useTranslation()
  const { setArrayOfBreadCrumb, setIsFetching } = useContext(UserContext)

  // It will call on page load of Edit button click in Action dropdown and set the input field value according to table content value.
  useEffect(() => {
    (async function () {
      setIsFetching(true)
      try {
        const response = await getEditDesignation(params.itemId!)
        if (response.data) {
          setDesignationInput(response.data.designation)
          setDutyInput(response.data.duty)
          setDefaultState(response.data.isDefault)
          setIsFetching(false)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🚀 ~ file: ViewDesignation.tsx ~ line 35 ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
        // toast.error(`${t('TOAST_ERROR.VIEW_DESIGNATION.FIELD_TO_LOAD')}`)
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.DESIGNATION')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE },
      { name: `${t('PAGE_NAME.VIEW_DESIGNATION')}`, url: '' }
    ])
    return () => { }
  }, [])

  return (
    <DesignationEditView
      field={{ designation: designationInput, duty: dutyInput, isDefault: isDefaultState, setDefaultState }}
      isDisabled={true}
      paramId={params.itemId!}
      cardTitle={`${t('PAGE_TITLE.VIEW_DESIGNATION')}`}
      redirectTo={`${t('DESIGNATION_PAGE.DESIGNATION_TABLE.ACTION_DROPDOWN.EDIT')}`}
      submitButtonName="EDIT"
      pageName="view-designation"

    />
  )
}

export default ViewDesignation
