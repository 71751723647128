import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select, { MultiValue } from 'react-select'
import { toast } from 'react-toastify'
import ImagePicker from '../../../Components/Common/ImagePicker'
import InnerLoader from '../../../Components/Common/InnerLoader'
import TooltipComponent from '../../../Components/Common/Tooltip'
import IsAuthorized from '../../../Components/IsAuthorized/IsAuthorized'
import UserContext from '../../../Context/UserContext'
import { getOrganizationsSettings, putOrganizationsSettings } from '../../../Services/OrganizationServices'
import { AVAILABLE_PAYMENT_OPTIONS, BANNER_TYPE, MODULE_ID, PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../utils/utils'
import { SelectDropdownModel } from '../../Register/UserRegistrationModel'

type Props = {}

const OrganizationsAppSettings = (props: Props) => {
  const { setIsFetching, setArrayOfBreadCrumb, setShowMediaModelDetails, setShowMediaModel, isFetching } = useContext(UserContext)
  const { t } = useTranslation()
  const formInput = useRef<HTMLFormElement>(null)
  const textInputRef = useRef(null)

  const [bannerIcon, setBannerIcon] = useState('')
  const [redirectLink, setRedirectLink] = useState('')
  const [isClickable, setIsClickable] = useState(false)
  const [pickedBackgroundColor, setPickedBackgroundColor] = useState('#000000')
  const [pickedTextColor, setPickedTextColor] = useState('#000000')
  const [paymentOption, setPaymentOption] = useState([] as { value: string, label: string, id: number }[])
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<MultiValue<SelectDropdownModel>>([] as { value: string, label: string, id: number }[])

  type OrganizationFeaturesDataModel = {
    bannerType: { value: string; label: string, id: number }
    bookingForOthers?: boolean
    hasCoupons?: boolean
    hasOutstations?: boolean
    hasPool?: boolean
    hasRental?: boolean
    hasScheduleBooking?: boolean
    multipleBooking?: boolean
    driverSwitchDelay?: number
    homeBanner: {
      title: string
      description: string
      backgroundColor: string
      icon: string
      isClickable: boolean
      redirectLink: string
      textColor: string
      type: number
    }

  }
  const [formData, setFormData] = useState({} as OrganizationFeaturesDataModel)
  const changeFormDataValue = (key: string, val: any | boolean, subKey?: string) => {
    if (subKey) {
      // @ts-ignore
      setFormData(currentFormData => ({ ...currentFormData, [key]: { ...currentFormData[key], [subKey]: val } }))
    } else {
      setFormData(currentFormData => ({ ...currentFormData, [key]: val }))
    }
  }

  useEffect(() => {
  }, [formData])
  useEffect(() => {
    (async () => {
      setIsFetching(true)
      try {
        const response = await getOrganizationsSettings()
        if (response.data) {
          console.log('🔥 ~ response.data', response.data)
          setIsFetching(false)
          const existingBannerType = BANNER_TYPE.find(banner => banner.id === response.data.homeBanner.type)
          const availablePaymentOption = response.data.availablePaymentOptions.split(',').map((option: string) => (
            AVAILABLE_PAYMENT_OPTIONS.find(opt => `${opt.id}` === option)
          ))
          setPaymentOption(availablePaymentOption)
          // TODO: the above value will set the option as per availablePaymentOption will come [Remain to update the option as per selectedPaymentOption getting from response.]
          if (existingBannerType) {
            changeFormDataValue('bannerType', existingBannerType)
          } else {
            changeFormDataValue('bannerType', BANNER_TYPE[1])
          }
          setFormData(formData => ({ ...formData, ...response.data }))
          setBannerIcon(response.data.homeBanner.icon)
          setRedirectLink(response.data.homeBanner.redirectLink)
          setIsClickable(response.data.homeBanner.isClickable)
        } else {
          throw response
        }
      } catch (error) {
        console.error('🔥 ~ error', error)
        setIsFetching(false)
      }
    })()

    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.ORGANIZATION_SETTINGS')}`, url: '' }
    ])
    return () => { }
  }, [])

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const OrganizationsSettings = {
      homeBanner: {
        title: formData.homeBanner.title,
        description: formData.homeBanner.description,
        type: formData.bannerType.id,
        isClickable,
        redirectLink,
        icon: bannerIcon,
        backgroundColor: pickedBackgroundColor,
        textColor: pickedTextColor

      },
      mapApi: 1,
      mapApiDetails: {},
      multipleBooking: formData.multipleBooking,
      bookingForOthers: formData.bookingForOthers,
      hasCoupons: formData.hasCoupons,
      hasOutstations: formData.hasOutstations,
      hasRental: formData.hasRental,
      hasPool: formData.hasPool,
      hasScheduleBooking: formData.hasScheduleBooking,
      driverSwitchDelay: formData.driverSwitchDelay
    }
    try {
      setIsFetching(true)
      const response = await putOrganizationsSettings(OrganizationsSettings)
      if (response.statusCode === 200) {
        setIsFetching(false)
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
      } else {
        throw response
      }
    } catch (error) {
      console.error('🔥 ~ handleSubmit ~ error', error)
      setIsFetching(false)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  const openMedia = () => {
    setShowMediaModelDetails({ setInputState: setBannerIcon, inputRef: null })
    setShowMediaModel(true)
  }
  return (
    <>
      {isFetching ? <InnerLoader /> :
        <Form onSubmit={(event) => handleSubmit(event)} ref={formInput}>

          <div>
            <hr className='mt-0 mb-5' />
            <div className='d-flex align-items-center justify-content-between'>
              {/* TODO : From translation */}
              <h3 className='ms-1'>User Settings</h3>
            </div>
            <Row className={''}>
              {_.has(formData, 'bookingForOthers') &&
                <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                  <Card className='form-card'>
                    <Card.Body className={'d-flex flex-column justify-content-between'}>
                      <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                        <FormLabel className='align-self-center mb-0 mt-1'>{`${t('ORGANIZATION_SETTINGS.BOOKING_FOR_OTHER')} :`}</FormLabel>
                        <Form.Check type="switch" name="bookingForOther" className="switch" checked={formData.bookingForOthers} onChange={(event) => changeFormDataValue('bookingForOthers', event.target.checked)} />
                      </FormGroup>
                      {/* TODO: take all this from translation */}
                      <p className='text-left mt-4 text-secondary'>To enable the switch, User can book rides for friends or for Family.</p>
                    </Card.Body>
                  </Card>
                </Col>
              }
              {_.has(formData, 'hasCoupons') && <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1'>{`${t('ORGANIZATION_SETTINGS.HAS_COUPON_CODE')} :`}</FormLabel>
                      <Form.Check type="switch" name="couponCode" className="switch" checked={formData.hasCoupons} onChange={(event) => changeFormDataValue('hasCoupons', event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, User can see & apply the Coupon to get discount. </p>
                  </Card.Body>
                </Card>
              </Col>}
              {_.has(formData, 'hasOutstations') && <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1'>{`${t('ORGANIZATION_SETTINGS.HAS_OUTSTATIONS')} :`}</FormLabel>
                      <Form.Check type="switch" name="outstation" className="switch" checked={formData.hasOutstations} onChange={(event) => changeFormDataValue('hasOutstations', event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, User can have the option to book Outstation ride. </p>
                  </Card.Body>
                </Card>
              </Col>}

              {_.has(formData, 'hasPool') && <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1' >{`${t('ORGANIZATION_SETTINGS.HAS_POOL')} :`}</FormLabel>
                      <Form.Check type="switch" name="pool" className="switch" checked={formData.hasPool} onChange={(event) => changeFormDataValue('hasPool', event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, User can have the option to pool car.</p>
                  </Card.Body>
                </Card>
              </Col>}

              {_.has(formData, 'hasRental') && <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1'>{`${t('ORGANIZATION_SETTINGS.HAS_RENTAL')} :`}</FormLabel>
                      <Form.Check type="switch" name="rental" className="switch" checked={formData.hasRental} onChange={(event) => changeFormDataValue('hasRental', event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, User can have the option to book ride on Rent bases.</p>
                  </Card.Body>
                </Card>
              </Col>}

              {_.has(formData, 'hasScheduleBooking') && <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1 text-truncate'>{`${t('ORGANIZATION_SETTINGS.HAS_SCHEDULE_BOOKINGS')} :`}</FormLabel>
                      <Form.Check type="switch" name="scheduleBooking" className="switch" checked={formData.hasScheduleBooking} onChange={(event) => changeFormDataValue('hasScheduleBooking', event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, User can book Later ride for future Time.</p>
                  </Card.Body>
                </Card>
              </Col>}
              <Col ssm={12} md={6} lg={4} xl={3} className='my-2'>
                <FormGroup>
                  <FormLabel >{'Select Payment Option'}</FormLabel>
                  <Select
                    // TODO: from translation
                    isMulti
                    name='selectedPaymentOption'
                    placeholder={'Select payment option'}
                    options={paymentOption}
                    className="form-control form-control-lg p-0 mb-1 react-select-menu"
                    classNamePrefix={'react-select'}
                    value={selectedPaymentOption}
                    onChange={(value) => setSelectedPaymentOption(value)}
                    captureMenuScroll={true}
                  ></Select>
                </FormGroup>
              </Col>

            </Row>
          </div>

          <div className='mt-3'>
            <Row>
              <Col sm={12} md={6} lg={4} xl={3} className='my-2'>
                <FormGroup>
                  <FormLabel>{`${t('INPUT_FIELD_ENUMS.BANNER_TITLE.LABEL_NAME')} :`}</FormLabel>
                  <FormControl
                    className='form-control form-control-lg border-left-0 w-100 mb-1'
                    // TODO: from translation
                    name='bannerTitle'
                    placeholder={`${t('INPUT_FIELD_ENUMS.BANNER_TITLE.PLACEHOLDER')} :`}
                    value={formData.homeBanner?.title}
                    onChange={(event) => changeFormDataValue('homeBanner', event.target.value, 'title')}
                  ></FormControl>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className='my-2 '>
                <FormGroup>
                  <FormLabel >{`${t('INPUT_FIELD_ENUMS.BANNER_DESCRIPTION.LABEL_NAME')} :`}</FormLabel>
                  <FormControl
                    className='form-control form-control-lg border-left-0 w-100 mb-1'
                    // TODO: from translation
                    name='bannerDescription'
                    placeholder={`${t('INPUT_FIELD_ENUMS.BANNER_DESCRIPTION.PLACEHOLDER')} :`}
                    value={formData.homeBanner?.description}
                    onChange={(event) => changeFormDataValue('homeBanner', event.target.value, 'description')}
                  ></FormControl>
                </FormGroup>
              </Col>
              <Col ssm={12} md={6} lg={4} xl={3} className='my-2'>
                <FormGroup>
                  <FormLabel >{`${t('INPUT_FIELD_ENUMS.BANNER_TYPE.LABEL_NAME')} :`}</FormLabel>
                  <Select
                    // TODO: from translation
                    name='bannerType'
                    placeholder={`${t('INPUT_FIELD_ENUMS.BANNER_TYPE.PLACEHOLDER')} :`}
                    options={BANNER_TYPE}
                    className="form-control form-control-lg p-0 mb-1 react-select-menu"
                    classNamePrefix={'react-select'}
                    value={formData.bannerType}
                    onChange={(value) => changeFormDataValue('bannerType', value)}
                    captureMenuScroll={true}
                  ></Select>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className={`my-2 ${formData.bannerType?.value === 'Custom' ? '' : 'd-none'}`}>
                <FormGroup>
                  <FormLabel >{`${t('INPUT_FIELD_ENUMS.BANNER_ICON.LABEL_NAME')} :`}<TooltipComponent toolTipMsg='Select Banner Icon' /></FormLabel>
                  <ImagePicker openMedia={openMedia}>
                    <FormControl
                      ref={textInputRef}
                      className='form-control form-control-lg border-left-0 w-100 mb-1'
                      // TODO: from translation
                      name='bannerIcon'
                      placeholder={`${t('INPUT_FIELD_ENUMS.BANNER_ICON.PLACEHOLDER')}`}
                      value={bannerIcon}
                      onChange={(event) => setBannerIcon(event.target.value)}
                    />
                  </ImagePicker>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className={`my-2 text-center ${formData.bannerType?.value === 'Custom' ? '' : 'd-none'}`}>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className={'my-2 switch-wrapper d-flex justify-content-between'}>
                      <FormLabel className='align-self-center mb-0 mt-1 text-truncate'>{`${t('ORGANIZATION_SETTINGS.PICK_BACKGROUND_COLOR')} :`}</FormLabel>
                      <div className='d-flex justify-content-center'>
                        <input type="color" name="bannerBackgroundColor" value={pickedBackgroundColor} min={1} onChange={(event) => setPickedBackgroundColor(event.target.value)} />
                      </div>
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To change colour will reflect on the Dashboard Banner to the Users. </p>

                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className={`my-2 text-center ${formData.bannerType?.value === 'Custom' ? '' : 'd-none'}`}>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className={'my-2 switch-wrapper d-flex justify-content-between'}>
                      <FormLabel className='align-self-center mb-0 mt-1 text-truncate'>{`${t('ORGANIZATION_SETTINGS.PICK_TEXT_COLOR')} :`}</FormLabel>
                      <div className='d-flex justify-content-center'>
                        <input type="color" name="bannerTextColor" value={pickedTextColor} min={1} onChange={(event) => setPickedTextColor(event.target.value)} />
                      </div>
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To change colour will reflect on the Dashboard Text Banner to the Users. </p>

                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className={`my-2 text-center ${formData.bannerType?.value === 'Custom' ? '' : 'd-none'}`}>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1 text-truncate me-1'>{`${t('ORGANIZATION_SETTINGS.HOME_BANNER_IS_CLICKABLE')} :`}</FormLabel>
                      <Form.Check type="switch" name="bannerClickable" className="switch d-block ms-auto" checked={isClickable} onChange={(event) => setIsClickable(event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, User can click on the Dashboard Message.</p>

                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className={`my-2 ${formData.bannerType?.value === 'Custom' && isClickable ? '' : 'd-none'}`}>
                <FormGroup>
                  <FormLabel >{`${t('INPUT_FIELD_ENUMS.BANNER_REDIRECT_LINK.LABEL_NAME')} :`}</FormLabel>
                  <FormControl
                    className='form-control form-control-lg border-left-0 w-100 mb-1'
                    // TODO: from translation
                    name='redirectLink'
                    placeholder={`${t('INPUT_FIELD_ENUMS.BANNER_REDIRECT_LINK.PLACEHOLDER')} :`}
                    value={redirectLink}
                    onChange={(event) => setRedirectLink(event.target.value)}
                  ></FormControl>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className='mt-5'>
            <h3 className='ms-1'>Driver Settings</h3>
            <Row>
              <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 w-50 text-truncate text-left'>{`${t('ORGANIZATION_SETTINGS.SWITCH_DELAY')} :`}</FormLabel>
                      <div className='d-flex justify-content-center ms-1 w-50'>
                        <input type="range" name="switchDelay" className="range-style w-100" value={formData.driverSwitchDelay} min={1} onChange={(event) => changeFormDataValue('driverSwitchDelay', event.target.value)} />
                        {/* <p className='text-left my-0 ms-1 secondary-flex align-items-center text-secondary'><strong>{}</strong></p> */}
                      </div>
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>By changing this option, Driver cannot offline until {`${formData.driverSwitchDelay} min`}, after Online.  </p>
                  </Card.Body>
                </Card>
              </Col>
              {/* {_.has(formData, 'bookingForOthers') &&
                <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                  <Card className='form-card'>
                    <Card.Body className={'d-flex flex-column justify-content-between'}>
                      <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                        <FormLabel className='align-self-center mb-0 mt-1' >{`${t('ORGANIZATION_SETTINGS.HAS_MULTIPLE_STOP')} :`}</FormLabel>
                        <Form.Check type="switch" name="bookingForOther" className="switch" checked={formData.bookingForOthers} onChange={(event) => changeFormDataValue('bookingForOthers', event.target.checked)} />
                      </FormGroup>
                      <p className='text-left mt-4 text-secondary'>To enable the switch, User can create booking with Multiple stops Locations.</p>
                    </Card.Body>
                  </Card>
                </Col>
              } */}
            </Row>
          </div>
          <div className='mt-5' >
            <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.ORGANIZATION_APP_SETTINGS, rights: 'create' }]}>
              <Button type='submit' className='py-1 ms-3 my-0 add-button'>
                {`${t('PROJECT_BUTTON.SUBMIT')}`}
              </Button>
            </IsAuthorized>
          </div>
        </Form>
      }
    </>
  )
}

export default OrganizationsAppSettings
