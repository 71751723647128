import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import InnerLoader from '../../../Components/Common/InnerLoader'
import UserContext from '../../../Context/UserContext'
import { getVehicleList } from '../../../Services/VehicleService'
import { PROJECT_ROUTES_ENUMS, VEHICLE_TYPE } from '../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../utils/utils'

type Props = {}

const VehicleList = (props: Props) => {
  const { t } = useTranslation()
  const { setArrayOfBreadCrumb, setIsFetching, isFetching } = useContext(UserContext)
  const navigate = useNavigate()

  const [arrayOfVehicle, setArrayOfVehicle] = useState([])

  useEffect(() => {
    (async () => {
      setIsFetching(true)
      try {
        const response = await getVehicleList()
        if (response.data) {
          console.log('🔥 ~ response.data', response.data)
          setIsFetching(false)
          setArrayOfVehicle(response.data)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🔥 ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST')}`, url: '' }
    ])
    return () => { }
  }, [])

  return (
    <>
      {isFetching ? <InnerLoader /> :
        <Row>
          {arrayOfVehicle ? arrayOfVehicle.map((vehicle: any, vehicleIndex: number) => {
            return (
              <Col key={vehicleIndex} sm={12} md={6} lg={4} xl={3} className='mb-3 card-item-list'>
                <Card title='Update Vehicle Details' className={'form-card card-with-image cursor-p'}>
                  <Row className='align-items-center'>
                    <Card.Title className={'py-3 px-4'}>
                      <Col xs={12}>
                        <Row className='align-items-center'>
                          <Col xs={6}>
                            {/* {TODO: from translation} */}
                            <p className='mb-2 font-weight-bold card-details text-truncate'>{Object.keys(VEHICLE_TYPE).find((type: string) => VEHICLE_TYPE[type as keyof typeof VEHICLE_TYPE] === vehicle.vehicleType)}, {vehicle.name}</p>
                          </Col>
                          <Col xs={6} className='d-flex nav-item align-items-center justify-content-center'>
                            <i title='Update Vehicle Details' className='mdi mdi-file-document button-as-icon me-0' onClick={() => navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE, { state: vehicle.id })} />
                          </Col>
                        </Row>
                      </Col>
                    </Card.Title>
                    <Card.Body className='py-0'>
                      <Col xs={12}>
                        <Card.Img src={vehicle.vehicleIcon} className={'d-block mx-auto h-75'} onClick={() => navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE}/edit/${vehicle.id}`)}>
                        </Card.Img>
                      </Col>
                    </Card.Body>
                  </Row>
                </Card>
              </Col>
            )
          }) : <h5 className='text-center'>{`${t('VEHICLE_LIST_PAGE.NO_VEHICLE')}`}</h5>}

        </Row>
      }
    </>
  )
}

export default VehicleList
