import axios, { serviceFailureHandle, serviceSuccessHandle } from './AxiosConfig'

const getOrganizationsSettings = () => {
  return axios.get('/api/organization/app/settings').then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const putOrganizationsSettings = (OrganizationAppSettings: object) => {
  return axios.put('/api/organization/app/settings', OrganizationAppSettings).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

// *********** media ****************

const getMediaByCategory = (sortDetailsObject?: any) => {
  return axios.post('/api/organization/media/public/list', sortDetailsObject).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const deleteImageFromMedia = (imageId: string) => {
  return axios.delete(`/api/organization/media/public/${imageId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const putUpdateImageTitle = (imageId: string, title: string) => {
  return axios.put(`/api/organization/media/public/${imageId}`, { title }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

// *********** Driver Shift Settings ****************

const getShiftDetails = () => {
  return axios.get('/api/organization/shift').then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const patchUpdateShift = (shiftId: string, startTime: string, endTime: string, isActive: number, isDefault: number) => {
  return axios.patch(`/api/organization/shift/${shiftId}`, { startTime, endTime, isActive, isDefault }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const deleteShift = (shiftId: string) => {
  return axios.delete(`/api/organization/shift/${shiftId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const putAddDriverShift = (shift: object) => {
  return axios.put('/api/organization/shift', { ...shift }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const getShiftPrice = (shiftId: string) => {
  return axios.get(`/api/organization/vehiclePrice/?orgShiftId=${shiftId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const patchUpdateVehicleShiftPrice = (organizationShiftId: string, orgShiftData: object) => {
  return axios.put(`/api/organization/vehiclePrice/${organizationShiftId}`, orgShiftData).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const getVehicleDefaultPrice = (vehicleId: string) => {
  return axios.get(`/api/organization/vehiclePrice/?orgVehicleDetailId=${vehicleId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

export {
  getOrganizationsSettings,
  putOrganizationsSettings,
  getMediaByCategory,
  deleteImageFromMedia,
  putUpdateImageTitle,
  getShiftDetails,
  patchUpdateShift,
  deleteShift,
  putAddDriverShift,
  getShiftPrice,
  patchUpdateVehicleShiftPrice,
  getVehicleDefaultPrice
}
