import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, /* Form, */ FormControl, /* FormGroup, */ Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import UserContext from '../../../Context/UserContext'
import { deleteShift, getShiftDetails, patchUpdateShift } from '../../../Services/OrganizationServices'
import { PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { fetchResponseMessage, getStatus } from '../../../utils/utils'
import { ReactDataTable/* , UserListModel */ } from '../../Models/CommonModel'
import PickShiftModal from './PickShiftModal'

type Props = {}

const ShiftSettings = (props: Props) => {
  const { setIsFetching, setArrayOfBreadCrumb, isFetching } = useContext(UserContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [searchBar, setSearchBar] = useState('')
  const [selectedShiftId, setSelectedShiftId] = useState('')
  const [shiftHourArray, setShiftHourArray] = useState([] as Array<{ id: string, startTime: string, endTime: string, isActive: boolean }>)
  const [selectedShiftStartTime, setSelectedShiftStartTime] = useState('')
  const [selectedShiftEndTime, setSelectedShiftEndTime] = useState('')
  const [selectedShiftStatus, setSelectedShiftStatus] = useState(false)

  const fetchShiftDetails = async () => {
    setIsFetching(true)
    try {
      const response = await getShiftDetails()
      if (response.data) {
        const updatedResponse = response.data.map((data: { id: string, startTime: string, endTime: string, isActive: number }, index: number) => (
          {
            ...data,
            status: (
              <div className={`badge badge-pill me-1 ${data.isActive ? 'badge-success' : 'badge-danger'}`}>{getStatus(Number(data.isActive))}</div>
            )
          }
        ))
        setIsFetching(false)
        setShiftHourArray(updatedResponse)
      } else {
        throw response
      }
    } catch (error) {
      console.error('🔥 ~ error', error)
      setIsFetching(false)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }
  useEffect(() => {
    fetchShiftDetails()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.SHIFT_SETTINGS')}`, url: '' }
    ])
    return () => { }
  }, [])

  const editShiftHours = (shiftId: string) => {
    setShowModal(true)
    const selectedShiftObject = shiftHourArray.find(shift => shift.id === shiftId)
    setSelectedShiftStartTime(selectedShiftObject!.startTime)
    setSelectedShiftEndTime(selectedShiftObject!.endTime)
    setSelectedShiftStatus(selectedShiftObject!.isActive)
    setTimeout(() => {
      setSelectedShiftId(shiftId)
    }, 200)
  }

  const deleteShiftHours = async (shiftId: string) => {
    setIsFetching(true)
    try {
      const response = await deleteShift(shiftId)
      if (response.statusCode === 200) {
        setIsFetching(false)
        const responseMessage = fetchResponseMessage(response.responseCode)
        fetchShiftDetails()
        toast.success(responseMessage)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ deleteShiftHours ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  const gotoShiftPriceSettings = (shiftId: string, shiftName: string) => {
    console.log('🚀 ~ file: ShiftSettings.tsx:101 ~ gotoShiftPriceSettings ~ shiftName', shiftName)
    navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_SHIFT_PRICE_PAGE, { state: { shiftId, shiftName } })
  }

  // const dropdownTitleArray = _.compact(['edit', 'delete', 'price settings'].map((title: string) => (
  //   getDropdownTitle(title, MODULE_ID.SHIFT, userPermissions)
  // )))
  const columns: ReactDataTable[] = [
    {
      name: `${t('ORGANIZATION_SHIFT_SETTINGS_PAGE.TABLE.TITLE')}`,
      selector: (row: { title: string }) => row.title,
      sortable: true
    },
    {
      name: `${t('ORGANIZATION_SHIFT_SETTINGS_PAGE.TABLE.START_TIME')}`,
      selector: (row: { startTime: string }) => row.startTime,
      sortable: true
    },
    {
      name: `${t('ORGANIZATION_SHIFT_SETTINGS_PAGE.TABLE.END_TIME')}`,
      selector: (row: { endTime: string }) => row.endTime,
      sortable: true
    },
    {
      name: `${t('USER_LIST_PAGE.USER_TABLE.STATUS')}`,
      selector: (row: { status: any }) => row.status,
      sortable: false
      // cell: (tableRow: { status: number }) => (
      //   <>
      //     <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
      //       <Form.Check type="switch" name="bookingForOther" className="switch" checked={tableRow.status !== -1} onChange={() => null} />
      //     </FormGroup>
      //   </>
      // )
    },
    // {
    //   name: `${t('ORGANIZATION_SHIFT_SETTINGS_PAGE.TABLE.ACTIVE_STATUS')}`,
    //   selector: (row: { status: string }) => row.status,
    //   sortable: true
    // },
    {
      name: '',
      cell: (tableRow: any) => (
        <>
          <span onClick={() => editShiftHours(tableRow.id)} className='mdi mdi-pencil table-setting-icon mx-2 text-primary'></span>
          <span onClick={() => deleteShiftHours(tableRow.id)} className='mdi mdi-trash-can-outline table-setting-icon mx-2 text-danger delete'></span>
          <span onClick={() => gotoShiftPriceSettings(tableRow.id, tableRow.title)} className='mdi mdi-file-document table-setting-icon mx-2 text-primary delete'></span>
        </>
      )
    }
    // {
    //   name: `${t('ACTION_DROPDOWN.TITLE')}`,
    //   cell: (tableRow: any) => (
    //     <TableActionDropdown
    //       dropdownTitle={dropdownTitleArray}
    //       dropdownItemId={tableRow.id}
    //       dropdownNameOrBadge={`${t('ACTION_DROPDOWN.TITLE')}`}
    //       dropdownItemOnClickHandler={ActionButtonClickHandler}
    //     />
    //   )
    // }
  ]

  /**
 * @function ActionButtonClickHandler
 * @return void
 * @param action
 * @param itemId
 * @description Handle Edit,Delete and View options in Action Button and redirect to function according to function call.
 */
  // const ActionButtonClickHandler = (action: number, itemId: string) => {
  //   switch (action) {
  //     case DROPDOWN_OPTIONS_ID.EDIT:
  //       editShiftHours(itemId)
  //       break
  //     case DROPDOWN_OPTIONS_ID.DELETE:
  //       deleteShiftHours(itemId)
  //       break
  //     case DROPDOWN_OPTIONS_ID.PRICE_SETTINGS:
  //       gotoShiftPriceSettings(itemId)
  //       break
  //     default:
  //       break
  //   }
  // }

  const editShift = async (shiftId: string, startTime: string, endTime: string, isActive: boolean) => {
    setIsFetching(false)
    try {
      const isDefault = 0
      const response = await patchUpdateShift(shiftId, startTime, endTime, Number(isActive), isDefault)
      if (response.statusCode === 200) {
        setIsFetching(false)
        setShowModal(false)
        fetchShiftDetails()
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ editShift ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  const searchBarHandler = (value: string) => {
    setSearchBar(value)
  }

  /*   const gotoAddDriverShift = () => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_SHIFT_SETTINGS}/add`)
  } */
  return (
    <>
      <PickShiftModal
        selectedShiftId={selectedShiftId}
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        modalTitle={t('ORGANIZATION_SHIFT_SETTINGS_PAGE.MODAL_TITLE')}
        editShift={editShift}
        selectedShiftStartTime={selectedShiftStartTime}
        selectedShiftEndTime={selectedShiftEndTime}
        shiftStatus={selectedShiftStatus}
      />
      <Row>
        <Col sm={4} className='mb-4'>
          <FormControl
            type="text"
            className="form-control ms-auto search-bar"
            placeholder={`${t('DESIGNATION_PAGE.SEARCH_BAR_PLACEHOLDER')}`}
            onChange={(event) => searchBarHandler(event.target.value)}
            value={searchBar}
          />
        </Col>
      </Row>
      <Card className="shift-settings-table">
        <Card.Body>
          <Row>
            <Col>
              {/* TODO: make this Add button visible in future and handle this search as clint side searching */}
              <div>
                <div className='d-flex align-items-center justify-content-between'>
                </div>
                <h3 className="mb-3"></h3>
                <Row>
                  <Col>
                    {isFetching ? (
                      <div className="loading d-block mx-auto">
                        <div className="spinner-border"></div>
                      </div>
                    ) : shiftHourArray.length === 0 ? (
                      <div className="text-center font-weight-bold">{`${t('ORGANIZATION_SHIFT_SETTINGS_PAGE.TABLE.NO_DATA_FOUND')}`}</div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={shiftHourArray}
                        className="table-with-pagination"
                        highlightOnHover
                        pagination
                        paginationComponentOptions={{
                          rowsPerPageText: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.TABLE.ROW_PER_PAGE')}`,
                          rangeSeparatorText: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.TABLE.RANGE_SELECTION')}`
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default ShiftSettings
