import { FormikValues, useFormik } from 'formik'
import _ from 'lodash'
import React, { useContext, useEffect } from 'react'
import { Button, Col, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import UserContext from '../../../Context/UserContext'
import { putAddDriverShift } from '../../../Services/OrganizationServices'
import { PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../utils/utils'
import { AddShiftSchema } from './AddShiftSchema'

type Props = {}

const AddShift = (props: Props) => {
  const { t } = useTranslation()
  const { setArrayOfBreadCrumb, setIsFetching } = useContext(UserContext)

  useEffect(() => {
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.SHIFT_SETTINGS')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ORGANIZATION_SHIFT_SETTINGS },
      { name: `${t('PAGE_NAME.ADD_SHIFT')}`, url: '' }
    ])
    return () => { }
  }, [])

  const initialShiftInfo = {
    title: '',
    startTime: '',
    endTime: '',
    isActive: false
  }
  const formik = useFormik({
    initialValues: initialShiftInfo,
    validationSchema: () => AddShiftSchema,
    onSubmit: (values: FormikValues, formik) => {
      addShift(values, formik)
    }
  })
  const addShift = async (formValue: FormikValues, { resetForm }: FormikValues) => {
    setIsFetching(true)
    try {
      const createdShift = {
        title: formik.values.title,
        startTime: formik.values.startTime,
        endTime: formik.values.endTime,
        // isActive: Number(formik.values.isActive),
        // TODO : For now i am adding default 1 please change it later once you will add switch
        isActive: 1,
        isDefault: 0
      }
      const response = await putAddDriverShift({ ...createdShift })
      if (response.statusCode === 200) {
        setIsFetching(false)
        resetForm()
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
      } else {
        throw response
      }
    } catch (error) {
      console.error('🔥 ~ addShift ~ error', error)
      setIsFetching(false)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  const handleInputChange = (value: string, inputName: string) => {
    formik.setFieldValue(inputName, value)
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col sm={4} className={'my-2'}>
          <FormGroup>
            <FormLabel>{`${t('INPUT_FIELD_ENUMS.SHIFT_TITLE.LABEL_NAME')}`}</FormLabel>
            <FormControl name='title' placeholder={`${t('INPUT_FIELD_ENUMS.SHIFT_TITLE.PLACEHOLDER')}`}
              value={formik.values.title}
              onChange={(event) => handleInputChange(event.target.value, event.target.name)}
            ></FormControl>
          </FormGroup>
          {formik.errors.title ? <div className='text-danger'>{`${formik.errors.title}`}</div> : null}
        </Col>
      </Row>
      <Row>
        <Col sm={4} className={'my-2'}>
          <FormGroup>
            <FormLabel>{`${t('INPUT_FIELD_ENUMS.SHIFT_START_TIME.LABEL_NAME')}`}</FormLabel>
            <FormControl name={'startTime'} type={'time'} step='2' placeholder={`${t('INPUT_FIELD_ENUMS.SHIFT_START_TIME.PLACEHOLDER')}`}
              value={formik.values.startTime}
              onChange={(event) => handleInputChange(event.target.value, event.target.name)}></FormControl>
            {formik.errors.startTime ? <div className='text-danger'>{`${formik.errors.startTime}`}</div> : null}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={4} className={'my-2'}>
          <FormGroup>
            <FormLabel>{`${t('INPUT_FIELD_ENUMS.SHIFT_END_TIME.LABEL_NAME')}`}</FormLabel>
            <FormControl name={'endTime'} type={'time'} step='2' placeholder={`${t('INPUT_FIELD_ENUMS.SHIFT_END_TIME.PLACEHOLDER')}`}
              value={formik.values.endTime}
              onChange={(event) => handleInputChange(event.target.value, event.target.name)}
            ></FormControl>
            {formik.errors.endTime ? <div className='text-danger'>{`${formik.errors.endTime}`}</div> : null}
          </FormGroup>
        </Col>
      </Row>
      <div>
        <Button className='d-block me-auto mt-3' type='submit'>
          {`${t('PROJECT_BUTTON.ADD')}`}
        </Button>
      </div>
    </Form>
  )
}

export default AddShift
