// TODO: While Https status code 409, it is not showing any error message
// TODO: set min max date in date of births date
// TODO: redirect to login if registration  successful
import { Formik, FormikValues } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as _ from 'lodash'

import { registrationSchema } from './RegistrationSchema'
import { CityDataModel, CountryDataModel, OptionDataModel, ReactSelectOptionModel, SelectDropdownModel, StateDataModel } from './UserRegistrationModel'
import { postSignUpUser } from '../../Services/AuthService'
import { getCities, getCountries, getStates } from '../../Services/CommonService'
import FormInputCheckBox from '../../Components/Form/FormInputCheckBox/FormInputCheckBox'
import FormInputSelect from '../../Components/Form/FormInputSelect/FormInputSelect'
import FormInputText from '../../Components/Form/FormInputText/FormInputText'
import FormButton from '../../Components/Form/FormButton/FormButton'
import { INPUT_FIELD_ENUMS, PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { ActionMeta, SingleValue } from 'react-select'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'react-bootstrap'
import UserContext from '../../Context/UserContext'
import { toast } from 'react-toastify'
import { fetchResponseMessage } from '../../utils/utils'

type Props = {}
const Register = (props: Props) => {
  const { t } = useTranslation()
  const { isFetching, setIsFetching } = useContext(UserContext)
  const navigate = useNavigate()
  const [countriesCodeOptions, setCountriesCodeOptions] = useState([] as undefined | OptionDataModel)
  const [countriesOptions, setCountriesOptions] = useState([] as undefined | OptionDataModel)
  const [stateOptions, setStateOptions] = useState([] as undefined | OptionDataModel)
  const [citiesOptions, setCitiesOptions] = useState([] as undefined | OptionDataModel)
  const [selectedCountry, setSelectedCountry] = useState<SingleValue<SelectDropdownModel>>({} as SelectDropdownModel)
  const [selectedState, setSelectedState] = useState<SingleValue<SelectDropdownModel>>({} as SelectDropdownModel)
  const [selectedCity, setSelectedCity] = useState<SingleValue<SelectDropdownModel>>({} as SelectDropdownModel)
  const [selectedCityId, setSelectedCityId] = useState<number | null>(null)
  const [isDisable, setIsDisable] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState<SingleValue<SelectDropdownModel>>({} as SelectDropdownModel)
  const [isChecked, setIsChecked] = useState(true)

  const initialUserInfo = {
    firstName: '',
    middleName: '',
    lastName: '',
    country: '',
    state: '',
    city: '',
    address: '',
    email: '',
    countryCode: '',
    phone: '',
    dob: '',
    password: '',
    confirmPassword: ''
  }

  useEffect(() => {
    ; (async function () {
      try {
        // This axios call will fetch the list of countries while page load first time
        const response = await getCountries()
        if (response.status) {
          // debugger
          const countryCode: ReactSelectOptionModel = []
          const countries: ReactSelectOptionModel = response.data.map((responseData: CountryDataModel) => {
            countryCode.push({
              value: '+' + responseData.phoneCode,
              label: '+' + responseData.phoneCode,
              id: responseData.id,
              inputType: 'countryCode'
            })
            return {
              value: responseData.name,
              label: responseData.name,
              id: responseData.id,
              inputType: 'country',
              phoneCode: responseData.phoneCode
            }
          })
          setCountriesOptions(countries)
          setCountriesCodeOptions(countryCode)
        } else {
          throw response
        }
      } catch (error) {
        console.error('🚀 ~ file: RegisterForm.tsx ~ line 74 ~ useEffect ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
        // toast.error(`${t('TOAST_ERROR.REGISTER_FORM.COUNTRY_FETCH_FIELD')}`)
      }
    })()
    return () => { }
  }, [])

  /**
   * @function setSelection
   * @return null
   * @param selection
   * @param formik
   * @description To Handle selection of react select for country, state, city and country code and function and to call axios call for dropdown options as per previous selection change
   *
   */
  const setSelection = async (selection: SingleValue<SelectDropdownModel>, formik: FormikValues) => {
    if (selection && selection.inputType === 'country') {
      const previousCountry = selectedCountry && selectedCountry.value ? selectedCountry.value : null
      setSelectedCountry(selection)
      setSelectedState(null)
      setSelectedCityId(null)
      setSelectedCity(null)
      formik.values.country = selection
      formik.values.state = ''
      formik.values.city = ''
      const newObj: {
        value: string
        label: string
        id: number | undefined
        inputType: string
      } = {
        value: '+' + selection.phoneCode,
        label: '+' + selection.phoneCode,
        id: selection.id,
        inputType: 'countyCode'
      }
      formik.values.countryCode = newObj
      setSelectedCountryCode(newObj)
      if (selection.value !== previousCountry) {
        try {
          const response = await getStates(selection.id!)
          if (response.status) {
            const states: ReactSelectOptionModel = response.data.map((responseData: StateDataModel) => ({
              value: responseData.name,
              label: responseData.name,
              id: responseData.id,
              inputType: 'state'
            }))
            setStateOptions(states)
          } else {
            throw response
          }
        } catch (error) {
          console.error('🚀 ~ file: RegisterForm.tsx ~ line 95 ~ setSelection ~ error', error)
          if (error && _.has(error, 'response.data.responseCode')) {
            // @ts-ignore
            const responseMessage = fetchResponseMessage(error.response.data.responseCode)
            toast.error(responseMessage)
          }
          // toast.error(`${t('TOAST_ERROR.REGISTER_FORM.STATE_FETCH_FIELD')}`)
        }
      }
    } else if (selection && selection.inputType === 'state') {
      const previousState = selectedCountry && selectedCountry.value ? selectedCountry.value : null
      setSelectedState(selection)
      setSelectedCityId(null)
      setSelectedCity(null)
      formik.values.state = selection
      formik.values.city = ''
      if (selection.value !== previousState) {
        try {
          const response = await getCities(selection.id!)
          if (response.status) {
            const cities: ReactSelectOptionModel = response.data.map((responseData: CityDataModel) => ({
              value: responseData.name,
              label: responseData.name,
              id: responseData.id,
              inputType: 'city'
            }))
            setCitiesOptions(cities)
          } else {
            throw response
          }
        } catch (error) {
          console.error('🚀 ~ file: RegisterForm.tsx ~ line 116 ~ setSelection ~ error', error)
          if (error && _.has(error, 'response.data.responseCode')) {
            // @ts-ignore
            const responseMessage = fetchResponseMessage(error.response.data.responseCode)
            toast.error(responseMessage)
          }
          // toast.error(`${t('TOAST_ERROR.REGISTER_FORM.CITY_FETCH_FIELD')}`)
        }
      }
    } else if (selection && selection.inputType === 'city') {
      setSelectedCity(selection)
      setSelectedCityId(selection.id!)
      formik.values.city = selection
    }
  }

  /**
   * @function checkBoxToggle
   * @param event
   * @returns void
   * @description To toggle check box and to disable singUp button
   * @author Sagar Agravat
   */
  const checkBoxToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setIsChecked(!isChecked)
      setIsDisable(false)
    } else {
      setIsChecked(!isChecked)
      setIsDisable(true)
    }
  }

  /**
   * @function handleSubmit
   * @param values
   * @returns null
   * @description While user click on register button this function is getting called
   * @author Sagar Agravat
   */
  const handleSubmit = async (values: FormikValues, { resetForm }: FormikValues) => {
    setIsFetching(true)
    try {
      let newValues = _.omit(values, ['city', 'country', 'state', 'phone', 'confirmPassword'])
      if (values.dob === '') {
        newValues = _.omit(newValues, ['dob'])
      }
      newValues = {
        ...newValues,
        cityId: selectedCityId && +selectedCityId,
        mobile: +values.phone
      }
      newValues.countryCode = Number(values.countryCode.value)
      const response = await postSignUpUser(newValues)
      // TODO : Get this status code from constant and get the response code
      if (response.statusCode === 201) {
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
        // toast.success(`${t('TOAST_ERROR.REGISTER_FORM.SUBMIT_SUCCESS')}`)
        navigate(PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.LOGIN_PAGE)
        setIsFetching(false)
        resetForm()
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🚀 ~ file: RegisterForm.tsx ~ line 179 ~ handleSubmit ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
      // toast.error(`${t('TOAST_ERROR.REGISTER_FORM.SUBMIT_FIELD')}`)
    }
  }

  /*  const formik = useFormik({
    initialValues: initialUserInfo,
    validationSchema: () => registrationSchema,
    onSubmit: (values: FormikValues, formik) => {
      handleSubmit(values, formik)
    }
  })
  */
  return (
    <Formik
      initialValues={initialUserInfo}
      validationSchema={() => registrationSchema}
      onSubmit={(values: FormikValues, formik) => {
        handleSubmit(values, formik)
      }}
    >
      {(formik: FormikValues) => (
        <Form className="pt-3" onSubmit={formik.handleSubmit}>
          <Row>
            <Col sm={4}>
              <FormInputText
                inputName={INPUT_FIELD_ENUMS.FIRST_NAME.INPUT_NAME}
                type={INPUT_FIELD_ENUMS.FIRST_NAME.INPUT_TYPE}
                isRequired={INPUT_FIELD_ENUMS.FIRST_NAME.IS_REQUIRED}
                inputField={'FIRST_NAME'}
              />
            </Col>
            <Col sm={4}>
              <FormInputText
                inputName={INPUT_FIELD_ENUMS.MIDDLE_NAME.INPUT_NAME}
                type={INPUT_FIELD_ENUMS.MIDDLE_NAME.INPUT_TYPE}
                isRequired={INPUT_FIELD_ENUMS.MIDDLE_NAME.IS_REQUIRED}
                inputField={'MIDDLE_NAME'}
              />
            </Col>
            <Col sm={4}>
              <FormInputText
                inputName={INPUT_FIELD_ENUMS.LAST_NAME.INPUT_NAME}
                type={INPUT_FIELD_ENUMS.LAST_NAME.INPUT_TYPE}
                isRequired={INPUT_FIELD_ENUMS.LAST_NAME.IS_REQUIRED}
                inputField={'LAST_NAME'}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <FormInputSelect
                inputField={'COUNTRY'}
                isRequired={INPUT_FIELD_ENUMS.COUNTRY.IS_REQUIRED}
                inputName={INPUT_FIELD_ENUMS.COUNTRY.INPUT_NAME}
                options={countriesOptions}
                defaultSelected={selectedCountry}
                handleSelection={(event: SingleValue<SelectDropdownModel>, action: ActionMeta<SelectDropdownModel>) => {
                  setSelection(event, formik)
                }}
                isMulti={false}
              />
            </Col>
            <Col sm={4}>
              <FormInputSelect
                inputField={'STATE'}
                isRequired={INPUT_FIELD_ENUMS.STATE.IS_REQUIRED}
                inputName={INPUT_FIELD_ENUMS.STATE.INPUT_NAME}
                options={stateOptions}
                defaultSelected={selectedState}
                handleSelection={(event: SingleValue<SelectDropdownModel>) => {
                  setSelection(event, formik)
                }}
                isMulti={false}
              />
            </Col>
            <Col sm={4}>
              <FormInputSelect
                inputField={'CITY'}
                isRequired={INPUT_FIELD_ENUMS.CITY.IS_REQUIRED}
                inputName={INPUT_FIELD_ENUMS.CITY.INPUT_NAME}
                options={citiesOptions}
                defaultSelected={selectedCity}
                handleSelection={(event: SingleValue<SelectDropdownModel>) => {
                  setSelection(event, formik)
                }}
                isMulti={false}
              />
            </Col>
          </Row>
          <FormInputText
            inputName={INPUT_FIELD_ENUMS.ADDRESS.INPUT_NAME}
            type={INPUT_FIELD_ENUMS.ADDRESS.INPUT_TYPE}
            isRequired={INPUT_FIELD_ENUMS.ADDRESS.IS_REQUIRED}
            inputField={'ADDRESS'}
          />
          <FormInputText
            inputName={INPUT_FIELD_ENUMS.EMAIL_ADDRESS.INPUT_NAME}
            type={INPUT_FIELD_ENUMS.EMAIL_ADDRESS.INPUT_TYPE}
            isRequired={INPUT_FIELD_ENUMS.EMAIL_ADDRESS.IS_REQUIRED}
            inputField={'EMAIL_ADDRESS'}
          />
          <Row>
            <Col xl={2} xs={4}>
              <FormInputSelect
                inputField={'COUNTRY_CODE'}
                isRequired={INPUT_FIELD_ENUMS.COUNTRY_CODE.IS_REQUIRED}
                inputName={INPUT_FIELD_ENUMS.COUNTRY_CODE.INPUT_NAME}
                defaultSelected={selectedCountryCode}
                handleSelection={(event: SingleValue<SelectDropdownModel>) => {
                  formik.values.countryCode = event
                  setSelectedCountryCode(event)
                }}
                options={countriesCodeOptions}
                isMulti={false}
              />
            </Col>
            <Col xl={5} xs={4}>
              <FormInputText
                inputName={INPUT_FIELD_ENUMS.PHONE_NUMBER.INPUT_NAME}
                type={INPUT_FIELD_ENUMS.PHONE_NUMBER.INPUT_TYPE}
                isRequired={INPUT_FIELD_ENUMS.PHONE_NUMBER.IS_REQUIRED}
                inputField={'PHONE_NUMBER'}
              />
            </Col>
            <Col xl={5} xs={4}>
              <FormInputText
                inputName={INPUT_FIELD_ENUMS.DATE_OF_BIRTH.INPUT_NAME}
                type={INPUT_FIELD_ENUMS.DATE_OF_BIRTH.INPUT_TYPE}
                isRequired={INPUT_FIELD_ENUMS.DATE_OF_BIRTH.IS_REQUIRED}
                inputField={'DATE_OF_BIRTH'}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormInputText
                inputName={INPUT_FIELD_ENUMS.PASSWORD.INPUT_NAME}
                type={INPUT_FIELD_ENUMS.PASSWORD.INPUT_TYPE}
                isRequired={INPUT_FIELD_ENUMS.PASSWORD.IS_REQUIRED}
                inputField={'PASSWORD'}
                class="date-picker"
              />
            </Col>
            <Col xs={6}>
              <FormInputText
                inputName={INPUT_FIELD_ENUMS.CONFIRM_PASSWORD.INPUT_NAME}
                type={INPUT_FIELD_ENUMS.CONFIRM_PASSWORD.INPUT_TYPE}
                isRequired={INPUT_FIELD_ENUMS.CONFIRM_PASSWORD.IS_REQUIRED}
                inputField={'CONFIRM_PASSWORD'}
              />
            </Col>
          </Row>
          <div className="form-check">
            <label className="form-check-label text-muted">
              <FormInputCheckBox checkBoxToggle={checkBoxToggle} isChecked={isChecked} />
              {`${t('FORM_PAGE_CONSTANTS.CHECKBOX_CONDITION.REGISTER_PAGE')} ${t('FORM_PAGE_CONSTANTS.REDIRECT_LINK.TERMS_CONDITION')}`}
              {/* <Link to={PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.REGISTRATION_TERMS_CONDITIONS_PAGE}>{`${t('FORM_PAGE_CONSTANTS.REDIRECT_LINK.TERMS_CONDITION')}`}</Link> */}
            </label>
          </div>
          <FormButton buttonName={`${t('FORM_PAGE_CONSTANTS.BUTTON_NAME.REGISTER_PAGE')}`} isDisable={isDisable} isFetching={isFetching} />
          <div className="text-center mt-4 font-weight-light">
            {`${`${t('FORM_PAGE_CONSTANTS.BOTTOM_LINE.REGISTER_PAGE')}`}`}&nbsp;
            <Link to={PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.LOGIN_PAGE} className="text-primary">
              {`${t('FORM_PAGE_CONSTANTS.BUTTON_NAME.LOGIN_PAGE')}`}
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Register
