import React, { useContext, useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import UserContext from '../../../Context/UserContext'
import { MenuData } from '../../../Pages/Models/CommonModel'
import SubMenus from './SubMenus/SubMenus'

type Props = {
  menuData: MenuData
  index: string
  id: string
  isMainActive: boolean
}

const MainMenuComponent = (props: Props) => {
  const [open, setOpen] = useState(false)
  const { showSidebar } = useContext(UserContext)
  useEffect(() => {
    props.isMainActive && showSidebar ? setOpen(true) : setOpen(false)
  }, [showSidebar])
  return (
    <>
      <li className={`nav-item ${open ? 'show' : ''} ${props.isMainActive ? 'active' : ''}`}>
        <a className="nav-link collapsed" onClick={showSidebar ? () => setOpen(!open) : () => { }} aria-expanded={open && showSidebar} id={props.id} aria-controls={props.id}>
          {props.menuData.icon}
          <span className="menu-title">{props.menuData.name}</span>
          <i className="menu-arrow"></i>
        </a>
        <Collapse in={open}>
          <div id={props.id}>
            <ul className="nav flex-column sub-menu pt-0">{props.menuData && props.menuData.children && <SubMenus menuData={props.menuData} />}</ul>
          </div>
        </Collapse>
      </li>
    </>
  )
}

export default MainMenuComponent
