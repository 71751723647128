import i18n from '../../i18n'
import * as yup from 'yup'
import { AUTH_ENUMS } from '../../utils/enums/enums'

export const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.EMAIL_REQUIRED')}`)
    .email(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.EMAIL_INVALID')}`)
    .lowercase(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.EMAIL_LOWER_CASE')}`)
    .max(AUTH_ENUMS.MAX_VALUE_Email, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.EMAIL_MAX_LENGTH')}`),
  password: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_REQUIRED')}`)
    .min(AUTH_ENUMS.MIN_VALUE_PASSWORD, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_MIN_LENGTH')}`)
    .max(AUTH_ENUMS.MAX_VALUE_PASSWORD, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_MAX_LENGTH')}`)
})
