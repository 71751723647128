import React from 'react'
import Select, { ActionMeta, createFilter, MultiValue, SingleValue } from 'react-select'
import { ErrorMessage, useField } from 'formik'
import { OptionDataModel, SelectDropdownModel } from '../../../Pages/Register/UserRegistrationModel'
import i18n from '../../../i18n'
import { FormGroup, FormLabel } from 'react-bootstrap'

type Props = {
  inputName: string
  options?: OptionDataModel
  colClass?: string
  defaultSelected?: SingleValue<SelectDropdownModel> | MultiValue<SelectDropdownModel>,
  inputField?: string
  isRequired: boolean
  handleSelection?: (newValue: any, actionMeta: ActionMeta<SelectDropdownModel>) => void
  isMulti?: boolean
  readOnly?: boolean
}

const FormInputSelect = (props: Props) => {
  const [field] = useField(props.inputName)

  return (
    <FormGroup className={`form-group ${props.colClass && props.colClass}`}>
      <FormLabel htmlFor="exampleFormControlSelect2">
        {props.isRequired ? `${i18n.t(`INPUT_FIELD_ENUMS.${props.inputField}.LABEL_NAME`) + '*'}` : `${i18n.t(`INPUT_FIELD_ENUMS.${props.inputField}.LABEL_NAME`)}`}
      </FormLabel>
      <Select
        isMulti={props.isMulti}
        isDisabled={!!props.readOnly}
        name={field.name}
        placeholder={`${i18n.t(`INPUT_FIELD_ENUMS.${props.inputField}.PLACEHOLDER`)}`}
        options={props.options}
        className="form-control form-control-lg p-0 mb-1 react-select-menu"
        classNamePrefix={'react-select'}
        value={field.value}
        onChange={props.handleSelection}
        captureMenuScroll={true}
        filterOption={createFilter({ matchFrom: 'start' })}
        components={field.name === 'countryCode' ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : { IndicatorSeparator: () => null }}
      />
      <ErrorMessage component="div" name={field.name} className="form-error" />
    </FormGroup>
  )
}

export default FormInputSelect
