import { FormikValues } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'
import VehicleCreateViewEdit from '../../../Components/Common/VehicleCreateViewEdit'
import UserContext from '../../../Context/UserContext'
import { PROJECT_ROUTES_ENUMS, VEHICLE_TYPE } from '../../../utils/enums/enums'
import { VehiclePriceModel } from '../../Models/CommonModel'
import { OptionDataModel, SelectDropdownModel } from '../../Register/UserRegistrationModel'

type Props = {}
const AddVehicle = (props: Props) => {
  const { t } = useTranslation()
  const { setArrayOfBreadCrumb } = useContext(UserContext)
  const [vehicleIsActiveState, setVehicleIsActiveState] = useState(false)
  const [vehicleNameState, setVehicleNameState] = useState('')
  const [vehicleDescriptionState, setVehicleDescriptionState] = useState('')
  const [vehicleTypeState, setVehicleTypeState] = useState<SingleValue<SelectDropdownModel>>()
  const [seatingCapacityState, seSeatingCapacityState] = useState<number>(0)
  const [vehicleImageState, setVehicleImageState] = useState('')
  const [averageSpeedState, setAverageSpeedState] = useState(500)
  const [vehicleMapIconState, setVehicleMapIconState] = useState('')
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([] as OptionDataModel)
  const [vehicleDefaultPrice, setVehicleDefaultPrice] = useState({} as VehiclePriceModel)

  useEffect(() => {
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST_ADD')}`, url: '' }
    ])
    setVehicleIsActiveState(false)
    setVehicleNameState('')
    setVehicleDescriptionState('')
    setVehicleMapIconState('')
    setVehicleTypeOptions(Object.keys(VEHICLE_TYPE).map((vehicleType: any) => ({ value: vehicleType, label: vehicleType })))
    seSeatingCapacityState(0)
    setVehicleImageState('')
    setAverageSpeedState(500)
    setVehicleDefaultPrice({
      additionalPersonCharge: 0,
      baseRate: 0,
      cancelRate: 0,
      distanceRate: 0,
      durationRate: 0,
      initialDistance: 0,
      initialDuration: 0,
      initialPickupDistance: 0,
      initialWaitTime: 0,
      luggageCharge: 0,
      pickupDistanceRate: 0,
      stopRate: 0,
      surCharge: 0,
      waitRate: 0,
      orgVehicleDetailId: '',
      id: ''
    })

    return () => { }
  }, [])

  const setFieldValue = (formik: FormikValues) => {
    setVehicleIsActiveState(formik.values.vehicleIsActive)
    setVehicleNameState(formik.values.vehicleName)
    setVehicleDescriptionState(formik.values.vehicleDescription)
    setVehicleTypeState(formik.values.vehicleType)
    seSeatingCapacityState(formik.values.seatingCapacity)
    setVehicleMapIconState(formik.values.vehicleMapIcon)
  }

  return (
    <VehicleCreateViewEdit
      field={{
        vehicleName: vehicleNameState,
        vehicleDescription: vehicleDescriptionState,
        vehicleType: vehicleTypeState,
        seatingCapacity: seatingCapacityState,
        vehicleIsActive: vehicleIsActiveState,
        vehicleImage: vehicleImageState,
        averageSpeed: averageSpeedState,
        option: vehicleTypeOptions,
        vehicleMapIcon: vehicleMapIconState,
        setVehicleIsActiveState
      }}
      isDisabled={false}
      cardTitle={`${t('PAGE_TITLE.ADD_VEHICLE')}`}
      submitButtonName="ADD"
      setFieldValue={setFieldValue}
      pageName="add-vehicle"
      vehiclePrice={vehicleDefaultPrice}
    />
  )
}

export default AddVehicle
