import _ from 'lodash'
import moment from 'moment'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
import { getBookingDetails } from '../../Services/BookingService'
import { PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { fetchResponseMessage } from '../../utils/utils'
import { useParams } from 'react-router-dom'
import RideTrack from '../../Components/GoogleMap/RideTrack'

type Props = {}

const BookingDetails = (props: Props) => {
  const params = useParams()
  const bookingId = params.itemId
  const { t } = useTranslation()
  const { setArrayOfBreadCrumb, setIsFetching } = useContext(UserContext)
  const [bookingDetails, setBookingDetails] = React.useState<any>(null)

  const fetchBookingDetails = async () => {
    setIsFetching(true)
    try {
      const response = await getBookingDetails(`${bookingId}`)
      if (response.data) {
        console.log('🚀 ~ file: BookingDetails.tsx:24 ~ fetchBookingDetails ~ response.data', response.data)
        setBookingDetails(response.data)
        setIsFetching(false)
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🚀 ~ file: Designation.tsx ~ line 73 ~ useEffect ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  useEffect(() => {
    fetchBookingDetails()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.BOOKING_DETAILS')}`, url: '' }
    ])
    return () => { }
  }, [])
  return (
    <div className='content-wrapper'>
      <div className="row">
        <div className="col-md-6 my-2">
          <div className="card">
            <div className="card-header">
              <h3>Booking Details</h3>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Pickup Distance</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.pickupDistance ? bookingDetails?.pickupDistance : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Total Duration</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.totalDuration ? bookingDetails?.totalDuration : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Total Distance</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.totalDistance ? bookingDetails?.totalDistance : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Average Speed</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.avgSpeed ? bookingDetails?.avgSpeed : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Average Time</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.avgTime ? bookingDetails?.avgTime : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Total Stops</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.totalStops ? bookingDetails?.totalStops : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Vehicle Name</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.orgVehicleName ? bookingDetails?.orgVehicleName : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Booking Status</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.bookingStatus ? bookingDetails?.bookingStatus : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Booking Time</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.bookingTime ? moment(bookingDetails?.bookingTime).format('YYYY-MM-DD, h:mm:ss a') : 'N/A'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Payment Type</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.paymentType ? bookingDetails?.paymentType : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Payment Status</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.paymentStatus ? bookingDetails?.paymentStatus : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Booking Type</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.bookingType ? bookingDetails?.bookingType : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Location Start Address</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.locationData[0].properties.address ? bookingDetails?.locationData[0].properties.address : 'N/A'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Location End Address</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.locationData[bookingDetails?.locationData.length - 1].properties.address ? bookingDetails?.locationData[bookingDetails?.locationData.length - 1].properties.address : 'N/A'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Vehicle Image</p>
                <img alt='Vehicle Image' src={bookingDetails?.orgVehicleIcon} className="mb-2 font-weight-bold card-details title-value" height = "100" width="100"></img>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 my-2">
          <div className="card">
            <div className="card-header">
              <h3>Booking Pricing</h3>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Total Price</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.totalPriceEstimation ? bookingDetails?.totalPriceEstimation : 'N/A' } </p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Base Rate</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.baseRate ? bookingDetails?.baseRate : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Payable Distance Amt.</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.payableDistanceAmount ? bookingDetails?.payableDistanceAmount : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Payable Duration Amt.</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.payableDurationAmount ? bookingDetails?.payableDurationAmount : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Payable Stops Amt.</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.payableStopAmount ? bookingDetails?.payableStopAmount : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Payable Pickup Distance Amt.</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.payablePickupDistanceAmount ? bookingDetails?.payablePickupDistanceAmount : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Pickup Distance Amt.</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.pickupDistance ? bookingDetails?.pickupDistance : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Payable Waiting Time Amt.</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.payableWaitingTimeAmount ? bookingDetails?.payableWaitingTimeAmount : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Tax Amount</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.taxAmount ? bookingDetails?.taxAmount : '0'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Total Price</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.totalPrice ? bookingDetails?.totalPrice : '0'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 my-2">
          <div className="card">
            <div className="card-header">
              <h3>User Details</h3>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">User Name</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.userName ? bookingDetails?.userName : 'N/A'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Cancel Reason</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.cancelReasonByUser ? bookingDetails?.cancelReasonByUser : 'N/A'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Rating</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.userRating ? bookingDetails?.userRating : 'N/A'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Review</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.userReview ? bookingDetails?.userReview : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 my-2">
          <div className="card">
            <div className="card-header">
              <h3>Driver Details</h3>
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Driver Name</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.driverName ? bookingDetails?.driverName : 'N/A'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Cancel Reason</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.cancelReasonByDriver ? bookingDetails?.cancelReasonByDriver : 'N/A'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Rating</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.driverRating ? bookingDetails?.driverRating : 'N/A'}</p>
              </div>
              <div className="d-flex justify-content-between my-1 col-12">
                <p className="mb-2 font-weight-bold card-details title-name">Review</p>
                <p className="mb-2 font-weight-bold card-details title-value">{bookingDetails?.driverReview ? bookingDetails?.driverReview : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 my-2">
          <div className="card">
            <div className="card-header">
              <h3>Booking Track</h3>
            </div>
            <div className="card-body">
              {
                bookingDetails?.ridePath ?
                <RideTrack bookingDetails={bookingDetails} />
                  :
                  null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingDetails
