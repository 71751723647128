export const API_URL = {
  BASE_URL: process.env.REACT_APP_BASE_URL
}

export const AUTH_ENUMS = Object.freeze({
  MAX_VALUE_NAME: 50,
  MAX_VALUE_Email: 250,
  MAX_VALUE_ADDRESS: 250,
  MAX_VALUE_PHONE_NUMBER: 999999999999,
  MIN_VALUE_PHONE_NUMBER: 100000,
  MAX_VALUE_PASSWORD: 18,
  MIN_VALUE_PASSWORD: 6
})

export const PROJECT_VARIABLES = Object.freeze({
  DEFAULT_PAGE_TITLE: 'Welcome to Colleague Cab', // Default page title when no breadcrumb is present
  PAGE_TITLE_PREFIX: '', // This is the prefix for the every page title
  PAGE_TITLE_SUFFIX: ' | Colleague' // This is the suffix for the every page title
})

export const LANGUAGES = [
  {
    LANGUAGE_ID: 1,
    LANGUAGE_NAME: 'English',
    LANGUAGE_SELECTOR: 'en'
  },
  {
    LANGUAGE_ID: 2,
    LANGUAGE_NAME: 'French',
    LANGUAGE_SELECTOR: 'fr'
  }
]

export const INPUT_FIELD_ENUMS = Object.freeze({
  FIRST_NAME: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'firstName'
  },
  LAST_NAME: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'lastName'
  },
  MIDDLE_NAME: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'middleName'
  },
  COUNTRY: {
    PLACEHOLDER: 'Country',
    LABEL_NAME: 'Country',
    IS_REQUIRED: true,
    INPUT_TYPE: '',
    INPUT_NAME: 'country'
  },
  STATE: {
    PLACEHOLDER: 'State',
    LABEL_NAME: 'State',
    IS_REQUIRED: true,
    INPUT_TYPE: '',
    INPUT_NAME: 'state'
  },
  CITY: {
    PLACEHOLDER: 'City',
    LABEL_NAME: 'city',
    IS_REQUIRED: true,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'city'
  },
  ADDRESS: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'address'
  },
  EMAIL_ADDRESS: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'email'
  },
  COUNTRY_CODE: {
    PLACEHOLDER: 'Country Code',
    LABEL_NAME: 'Country Code',
    IS_REQUIRED: true,
    INPUT_TYPE: '',
    INPUT_NAME: 'countryCode'
  },
  PHONE_NUMBER: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'phone'
  },
  DATE_OF_BIRTH: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'date',
    INPUT_NAME: 'dob'
  },
  PASSWORD: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'password',
    INPUT_NAME: 'password'
  },
  CONFIRM_PASSWORD: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'password',
    INPUT_NAME: 'confirmPassword'
  },
  LOGIN_EMAIL_OR_PHONE_NUMBER: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'emailOrPhoneNumber'
  },
  LOGIN_PASSWORD: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'password',
    INPUT_NAME: 'password'
  },
  DESIGNATION_SEARCH: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'designationSearch'
  },
  DESIGNATION: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'Designation'
  },
  DOCUMENT_NAME: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'documentName'
  },
  DOCUMENT_DESCRIPTION: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'documentDescription'
  },
  DOCUMENT_FORMAT: {
    IS_REQUIRED: true,
    INPUT_TYPE: '',
    INPUT_NAME: 'documentFormat'
  },
  DOCUMENT_SIZE: {
    IS_REQUIRED: true,
    INPUT_TYPE: 'range',
    INPUT_NAME: 'documentSize'
  },
  DOCUMENT_DO_SHOW: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'documentShowHide'
  },
  DOCUMENT_EXPIRY: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'documentExpiry'
  },
  DOCUMENT_PLACE_HOLDING_IMAGE: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'documentImage'
  },
  IS_CAPTURE_ONLY: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'documentCaptureOnly'
  },
  DOCUMENT_REQUIRED: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'documentRequired'
  },
  VEHICLE_NAME: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleName'
  },
  VEHICLE_AVG_SPEED: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'number',
    INPUT_NAME: 'averageSpeed',
    LABEL_NAME: 'Average Speed'
  },
  VEHICLE_DESCRIPTION: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleDescription'
  },
  VEHICLE_IMAGE: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleImage'
  },
  VEHICLE_ICON_IMAGE: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleMapIcon'
  },
  VEHICLE_IS_DEFAULT: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleIsDefault'
  },
  VEHICLE_TYPE: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleType'
  },
  VEHICLE_CAPACITY: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'seatingCapacity'
  },
  DRIVER_NAME: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'name'
  },
  DRIVER_GENDER: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'gender'
  },
  DRIVER_DOB: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'date',
    INPUT_NAME: 'dob'
  },
  DRIVER_EMAIL: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'email'
  },
  DRIVER_MOBILE: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'mobile'
  },
  DRIVER_STATUS: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'status'
  },
  DRIVER_CREATED: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'createdAt'
  },
  DRIVER_ADDRESS: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'address'
  },
  DRIVER_CITY: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'city'
  },
  VEHICLE_BRAND: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleBrand'
  },
  VEHICLE_MODEL: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleModel'
  },
  VEHICLE_PLATE: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehiclePlate'
  },
  VEHICLE_SEAT_CAPACITY: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleSeatCapacity'
  },
  VEHICLE_MAKE_YEAR: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleMakeYear'
  },
  VEHICLE_COLOR: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'vehicleColor'
  },
  BRAND_NAME: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'brandName'
  },
  USER_NAME: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'name'
  },
  USER_GENDER: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'gender'
  },
  USER_DOB: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'date',
    INPUT_NAME: 'dob'
  },
  USER_EMAIL: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'email'
  },
  USER_MOBILE: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'mobile'
  },
  USER_STATUS: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'status'
  },
  USER_CREATED: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'createdAt'
  },
  USER_COUNTRY: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'countryCode'
  },
  USER_CITY: {
    IS_REQUIRED: false,
    INPUT_TYPE: 'text',
    INPUT_NAME: 'city'
  }

})

export const PROJECT_ROUTES_ENUMS = Object.freeze({
  PUBLIC_ROUTES: {
    LOGIN_PAGE: '/auth/login',
    SIGN_UP_PAGE: '/auth/register',
    RESET_PASSWORD_PAGE: '/auth/reset-password',
    REQUEST_RESET_PASSWORD_PAGE: '/auth/request-reset-password'
  },
  PRIVATE_ROUTES: {
    HOME_PAGE: '/',
    REGISTRATION_TERMS_CONDITIONS_PAGE: '/terms-condition',
    DESIGNATION_PAGE: '/admin/designation',
    DESIGNATION_RIGHT_PAGE: '/admin/designation-rights',
    ADMIN_LIST_PAGE: '/admin/admin-list',
    USER_LIST_PAGE: '/user/user-list',
    DRIVER_LIST_PAGE: '/driver/driver-list',
    DRIVER_DOCUMENT_PAGE: '/driver/driver-document',
    DRIVER_SUBMITTED_DOCUMENT_PAGE: '/driver/driver-document-verification',
    VEHICLE_LIST_PAGE: '/vehicle/vehicle-list',
    VEHICLE_DOCUMENT_PAGE: '/vehicle/vehicle-document',
    VEHICLE_BRAND_PAGE: '/vehicle/vehicle-brand',
    ORGANIZATION_APP_SETTINGS_PAGE: '/organization/organization-app-settings',
    ORGANIZATION_MEDIA: '/organization/media',
    ORGANIZATION_SHIFT_SETTINGS: '/organization/shift',
    ORGANIZATION_SHIFT_PRICE_PAGE: '/organization/shift/price',
    BOOKING_PAGE: '/booking/details'
  },
  OTHER_ROUTES: {
    PAGE_NOT_FOUND: '/page-not-found',
    UNDER_MAINTENANCE: '/under-maintenance',
    UNAUTHORIZED: '/unauthorized'
  }
})

export const SCREEN_SIZE = Object.freeze({
  SCREEN_SIZE_1200: 1200,
  SCREEN_SIZE_991: 992,
  SCREEN_SIZE_720: 768,
  SCREEN_SIZE_480: 576,
  SCREEN_SIZE_320: 320
})

export const MODULE_ID = Object.freeze({
  DEFAULT_ACCESS: 101,
  VEHICLE_BRAND_ADD: 102,
  ADMIN_MENU: 4,
  ADMIN_LIST: 8,
  ADMIN_DESIGNATION: 5,
  ADMIN_DESIGNATION_RIGHTS: 6,
  USER_MENU: 1,
  USER_LIST: 9,
  DRIVER_MENU: 2,
  DRIVER_LIST: 10,
  DRIVER_DOCUMENT: 3,
  DRIVER_DOCUMENTS_VERIFICATION: 13,
  VEHICLE_MENU: 11,
  VEHICLE_DOCUMENT: 12,
  VEHICLE_LIST: 14,
  ORGANIZATION: 15,
  ORGANIZATION_APP_SETTINGS: 16,
  MEDIA: 17,
  SHIFT: 18,
  ORGANIZATION_SHIFT_PRICE: 19,
  BOOKING: 20
})

export const documentFormatArray = [
  { value: 'jpg', label: 'jpg', id: 1, inputType: 'text' },
  { value: 'jpeg', label: 'jpeg', id: 2, inputType: 'text' },
  { value: 'png', label: 'png', id: 3, inputType: 'text' },
  { value: 'svg', label: 'svg', id: 4, inputType: 'text' },
  { value: 'webp', label: 'webp', id: 5, inputType: 'text' },
  { value: 'pdf', label: 'pdf', id: 6, inputType: 'text' }
]

export const VEHICLE_TYPE = Object.freeze({
  OTHER: 0,
  CAR: 1,
  BIKE: 2,
  AUTO: 3
})

export const GENDER_OPTION = [
  { value: 'Male', label: 'Male', id: 1 },
  { value: 'Female', label: 'Female', id: 2 },
  { value: 'Other', label: 'Other', id: 3 }
]

export const DROPDOWN_OPTIONS_ID = {
  VIEW: 1,
  EDIT: 2,
  DELETE: 3,
  BLOCK: 4,
  RIGHTS: 5,
  VERIFICATION: 6,
  DESIGNATION: 7,
  ACTIVE: 8,
  PRICE_SETTINGS: 9
}
type driverDocumentStatus = {
  PENDING: { STATUS_KEY: 1 | -2 | -1 | 0, STATUS_NAME: string, COLOR: string },
  APPROVED: { STATUS_KEY: 1 | -2 | -1 | 0, STATUS_NAME: string, COLOR: string },
  SUBMITTED: { STATUS_KEY: 1 | -2 | -1 | 0, STATUS_NAME: string, COLOR: string },
  REJECTED: { STATUS_KEY: 1 | -2 | -1 | 0, STATUS_NAME: string, COLOR: string },
}
export const DRIVER_DOCUMENT_VERIFICATION_STATUS: driverDocumentStatus = Object.freeze({
  PENDING: { STATUS_KEY: -1, STATUS_NAME: 'Not Submitted', COLOR: 'text-muted' },
  APPROVED: { STATUS_KEY: 1, STATUS_NAME: 'Approved', COLOR: 'text-success' },
  SUBMITTED: { STATUS_KEY: 0, STATUS_NAME: 'Pending', COLOR: 'text-secondary' },
  REJECTED: { STATUS_KEY: -2, STATUS_NAME: 'Rejected', COLOR: 'text-danger' }
})

export const ACCOUNT_STATUS = Object.freeze({
  BLOCKED: { STATUS: -1, CLASS: 'badge-danger' },
  ACTIVE: { STATUS: 1, CLASS: 'badge-success' },
  INACTIVE: { STATUS: 0, CLASS: 'badge-secondary' },
  PENDING_FOR_ADMIN_DESIGNATION_APPROVAL: { STATUS: -2, CLASS: 'badge-primary' }// this is for admin account
})

export const DRIVER_VEHICLE_STATUS = Object.freeze({
  DEACTIVATED: -3,
  SUBMITTED: 0,
  APPROVED: 1
})

export const DRIVER_ALL_DOCUMENT_STATUS = Object.freeze({
  PENDING_VERIFICATION: -1,
  APPROVED: 1
})

export const BANNER_TYPE = Object.freeze([
  { value: 'General', label: 'General', id: 1 },
  { value: 'Alert', label: 'Alert', id: 2 },
  { value: 'Custom', label: 'Custom', id: 3 }
])

export const ORGANIZATION_RESOURCE_UPLOAD_TYPE = Object.freeze({
  GENERAL: { id: 1, value: 'general' },
  VEHICLE: { id: 2, value: 'vehicle' },
  DRIVER: { id: 3, value: 'driver' },
  USER: { id: 4, value: 'user' },
  ADMIN: { id: 5, value: 'admin' },
  DISPATCHER: { id: 6, value: 'dispatcher' },
  ORGANIZATION: { id: 7, value: 'organization' }
})

export const CRUD_KEY_DESIGNATION_RIGHT = Object.freeze([
  { name: 'create', id: 1 },
  { name: 'read', id: 2 },
  { name: 'update', id: 3 },
  { name: 'delete', id: 4 }
])

export const MULTIPLICATION_CONSTANTS_FOR_MB_TO_BYTE = 1000000

export const AVAILABLE_PAYMENT_OPTIONS = Object.freeze([
  { id: 1, value: 'Pay-tm', label: 'Pay-tm' },
  { id: 2, value: 'Google Pay', label: 'Google Pay' },
  { id: 3, value: 'Card Payment', label: 'Card Payment' },
  { id: 4, value: 'Crypto', label: 'Crypto' }

])

export const BOOKING_STATUS = Object.freeze([
  { id: -3, value: 'Driver not Found' },
  { id: -2, value: 'Cancelled By Driver' },
  { id: -1, value: 'Cancelled By User' },
  { id: 0, value: 'Searching Driver' },
  { id: 1, value: 'Driver Accepted' },
  { id: 2, value: 'Completed' }
])
