import React, { useContext } from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import UserContext from '../../Context/UserContext'
import { PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { isAuthRoutes } from '../../utils/utils'

const PublicRoute = () => {
  const { authToken } = useContext(UserContext)
  const { pathname } = useLocation()

  if (authToken && isAuthRoutes(pathname)) {
    return <Navigate to={PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE} replace={true} />
  }

  return <Outlet />
}

export default PublicRoute
