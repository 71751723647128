// TODO  : Need to review this file from the beginning
import axios, { serviceFailureHandle, serviceSuccessHandle } from './AxiosConfig'

const getDesignation = () => {
  return axios.get('/api/designations').then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const patchUpdateDesignation = (designationId: string, updatedRights: object) => {
  return axios.patch(`/api/designations/permissions/${designationId}`, { data: updatedRights }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const patchEditDesignation = (editedEntry: object, itemId: string) => {
  return axios.patch(`/api/designations/${itemId}`, editedEntry).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const getEditDesignation = (itemId: string) => {
  return axios.get(`/api/designations/${itemId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const deleteDesignation = (itemId: string, id?: string) => {
  return axios.delete(`/api/designations/${itemId}`, { data: { newDesignationId: id } }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const postAddDesignation = (newEntry: object) => {
  return axios.post('/api/designations', newEntry).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const getDesignationRights = (designationId: string) => {
  return axios.get(`/api/designations/permissions/${designationId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const getAssociatedAdmin = (id: string) => {
  return axios.get(`/api/designations/admin/${id}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

// adminList Api
const getAdminList = () => {
  return axios.get('/api/adminlist').then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const getAdminDetails = (id: string) => {
  return axios.get(`/api/adminlist/details/${id}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const putAssignAdminDesignation = (adminId: string, designation: string) => {
  return axios.put(`api/adminList/update/designation/${adminId}`, { designationId: designation }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const putChangeAdminStatus = (adminId: string, status: number) => {
  return axios.put(`api/adminlist/update/status/${adminId}`, { status }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

export {
  patchEditDesignation,
  deleteDesignation,
  postAddDesignation,
  getDesignationRights,
  getEditDesignation,
  getDesignation,
  getAssociatedAdmin,
  patchUpdateDesignation,
  getAdminList,
  getAdminDetails,
  putAssignAdminDesignation,
  putChangeAdminStatus
}
