import React, { useContext } from 'react'
import UserContext from '../../../Context/UserContext'

type Props = {
  classType: string
}
const Toggler = ({ classType }: Props) => {
  const { showSidebar, setShowSidebar } = useContext(UserContext)
  return (
    <button className={`navbar-toggler ${classType} align-self-center`} type="button" onClick={() => setShowSidebar(!showSidebar)} data-toggle="minimize">
      <span className="mdi mdi-menu"></span>
    </button>
  )
}

export default Toggler
