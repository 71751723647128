import React, { ReactElement } from 'react'

type Props = {
  children: ReactElement
  openMedia: () => void
}

const ImagePicker = ({ children, openMedia }: Props) => {
  return (
    <div className='d-flex align-items-center image-picker'>
      {children}
      <div onClick={openMedia} className="input-group-append cursor-pointer h-50 mb-1">
        <span className="input-group-text" id="search">
          <i className="mdi mdi-magnify"></i>
        </span>
      </div>
    </div>
  )
}

export default ImagePicker
