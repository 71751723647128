import i18n from '../../i18n'
import * as yup from 'yup'
import { AUTH_ENUMS } from '../../utils/enums/enums'

export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_REQUIRED')}`)
    .min(AUTH_ENUMS.MIN_VALUE_PASSWORD, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_MIN_LENGTH')}`)
    .max(AUTH_ENUMS.MAX_VALUE_PASSWORD, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_MAX_LENGTH')}`),
  confirmPassword: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.CONFIRM_PASSWORD_REQUIRED')}`)
    .oneOf([yup.ref('password'), null], `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_SHOULD_MATCHED')}`)
    .min(AUTH_ENUMS.MIN_VALUE_PASSWORD, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_MIN_LENGTH')}`)
    .max(AUTH_ENUMS.MAX_VALUE_PASSWORD, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_MAX_LENGTH')}`)
})
