import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Card, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import UserContext from '../../../Context/UserContext'
import { getAdminDetails } from '../../../Services/DesignationService'
import { PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../utils/utils'

type Props = {}

const AdminDetails = (props: Props) => {
  const { setIsFetching, setArrayOfBreadCrumb } = useContext(UserContext)
  const params = useParams()
  const [adminDetails, setAdminDetails] = useState<any>()
  const { t } = useTranslation()
  const [formData, setFormData] = useState({} as { firstName: string, lastName: string })

  useEffect(() => {
    (async () => {
      setIsFetching((isFetching) => true)
      try {
        // @ts-ignore
        const response = await getAdminDetails(params.itemId!)
        if (response.statusCode === 200) {
          setAdminDetails(response.data)
          setIsFetching(false)
          setFormData(response.data)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🔥 ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.ADMIN_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ADMIN_LIST_PAGE },
      { name: `${t('PAGE_NAME.ADMIN_DETAILS')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ADMIN_LIST_PAGE }
    ])
    return () => { }
  }, [])

  const handleInputChange = (field: string, value: string) => {
    setFormData(formData => ({ ...formData, [field]: value }))
  }

  return (
    <Card className="designation-table">
      <Card.Body>
        <Card.Title className="mb-5">{`${t('ADMIN_DETAILS_PAGE.TITLE')}`}</Card.Title>
        {adminDetails ? <Row className='mt-3'>
          <FormGroup className='d-flex mb-3'>
            <FormLabel className='mb-0 align-self-center'>{`${t('INPUT_FIELD_ENUMS.FIRST_NAME.LABEL_NAME')} :`}</FormLabel>
            <FormControl className='ms-1 px-3 py-1 editable-input' value={formData.firstName} onChange={(event) => handleInputChange('firstName', event.target.value)}></FormControl>
          </FormGroup>
          <div className='d-flex'>
            <h6 className='mb-3'>{`${t('INPUT_FIELD_ENUMS.LAST_NAME.LABEL_NAME')} :`}</h6>
            <p className='ms-2'>{adminDetails.middleName}</p>
          </div>
          <div className='d-flex'>
            <h6 className='mb-3'>{`${t('INPUT_FIELD_ENUMS.MIDDLE_NAME.LABEL_NAME')} :`}</h6>
            <p className='ms-2'>{adminDetails.lastName}</p>
          </div>
          <h6 className='mb-3'>{''}</h6>
          <div className='d-flex'>
            <h6 className='mb-3'>{`${t('INPUT_FIELD_ENUMS.EMAIL_ADDRESS.LABEL_NAME')} :`}</h6>
            <p className='ms-2'>{adminDetails.email}</p>
          </div>
          <div className='d-flex'>
            <h6 className='mb-3'>{`${t('INPUT_FIELD_ENUMS.PHONE_NUMBER.LABEL_NAME')} :`}</h6>
            <p className='ms-2'>{adminDetails.mobile}</p>
          </div>
          <div className='d-flex'>
            <h6 className='mb-3'>{`${t('INPUT_FIELD_ENUMS.DATE_OF_BIRTH.LABEL_NAME')} :`}</h6>
            <p className='ms-2'>{adminDetails.dob.split('T')[0]}</p>
          </div>
          <h6 className='mb3'>{''}</h6>
          <div className='d-flex'>
            <h6 className='mb-3'>{`${t('INPUT_FIELD_ENUMS.ADDRESS.LABEL_NAME')} :`}</h6>
            <p className='ms-2'>{adminDetails.address ? adminDetails.address : '-'}</p>
          </div>
          <div className='d-flex'>
            <h6 className='mb-3'>{`${t('INPUT_FIELD_ENUMS.CITY.LABEL_NAME')} :`}</h6>
            <p className='ms-2'>{adminDetails.cityName}</p>
          </div>
        </Row> : <h5 className='text-center'>No Data to show</h5>}
      </Card.Body>
    </Card>
  )
}

export default AdminDetails
