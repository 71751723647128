import { Formik, FormikValues } from 'formik'
import _ from 'lodash'
// import _ from 'lodash'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { Button, Card, Col, Form, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { SingleValue } from 'react-select'
import { toast } from 'react-toastify'
// import { useParams } from 'react-router-dom'
// import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
import { AddDriverSchema } from '../../Pages/DriverMenu/DriverList/AddDriverSchema'
import { SelectDropdownModel } from '../../Pages/Register/UserRegistrationModel'
import { patchEditDriverDetails, postAddDriver } from '../../Services/DriverService'
import { GENDER_OPTION, INPUT_FIELD_ENUMS, MODULE_ID, PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { fetchResponseMessage } from '../../utils/utils'
import FormInputSelect from '../Form/FormInputSelect/FormInputSelect'
import FormInputText from '../Form/FormInputText/FormInputText'
import IsAuthorized from '../IsAuthorized/IsAuthorized'

type Props = {
  field: {
    name: string,
    gender: SingleValue<SelectDropdownModel> | undefined,
    dob: string,
    email: string,
    mobile: string,
    status: boolean,
    createdAt: string,
    address: string,
    city: string,
    setStatus: Dispatch<SetStateAction<boolean>>
  }
  isDisabled?: boolean
  cardTitle: string
  paramId?: string
  redirectTo?: string
  submitButtonName: string
  setFieldValue?: (formik: FormikValues) => void
  pageName: string
  noDataFetched?: boolean
}

const DriverCreateViewEdit = (props: Props) => {
  const { setIsFetching, isFetching } = useContext(UserContext)
  const params = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const editCreateDriver = async (values: FormikValues, { resetForm }: FormikValues) => {
    if (props.pageName === 'view-driver') {
      navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE}/edit/${props.paramId}`)
    } else {
      setIsFetching(true)
      const fieldValue = {
        name: values.name,
        gender: values.gender.id,
        dob: values.dob,
        email: values.email,
        mobile: values.mobile,
        status: props.field.status,
        createdAt: values.createdAt,
        address: values.address,
        city: values.city
      }
      try {
        let response
        const lastParamsOfPath = location.pathname.split('/')[location.pathname.split('/').length - 1]
        if (lastParamsOfPath === 'add') {
          response = await postAddDriver(params.itemId!, fieldValue)
        } else {
          response = await patchEditDriverDetails(params.itemId!, fieldValue)
        }
        if (response.data) {
          const responseMessage = fetchResponseMessage(response.responseCode)
          toast.success(responseMessage)
          setIsFetching(false)
          resetForm()
          navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🚀 ~ file: AddDesignation.tsx ~ line 52 ~ addDesignationSubmitHandler ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    }
  }

  const setSelection = (event: SingleValue<SelectDropdownModel>, formik: FormikValues) => {
    formik.values.gender = event
    props.setFieldValue!(formik)
  }
  const initialDriverInfo = {
    name: props.field.name,
    dob: props.field.dob,
    email: props.field.email,
    mobile: props.field.mobile,
    address: props.field.address,
    city: props.field.city,
    gender: props.field.gender
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialDriverInfo}
      validationSchema={() => AddDriverSchema}
      onSubmit={(values: FormikValues, formik) => {
        editCreateDriver(values, formik)
      }}
    >
      {(formik: FormikValues) => (
        <Form className="pt-3" onSubmit={formik.handleSubmit}>
          <fieldset disabled={props.isDisabled}>
            <div>
              <Row>
                <Col sm={9}>
                  <Row>

                    <Col lg={4} className='my-2'>
                      <FormInputText
                        inputName={INPUT_FIELD_ENUMS.DRIVER_NAME.INPUT_NAME}
                        type={INPUT_FIELD_ENUMS.DRIVER_NAME.INPUT_TYPE}
                        isRequired={INPUT_FIELD_ENUMS.DRIVER_NAME.IS_REQUIRED}
                        inputField={'DRIVER_NAME'}
                      />
                    </Col>
                    <Col lg={4} className='my-2'>
                      <FormInputText
                        inputName={INPUT_FIELD_ENUMS.DRIVER_EMAIL.INPUT_NAME}
                        type={INPUT_FIELD_ENUMS.DRIVER_EMAIL.INPUT_TYPE}
                        isRequired={INPUT_FIELD_ENUMS.DRIVER_EMAIL.IS_REQUIRED}
                        inputField={'DRIVER_EMAIL'}
                      />
                    </Col>
                    <Col lg={4} className='my-2'>
                      <FormInputText
                        inputName={INPUT_FIELD_ENUMS.DRIVER_DOB.INPUT_NAME}
                        type={INPUT_FIELD_ENUMS.DRIVER_DOB.INPUT_TYPE}
                        isRequired={INPUT_FIELD_ENUMS.DRIVER_DOB.IS_REQUIRED}
                        inputField={'DRIVER_DOB'}
                      />
                    </Col>
                    <Col lg={4} className='my-2'>
                      <FormInputSelect
                        inputField={'DRIVER_GENDER'}
                        isRequired={INPUT_FIELD_ENUMS.DRIVER_GENDER.IS_REQUIRED}
                        inputName={INPUT_FIELD_ENUMS.DRIVER_GENDER.INPUT_NAME}
                        options={GENDER_OPTION}
                        handleSelection={(event: SingleValue<SelectDropdownModel>) => {
                          setSelection(event, formik)
                        }}
                        isMulti={false}
                      />
                    </Col>
                    <Col lg={4} className='my-2'>
                      <FormInputText
                        inputName={INPUT_FIELD_ENUMS.DRIVER_MOBILE.INPUT_NAME}
                        type={INPUT_FIELD_ENUMS.DRIVER_MOBILE.INPUT_TYPE}
                        isRequired={INPUT_FIELD_ENUMS.DRIVER_MOBILE.IS_REQUIRED}
                        inputField={'DRIVER_MOBILE'}
                      />
                    </Col>
                    <Col lg={4} className='my-2'>
                      <FormInputText
                        inputName={INPUT_FIELD_ENUMS.DRIVER_ADDRESS.INPUT_NAME}
                        type={INPUT_FIELD_ENUMS.DRIVER_ADDRESS.INPUT_TYPE}
                        isRequired={INPUT_FIELD_ENUMS.DRIVER_ADDRESS.IS_REQUIRED}
                        inputField={'DRIVER_ADDRESS'}
                      />
                    </Col>
                    <Col lg={4} className='my-2'>
                      <FormInputText
                        inputName={INPUT_FIELD_ENUMS.DRIVER_CITY.INPUT_NAME}
                        type={INPUT_FIELD_ENUMS.DRIVER_CITY.INPUT_TYPE}
                        isRequired={INPUT_FIELD_ENUMS.DRIVER_CITY.IS_REQUIRED}
                        inputField={'DRIVER_CITY'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                  <Card className='form-card'>
                    <Card.Body className={'d-flex flex-column justify-content-between'}>
                      <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                        <FormLabel className='align-self-center mb-0 mt-1'>{`${t('INPUT_FIELD_ENUMS.DRIVER_STATUS.LABEL_NAME')} :`}</FormLabel>
                        <Form.Check type="switch" name="isRequired" className="switch d-block ms-auto" checked={!!props.field.status} onChange={(event) => props.field.setStatus(event.target.checked)} />
                      </FormGroup>
                      {/* TODO: from translation */}
                      <p className='text-left mt-4 text-secondary'>To active and Inactive Driver from here.</p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </fieldset>
          <div className='d-flex align-items-start mt-4'>
            <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.DRIVER_LIST, rights: props.submitButtonName === 'ADD' ? 'create' : 'update' }]}>
              <Button type="submit" className='py-1 my-0 add-button' disabled={props.noDataFetched}>
                {isFetching ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : t(`PROJECT_BUTTON.${props.submitButtonName}`)}
              </Button>
            </IsAuthorized>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default DriverCreateViewEdit
