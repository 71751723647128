import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { awsUploadMedia, setPresignedUrl } from '../../Services/CommonService'
// import { awsUploadMedia, setPresignedUrl } from '../../Services/CommonService'
type Props = {
  file: any,
  removeUploadedFile: any
  category: number
}
const MediaUploading = ({ file, removeUploadedFile, category }: Props) => {
  const [uploadProgress, setUploadProgress] = useState(0)
  const [shouldHide, setShouldHide] = useState(0)
  useEffect(() => {
    (
      async () => {
        try {
          await awsUploadMedia(file.fileUploadInfo.url, file.fileUploadInfo.fields, file.fileObj)
          await setPresignedUrl({
            key: file.fileUploadInfo.key,
            contentType: file.fileObj.type || 'image/jpeg',
            category
          })
          setUploadProgress(1)
        } catch (error) {
          console.error('🚀 ~ file: MediaUploading.tsx ~ line 16 ~ error', error)
          setUploadProgress(-1)
        } finally {
          setTimeout(() => {
            setShouldHide(1)
            removeUploadedFile(file.fileUploadInfo.key)
          }, 5000)
        }
      }
    )()
    return () => { }
  }, [])
  return (
    <>
      {shouldHide ? null : <Card className="media-uploading-card">
        <Card.Img className="card-img-h-90" key={file.fileObj.name}
          src={file.fileObj.preview}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.fileObj.preview) }}
        />
        <div className="progress progress-md">
          <div className={`progress-bar bg-${uploadProgress === 1 ? 'success' : uploadProgress === -1 ? 'danger' : 'primary'} progress-bar-striped progress-bar-animated`} role="progressbar" style={{ width: '100%' }}>
          </div>
        </div>
      </Card>
      }
    </>
  )
}

export default MediaUploading
