import { Formik, FormikValues } from 'formik'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { Button, Card, Col, Form, FormControl, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
import { INPUT_FIELD_ENUMS, MODULE_ID, PROJECT_ROUTES_ENUMS, VEHICLE_TYPE } from '../../utils/enums/enums'
import { useNavigate, useParams } from 'react-router-dom'
import IsAuthorized from '../IsAuthorized/IsAuthorized'
import { fetchResponseMessage } from '../../utils/utils'
import _ from 'lodash'
import FormInputText from '../Form/FormInputText/FormInputText'
import { VehicleListSchema } from '../../Pages/VehicleMenu.tsx/VehicleList/VehicleListSchema'
import { patchEditVehicle, postCreateVehicle, putUpdateVehiclePrice } from '../../Services/VehicleService'
import FormInputSelect from '../Form/FormInputSelect/FormInputSelect'
import { SingleValue } from 'react-select'
import { SelectDropdownModel } from '../../Pages/Register/UserRegistrationModel'
import ImagePicker from './ImagePicker'
import ShitPriceAccordion from './ShitPriceAccordion'
import { VehiclePriceModel } from '../../Pages/Models/CommonModel'

type Props = {
  field: {
    vehicleName: string,
    vehicleDescription: string,
    vehicleType: any,
    setVehicleTypeState?: Dispatch<SetStateAction<{ value: string, label: string }>>,
    seatingCapacity: number,
    averageSpeed: number,
    vehicleIsActive: boolean,
    vehicleImage: string,
    vehicleMapIcon: string,
    setVehicleIsActiveState: Dispatch<SetStateAction<boolean>>,
    option: { value: string, label: string }[]
  }
  isDisabled?: boolean
  cardTitle: string
  paramId?: string
  redirectTo?: string
  submitButtonName: string
  setFieldValue?: (formik: FormikValues) => void
  pageName: string
  vehiclePrice?: VehiclePriceModel
  vehicleId?: string
}

const VehicleCreateViewEdit = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { isFetching, setIsFetching, setShowMediaModelDetails, setShowMediaModel } = useContext(UserContext)

  /**
  * @function editDesignationSubmit
  * @return void
  * @param value
  *@description To handle submit  after changes are made in  any Designation field
  */
  const editCreateVehicle = async (values: FormikValues, { resetForm }: FormikValues) => {
    console.log('🚀 ~ file: VehicleCreateViewEdit.tsx:60 ~ editCreateVehicle ~ values', values)
    if (props.pageName === 'view-vehicle') {
      navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE}/edit/${props.paramId}`)
    } else {
      setIsFetching(true)
      const fieldValue = {
        description: values.vehicleDescription,
        name: values.vehicleName,
        vehicleType: VEHICLE_TYPE[values.vehicleType.value as keyof typeof VEHICLE_TYPE],
        seatCapacity: values.seatingCapacity,
        isActive: props.field.vehicleIsActive,
        vehicleIcon: values.vehicleImage,
        averageSpeed: values.averageSpeed
      }
      try {
        let response
        const lastParamsOfPath = location.pathname.split('/')[location.pathname.split('/').length - 1]
        if (lastParamsOfPath === 'add') {
          response = await postCreateVehicle(fieldValue)
        } else {
          response = await patchEditVehicle(fieldValue, params.itemId!)
        }
        if (response.data) {
          const responseMessage = fetchResponseMessage(response.responseCode)
          toast.success(responseMessage)
          setIsFetching(false)
          resetForm()
          navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🚀 ~ file: AddDesignation.tsx ~ line 52 ~ addDesignationSubmitHandler ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    }
  }

  const setSelection = (event: any, formik: FormikValues) => {
    formik.values.vehicleType = event
    props.setFieldValue!(formik)
  }
  const initialUserInfo = {
    vehicleName: props.field.vehicleName,
    vehicleDescription: props.field.vehicleDescription,
    vehicleType: props.field.vehicleType,
    seatingCapacity: props.field.seatingCapacity,
    vehicleImage: props.field.vehicleImage,
    vehicleMapIcon: props.field.vehicleMapIcon,
    averageSpeed: props.field.averageSpeed
  }

  const openMedia = (formik: FormikValues, inputName: string) => {
    function setFormikValue(newValue: string) {
      formik.setFieldValue(inputName, newValue)
    }
    setShowMediaModelDetails({ setInputState: setFormikValue, inputRef: null })
    setShowMediaModel(true)
  }
  // TODO : Remove Any from here
  // TODO : Handle try catch and everthing in this
  const handelPriceSubmit = async (values: any) => {
    setIsFetching(true)
    const priceData = {
      orgVehicleDetailId: values.orgVehicleDetailId,
      orgShiftId: values.orgShiftId,
      initialDistance: values.initialDistance,
      baseRate: values.baseRate,
      distanceRate: values.distanceRate,
      durationRate: values.durationRate,
      waitRate: values.waitRate,
      stopRate: values.stopRate,
      surCharge: values.surCharge,
      cancelRate: values.cancelRate,
      pickupDistanceRate: values.pickupDistanceRate,
      initialWaitTime: values.initialWaitTime,
      initialPickupDistance: values.initialPickupDistance,
      initialDuration: values.initialDuration,
      luggageCharge: values.luggageCharge,
      additionalPersonCharge: values.additionalPersonCharge
    }
    const response = await putUpdateVehiclePrice(priceData, values.orgVehiclePriceId)
    if (response.data) {
      const responseMessage = fetchResponseMessage(response.responseCode)
      toast.success(responseMessage)
      setIsFetching(false)
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialUserInfo}
      validationSchema={() => VehicleListSchema}
      onSubmit={(values: FormikValues, formik) => {
        editCreateVehicle(values, formik)
      }}
    >
      {(formik: FormikValues) => (
        <Form onSubmit={formik.handleSubmit}>
          <fieldset disabled={props.isDisabled}>
            <Row>
              <Col lg={9}>
                <Row>
                  <Col lg={4} className='my-2'>
                    <FormInputText
                      inputName={INPUT_FIELD_ENUMS.VEHICLE_NAME.INPUT_NAME}
                      type={INPUT_FIELD_ENUMS.VEHICLE_NAME.INPUT_TYPE}
                      isRequired={INPUT_FIELD_ENUMS.VEHICLE_NAME.IS_REQUIRED}
                      inputField={'VEHICLE_NAME'}
                    />
                  </Col>
                  <Col lg={4} className='my-2'>
                    <FormInputText
                      inputName={INPUT_FIELD_ENUMS.VEHICLE_DESCRIPTION.INPUT_NAME}
                      type={INPUT_FIELD_ENUMS.VEHICLE_DESCRIPTION.INPUT_TYPE}
                      isRequired={INPUT_FIELD_ENUMS.VEHICLE_DESCRIPTION.IS_REQUIRED}
                      inputField={'VEHICLE_DESCRIPTION'}
                    />
                  </Col>
                  <Col lg={4} className='my-2'>
                    <FormInputSelect
                      inputField={'VEHICLE_TYPE'}
                      isRequired={INPUT_FIELD_ENUMS.VEHICLE_TYPE.IS_REQUIRED}
                      inputName={INPUT_FIELD_ENUMS.VEHICLE_TYPE.INPUT_NAME}
                      options={props.field.option}
                      handleSelection={(event: SingleValue<SelectDropdownModel>) => {
                        setSelection(event, formik)
                      }}
                      isMulti={false}
                    />
                  </Col>
                  <Col lg={4} className='my-2'>
                    <FormInputText
                      inputName={INPUT_FIELD_ENUMS.VEHICLE_AVG_SPEED.INPUT_NAME}
                      type={INPUT_FIELD_ENUMS.VEHICLE_AVG_SPEED.INPUT_TYPE}
                      isRequired={INPUT_FIELD_ENUMS.VEHICLE_AVG_SPEED.IS_REQUIRED}
                      inputField={'VEHICLE_AVG_SPEED'}
                    />
                  </Col>
                  {/* <Col lg={4} className='my-2'>
                    <FormGroup>
                      <FormLabel >{`${t('INPUT_FIELD_ENUMS.VEHICLE_ICON_IMAGE.LABEL_NAME')} :`}</FormLabel>
                      <ImagePicker openMedia={() => props.isDisabled ? null : openMedia(formik, INPUT_FIELD_ENUMS.VEHICLE_ICON_IMAGE.INPUT_NAME)}>
                        <FormControl
                          className='form-control form-control-lg border-left-0 w-100 mb-1'
                          // TODO: from translation
                          name={INPUT_FIELD_ENUMS.VEHICLE_ICON_IMAGE.INPUT_NAME}
                          placeholder={`${t('INPUT_FIELD_ENUMS.VEHICLE_ICON_IMAGE.PLACEHOLDER')}`}
                          value={formik.values.vehicleMapIcon}
                          onChange={(event) => formik.setFieldValue(INPUT_FIELD_ENUMS.VEHICLE_ICON_IMAGE.INPUT_NAME, event.target.value)}
                        />
                      </ImagePicker>
                    </FormGroup>
                  </Col> */}
                  <Col lg={4} className='my-2'>
                    <FormGroup>
                      <FormLabel >{`${t('INPUT_FIELD_ENUMS.VEHICLE_IMAGE.LABEL_NAME')} :`}</FormLabel>
                      <ImagePicker openMedia={() => props.isDisabled ? null : openMedia(formik, INPUT_FIELD_ENUMS.VEHICLE_IMAGE.INPUT_NAME)}>
                        <FormControl
                          className='form-control form-control-lg border-left-0 w-100 mb-1'
                          // TODO: from translation
                          name={INPUT_FIELD_ENUMS.VEHICLE_IMAGE.INPUT_NAME}
                          placeholder={`${t('INPUT_FIELD_ENUMS.VEHICLE_IMAGE.PLACEHOLDER')}`}
                          value={formik.values.vehicleImage}
                          onChange={(event) => formik.setFieldValue(INPUT_FIELD_ENUMS.VEHICLE_IMAGE.INPUT_NAME, event.target.value)}
                        />
                      </ImagePicker>
                    </FormGroup>
                  </Col>
                  <Col lg={4} className='my-2'>
                    <FormInputText
                      inputName={INPUT_FIELD_ENUMS.VEHICLE_CAPACITY.INPUT_NAME}
                      type={INPUT_FIELD_ENUMS.VEHICLE_CAPACITY.INPUT_TYPE}
                      isRequired={INPUT_FIELD_ENUMS.VEHICLE_CAPACITY.IS_REQUIRED}
                      inputField={'VEHICLE_CAPACITY'}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={12} lg={9} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1 text-truncate me-1'>{`${t('INPUT_FIELD_ENUMS.VEHICLE_IS_ACTIVE.LABEL_NAME')} :`}</FormLabel>
                      <Form.Check type="switch" name="isRequired" className="switch d-block ms-auto" checked={props.field.vehicleIsActive} onChange={(event) => props.field.setVehicleIsActiveState(event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To active the vehicle and will get active if default price is set.</p>

                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {
            props.submitButtonName === 'ADD' ? null :
            <Row>
              <Col sm={9} xl={6}>
                <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.ORGANIZATION_SHIFT_PRICE, rights: 'create' }]}>
                  <div className='mt-3'>
                    <ShitPriceAccordion
                      eventKey={0}
                      vehiclePrice={props.vehiclePrice!}
                      vehicleId={props.vehicleId!}
                      handleSubmit={handelPriceSubmit}
                    />
                  </div>
                </IsAuthorized>
              </Col>
            </Row>
          }
          </fieldset>
          <FormGroup className="d-flex align-item-center justify-content-start">
            <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.VEHICLE_DOCUMENT, rights: props.submitButtonName === 'ADD' ? 'create' : 'update' }]}>
              <Button type="submit" className='m-3 mw-50'>
                {isFetching ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : t(`PROJECT_BUTTON.${props.submitButtonName}`)}
              </Button>
            </IsAuthorized>
          </FormGroup>
        </Form>)
}
    </Formik >
  )
}

export default VehicleCreateViewEdit
