import React, { ReactElement } from 'react'

type Props = {
  children: ReactElement
  redirectLink: string
}

const Preview = (props: Props) => {
  return (
    <div className='preview-container'>
      <div className='preview-text'>
        <a href={props.redirectLink} target='_blank' rel="noreferrer"><span className='mdi mdi-user'></span>Preview</a>
      </div>
      {props.children}
    </div>
  )
}

export default Preview
