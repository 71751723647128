import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion, Button, Card, Col, FormGroup, FormLabel, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
import { getDriverSingleDocumentDetails, getVehicleSingleDocumentDetails, putUpdateDriverDocStatus } from '../../Services/DriverService'
import { fetchResponseMessage } from '../../utils/utils'
import fscreen from 'fscreen'
import { DRIVER_DOCUMENT_VERIFICATION_STATUS } from '../../utils/enums/enums'
import { putUpdateVehicleDocStatus } from '../../Services/VehicleService'
import Preview from './Preview'

type Props = {
  show?: boolean
  documentTitle?: string
  documentId?: string
  documentType: string
  isClickable?: boolean
  status: { STATUS_KEY: number, STATUS_NAME: string, COLOR: string }
  rerenderAllDocument: () => void
  contentType?: string
}

const DocumentAccordion = (props: Props) => {
  const { t } = useTranslation()
  const { setIsFetching } = useContext(UserContext)
  const [statusToChange, setStatusToChange] = useState('' as 'APPROVED' | 'REJECTED')
  const [isDisable, setIsDisable] = useState(false)
  const [rejectReason, setRejectReason] = useState('')
  const [documentImage, setDocumentImage] = useState('')
  const [documentNumber, setDocumentNumber] = useState('')
  const [documentExpiry, setDocumentExpiry] = useState('')
  const [contentType, setContentType] = useState('')
  const [radioStatus, setRadioStatus] = useState(0)

  const setStatus = (event: any) => {
    const element = event.target as HTMLElement
    if (element.id === 'approveRadio') {
      setIsDisable(false)
      setStatusToChange('APPROVED')
    } else if (element.id === 'rejectRadio') {
      setIsDisable(true)
      setStatusToChange('REJECTED')
    }
  }

  useEffect(() => {
    setRadioStatus(statusToChange === 'APPROVED' ? 1 : -2)
  }, [statusToChange])

  const handleInputRejectChange = (value: string) => {
    setRejectReason(value)
  }

  useEffect(() => {
    setIsDisable(false)
    setRejectReason('')
  }, [props.show])

  useEffect(() => {
    setRadioStatus(props.status.STATUS_KEY)
  }, [])
  const handleAccordionClick = (event: any, documentId: string) => {
    if (event.target.className.includes('collapsed')) {
      if (props.documentType === 'driverDocument') {
        fetchDriverDocument(documentId)
      } else if (props.documentType === 'vehicleDocument') {
        fetchVehicleDocument(documentId)
      }
    }
  }

  /**
   * @function fetchDriverDocument
   * @return Promise<void>
   * @param documentId
   * @description will fetch driver submitted document and open a modal so admin can approve or reject the document from here.
   */

  const fetchDriverDocument = async (documentId: string) => {
    // setIsFetching(true)
    try {
      const response = await getDriverSingleDocumentDetails(documentId)
      if (response.data) {
        setIsFetching(false)
        setDocumentImage(response.data.imageUrl)
        setDocumentNumber(response.data.documentNumber)
        setDocumentExpiry(response.data.documentExpiryDate)
        setContentType(response.data.contentType)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ fetchDriverDocument ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  /**
   * @function fetchVehicleDocument
   * @return Promise<void>
   * @param documentId
   * @description will fetch vehicle submitted document and open a modal so admin can approve or reject the document from here.
   */

  const fetchVehicleDocument = async (documentId: string) => {
    // setIsFetching(true)
    try {
      const response = await getVehicleSingleDocumentDetails(documentId)
      if (response.data) {
        setIsFetching(false)
        setDocumentImage(response.data.imageUrl)
        setDocumentNumber(response.data.documentNumber)
        setDocumentExpiry(response.data.documentExpiryDate)
        setContentType(response.data.contentType)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ fetchDriverDocument ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }
  const documentImgComponent = useRef(null!)
  const documentPdfComponent = useRef(null!)

  const cardClickHandler = (documentImage: string) => {
    fscreen.requestFullscreen(documentImgComponent.current)
  }

  /**
          * @function handleUpdateStatusSubmit
          * @return Promise<void>
          * @param documentId
          * @param documentType
          * @param status
          * @param reason
          * @description This function will called after clicking on submit button in modal and make a put call to approve and reject the document of driver or vehicle.
          */
  const handleSubmit = async (documentId: string, documentType: string, status: 'APPROVED' | 'REJECTED', reason: string) => {
    setIsFetching(true)
    try {
      let response
      let payLoadObject
      if (reason) {
        payLoadObject = {
          status: DRIVER_DOCUMENT_VERIFICATION_STATUS[status].STATUS_KEY,
          rejectionReason: reason
        }
      } else {
        payLoadObject = {
          status: DRIVER_DOCUMENT_VERIFICATION_STATUS[status].STATUS_KEY
        }
      }
      if (documentType === 'driverDocument') {
        response = await putUpdateDriverDocStatus(documentId, payLoadObject)
      } else if (documentType === 'vehicleDocument') {
        response = await putUpdateVehicleDocStatus(documentId, payLoadObject)
      }
      if (response.statusCode === 200) {
        setIsFetching(false)
        props.rerenderAllDocument()
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
      } else {
        throw response
      }
    } catch (error) {
      console.error('🔥 ~ approveDocument ~ error', error)
      setIsFetching(false)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }
  return (

    <Accordion className={`mb-3 document-accordion ${!props.isClickable ? 'pe-none' : ''}`} onClick={(event) => handleAccordionClick(event, props.documentId!)}>
      <Accordion.Item eventKey={`${props.documentId}`}>
        {props.documentTitle &&
          <Accordion.Header className='justify-content-between'>
            <p className='m-0 text-overflow-none'>{props.documentTitle}</p>
            <p className={`ms-auto mb-0 font-weight-bold ${props.status.COLOR}`}>{props.status.STATUS_NAME}</p>
          </Accordion.Header>
        }
        <Accordion.Body>
          <Row className='w-100 m-0'>
            <Col sm={6} className={'d-flex flex-column justify-content-start ps-0 pe-2'}>
              <div>
                {/* TODO: from translation */}
                <p className='m-0 text-overflow-none title-name'>Document No. : </p>
                <p className={'mb-0 mt-1 font-weight-bold text-secondary title-value'}>{documentNumber || ''}</p>
              </div>
              <div className='mt-3'>
                {/* TODO: from translation */}
                <p className='m-0 text-overflow-none title-name'>Expiry Data : </p>
                <p className={'mb-0 mt-1 font-weight-bold text-secondary title-value'}>{documentExpiry || ''}</p>
              </div>

            </Col>
            <Col sm={6} className='p-0'>
              {documentImage ?
                <Col lg={12} sm={8} className='p-0 d-flex align-items-end mx-auto'>
                  <>
                    {contentType.split('/')[1] === 'pdf' ?
                      <Preview redirectLink={documentImage}>
                        <object data={documentImage} ref={documentPdfComponent} />
                      </Preview>
                      :
                      <Card.Img ref={documentImgComponent} variant='bottom' src={documentImage} className={'card-image cursor-p'} onClick={documentImage ? () => cardClickHandler(documentImage!) : () => null}></Card.Img>
                    }
                  </>
                  {/* TODO: from translation */}
                </Col> : <h6 className='text-end'>No Image to show</h6>
              }
            </Col>
            <Col sm={6} className={'pt-2 px-0'}>
              <>
                <FormGroup className='mb-3 d-flex justify-content-start'>
                  <div className='me-2'>
                    <FormLabel htmlFor='approveRadio' className='mx-2 cursor-p'>{`${t('DRIVER_DOCUMENT_VERIFICATION_PAGE.APPROVE')}`}</FormLabel>
                    <input type="radio" name="status" checked={radioStatus === 1} className="mx-1 cursor-p" id='approveRadio' onChange={(event) => setStatus(event)} />
                  </div>
                  <div className='me-2'>
                    <>
                      <FormLabel htmlFor='rejectRadio' className='mx-2 cursor-p'>{`${t('DRIVER_DOCUMENT_VERIFICATION_PAGE.REJECT')}`}</FormLabel>
                      <input type="radio" name="status" checked={radioStatus === -2} className="mx-1 cursor-p" id='rejectRadio' onChange={(event) => setStatus(event)} />
                    </>
                  </div>
                </FormGroup>
              </>
              <FormGroup className='d-flex justify-content-start'>
                <Button onClick={() => handleSubmit(props.documentId!, props.documentType!, statusToChange, rejectReason)} disabled={isDisable && (isDisable && !(rejectReason.length > 10))}>
                  {`${t('PROJECT_BUTTON.SUBMIT')}`}
                </Button>
              </FormGroup>
            </Col>
            <Col sm={6} className={'pt-2 px-0'}>
              {isDisable ?
                <FormGroup>
                  <FormLabel>{`${t('DRIVER_DOCUMENT_VERIFICATION_PAGE.MENTION_REASON')}`} :</FormLabel>
                  <input type="text" value={rejectReason} className='form-control form-control-lg border-left-0 w-100 mb-1' placeholder='Please Mention The Reason' onChange={(event) => handleInputRejectChange(event.target.value)} />
                </FormGroup> :
                null
              }
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion >
  )
}

export default DocumentAccordion
