'use strict'
import React, { useContext, useEffect } from 'react'
// Css files
import './assets/css/materialIcons/materialDesignIcons.min.css'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import 'react-toastify/dist/ReactToastify.css'
import UserContext from './Context/UserContext'
import i18n from './i18n'
import ProjectRoutes from './Route/ProjectRoutes'
// import { Online } from 'react-detect-offline'
import instance, { versionInstance } from './Services/AxiosConfig'

/**
 * @function App
 * @returns null
 * @description To change language if it is available in localStorage else continue to default language
 */
const App = () => {
  const { selectedLanguage, setSelectedLanguage, isAppLoading, setIsAppLoading, backdropLoader } = useContext(UserContext)

  instance.interceptors.request.use(
    async (config) => {
      const token = sessionStorage.getItem('token')
      if (token) {
        Object.assign(config.headers!, { authorization: `Bearer ${token}` })
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  versionInstance.interceptors.request.use(
    async (config) => {
      const token = sessionStorage.getItem('token')
      if (token) {
        Object.assign(config.headers!, { authorization: `Bearer ${token}` })
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  useEffect(() => {
    const userSelectedLanguage = localStorage.getItem('language')
    if (userSelectedLanguage) {
      setSelectedLanguage(JSON.parse(JSON.stringify(userSelectedLanguage)))
    }
    return () => { }
  }, [])

  // For Default Page Loading
  useEffect(() => {
    setTimeout(() => {
      setIsAppLoading(false)
    }, 2000)
    return () => { }
  }, [isAppLoading])

  /**
   * @description to make instance reflection on UI while changing the language as selected language changes
   */
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.LANGUAGE_SELECTOR, (error) => {
      if (error) console.error('🔥 ~ i18n.changeLanguage ~ error', error)
    })
    return () => { }
  }, [selectedLanguage])

  return (
    <>
      {/* <Online> */}
      {isAppLoading && backdropLoader ? (
        <div className="main-loader">
          <div className="spinner-border"></div>
        </div>
      ) : (
        <ProjectRoutes />
      )}
      {/* </Online> */}
      {/* <Offline>
        <NoInternet />
      </Offline> */}
    </>
  )
}

export default App
