import axios, { serviceFailureHandle, serviceSuccessHandle } from './AxiosConfig'

const getUserList = (pageDetail?: object) => {
  return axios.post('/api/user/userList/list', pageDetail).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const getSingleUser = (userId: string) => {
  return axios.get(`/api/user/userlist/details/${userId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const putChangeUserStatus = (userId: string, status: number) => {
  return axios.put(`/api/user/userlist/update/status/${userId}`, { status }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

export {
  getUserList,
  putChangeUserStatus,
  getSingleUser
}
