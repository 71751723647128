import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Dropzone from 'react-dropzone'
import _ from 'lodash'
import UserContext from '../../Context/UserContext'
import MediaUploading from './MediaUploading'
import { getPresignedUrl } from '../../Services/CommonService'
import { deleteImageFromMedia, getMediaByCategory, putUpdateImageTitle } from '../../Services/OrganizationServices'
import { ORGANIZATION_RESOURCE_UPLOAD_TYPE } from '../../utils/enums/enums'
import Category from './Category'
import { toast } from 'react-toastify'
import { fetchResponseMessage } from '../../utils/utils'
import { MediaImageModel } from '../../Pages/Models/CommonModel'

type Props = {
  setUrl?: (url: string) => void,
  inputRef?: any
}
const ListMedia = (props: Props) => {
  const { showMediaModelDetails, setShowMediaModelDetails, setShowMediaModel, showMediaModel, setBackdropLoader, setIsFetching } = useContext(UserContext)
  const { t } = useTranslation()
  const [selectedFilesPreview, setSelectedFilesPreview] = useState([] as any[])
  const [pickedImage, setPickedImage] = useState({} as MediaImageModel)
  const [uploadedUrl, setUploadedUrl] = useState([] as string[])
  const [searchBar, setSearchBar] = useState('')

  const [mediaByCategory, setMediaByCategory] = useState([] as MediaImageModel[])
  const [totalDataLength, setTotalDataLength] = useState(0)
  const [perPage] = useState<number>(5)
  const [nextPage, setNextPage] = useState(0)
  const [categoryState, setCategoryState] = useState<number>(1)

  // const [scrollPoint, setScrollPoint] = useState(0)

  const onDrop = async (acceptedFiles: any) => {
    try {
      if (acceptedFiles && acceptedFiles.length > 0) {
        let updatedArray: any = {}
        acceptedFiles.forEach((file: any) => {
          const type = file.path.split('.').pop()
          if (type in updatedArray) {
            updatedArray[type]++
          } else {
            updatedArray = { ...updatedArray, [type]: 1 }
          }
        })
        const finalArray = Object.keys(updatedArray).map((type: string) => ({
          contentType: type !== 'pdf' ? `image/${type}` : 'application/pdf',
          requiredQty: updatedArray[type],
          type: categoryState,
          fileExtension: type
        }))
        setUploadedUrl([])
        setSelectedFilesPreview([])
        setBackdropLoader(true)
        const getPresignedUrlData = await getPresignedUrl(finalArray)
        const arrayWithLinks: any = []
        getPresignedUrlData.data.forEach((dataObject: any, dataObjectIndex: number) => {
          dataObject.data.forEach((data: any, dataIndex: number) => {
            acceptedFiles.every((file: any, fileIndex: number) => {
              if (dataObject.fileExtension === file.type?.split('/')[1]) {
                arrayWithLinks.push({
                  fileObj: Object.assign(file, {
                    preview: URL.createObjectURL(file)
                  }),
                  fileUploadInfo: data
                })
                acceptedFiles.splice(fileIndex, 1)
                // break;
                return false
              } else {
                return true
              }
            })
          })
        })
        setSelectedFilesPreview(arrayWithLinks)
        // setSelectedFilesPreview(acceptedFiles.map((file: any, acceptedFileIndex: number) => {
        //   if (getPresignedUrlData)
        // }))
        // {
        //   fileObj: Object.assign(file, {
        //     preview: URL.createObjectURL(file)
        //   }),
        //     fileUploadInfo: getPresignedUrlData.data[acceptedFileIndex]
        // }
        setBackdropLoader(false)
      }
    } catch (error) {
      console.error('🚀 ~ file: ListMedia.tsx ~ line 116 ~ onDrop ~ error', error)
      setBackdropLoader(false)
    }
  }
  const removeUploadedFile = (fileKey: string) => {
    setUploadedUrl((uploadedUrls: string[]) => [...uploadedUrls, fileKey])
  }
  useEffect(() => {
    if (selectedFilesPreview.length === uploadedUrl.length && uploadedUrl.length > 0) {
      toast.success(`${t('TOAST_ERROR.MEDIA.UPLOAD_IMAGE_SUCCESS')}`)
      setMediaByCategory([])
      fetchMediaFiles({ category: categoryState, limit: perPage })
    }
  }, [uploadedUrl])

  const insertImage = () => {
    if (showMediaModelDetails.setInputState != null) {
      showMediaModelDetails.setInputState(pickedImage.url)
    }
    if (showMediaModelDetails.inputRef != null) {
      showMediaModelDetails.inputRef.current.value = pickedImage.url
    }
    if (props.setUrl != null) {
      props.setUrl(pickedImage.url)
    }
    if (props.inputRef) {
      props.inputRef.current.value = pickedImage.url
    }
    setShowMediaModelDetails({ setInputState: null, inputRef: null })
    setShowMediaModel(false)
  }

  const fetchMediaFiles = async (sortDetailsObject: object) => {
    setIsFetching(true)
    try {
      const response = await getMediaByCategory(sortDetailsObject)
      console.log('🔥 ~ fetchMediaFiles ~ response')
      if (response.data) {
        let updatedImageArray
        if ('page' in sortDetailsObject) {
          updatedImageArray = [...mediaByCategory, ...response.data.data]
        } else {
          updatedImageArray = response.data.data
        }
        setMediaByCategory(updatedImageArray)
        setIsFetching(false)
        setNextPage(response.data.nextPage)
        setTotalDataLength(response.data.total)
      } else {
        throw response
      }
    } catch (error) {
      console.error('🔥 ~ fetchMediaFiles ~ error', error)
      setIsFetching(false)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }
  const handleTabChange = async (category: number) => {
    setCategoryState(category)
    setPickedImage({} as MediaImageModel)
    setMediaByCategory([])
    setTotalDataLength(0)
    setNextPage(0)
  }

  useEffect(() => {
    fetchMediaFiles({ category: categoryState, limit: perPage })
    return () => { }
  }, [categoryState])

  const handleOnScroll = async (event: any, category: number) => {
    const scrollTopPosition = event.target.scrollTop
    if (event.target.scrollHeight - scrollTopPosition === event.target.offsetHeight) {
      const page = +(mediaByCategory.length / perPage).toFixed() + 1
      if (category === categoryState && nextPage !== null) {
        fetchMediaFiles({ category, limit: perPage, page })
        setTimeout(() => {
          event.target.scrollTop += (scrollTopPosition + 50)
        }, 500)
      }
    }
  }

  const handleDelete = async () => {
    setIsFetching(true)
    try {
      const response = await deleteImageFromMedia(pickedImage.id)
      if (response.statusCode === 200) {
        setIsFetching(false)
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
        setMediaByCategory([])
        setPickedImage({} as MediaImageModel)
        setTimeout(() => {
        }, 5000)
        fetchMediaFiles({ category: categoryState, limit: perPage })
      } else {
        throw response
      }
    } catch (error) {
      console.error('🔥 ~ handleDelete ~ error', error)
      setIsFetching(false)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  const setImageTitle = async () => {
    setIsFetching(true)
    try {
      const response = await putUpdateImageTitle(pickedImage.id, pickedImage.title!)
      if (response.statusCode === 200) {
        setIsFetching(false)
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
        fetchMediaFiles({ category: categoryState, limit: perPage })
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ setImageTitle ~ error)', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  const searchBarHandler = async (searchContent: string) => {
    setSearchBar(searchContent)
    fetchMediaFiles({ category: categoryState, limit: perPage, q: searchContent.toLowerCase() })
  }

  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey={ORGANIZATION_RESOURCE_UPLOAD_TYPE.GENERAL.value}>
        <Row className={'media-main-scrollable-div'} onScroll={(event) => handleOnScroll(event, categoryState)}>
          <Col sm={6}>
            <div>
              <Row>
                <Col>
                  <FormControl
                    type="text"
                    className="form-control ms-auto search-bar"
                    placeholder={`${t('DESIGNATION_PAGE.SEARCH_BAR_PLACEHOLDER')}`}
                    onChange={(event) => searchBarHandler(event.target.value)}
                    value={searchBar}
                  />
                </Col>
              </Row>
            </div>
            <Nav variant="pills" className="nav nav-pills nav-pills-custom mt-4" id="pills-tab-custom">
              <Row className='w-100'>
                {Object.keys(ORGANIZATION_RESOURCE_UPLOAD_TYPE).map((key, keyIndex) => {
                  return (
                    <Col xs={6} lg={4} xl={3} key={keyIndex} className={'my-2 px-0'}>
                      <Nav.Item className='m-0 mx-auto d-block w-75'>
                        <Nav.Link className={'px-3 text-overflow-none text-center'} eventKey={ORGANIZATION_RESOURCE_UPLOAD_TYPE[key as keyof typeof ORGANIZATION_RESOURCE_UPLOAD_TYPE].value} onClick={() => handleTabChange(ORGANIZATION_RESOURCE_UPLOAD_TYPE[key as keyof typeof ORGANIZATION_RESOURCE_UPLOAD_TYPE].id)}>{`${t(`MEDIA_PAGE.CATEGORY.${key}`)}`}</Nav.Link>
                      </Nav.Item>
                    </Col>
                  )
                })}
              </Row>
            </Nav>
          </Col>
          <Col sm={6}>
            <Dropzone onDrop={onDrop} multiple={true} maxFiles={8}>
              {({ getRootProps, getInputProps }) => (
                <section >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {selectedFilesPreview && selectedFilesPreview.length && uploadedUrl.length !== selectedFilesPreview.length ? (
                      <div className="selected-file  h-100 d-flex justify-content-evenly align-items-center ">
                        {selectedFilesPreview.length > 0 && selectedFilesPreview.map((file: any, index: number) =>
                          <MediaUploading removeUploadedFile={removeUploadedFile} key={`mediaUploading-${index}`} file={file} category={categoryState} />)}
                      </div>
                    ) : (
                      <div className='dz-message  h-100 d-flex flex-column justify-content-center align-items-center'>
                        <h1 className='mdi mdi-file text-muted'></h1>
                        <span className='text-dark'>{`${t('MEDIA_PAGE.DROP_AREA_TITLE1')}`}</span>
                        <span className='text-dark'>{`${t('MEDIA_PAGE.DROP_AREA_TITLE2')}`}</span>
                      </div>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </Col>
          <Col md={!_.isEmpty(pickedImage) ? 9 : 12}>
            <Tab.Content className="tab-content-custom-pill">
              {Object.keys(ORGANIZATION_RESOURCE_UPLOAD_TYPE).map((categoryType: string, categoryTypeIndex: number) => {
                return <Category
                  key={categoryTypeIndex}
                  eventKey={categoryType.toLowerCase()}
                  mediaList={mediaByCategory}
                  setPickedImage={setPickedImage}
                  pickedImage={pickedImage}
                  totalDataLength={totalDataLength}
                  category={ORGANIZATION_RESOURCE_UPLOAD_TYPE[categoryType as keyof typeof ORGANIZATION_RESOURCE_UPLOAD_TYPE].id}
                  nextPage={nextPage}
                />
              })}
            </Tab.Content>
          </Col>
          {!_.isEmpty(pickedImage) && (
            <Col md={3} className="my-4">
              <span className='px-3 mdi mdi-close d-flex justify-content-end my-2' onClick={() => setPickedImage({} as MediaImageModel)}></span>
              <h5 className='text-center'>{`${t('MEDIA_PAGE.ATTACHMENT_DETAILS')}`}</h5>
              <img className="img-fluid img-thumbnail" src={pickedImage.url} alt="" />
              {showMediaModel ?
                <button className='btn btn-primary d-block ms-auto mt-4' onClick={insertImage}>{`${t('PROJECT_BUTTON.INSERT')}`}</button>
                :
                <div className='mt-3'>
                  <div className="d-flex flex-column justify-content-center px-2">
                    <FormGroup>
                      <FormLabel>Image Name:</FormLabel>
                      <FormControl placeholder='Set Image Title' onChange={(event) => setPickedImage((pickedImage: MediaImageModel) => ({ ...pickedImage, title: event.target.value }))} value={pickedImage.title ? pickedImage.title : ''} />
                    </FormGroup>
                    <div className='d-flex justify-content-center gap-3'>
                      <Button variant='light' className='p-2 my-2' onClick={handleDelete}>{`${t('PROJECT_BUTTON.DELETE')}`}</Button>
                      <Button variant='primary' className='p-2 my-2' onClick={setImageTitle}>{`${t('PROJECT_BUTTON.UPDATE')}`}</Button>
                    </div>
                  </div>
                </div>
              }
            </Col>
          )}
        </Row>
      </Tab.Container>
    </>

  )
}

export default ListMedia
