import { FormikValues } from 'formik'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import VehicleDocumentCreateViewEdit from '../../../Components/Common/VehicleDocumentCreateViewEdit'
import UserContext from '../../../Context/UserContext'
import { getVehicleDocumentDetails } from '../../../Services/VehicleService'
import { PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../utils/utils'

type Props = {}
const EditVehicleDocument = (props: Props) => {
  const { t } = useTranslation()
  const params = useParams()
  const { setArrayOfBreadCrumb, setIsFetching } = useContext(UserContext)
  const [selectedFormat, setSelectedFormat] = useState([] as any)
  const [doShowState, setDoShow] = useState(false)
  const [doseExpiryState, setDoseExpiry] = useState(false)
  const [isRequiredStateState, setIsRequiredState] = useState(true)
  const [isCaptureOnlyStateState, setIsCaptureOnlyState] = useState(false)
  const [documentNameState, setDocumentNameState] = useState('')
  const [documentDescriptionState, setDocumentDescriptionState] = useState('')
  const [documentSizeState, setDocumentSizeState] = useState(0)
  const [documentImageState, setDocumentImageState] = useState('')

  useEffect(() => {
    (async () => {
      setIsFetching(true)
      try {
        const vehicleId = params.itemId?.split('&&')[0]
        const documentId = params.itemId?.split('&&')[1]
        const response = await getVehicleDocumentDetails(vehicleId!, documentId!)
        if (response.data) {
          setDocumentNameState(response.data.title)
          setDocumentDescriptionState(response.data.description)
          setDocumentImageState(response.data.placeHolderImg)
          setDoShow(response.data.doShowDocumentNumber)
          setDoseExpiry(response.data.doShowDocumentExpiryDate)
          setIsRequiredState(response.data.isRequired)
          setIsCaptureOnlyState(response.data.isCaptureOnly)
          const arrayOfContentType = response.data.contentType.split(',').map((formatType: string) => ({ value: formatType, label: formatType }))
          setSelectedFormat(arrayOfContentType)
          setIsFetching(false)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🔥 ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_DOCUMENT')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE, state: params.itemId?.split('&&')[0] },
      { name: `${t('PAGE_NAME.VEHICLE_DOCUMENT_VIEW')}`, url: '' }
    ])
    return () => { }
  }, [])

  const setFieldValue = (formik: FormikValues) => {
    setSelectedFormat(formik.values.documentFormat)
    setDocumentNameState(formik.values.documentName)
    setDocumentDescriptionState(formik.values.documentDescription)
    setDocumentSizeState(formik.values.documentSize)
    setDocumentImageState(formik.values.documentImage)
  }

  return (
    <VehicleDocumentCreateViewEdit
      field={{
        documentName: documentNameState,
        documentDescription: documentDescriptionState,
        documentSize: documentSizeState,
        documentImage: documentImageState,
        documentFormat: selectedFormat,
        setDoShow,
        setDoseExpiry,
        setIsRequiredState,
        setIsCaptureOnlyState,
        setSelectedFormat,
        setDocumentSizeState,
        doShow: doShowState,
        doseExpiry: doseExpiryState,
        isRequiredState: isRequiredStateState,
        isCaptureOnlyState: isCaptureOnlyStateState
      }}
      isDisabled={false}
      cardTitle={`${'EDIT_VEHICLE_DOCUMENT_PAGE.TITLE'}`}
      submitButtonName="EDIT"
      setFieldValue={setFieldValue}
      pageName="edit-vehicle-documents"
      paramId={params.itemId}
    />
  )
}

export default EditVehicleDocument
