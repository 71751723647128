import React from 'react'

type Props = {}

const EditUser = (props: Props) => {
  return (
    <div>EditUser</div>
  )
}

export default EditUser
