import React from 'react'
// import { ReactComponent as Logo } from '../../../assets/logo/logo.svg'

const BrandLogo = () => {
  return (
    <>
      {/* <a className="navbar-brand brand-logo d-flex justify-content-center">
        <Logo />
      </a> */}
      <a className="navbar-brand brand-logo d-flex justify-content-center">
        Colleague

      </a>
    </>
  )
}

export default BrandLogo
