import { Formik, FormikHandlers, FormikValues } from 'formik'
import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { INPUT_FIELD_ENUMS, PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { RequestResetPasswordSchema } from '../../Pages/RequestResetPassword/RequestResetPasswordSchema'
import { useTranslation } from 'react-i18next'
import { postRequestResetPassword } from '../../Services/AuthService'
import FormInputText from '../../Components/Form/FormInputText/FormInputText'
import FormButton from '../../Components/Form/FormButton/FormButton'
import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
import { fetchResponseMessage } from '../../utils/utils'
import _ from 'lodash'

type Props = {}

const RequestResetPassword = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setIsFetching, isFetching } = useContext(UserContext)

  // initialUserInfo is for formik initial value.
  const initialUserInfo = {
    email: ''
  }
  /**
   * @function handleRequestOfResetPassword
   * @return void
   * @param values
   * @description function will send the post request with email id entered and will navigate another page.
   */
  const handleRequestOfResetPassword = async (values: FormikValues) => {
    try {
      setIsFetching(true)
      const response = await postRequestResetPassword({ email: values.email })
      const responseMessage = fetchResponseMessage(response.responseCode)
      if (response.statusCode === 200) {
        setIsFetching(false)
        toast.success(responseMessage)
        if (response.debug) {
          const token = response.debug.resetToken
          navigate(PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.RESET_PASSWORD_PAGE + `/${token}`)
        }
      } else {
        throw response
      }
    } catch (error: any) {
      setIsFetching(false)
      console.error('🚀 ~ file: RequestResetPasswordForm.tsx ~ line 28 ~ handleRequestOfResetPassword ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  return (
    <Formik
      initialValues={initialUserInfo}
      validationSchema={() => RequestResetPasswordSchema}
      onSubmit={(values) => {
        handleRequestOfResetPassword(values)
      }}
    >
      {(formik: FormikHandlers) => (
        <form className="pt-3" onSubmit={formik.handleSubmit}>
          <FormInputText
            inputField="EMAIL_ADDRESS"
            type={INPUT_FIELD_ENUMS.EMAIL_ADDRESS.INPUT_TYPE}
            inputName={INPUT_FIELD_ENUMS.EMAIL_ADDRESS.INPUT_NAME}
            isRequired={INPUT_FIELD_ENUMS.EMAIL_ADDRESS.IS_REQUIRED}
          />
          <FormButton buttonName={`${t('FORM_PAGE_CONSTANTS.BUTTON_NAME.REQUEST_RESET_PASSWORD')}`} isDisable={false} isFetching={isFetching} />
          <div className="text-center mt-4 font-weight-light">
            {`${`${t('FORM_PAGE_CONSTANTS.BOTTOM_LINE.REQUEST_RESET_PASSWORD')}`}`} &nbsp;
            <Link to={PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.LOGIN_PAGE} className="text-primary">
              {`${t('FORM_PAGE_CONSTANTS.BUTTON_NAME.LOGIN_PAGE')}`}
            </Link>
            &nbsp;
          </div>
        </form>
      )}
    </Formik>
  )
}

export default RequestResetPassword
