import { ErrorMessage, useField } from 'formik'
import React from 'react'
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

type Props = {
  inputName: string
  type: string
  isRequired?: boolean
  inputField?: string
  isDisabled?: boolean
  class?: string
}

const FormInputText = (props: Props) => {
  const { i18n } = useTranslation()
  const [field] = useField(props.inputName)

  return (
    <FormGroup className={'form-group text-truncate'}>
      <FormLabel className=''>{`${i18n.t(`INPUT_FIELD_ENUMS.${props.inputField}.LABEL_NAME`)} ${props.isRequired ? '*' : ''}`}</FormLabel>
      <div className="d-flex flex-column">
        <FormControl
          type={props.type}
          className={`form-control form-control-lg border-left-0 w-100 mb-1 ${props.class && props.class}`}
          placeholder={`${i18n.t(`INPUT_FIELD_ENUMS.${props.inputField}.PLACEHOLDER`)}`}
          {...field}
          value={field.value !== null ? field.value : ''}
          autoComplete="off"
          disabled={!!props.isDisabled}
        />
        <ErrorMessage component="div" name={field.name} className="form-error" />
      </div>
    </FormGroup>
  )
}

export default FormInputText
