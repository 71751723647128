/* eslint-disable indent */
// TODO : Remove Eslint disabled hack
// TODO : Refactor this file this file is not yet reviewed
// TODO : This file is pending for review
import _ from 'lodash'
import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Form, FormGroup, Row, Spinner } from 'react-bootstrap'
import CheckboxTree, { Node } from 'react-checkbox-tree'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
// import { useLocation } from 'react-router-dom'
import ReactSelect, { SingleValue } from 'react-select'
import { toast } from 'react-toastify'
import InnerLoader from '../../Components/Common/InnerLoader'
import UserContext from '../../Context/UserContext'
import { getDesignation, getDesignationRights, patchUpdateDesignation } from '../../Services/DesignationService'
import { CRUD_KEY_DESIGNATION_RIGHT, PROJECT_ROUTES_ENUMS, SCREEN_SIZE } from '../../utils/enums/enums'
import { fetchResponseMessage, getTreeFromFlatData } from '../../utils/utils'
import { DesignationResponseObject, DesignationTableRow, /* DesignationTableRow, */ Rights, SingleDesignationObject } from '../Models/CommonModel'
// import { OptionDataModel } from '../Register/UserRegistrationModel'
type Props = {}
const DesignationRights = (props: Props) => {
  const [expanded, setExpand] = useState<Array<string>>([])
  const [responseObject, setResponseObject] = useState<DesignationResponseObject>([])
  const [finalTreeObject, setFinalTreeObject] = useState<Node[]>([])
  // TODO: optionDataModel will be changed as per data we will get for designation
  const [designationOption, setDesignationOption] = useState({} as { value: string; label: string; id?: string }[])
  const [selectedChoice, setSelectedChoice] = useState<SingleValue<{ value: string; label: string; id?: string }>>({} as { value: string; label: string; id?: string })
  const { t } = useTranslation()
  const { setArrayOfBreadCrumb, isFetching, setIsFetching } = useContext(UserContext)
  const location = useLocation()
  // const location = useLocation()

  /**
   * @function checkParentElement
   * @return void
   * @param parentId
   * @param moduleRights
   *  @description Function will check mark all the parent check box.
   */
  const checkParentElement = (parentId: string, moduleRights: string) => {
    let newParentId = parentId
    responseObject.map((obj: SingleDesignationObject) => {
      if (obj.moduleId === parentId) {
        obj[moduleRights as keyof Rights] = Number(true)
        newParentId = obj.parentModuleId
      }
      return obj
    })
    if (newParentId !== null) {
      checkParentElement(newParentId, moduleRights)
    }
  }

  /**
   * @function checkParentElement
   * @return void
   * @param parentId
   * @param moduleRights
   *  @description Function will remove the check mark from all the children check box.
   */
  const uncheckChildren = (moduleId: string, moduleRights: string) => {
    let newModuleId = moduleId
    responseObject.map((obj: SingleDesignationObject) => {
      if (`${obj.parentModuleId}` === moduleId) {
        obj[moduleRights as keyof Rights] = Number(false)
        newModuleId = `${obj.moduleId}`
        const isAnyChildren = responseObject.find((obj) => `${obj.parentModuleId}` === newModuleId)
        if (isAnyChildren) {
          uncheckChildren(newModuleId, moduleRights)
        }
      }
      return obj
    })
  }

  /**
   * @function checkBoxClickHandler
   * @return void
   * @param event
   * @description will call when user will click on check box and function will check parent and children check box according to that
   *
   */
  const checkBoxClickHandler = (event: React.MouseEvent) => {
    const element = event.target as HTMLInputElement
    const isClicked = element.checked
    let parentId = null
    const moduleId = element.id.split('.')[0].trim()
    const moduleRights = element.id.split('.')[1].trim()
    const setObj = responseObject.map((obj: SingleDesignationObject) => {
      if (parseInt(obj.moduleId) === parseInt(moduleId)) {
        obj[moduleRights as keyof Rights] = Number(!obj[moduleRights as keyof Rights])
        parentId = obj.parentModuleId
      }
      return obj
    })
    setResponseObject(setObj)
    if (parentId !== null && isClicked) {
      checkParentElement(parentId, moduleRights)
    } else if (!isClicked) {
      uncheckChildren(moduleId, moduleRights)
    }
  }

  /**
   * @function addFieldToTreeObject
   * @param array
   * @returns array of tree object
   * @description this function will tack array of tree object generate from function getTreeFromFlatData() and delete and add some field and make it compatible with CheckBoxTree component of react checkbox-tree.
   */
  const addFieldToTreeObject = (array: DesignationResponseObject) => {
    let actionNameArray: any
    window.innerWidth > SCREEN_SIZE.SCREEN_SIZE_720
      ? (actionNameArray = [
        { name: `${t('DESIGNATION_RIGHT_PAGE.RIGHTS_NAME.CREATE')}`, id: 1 },
        { name: `${t('DESIGNATION_RIGHT_PAGE.RIGHTS_NAME.READ')}`, id: 2 },
        { name: `${t('DESIGNATION_RIGHT_PAGE.RIGHTS_NAME.UPDATE')}`, id: 3 },
        { name: `${t('DESIGNATION_RIGHT_PAGE.RIGHTS_NAME.DELETE')}`, id: 4 }
      ])
      : (actionNameArray = [
        { name: `${t('DESIGNATION_RIGHT_PAGE.RIGHTS_NAME_MOBILE_VIEW.CREATE')}`, id: 1 },
        { name: `${t('DESIGNATION_RIGHT_PAGE.RIGHTS_NAME_MOBILE_VIEW.READ')}`, id: 2 },
        { name: `${t('DESIGNATION_RIGHT_PAGE.RIGHTS_NAME_MOBILE_VIEW.UPDATE')}`, id: 3 },
        { name: `${t('DESIGNATION_RIGHT_PAGE.RIGHTS_NAME_MOBILE_VIEW.DELETE')}`, id: 4 }
      ])
    const newObj = array.map((obj: any, index) => {
      if ('children' in obj) {
        let obj1 = {
          ...obj,
          value: obj.moduleId,
          label: (
            <React.Fragment key={`${index} ${obj.moduleName}`}>
              {<h6 className="designation-rights-heading">{`${obj.moduleName}`}</h6>}&nbsp;&nbsp;&nbsp;
              {CRUD_KEY_DESIGNATION_RIGHT.map((action: { name: string, id: number }, actionIndex: number) => (
                <React.Fragment key={`checkbox ${actionIndex}`}>
                  <>
                    <label htmlFor={`${obj.moduleId}.${action.name}`}>{`${(actionNameArray.find((obj: any) => obj.id === action.id).name)}`}</label>
                    {
                      <input
                        type="checkbox"
                        checked={!!(obj[action.name])}
                        id={`${obj.moduleId}.${action.name}`}
                        className="children-checkbox"
                        onClick={(event) => checkBoxClickHandler(event)}
                        onChange={() => null}
                      />
                    }
                    | &nbsp;
                  </>
                </React.Fragment>
              ))}
            </React.Fragment>
          ),
          showCheckbox: false
        }
        const children = obj1.children
        obj1 = _.omit(obj1, ['children', 'moduleName', 'moduleId', 'create', 'read', 'update', 'delete', 'parentModuleId'])
        obj1 = {
          ...obj1,
          children: addFieldToTreeObject(children)
        }
        return obj1
      } else {
        let obj1 = {
          ...obj,
          value: obj.moduleId,
          label: (
            <React.Fragment key={`${index} ${obj.moduleName}`}>
              <div className="d-flex">
                {<h6 className="designation-rights-heading">{`${obj.moduleName}:`}</h6>}&nbsp;&nbsp;&nbsp;
              </div>

              {CRUD_KEY_DESIGNATION_RIGHT.map((action: { name: string, id: number }, actionIndex: number) => (
                <React.Fragment key={`checkbox ${actionIndex}`}>
                  <label htmlFor={`${obj.moduleId}.${action.name}`}>{`${(actionNameArray.find((obj: any) => obj.id === action.id).name)}`}</label>
                  {
                    <>
                      <input
                        type="checkbox"
                        id={`${obj.moduleId}.${action.name}`}
                        checked={!!(obj[action.name])}
                        className="children-checkbox"
                        onClick={(event) => checkBoxClickHandler(event)}
                        onChange={() => null}
                      />
                    </>
                  }
                  | &nbsp;
                </React.Fragment>
              ))}
            </React.Fragment>
          ),
          showCheckbox: false
        }
        obj1 = _.omit(obj1, ['children', 'moduleName', 'moduleId', 'create', 'read', 'update', 'delete', 'parentModuleId'])
        return obj1
      }
    })
    return newObj
  }

  useEffect(() => {
    // setScreenSize(window.innerWidth)
    ; (async () => {
      let designationId: string
      setIsFetching(true)
      try {
        const response = await getDesignation()
        if (response.data) {
          const selectMenuOption = response.data.map((singleObject: DesignationTableRow) => ({
            value: singleObject.designation,
            label: singleObject.designation,
            id: singleObject.id
          }))
          setDesignationOption(selectMenuOption)
          if (_.has(location, 'state.id')) {
            // @ts-ignore
            designationId = location.state.id
            const defaultSelected = selectMenuOption.filter((defaultSelected: any) => defaultSelected.id === designationId)
            setSelectedChoice(defaultSelected[0])
          } else {
            designationId = selectMenuOption[0].id
            setSelectedChoice(selectMenuOption[0])
          }
          // selectChangeHandler(designationId)
          setIsFetching(false)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🚀 ~ file: DesignationRights.tsx ~ line 284 ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
        // toast.error(`${t('TOAST_ERROR.DESIGNATION_RIGHTS.FIELD_TO_LOAD_DESIGNATION_MENU')}`)
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.DESIGNATION_RIGHTS')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_RIGHT_PAGE }
    ])
  }, [])
  useEffect(() => {
    const treeObject = getTreeFromFlatData(_.cloneDeep(responseObject))
    const convertedTreeObject = addFieldToTreeObject(treeObject)
    setFinalTreeObject(convertedTreeObject)

    return () => { }
  }, [responseObject])

  useEffect(() => {
    setIsFetching(true)
    try {
      if (selectedChoice && _.has(selectedChoice, 'id') && selectedChoice.id) {
        getDesignationRights(selectedChoice.id).then((response) => {
          if (response.data) {
            setResponseObject(response.data)
            setIsFetching(false)
          } else {
            throw response
          }
        })
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🚀 ~ file: DesignationRights.tsx ~ line 348 ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }

    return () => { }
  }, [selectedChoice])

  const designationRightSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    try {
      setIsFetching((isFetching) => true)
      if (selectedChoice && selectedChoice.id && _.has(selectedChoice, 'id')) {
        const response = await patchUpdateDesignation(selectedChoice.id, responseObject)
        if (response.data) {
          setIsFetching((isFetching) => false)
          const responseMessage = fetchResponseMessage(response.responseCode)
          toast.success(responseMessage)
          // TODO : From Renish - Sagar, Why you added this timeout?
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          throw response
        }
      }
    } catch (error) {
      setIsFetching((isFetching) => false)
      console.error('🚀 ~ file: DesignationRights.tsx ~ line 285 ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  return (
    <Form onSubmit={designationRightSubmitHandler}>
      <Card.Title className="mb-3"></Card.Title>
      {isFetching ? (
        <InnerLoader />
      ) : (
        <Row className='justify-content-center'>
          <Col sm={12} md={6} lg={6} xl={6} className='align-items-center'>
            <FormGroup className={'form-group'}>
              <ReactSelect
                name={'designation'}
                placeholder={'designation'}
                options={designationOption}
                value={selectedChoice}
                className="form-control form-control-lg p-0 mb-1 react-select-menu"
                classNamePrefix={'react-select'}
                captureMenuScroll={true}
                onChange={(value) => {
                  setSelectedChoice(value)
                }}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <Card.Body className="designation-rights">
              {finalTreeObject.length > 0 ? (
                <CheckboxTree
                  nodes={finalTreeObject}
                  nativeCheckboxes={true}
                  expanded={expanded}
                  showNodeTitles={false}
                  showNodeIcon={false}
                  onExpand={(expanded) => setExpand(expanded)}
                  showExpandAll={true}
                  icons={{
                    expandClose: <span className="mdi mdi-chevron-right" />,
                    expandOpen: <span className="mdi mdi-chevron-down" />,
                    expandAll: <span className="mdi mdi-expand-all-outline" />,
                    collapseAll: <span className="mdi mdi-collapse-all-outline" />
                  }}
                />
              ) : (
                <div className="text-center font-weight-bold">{`${t('DESIGNATION_RIGHT_PAGE.NO_DESIGNATION')}`}</div>
              )}
            </Card.Body>
          </Col>
        </Row>
      )}
      <div className='d-flex justify-content-between mt-4'>
        <div>
          <Button type='submit' className={`py-1 my-0 add-button ${!finalTreeObject.length && 'd-none'}`}>
            {isFetching ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : `${t('PROJECT_BUTTON.SUBMIT')}`}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default DesignationRights
