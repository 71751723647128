import React, { useContext } from 'react'
import NotificationIcon from './RightSideIcons/NotificationIcon/NotificationIcon'
import Toggler from './Toggler/Toggler'
import UserIcon from './RightSideIcons/UserIcon/UserIcon'
import LanguageOptionButton from '../LanguageOptionButton/LanguageOptionButton'
import UserContext from '../../Context/UserContext'
import { useTranslation } from 'react-i18next'
type Props = {}
const Navbar = (props: Props) => {
  const { setDarkMode, darkMode, adminName } = useContext(UserContext)
  const { t } = useTranslation()
  const changeTheme = () => {
    setDarkMode(!darkMode)
    localStorage.setItem('theme', JSON.stringify(!darkMode))
  }
  return (
    <div>
      <nav className="navbar col-lg-12 col-12 p-0 d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex align-items-center justify-content-start">
          <a className="navbar-brand brand-logo-mini">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="28" height="28" rx="8" fill="#F02632" />
              <circle cx="19" cy="20" r="4" fill="#F8F9F9" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 14C18.2091 14 20 12.2091 20 10C20 7.79086 18.2091 6 16 6C14.3367 6 12.9106 7.01517 12.3073 8.45969C11.597 8.16354 10.8176 8 10 8C6.68629 8 4 10.6863 4 14C4 17.3137 6.68629 20 10 20C13.3137 20 16 17.3137 16 14Z"
                fill="#F8F9F9"
              />
            </svg>
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            {/* <Toggler classType={'navbar-toggler'} /> */}
            <div className="welcome-message d-lg-flex d-none">
              <>
                {t('NAVBAR.GREETING_LINE')}
                <div className='ms-3'>{adminName}</div>
                {/* <span className='ms-2'>
                  {userInfo.data.firstName}
                </span> */}
              </>
            </div>
          </div>
          {/* <SearchBar /> */}
          <ul className="navbar-nav navbar-nav-right">
            <li className="">
              <NotificationIcon />
            </li>
            <li className='nav-item'>
              <button className="table-button change-theme d-block mx-auto text-center" onClick={changeTheme}>
                <i className={'badge-icon mdi mdi-white-balance-sunny mx-0'}></i>
              </button>
            </li>
            <li>
              <LanguageOptionButton className="dropdown align-self-end" />
            </li>
            <li className="">
              <UserIcon header={adminName} />
            </li>
          </ul>
          <Toggler classType={'navbar-toggler-right d-lg-none'} />
        </div>
      </nav>
    </div>
  )
}

export default Navbar
