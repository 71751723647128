import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ENGLISH_TRANSLATION } from './translation/en/Translation'
import { FRENCH_TRANSLATION } from './translation/fr/Translation'
import { LANGUAGES } from './utils/enums/enums'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: ENGLISH_TRANSLATION
      },
      fr: {
        translation: FRENCH_TRANSLATION
      }
    },
    fallbackLng: LANGUAGES[0].LANGUAGE_SELECTOR
  })

export default i18n
