/* eslint-disable indent */
// TODO : this page is pending for review
// TODO : three dots instanced of action button
import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, FormControl, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ModalComponent from '../../../Components/Common/ModalComponent'
import UserContext from '../../../Context/UserContext'
import { deleteDesignation, getAssociatedAdmin, getDesignation } from '../../../Services/DesignationService'
import { PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import DataTable from 'react-data-table-component'
import { DesignationTableRow, ReactDataTable } from '../../Models/CommonModel'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { fetchResponseMessage/* , getDropdownTitle */ } from '../../../utils/utils'

type Props = {}

const Designation = (props: Props) => {
  const { t } = useTranslation()
  const [searchBar, setSearchBar] = useState('')
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [selectedDesignation, setSelectedDesignation] = useState('')
  const [tableBodyState, setTableBodyState] = useState([] as DesignationTableRow[])
  const [filteredTableOption, setFilteredTableOption] = useState([] as { value: string, label: string, id: string }[])
  const { setArrayOfBreadCrumb, isFetching, setIsFetching/* , permissionsObject */ } = useContext(UserContext)
  const [modalMessage, setModalMessage] = useState('')
  const [tableResponse, setTableResponse] = useState([] as DesignationTableRow[])
  const [numberOfAdminsToMove, setNumberOfAdminsToMove] = useState(0)

  // fetch content of table while you to to Designation page.
  const getTableData = async () => {
    setIsFetching(true)
    try {
      const response = await getDesignation()
      if (response.data) {
        setTableBodyState(response.data)
        setTableResponse(response.data)
        setIsFetching(false)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🚀 ~ file: Designation.tsx ~ line 56 ~ useEffect ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
      // toast.error(`${t('TOAST_ERROR.DESIGNATION.FILED_TO_LOAD')}`)
    }
  }
  useEffect(() => {
    getTableData()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.DESIGNATION')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE }
    ])
    return () => { }
  }, [])
  /**
   *@function searchBarHandler
   *@return void
   *@param event
   * @description To handle search on Designation page.
   */
  const searchBarHandler = async (event: React.ChangeEvent) => {
    const element = event.target as HTMLInputElement
    setSearchBar(element.value)
    const searchResult = tableResponse.filter((tableRow: DesignationTableRow) => tableRow.designation.toLowerCase().includes(element.value.toLowerCase()))
    setTableBodyState(searchResult)
  }

  const columns: ReactDataTable[] = [
    {
      name: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.DESIGNATION')}`,
      selector: (row: { designation: string }) => row.designation,
      sortable: true
    },
    {
      name: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.DUTY')}`,
      selector: (row: { duty: string }) => row.duty,
      sortable: true
    },
    {
      // TODO: from translation
      name: 'Action',
      cell: (tableRow: DesignationTableRow) => (
        <>
          <span onClick={() => editItem(tableRow.id)} className='mdi mdi-pencil table-setting-icon mx-1 text-primary'></span>
          <span onClick={() => deleteItem(tableRow.id)} className='mdi mdi-trash-can-outline table-setting-icon mx-1 text-danger delete'></span>
          <span onClick={() => designationRights(tableRow.id)} className='mdi mdi-account-edit  table-setting-icon mx-1'></span>
        </>
      )
    }
  ]

  // TODO Remove Above Action Button Click Handler and make it dynamic from array
  //  below three functions are called from switch case of ActionButtonClickHandler function.
  const editItem = async (itemId: string) => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE}/edit/${itemId}`)
  }
  const deleteItem = async (itemId: string) => {
    setSelectedDesignation(itemId)
    setIsFetching(true)
    const selectedField = tableBodyState.filter((designation) => designation.id === itemId)[0]
    try {
      const response = await getAssociatedAdmin(itemId)
      if (response && response.data) {
        if (_.has(response, 'data.adminsCount') && response.data.adminsCount > 0) {
          setNumberOfAdminsToMove(response.data.adminsCount)
          let filteredTable = tableBodyState.map((designation) => ({ ...designation, value: designation.designation, label: designation.designation }))
          filteredTable = filteredTable.filter((designation) => designation.id !== itemId)
          setFilteredTableOption(filteredTable)
          setModalMessage(`You have ${response.data.adminsCount} entry ${selectedField.designation} designation if you want to move this under another designation then select.`)
          setShowModal(true)
        } else {
          setNumberOfAdminsToMove(0)
          setModalMessage(`${t('DESIGNATION_PAGE.MODAL.MODAL_MESSAGE')} ${selectedField.designation}?`)
          setShowModal(true)
        }
        setIsFetching(false)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🚀 ~ file: Designation.tsx ~ line 162 ~ deleteItem ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
      // toast.error('can not fetch designation')
    }
  }

  const viewItem = async (itemId: string) => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE}/view/${itemId}`)
  }

  const designationRights = (itemId: string) => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_RIGHT_PAGE}`, { state: { id: itemId } })
  }

  /**
   * @function confirmDeleteHandle
   * @return void
   * @param designationOrder
   * @description Delete entry from Designation table and Handle confirm button click event which is on modal that will appears while you click on Delete option in Action dropdown.
   */
  const confirmDeleteHandle = async (designationOrder: string, selectedDesignation: { value: string, label: string, id: string }) => {
    setIsFetching(true)
    try {
      let response
      if (numberOfAdminsToMove && selectedDesignation) {
        response = await deleteDesignation(designationOrder, selectedDesignation.id)
      } else if (!numberOfAdminsToMove) {
        response = await deleteDesignation(designationOrder)
      }
      if (response) {
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
        // toast.success(`${t('TOAST_ERROR.DESIGNATION.DELETE_SUCCESS')}`)
        setShowModal(false)
        getTableData()
      } else {
        throw response
      }
    } catch (error) {
      console.error('🚀 ~ file: Designation.tsx ~ line 200 ~ error', error)
      setShowModal(false)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
      // toast.error(`${t('TOAST_ERROR.DESIGNATION.FILED_TO_DELETE')}`)
    }
  }

  /**
   * @function goToAddDesignationPage
   * @return void
   * @description Go to add-designation page
   */

  /*  const goToAddDesignationPage = () => {
     navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE}/add `)
   } */
  return (
    <>
      <ModalComponent
        designationId={selectedDesignation}
        show={showModal}
        onHide={() => {
          setFilteredTableOption([])
          setShowModal(false)
          setNumberOfAdminsToMove(0)
        }}
        modalTitle={`${t('DESIGNATION_PAGE.MODAL.MODAL_TITLE')}`}
        // TODO: make bold designation in modal message below
        modalMessage={modalMessage}
        confirmDeleteHandle={confirmDeleteHandle}
        options={filteredTableOption}
      />
      <div className='mb-4'>
        <Row>
          <Col sm={12} md={6} lg={4} xl={3}>
            <FormControl
              type="text"
              className="form-control ms-auto search-bar w-10"
              placeholder={`${t('DESIGNATION_PAGE.SEARCH_BAR_PLACEHOLDER')}`}
              onChange={(event) => searchBarHandler(event)}
              value={searchBar}
            />
          </Col>
        </Row>
      </div>
      <Card className="designation-table">
        <Card.Body>
          <Row>
            <Col>
              <div>

                <Row>
                  <Col>
                    {isFetching ? (
                      <div className="loading d-block mx-auto">
                        <div className="spinner-border"></div>
                      </div>
                    ) : tableBodyState.length === 0 ? (
                      <div className="text-center font-weight-bold">{`${t('DESIGNATION_PAGE.DESIGNATION_TABLE.NO_DESIGNATION')}`}</div>
                    ) : (
                      <>
                        <DataTable
                          columns={columns}
                          data={tableBodyState}
                          onRowClicked={(row) => viewItem(row.id)}
                          className="table-with-pagination"
                          highlightOnHover
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.TABLE.ROW_PER_PAGE')}`,
                            rangeSeparatorText: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.TABLE.RANGE_SELECTION')}`
                          }}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default Designation
