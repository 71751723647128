import React from 'react'

const InnerLoader = () => {
  return (
    <div className="loading d-block mx-auto">
      <div className="spinner-border"></div>
    </div>
  )
}

export default InnerLoader
