import React from 'react'
import Navbar from '../Navbar/Navbar'

type Props = {
  toggleSidebar: () => void
}

const Header = ({ toggleSidebar }: Props) => {
  return <Navbar />
}

export default Header
