import React from 'react'

type Props = {}

const TermsCondition = (props: Props) => {
  return (
    <div>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque saepe doloribus explicabo, maiores magnam reprehenderit officia, ullam dicta nulla magni in dolore rerum error
      officiis minima assumenda consectetur facilis. Odit.
    </div>
  )
}

export default TermsCondition
