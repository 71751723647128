import { Formik, FormikValues } from 'formik'
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import { Button, Card, Col, Form, FormControl, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
import { documentFormatArray, INPUT_FIELD_ENUMS, MODULE_ID, MULTIPLICATION_CONSTANTS_FOR_MB_TO_BYTE, PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { useNavigate } from 'react-router-dom'
import IsAuthorized from '../IsAuthorized/IsAuthorized'
import { fetchResponseMessage } from '../../utils/utils'
import _ from 'lodash'
import FormInputText from '../Form/FormInputText/FormInputText'
import { patchEditVehicleDocument, postCreateVehicleDocument } from '../../Services/VehicleService'
import FormInputSelect from '../Form/FormInputSelect/FormInputSelect'
import { MultiValue, SingleValue } from 'react-select'
import { SelectDropdownModel, SingleOptionModel } from '../../Pages/Register/UserRegistrationModel'
import { VehicleDocumentSchema } from '../../Pages/VehicleMenu.tsx/VehicleDocument/VehicleDocumentSchema'
import ImagePicker from './ImagePicker'

type Props = {
  field: {
    documentName: string,
    documentDescription: string,
    documentSize: number,
    documentImage: string,
    documentFormat: any,
    setDoShow: Dispatch<SetStateAction<boolean>>,
    setDoseExpiry: Dispatch<SetStateAction<boolean>>,
    setIsRequiredState: Dispatch<SetStateAction<boolean>>,
    setIsCaptureOnlyState: Dispatch<SetStateAction<boolean>>,
    setSelectedFormat: Dispatch<SetStateAction<MultiValue<SingleOptionModel>>>,
    setDocumentSizeState: Dispatch<SetStateAction<number>>,
    doShow: boolean,
    doseExpiry: boolean,
    isRequiredState: boolean,
    isCaptureOnlyState: boolean
  }
  isDisabled?: boolean
  cardTitle: string
  paramId?: string
  redirectTo?: string
  submitButtonName: string
  setFieldValue?: (formik: FormikValues) => void
  pageName: string
}

const VehicleDocumentCreateViewEdit = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isFetching, setIsFetching, setArrayOfBreadCrumb, setShowMediaModelDetails, setShowMediaModel } = useContext(UserContext)

  useEffect(() => {
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST_VIEW')}`, url: '' }
    ])
    return () => { }
  }, [])

  /**
  * @function editDesignationSubmit
  * @return void
  * @param value
  *@description To handle submit  after changes are made in  any Designation field
  */
  const editCreateVehicleDocument = async (values: FormikValues, { resetForm }: FormikValues) => {
    if (props.pageName === 'view-vehicle-documents') {
      navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE}/edit/${props.paramId}`)
    } else {
      setIsFetching(true)
      const fieldValue = {
        title: values.documentName,
        description: values.documentDescription,
        contentType: (values.documentFormat.map((format: any) => format.value)).toString(),
        maxSize: (values.documentSize || 8) * MULTIPLICATION_CONSTANTS_FOR_MB_TO_BYTE,
        doShowDocumentNumber: props.field.doShow,
        doShowDocumentExpiryDate: props.field.doseExpiry,
        placeHolderImg: values.documentImage ? values.documentImage : 'https://media.istockphoto.com/vectors/card-icon-vector-design-template-trendy-style-with-editable-stroke-vector-id1276399907?k=20&m=1276399907&s=612x612&w=0&h=8t2JfrdM_omgNFtzSOhwFQT1kYAArkBWSoqTmv7xBg8=',
        isCaptureOnly: props.field.isCaptureOnlyState,
        isRequired: props.field.isRequiredState
      }
      // let toastType
      try {
        let response
        const lastParamsOfPath = location.pathname.split('/')[location.pathname.split('/').length - 1]
        if (lastParamsOfPath === 'add') {
          response = await postCreateVehicleDocument(props.paramId?.split('&&')[0]!, fieldValue)
        } else {
          response = await patchEditVehicleDocument(props.paramId?.split('&&')[0]!, props.paramId?.split('&&')[1]!, fieldValue)
        }
        if (response.data) {
          const responseMessage = fetchResponseMessage(response.responseCode)
          toast.success(responseMessage)
          setIsFetching(false)
          resetForm()
          navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE, { state: props.paramId?.split('&&')[0] })
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🚀 ~ file: AddDesignation.tsx ~ line 52 ~ addDesignationSubmitHandler ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    }
  }

  const setSelection = (event: any, formik: FormikValues) => {
    props.field.setSelectedFormat!(event)
    formik.values.documentFormat = event
    props.setFieldValue!(formik)
  }
  const initialUserInfo = {
    documentName: props.field.documentName,
    documentDescription: props.field.documentDescription,
    documentSize: props.field.documentSize,
    documentImage: props.field.documentImage,
    documentFormat: props.field.documentFormat
  }

  const openMedia = (formik: FormikValues, inputName: string) => {
    function setFormikValue (newValue: string) {
      formik.setFieldValue(inputName, newValue)
    }
    setShowMediaModelDetails({ setInputState: setFormikValue, inputRef: null })
    setShowMediaModel(true)
  }

  return (

    <Formik
      enableReinitialize={true}
      initialValues={initialUserInfo}
      validationSchema={() => VehicleDocumentSchema}
      onSubmit={(values: FormikValues, formik) => {
        editCreateVehicleDocument(values, formik)
      }}
    >
      {(formik: FormikValues) => (
        <Form onSubmit={formik.handleSubmit}>
          <fieldset disabled={props.isDisabled}>
            <Row>
              <Col sm={12} md={6} lg={4} xl={3} className='my-2'>
                <FormInputText
                  inputName={INPUT_FIELD_ENUMS.DOCUMENT_NAME.INPUT_NAME}
                  type={INPUT_FIELD_ENUMS.DOCUMENT_NAME.INPUT_TYPE}
                  isRequired={INPUT_FIELD_ENUMS.DOCUMENT_NAME.IS_REQUIRED}
                  inputField={'DOCUMENT_NAME'}
                />
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className='my-2'>
                <FormInputText
                  inputName={INPUT_FIELD_ENUMS.DOCUMENT_DESCRIPTION.INPUT_NAME}
                  type={INPUT_FIELD_ENUMS.DOCUMENT_DESCRIPTION.INPUT_TYPE}
                  isRequired={INPUT_FIELD_ENUMS.DOCUMENT_DESCRIPTION.IS_REQUIRED}
                  inputField={'DOCUMENT_DESCRIPTION'}
                />
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className='my-2'>
                <FormInputSelect
                  inputField={'DOCUMENT_FORMAT'}
                  isRequired={INPUT_FIELD_ENUMS.DOCUMENT_FORMAT.IS_REQUIRED}
                  inputName={INPUT_FIELD_ENUMS.DOCUMENT_FORMAT.INPUT_NAME}
                  options={documentFormatArray}
                  defaultSelected={props.field.documentFormat}
                  handleSelection={(event: MultiValue<SingleOptionModel> | SingleValue<SelectDropdownModel>) => {
                    setSelection(event, formik)
                  }}
                  isMulti={true}
                />
              </Col>

              {/* TODO : Renish - This is not working fine */}
              <Col sm={12} md={6} lg={4} xl={3} className='my-2'>
                <FormGroup>
                  <FormLabel >{`${t('INPUT_FIELD_ENUMS.DOCUMENT_PLACE_HOLDING_IMAGE.LABEL_NAME')} :`}</FormLabel>
                  <ImagePicker openMedia={() => openMedia(formik, INPUT_FIELD_ENUMS.DOCUMENT_PLACE_HOLDING_IMAGE.INPUT_NAME)}>
                    <FormControl
                      className='form-control form-control-lg border-left-0 w-100 mb-1'
                      // TODO: from translation
                      name={INPUT_FIELD_ENUMS.DOCUMENT_PLACE_HOLDING_IMAGE.INPUT_NAME}
                      placeholder={`${t('INPUT_FIELD_ENUMS.DOCUMENT_PLACE_HOLDING_IMAGE.PLACEHOLDER')}`}
                      value={formik.values.documentImage}
                    />
                  </ImagePicker>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1 text-truncate me-1'>{`${t('DRIVER_DOCUMENT_PAGE.DOCUMENT_ADD_PAGE.HAS_NUMBER')} :`}</FormLabel>
                      <Form.Check type="switch" name="doShowNumber" className="switch d-block ms-auto" checked={props.field.doShow} onChange={(event) => props.field.setDoShow(event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, Driver must have to enter Document Number.</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1 text-truncate me-1'>{`${t('DRIVER_DOCUMENT_PAGE.DOCUMENT_ADD_PAGE.HAS_EXPIRY')} :`}</FormLabel>
                      <Form.Check type="switch" name="documentExpiry" className="switch d-block ms-auto" checked={props.field.doseExpiry} onChange={(event) => props.field.setDoseExpiry(event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, Driver must have to enter Document Expiry date.</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1 text-truncate me-1'>{`${t('DRIVER_DOCUMENT_PAGE.DOCUMENT_ADD_PAGE.IS_REQUIRED')} :`}</FormLabel>
                      <Form.Check type="switch" name="isRequired" className="switch d-block ms-auto" checked={props.field.isRequiredState} onChange={(event) => props.field.setIsRequiredState(event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, Driver must have to Upload this Document (Mandatory for Driver).</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1 text-truncate me-1'>{`${t('DRIVER_DOCUMENT_PAGE.DOCUMENT_ADD_PAGE.IS_CAPTURE_ONLY')} :`}</FormLabel>
                      <Form.Check type="switch" name="isCaptureOnly" className="switch d-block ms-auto" checked={props.field.isCaptureOnlyState} onChange={(event) => props.field.setIsCaptureOnlyState(event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, Driver can only take Picture cant upload from Gallery.</p>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col sm={12} md={6} lg={4} xl={3} className='my-2 text-center'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 w-50 text-truncate text-left'>{`${t('INPUT_FIELD_ENUMS.DOCUMENT_SIZE.LABEL_NAME')} :`}</FormLabel>
                      <div className='d-flex justify-content-center ms-1 w-50'>
                        <input type="range" name="documentSize" className="range-style w-100" value={props.field.documentSize} min={1}
                          onChange={(event) => props.field.setDocumentSizeState(+event.target.value)} />
                      </div>
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>By changing this option, Driver cannot upload document Grater than {props.field.documentSize} MB.  </p>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
          </fieldset>
          <FormGroup className="d-flex align-item-center justify-content-start">
            <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.ADMIN_DESIGNATION, rights: props.submitButtonName === 'ADD' ? 'create' : 'update' }]}>
              <Button type="submit" className='m-3 mw-50'>
                {isFetching ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : t(`PROJECT_BUTTON.${props.submitButtonName}`)}
              </Button>
            </IsAuthorized>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}

export default VehicleDocumentCreateViewEdit
