import _ from 'lodash'
import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState, useCallback, useLayoutEffect } from 'react'
import { toast } from 'react-toastify'
import { PermissionsObjectModel } from '../Pages/Models/CommonModel'
import { getAdminDetails, getPermission } from '../Services/CommonService'
import { LANGUAGES } from '../utils/enums/enums'
import { fetchResponseMessage } from '../utils/utils'

export type SelectedLanguage = {
  LANGUAGE_ID: number
  LANGUAGE_NAME: string
  LANGUAGE_SELECTOR: string
}
export type BreadCrumb = {
  name: string
  url: string
  state?: string
}
export type ActionValue = {
  show: boolean
  message: string
  type: string
  header: string
}

type UserContextModel = {
  selectedLanguage: SelectedLanguage
  setSelectedLanguage: Dispatch<SetStateAction<SelectedLanguage>>
  arrayOfBreadCrumb: BreadCrumb[]
  setArrayOfBreadCrumb: Dispatch<SetStateAction<BreadCrumb[]>>
  authToken: string
  setAuthToken: Dispatch<SetStateAction<string>>
  isAppLoading: boolean
  setIsAppLoading: Dispatch<SetStateAction<boolean>>
  isFetching: boolean
  setIsFetching: Dispatch<SetStateAction<boolean>>
  showMediaModel: boolean
  setShowMediaModel: Dispatch<SetStateAction<boolean>>
  showMediaModelDetails: { setInputState: any, inputRef: any }
  setShowMediaModelDetails: Dispatch<SetStateAction<{ setInputState: any, inputRef: any }>>
  showSidebar: boolean
  setShowSidebar: Dispatch<SetStateAction<boolean>>
  darkMode: boolean
  setDarkMode: Dispatch<SetStateAction<boolean>>
  backdropLoader: boolean
  setBackdropLoader: Dispatch<SetStateAction<boolean>>
  userPermissions: PermissionsObjectModel[]
  setUserPermissions: Dispatch<SetStateAction<PermissionsObjectModel[]>>
  adminName: string
  setAdminName: Dispatch<SetStateAction<string>>
}

const UserContext = createContext({} as UserContextModel)

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [isAppLoading, setIsAppLoading] = useState(true)
  const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES[0])
  const [arrayOfBreadCrumb, setArrayOfBreadCrumb] = useState<BreadCrumb[]>([])
  const [authToken, setAuthToken] = useState('')
  const [showMediaModel, setShowMediaModel] = useState(false)
  const [showMediaModelDetails, setShowMediaModelDetails] = useState({ setInputState: null, inputRef: null } as any)
  const [isFetching, setIsFetching] = useState(false)
  const [showSidebar, setShowSidebar] = useState(true)
  const [darkMode, setDarkMode] = useState(false)
  const [backdropLoader, setBackdropLoader] = useState(false)
  const [userPermissions, setUserPermissions] = useState([] as PermissionsObjectModel[])
  const [adminName, setAdminName] = useState('')

  // Get Auth Token
  const getAuthToken = useCallback(async () => {
    const sessionStorageToken = window.sessionStorage.getItem('token')
    const localStorageToken = window.localStorage.getItem('token')
    // Get if session storage token exists
    if (sessionStorageToken) {
      setAuthToken(sessionStorageToken)
    } else if (localStorageToken) {
      window.sessionStorage.setItem('token', localStorageToken)
      setAuthToken(localStorageToken)
    }
    // Check if default browser theme is dark
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setDarkMode(true)
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      setDarkMode(event.matches)
    })
  }, [])

  useEffect(() => {
    getAuthToken()
    return () => { }
  }, [getAuthToken])

  useLayoutEffect(() => {
    if (localStorage.getItem('token')) {
      (async () => {
        try {
          setBackdropLoader(true)
          const response = await getPermission()
          if (response.data) {
            console.log('🔥 ~ response.data', response.data)
            setUserPermissions(response.data)
            setBackdropLoader(false)
            const adminDetails = await getAdminDetails()
            console.log('🔥 ~ adminDetails', adminDetails)
            setAdminName(adminDetails.data.firstName)
          }
        } catch (error) {
          console.error('🔥 ~ error', error)
          if (error && _.has(error, 'response.data.responseCode')) {
            // @ts-ignore
            const responseMessage = fetchResponseMessage(error.response.data.responseCode)
            toast.error(responseMessage)
          }
          setBackdropLoader(false)
        }
      })()
    }
  }, [authToken])

  return (
    <UserContext.Provider
      value={{
        setSelectedLanguage,
        selectedLanguage,
        arrayOfBreadCrumb,
        setArrayOfBreadCrumb,
        authToken,
        setAuthToken,
        isAppLoading,
        setIsAppLoading,
        isFetching,
        setIsFetching,
        showSidebar,
        setShowSidebar,
        darkMode,
        setDarkMode,
        backdropLoader,
        setBackdropLoader,
        showMediaModel,
        setShowMediaModel,
        showMediaModelDetails,
        setShowMediaModelDetails,
        userPermissions,
        setUserPermissions,
        adminName,
        setAdminName
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
