import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import BreadCrumb from '../Components/Common/BreadCrumb'
import ToastMsg from '../Components/Common/ToastMsg'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import MediaModel from '../Components/Media/MediaModel'
import Sidebar from '../Components/Sidebar/Sidebar'
import UserContext from '../Context/UserContext'
import { PROJECT_VARIABLES, SCREEN_SIZE } from '../utils/enums/enums'

type Props = {
  children?: React.ReactNode
}

const AppLayout = ({ children }: Props) => {
  const { arrayOfBreadCrumb, showSidebar, setShowSidebar, darkMode, setDarkMode, backdropLoader } = useContext(UserContext)
  const { pathname } = useLocation()
  useEffect(() => {
    window.innerWidth > SCREEN_SIZE.SCREEN_SIZE_991 ? setShowSidebar(true) : setShowSidebar(false)
    // setShowToast(false)
    const theme = localStorage.getItem('theme')
    theme === 'true' ? setDarkMode(true) : setDarkMode(false)
    return () => { }
  }, [])

  useEffect(() => {
    let pageName = ''
    if (arrayOfBreadCrumb && arrayOfBreadCrumb.length > 0) {
      pageName = arrayOfBreadCrumb[arrayOfBreadCrumb.length - 1].name
      document.title = `${PROJECT_VARIABLES.PAGE_TITLE_PREFIX} ${pageName} ${PROJECT_VARIABLES.PAGE_TITLE_SUFFIX}`
    } else {
      document.title = PROJECT_VARIABLES.DEFAULT_PAGE_TITLE
    }
  }, [arrayOfBreadCrumb])

  const toggleSidebar = () => {
    setShowSidebar((showSidebar) => !showSidebar)
  }

  return (
    <>
      <div className={`backdrop-bg ${backdropLoader}`}>
        <div className="backdrop-spinner">
          <div className="spinner-border d-block mx-auto"></div>
        </div>
      </div>
      <div className={`container-scroller ${showSidebar ? 'sidebar-fixed' : 'sidebar-fixed sidebar-icon-only'} ${darkMode && 'dark-theme'}`}>
        <Header toggleSidebar={toggleSidebar} />
        <Sidebar showSidebar={showSidebar} />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <ToastMsg />
            <div className={`my-2 mt-4 ps-5 ${pathname === '/' && 'd-none'}`}>
              <h3>
                {`${arrayOfBreadCrumb.at(-1)?.name}`}
              </h3>
            </div>
            <div className="container-fluid ps-5 breadcrumb-container" >
              <BreadCrumb arrayOfPath={arrayOfBreadCrumb} />
            </div>
            <div className="content-wrapper">
              {children}
            </div>
          </div>
        </div>
        <MediaModel />
        <Footer />
      </div>
    </>
  )
}

export default AppLayout
