/* eslint-disable indent */
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
// import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import InnerLoader from '../../../Components/Common/InnerLoader'
import IsAuthorized from '../../../Components/IsAuthorized/IsAuthorized'
// import TableActionDropdown from '../../../Components/Table/TableActionDropdown'
import UserContext from '../../../Context/UserContext'
import { getVehicleDocument } from '../../../Services/VehicleService'
import { /* DROPDOWN_OPTIONS_ID, */ MODULE_ID, PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { fetchResponseMessage/* , getDropdownTitle */ } from '../../../utils/utils'
import { /* ReactDataTable, */ VehicleDocumentModel } from '../../Models/CommonModel'

type Props = {}

const VehicleDocument = (props: Props) => {
  const location = useLocation()
  const { setArrayOfBreadCrumb, setIsFetching, isFetching/* , userPermissions */ } = useContext(UserContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [arrayOfDocument, setArrayOfDocument] = useState([] as VehicleDocumentModel[])
  const [vehicleIdState, setVehicleIdState] = useState('')

  useEffect(() => {
    (async () => {
      setIsFetching(true)
      try {
        const vehicleId = location.state
        // @ts-ignore
        const response = await getVehicleDocument(vehicleId)
        // @ts-ignore
        setVehicleIdState(vehicleId)
        if (response.data) {
          setIsFetching(false)
          setArrayOfDocument(response.data)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🔥 ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_DOCUMENT')}`, url: '' }
    ])
    return () => { }
  }, [])

  // const gotoVehicleDocumentView = (documentId: string) => {
  //   console.log('🔥 ~ gotoVehicleDocumentView ~ documentId', documentId)
  //   navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE}/view/${vehicleIdState}&&${documentId}`)
  // }

  const gotoVehicleDocumentEdit = (documentId: string) => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE}/edit/${vehicleIdState}&&${documentId}`)
  }

  // const dropdownTitleArray = _.compact(['view', 'edit'].map((title: string) => (
  //   getDropdownTitle(title, MODULE_ID.VEHICLE_DOCUMENT, userPermissions)
  // )))

  // const columns: ReactDataTable[] = [
  //   {
  //     name: `${t('VEHICLE_DOCUMENTS_PAGE.TABLE.NAME')}`,
  //     selector: (row: { title: string }) => row.title,
  //     sortable: true
  //   },
  //   {
  //     name: `${t('VEHICLE_DOCUMENTS_PAGE.TABLE.ACTION')}`,
  //     cell: (tableRow: any) => (
  //       <TableActionDropdown
  //         dropdownTitle={dropdownTitleArray}
  //         dropdownItemId={`${vehicleIdState}&&${tableRow.id}`}
  //         dropdownNameOrBadge={`${t('ACTION_DROPDOWN.TITLE')}`}
  //         dropdownItemOnClickHandler={ActionButtonClickHandler}
  //       />
  //     )
  //   }
  // ]

  /**
 * @function ActionButtonClickHandler
 * @return void
 * @param action
 * @param itemId
 * @description Handle Edit,Delete and View options in Action Button and redirect to function according to function call.
 */
  // const ActionButtonClickHandler = (action: number, documentId: string) => {
  //   switch (action) {
  //     case DROPDOWN_OPTIONS_ID.VIEW:
  //       gotoVehicleDocumentView(documentId)
  //       break
  //     case DROPDOWN_OPTIONS_ID.EDIT:
  //       gotoVehicleDocumentEdit(documentId)
  //       break
  //     default:
  //       break
  //   }
  // }

  const goToAddVehicleDocument = () => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE}/add`, { state: vehicleIdState })
  }
  // TODO: from translation
  return (
    <Row>
      <Col>
        <div>
          {isFetching ? (
            <InnerLoader />
          ) : arrayOfDocument.length === 0 ? (
            <div className="text-center font-weight-bold">{`${t('VEHICLE_DOCUMENTS_PAGE.NO_DOCUMENTS')}`}</div>
          ) : (
            <Row>

              {arrayOfDocument.map((document, documentIndex) => {
                return (
                  <Col key={`${documentIndex}-vehicle-document`} sm={12} md={6} lg={4} xl={3} className='my-2'>
                    <Card className={'form-card card-with-detail'}>
                      <Card.Title className='px-4 pt-3 d-flex justify-content-between'>
                        {document.title}
                        <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.VEHICLE_DOCUMENT, rights: 'update' }]}>
                          <span className='mdi mdi-pencil text-primary cursor-p' onClick={() => gotoVehicleDocumentEdit(document.id)}></span>
                        </IsAuthorized>
                      </Card.Title>
                      <hr className='my-2'></hr>
                      <Card.Body className={'py-0 px-4'}>
                        <Row className='align-items-center'>
                          <Col xs={12} className='my-2'>
                            <p className='mb-2 card-details title-name text-line-2'>{document.description}</p>
                          </Col>
                          <Col xs={12} className='d-flex justify-content-between my-1'>
                            <p className='mb-2 font-weight-bold card-details title-name'>Format:</p>
                            <p className='mb-2 font-weight-bold card-details title-value'>{document.contentType}</p>
                          </Col>
                          <Col xs={12} className='d-flex justify-content-between my-1'>
                            <p className='mb-2 font-weight-bold card-details title-name'>Max Size:</p>
                            <p className='mb-2 font-weight-bold card-details title-value'>{document.maxSize / 1000000} MB</p>
                          </Col>
                          <Col xs={12} className='d-flex justify-content-between my-1'>
                            <p className='mb-2 font-weight-bold card-details title-name'>Doc Number:</p>
                            <p className='mb-2 font-weight-bold card-details title-value'>{document.doShowDocumentNumber ? 'Yes' : 'No'}</p>
                          </Col>
                          <Col xs={12} className='d-flex justify-content-between my-1'>
                            <p className='mb-2 font-weight-bold card-details title-name'>Show Expiry:</p>
                            <p className='mb-2 font-weight-bold card-details title-value'>{document.doShowDocumentExpiryDate ? 'Yes' : 'No'}</p>
                          </Col>
                          <Col xs={12} className='d-flex justify-content-between my-1'>
                            <p className='mb-2 font-weight-bold card-details title-name'>Required:</p>
                            <p className='mb-2 font-weight-bold card-details title-value'>{document.isRequired ? 'Yes' : 'No'}</p>
                          </Col>
                          <Col xs={12} className='d-flex justify-content-between my-1'>
                            <p className='mb-2 font-weight-bold card-details title-name'>Captured Only:</p>
                            <p className='mb-2 font-weight-bold card-details title-value'>{document.isCaptureOnly ? 'Yes' : 'No'}</p>
                          </Col>
                        </Row>

                      </Card.Body>
                    </Card>
                  </Col>)
              })}
            </Row>
          )}
        </div>
        <div className='d-block me-auto mt-4'>
          <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.VEHICLE_DOCUMENT, rights: 'create' }]}>
            <Button variant={'light'} className={'add-button my-2'} onClick={goToAddVehicleDocument}>
              {`${t('VEHICLE_DOCUMENTS_PAGE.ADD_DOCUMENTS')}`}
            </Button>
          </IsAuthorized>
        </div>
      </Col>
    </Row>
  )
}

export default VehicleDocument
