import * as yup from 'yup'
// TODO: from translation
export const AddShiftSchema = yup.object().shape({
  title: yup
    .string()
    .required('title is required')
    .max(20, 'max length is 20'),
  startTime: yup.string().required('start time is required'),
  endTime: yup
    .string()
    .required('End time is required'),
  isActive: yup.boolean()
})
