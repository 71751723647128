import React, { useContext, useState } from 'react'
import { Button, FormGroup, FormLabel, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ReactSelect, { SingleValue } from 'react-select'
import UserContext from '../../Context/UserContext'

type Props = {
  show: boolean
  onHide: () => void
  modalTitle: string
  assignSelectedDesignation: (designationId: string, selectedChoice: string) => void
  selectedAdminId: string
  options?: { value: string, label: string, id: string }[]
}

const AssignDesignationModal = (props: Props) => {
  const [selectedChoice, setSelectedChoice] = useState<SingleValue<{ value: string, label: string, id: string }>>()
  const { t } = useTranslation()
  const { darkMode } = useContext(UserContext)
  const handledModalHide = () => {
    setSelectedChoice(null)
    props.onHide()
  }
  return (
    <Modal show={props.show} onHide={handledModalHide}
      aria-labelledby="contained-modal-title-vcenter" className={darkMode ? 'dark-theme' : ''}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.options?.length ? (
          <FormGroup>
            <FormLabel>{`${t('ADMIN_LIST_PAGE.MODAL.SELECT_LABEL')}`}</FormLabel>
            <ReactSelect
              name={'designation'}
              placeholder={`${t('ADMIN_LIST_PAGE.MODAL.SELECT_PLACEHOLDER')}`}
              options={props.options}
              value={selectedChoice}
              className="form-control form-control-lg p-0 mb-1 react-select-menu"
              classNamePrefix={'react-select'}
              captureMenuScroll={true}
              onChange={(value) => setSelectedChoice((selectedChoice) => value)}
            />
          </FormGroup>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button className='d-block me-auto' onClick={() => props.assignSelectedDesignation(props.selectedAdminId, selectedChoice!.id)}>
          {`${t('PROJECT_BUTTON.ASSIGN')}`}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AssignDesignationModal
