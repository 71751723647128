import React, { useContext } from 'react'
import UserContext from '../../Context/UserContext'
import i18n from '../../i18n'
import { DropdownMenuItemObject } from '../../Pages/Models/CommonModel'
import { LANGUAGES } from '../../utils/enums/enums'
import DropdownMenu from '../DropdownMenu/DropdownMenu'

type Props = {
  className: string
}

const LanguageOptionButton = ({ className }: Props) => {
  const { selectedLanguage, setSelectedLanguage } = useContext(UserContext)

  const dropdownDetails: DropdownMenuItemObject = [
    {
      dropdownTitle: LANGUAGES[0].LANGUAGE_NAME,
      dropdownIcon: '',
      dropdownContent: '',
      isDropdownHeader: false
    },
    {
      dropdownTitle: LANGUAGES[1].LANGUAGE_NAME,
      dropdownIcon: '',
      dropdownContent: '',
      isDropdownHeader: false
    }
  ]

  /**
   *@function changeLanguage
   *@return void
   * @param event
   * @description change the language on select and save to localStorage to save as global for all pages.
   */
  const changeLanguage = (event: React.MouseEvent<HTMLElement>) => {
    const clickedElement = event.target as HTMLElement
    const userSelectedLanguage = LANGUAGES.find((languageObject) => languageObject.LANGUAGE_NAME === clickedElement.innerText)
    if (userSelectedLanguage) {
      setSelectedLanguage(userSelectedLanguage)
      localStorage.setItem('language', JSON.stringify(userSelectedLanguage))
      i18n.changeLanguage(selectedLanguage.LANGUAGE_SELECTOR)
    }
  }

  return <DropdownMenu dropdownDetails={dropdownDetails} dropdownItemOnClickHandler={changeLanguage} dropdownButtonIcon={'mdi-translate'} cssClassForStyle={className} />
}

export default LanguageOptionButton
