import _ from 'lodash'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { Card, Col, Row, Tab } from 'react-bootstrap'
import UserContext from '../../Context/UserContext'
import { MediaImageModel } from '../../Pages/Models/CommonModel'
import InnerLoader from '../Common/InnerLoader'

type Props = {
  eventKey: string
  mediaList: Array<Object>
  setPickedImage: Dispatch<SetStateAction<MediaImageModel>>
  pickedImage: MediaImageModel
  totalDataLength: number
  category: number
  nextPage: number | null
}

const Category = (props: Props) => {
  const { isFetching } = useContext(UserContext)
  return (
    <Tab.Pane eventKey={props.eventKey}>
      <Row>
        {isFetching ? <InnerLoader /> :
          <div className="row">
            <div className="col-md-12">
              <div className="row media-category-tab">
                {props.mediaList.map((media: any, index: number) => {
                  if (media.category === props.category) {
                    return (
                      <Col sm={12} md={6} lg={4} xl={3} key={`imageIndex-${index}`} className={'my-2'}>
                        <a onClick={() => props.setPickedImage(media)} className="d-block mb-4 h-100">
                          <Card className='h-100 align-items-center justify-content-center'>
                            <Card.Img className={`img-fluid img-thumbnail border-none ${!_.isEmpty(props.pickedImage) && props.pickedImage.id === media.id ? 'media-selected' : ''}`} src={media.url} alt="" />
                          </Card>
                        </a>
                      </Col>
                    )
                  } else {
                    return null
                  }
                })}
              </div>
            </div>
          </div>
        }
      </Row>
    </Tab.Pane>
  )
}

export default Category
