import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import UserContext from '../../../../Context/UserContext'
import { DropdownMenuItemObject } from '../../../../Pages/Models/CommonModel'
import { logoutHandler } from '../../../../Services/CommonService'
import { PROJECT_ROUTES_ENUMS } from '../../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../../utils/utils'
import DropdownMenu from '../../../DropdownMenu/DropdownMenu'
import _ from 'lodash'
type Props = {
  header?: string
}
const UserIcon = (props: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setAuthToken, setBackdropLoader, setUserPermissions } = useContext(UserContext)
  const dropdownDetails: DropdownMenuItemObject = [
    {
      dropdownTitle: props.header || `${t('NAVBAR.USER_MENU.MENU_TITLE')}`,
      dropdownIcon: '',
      dropdownContent: '',
      isDropdownHeader: true
    },
    {
      dropdownTitle: `${t('NAVBAR.USER_MENU.LOGOUT.MENU_TITLE')}`,
      dropdownIcon: 'mdi-account',
      dropdownContent: '',
      isDropdownHeader: false
    }
  ]

  /**
   * @function logoutHandler
   * @return void
   * @description make user log out and redirect to login page
   */
  const handleLogout = async () => {
    try {
      setBackdropLoader(true)
      const response = await logoutHandler()
      if (response.statusCode === 200) {
        const responseMessage = fetchResponseMessage(response.responseCode)
        localStorage.removeItem('token')
        sessionStorage.removeItem('token')
        setAuthToken('')
        setBackdropLoader(false)
        setUserPermissions([])
        setTimeout(() => {
          toast.success(responseMessage)
        }, 500)
      } else {
        throw response
      }
    } catch (error: any) {
      setBackdropLoader(false)
      console.error('🚀 ~ file: UserIcon.tsx ~ line 43 ~ handleLogout ~ response', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
      navigate(PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.LOGIN_PAGE)
    }
  }

  return <DropdownMenu dropdownDetails={dropdownDetails} dropdownButtonIcon={'mdi-account'} dropdownItemOnClickHandler={handleLogout} />
}

export default UserIcon
