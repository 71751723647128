/* eslint-disable indent */
// TODO : this page is pending for review

import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Form, FormControl, FormGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import UserContext from '../../../Context/UserContext'
import { ACCOUNT_STATUS, PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { AdminListModel, ReactDataTable, UserListModel } from '../../Models/CommonModel'
import { getAdminList, getDesignation, putAssignAdminDesignation, putChangeAdminStatus } from '../../../Services/DesignationService'
import { fetchResponseMessage, getStatus } from '../../../utils/utils'
import _ from 'lodash'
import AssignDesignationModal from '../../../Components/Common/AssignDesignationModal'

type Props = {}

const AdminList = (props: Props) => {
  const { t } = useTranslation()
  const [searchBar, setSearchBar] = useState('')
  const navigate = useNavigate()
  const [tableBodyState, setTableBodyState] = useState([] as AdminListModel[])
  const { setArrayOfBreadCrumb, isFetching, setIsFetching } = useContext(UserContext)
  const [tableResponse, setTableResponse] = useState([] as AdminListModel[])

  const [selectedAdminId, setSelectedAdminId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [designationList, setDesignationList] = useState([] as { value: string, label: string, id: string }[])
  const [modalTitle, setModalTitle] = useState('')

  // fetch content of table while you to to Designation page.
  const getTableData = async () => {
    setIsFetching(true)
    try {
      const response = await getAdminList()
      if (response.data) {
        const responseWithOrderNumber = response.data.map((data: AdminListModel, index: number) => (
          {
            ...data,
            status: (
              <div className={`badge badge-pill me-1 ${data.status === ACCOUNT_STATUS.ACTIVE.STATUS ? ACCOUNT_STATUS.ACTIVE.CLASS : data.status === ACCOUNT_STATUS.INACTIVE.STATUS ? ACCOUNT_STATUS.INACTIVE.CLASS : data.status === ACCOUNT_STATUS.BLOCKED.STATUS ? ACCOUNT_STATUS.BLOCKED.CLASS : ACCOUNT_STATUS.PENDING_FOR_ADMIN_DESIGNATION_APPROVAL.CLASS}`}>{getStatus(data.status)}</div>
            ),
            activeStatus: data.status,
            name: `${data.firstName} ${data.lastName}`
          }
        ))
        setTableBodyState(responseWithOrderNumber)
        setTableResponse(responseWithOrderNumber)
        setIsFetching(false)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🚀 ~ file: Designation.tsx ~ line 73 ~ useEffect ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
      // toast.error(`${t('TOAST_ERROR.DESIGNATION.FILED_TO_LOAD')}`)
    }
  }
  useEffect(() => {
    getTableData()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.ADMIN_LIST')}`, url: '' }
    ])
    return () => { }
  }, [])
  /**
   *@function searchBarHandler
   *@return void
   *@param event
   * @description To handle search on Designation page.
   */
  const searchBarHandler = async (value: string) => {
    setSearchBar(value)
    const searchResult = tableResponse.filter((tableRow: AdminListModel) => tableRow.firstName.toLowerCase().includes(value.toLowerCase()))
    setTableBodyState(searchResult)
  }

  /**
 * @function goToAddDesignationPage
 * @return void
 * @description Go to add-designation page
 */

  const gotoAdminDetails = (id: string) => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.ADMIN_LIST_PAGE}/view/${id} `)
  }

  const blockAdmin = (id: string) => {
    changeAdminStatus(id, ACCOUNT_STATUS.BLOCKED.STATUS)
  }

  const activeAdmin = (id: string) => {
    changeAdminStatus(id, ACCOUNT_STATUS.ACTIVE.STATUS)
  }

  const changeAdminStatus = async (userId: string, status: number) => {
    setIsFetching(true)
    try {
      const response = await putChangeAdminStatus(userId, status)
      if (response.statusCode === 200) {
        setIsFetching(false)
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
        getTableData()
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ blockDriver ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }
  const assignDesignation = async (id: string) => {
    console.log('🔥 ~ assignDesignation ~ id', id)
    try {
      const response = await getDesignation()
      if (response.data) {
        const updatedResponse = response.data.map((data: { designation: string }) => ({ ...data, value: data.designation, label: data.designation }))
        setDesignationList(updatedResponse)
        setSelectedAdminId(id)
        setModalTitle(`${t('ADMIN_LIST_PAGE.MODAL.TITLE_ASSIGN_DESIGNATION')}`)
        setIsFetching(false)
        setShowModal(true)
      } else {
        throw response
      }
    } catch (error) {
      console.error('🔥 ~ assignDesignation ~ error', error)
      setIsFetching(false)
    }
  }

  const assignSelectedDesignation = async (adminId: string, designationId: string) => {
    setIsFetching(true)
    try {
      const response = await putAssignAdminDesignation(adminId, designationId)
      if (response.statusCode === 200) {
        setIsFetching(false)
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
        getTableData()
        setShowModal(false)
      } else {
        throw response
      }
    } catch (error) {
      console.error('🔥 ~ assignSelectedDesignation ~ error', error)
      setIsFetching(false)
      setShowModal(false)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  const columns: ReactDataTable[] = [
    {
      name: `${t('ADMIN_LIST_PAGE.ADMIN_TABLE.NAME')}`,
      selector: (row: { name: string }) => row.name,
      sortable: true
    },
    {
      name: `${t('ADMIN_LIST_PAGE.ADMIN_TABLE.EMAIL')}`,
      selector: (row: { email: string }) => row.email,
      sortable: true
    },
    {
      name: `${t('ADMIN_LIST_PAGE.ADMIN_TABLE.DESIGNATION_NAME')}`,
      selector: (row: { designationName: string }) => row.designationName,
      sortable: true
    },
    {
      name: `${t('USER_LIST_PAGE.USER_TABLE.STATUS')}`,
      cell: (tableRow: UserListModel) => (
        <>
          <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
            <Form.Check type="switch" name="bookingForOther" className="switch" checked={tableRow.activeStatus !== -1} onChange={(event) => handleInputStatusChange(event.target.checked, tableRow.id)} />
          </FormGroup>
        </>
      )
    },
    {
      name: 'Actions',
      cell: (tableRow: UserListModel) => (
        <>
          {/* <span onClick={() => editAdmin(tableRow.id)} className='mdi mdi-pencil table-setting-icon mx-1 text-primary'></span> */}
          {/* <span onClick={() => deleteAdmin(tableRow.id)} className='mdi mdi-trash-can-outline table-setting-icon mx-1 text-danger delete'></span> */}
          <span onClick={() => assignDesignation(tableRow.id)} className='mdi mdi-account-key table-setting-icon mx-1 text-primary delete'></span>
        </>
      )
    }
  ]

  const handleInputStatusChange = (eventValue: boolean, userId: string) => {
    if (eventValue) {
      activeAdmin(userId)
    } else {
      blockAdmin(userId)
    }
  }

 /*  const editAdmin = (adminId: string) => {

  }
  const deleteAdmin = (adminId: string) => {

  } */

  return (
    <>
      <AssignDesignationModal
        selectedAdminId={selectedAdminId}
        show={showModal}
        onHide={() => {
          setDesignationList([])
          setShowModal(false)
          setSelectedAdminId('')
        }}
        modalTitle={modalTitle}
        // TODO: make bold designation in modal message below
        assignSelectedDesignation={assignSelectedDesignation}
        options={designationList}
      />
      <div>
        <Row className='mb-4'>
          <Col sm={12} md={6} lg={4} xl={3}>
            <FormControl
              type="text"
              className="form-control ms-auto search-bar"
              placeholder={`${t('DESIGNATION_PAGE.SEARCH_BAR_PLACEHOLDER')}`}
              onChange={(event) => searchBarHandler(event.target.value)}
              value={searchBar}
            />
          </Col>
        </Row>
      </div>
      <Card className="designation-table">
        <Card.Body>
          <Row>
            <Col>
              {/* TODO: make this Add button visible in future and handle this search as clint side searching */}
              <div>
                <Row>
                  <Col>
                    {isFetching ? (
                      <div className="loading d-block mx-auto">
                        <div className="spinner-border"></div>
                      </div>
                    ) : tableBodyState.length === 0 ? (
                      <div className="text-center font-weight-bold">{`${t('ADMIN_LIST_PAGE.ADMIN_TABLE.NO_ADMIN')}`}</div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={tableBodyState}
                        onRowClicked={(row) => gotoAdminDetails(row.id)}
                        className="table-with-pagination"
                        highlightOnHover
                        pagination
                        paginationComponentOptions={{
                          rowsPerPageText: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.TABLE.ROW_PER_PAGE')}`,
                          rangeSeparatorText: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.TABLE.RANGE_SELECTION')}`
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default AdminList
