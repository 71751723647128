import React, { useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import UserContext from '../../Context/UserContext'
import instance from '../../Services/AxiosConfig'
import { PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'

type Props = {}

const PrivateRoute = (props: Props) => {
  const { authToken, setAuthToken } = useContext(UserContext)
  const navigate = useNavigate()
  // 403 handler unauthorized
  instance.interceptors.response.use(async (response) => {
    return response
  }, (error) => {
    if (error.response.status === 403) {
      console.log('🔥 ~ instance.interceptors.response.use ~ error.response.status***********************************', error.response.status)
      return navigate(PROJECT_ROUTES_ENUMS.OTHER_ROUTES.UNAUTHORIZED, { replace: true })
    } else if (error.response.status === 404) {
      return navigate(PROJECT_ROUTES_ENUMS.OTHER_ROUTES.PAGE_NOT_FOUND, { replace: true })
    } else if (error.response.status === 401) {
      localStorage.removeItem('token')
      sessionStorage.removeItem('token')
      setAuthToken('')
      return navigate(`${PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.LOGIN_PAGE}?hasErrors=true`)
    }
  })

  if (!authToken) {
    navigate(PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.LOGIN_PAGE)
  }

  return <Outlet />
}

export default PrivateRoute
