import React, { useContext, useEffect, useState } from 'react'
import { Button, FormControl, FormGroup, FormLabel, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import UserContext from '../../../Context/UserContext'

type Props = {
  show: boolean
  onHide: () => void
  modalTitle: string
  editShift: (shiftId: string, startTime: string, endTime: string, shiftStatus: boolean) => void
  selectedShiftId: string
  selectedShiftStartTime: string
  selectedShiftEndTime: string
  shiftStatus: boolean
}

const PickShiftModal = (props: Props) => {
  const { darkMode } = useContext(UserContext)
  const { t } = useTranslation()
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [shiftStatus, setShiftStatus] = useState(false)

  useEffect(() => {
    setStartTime(props.selectedShiftStartTime)
    setEndTime(props.selectedShiftEndTime)
    setShiftStatus(props.shiftStatus)
  }, [props.selectedShiftStartTime, props.selectedShiftEndTime, props.shiftStatus])
  return (
    <Modal show={props.show} onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter" className={darkMode ? 'dark-theme' : ''}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <FormGroup className='mb-3'>
            <FormLabel>
              {/* TODO: from translation */}
              Start Time
            </FormLabel>
            <FormControl type={'time'} value={startTime} onChange={(event) => setStartTime(event.target.value)} step='2'></FormControl>
          </FormGroup>
          <FormGroup className='mb-3'>
            <FormLabel>
              End Time
            </FormLabel>
            <FormControl type={'time'} value={endTime} onChange={(event) => setEndTime(event.target.value)} step='2'></FormControl>
          </FormGroup>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='d-block me-auto' onClick={() => props.editShift(props.selectedShiftId, startTime, endTime, shiftStatus)}>
          {`${t('PROJECT_BUTTON.UPDATE')}`}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PickShiftModal
