import i18n from '../../i18n'
import * as yup from 'yup'
import { AUTH_ENUMS } from '../../utils/enums/enums'
export const registrationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.FIRST_NAME_REQUIRED')}`)
    .max(AUTH_ENUMS.MAX_VALUE_NAME, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.FIRST_NAME_MAX_LENGTH')}`),
  middleName: yup.string().max(AUTH_ENUMS.MAX_VALUE_NAME, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.MIDDLE_NAME_REQUIRED')}`),
  lastName: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.LAST_NAME_REQUIRED')}`)
    .max(AUTH_ENUMS.MAX_VALUE_NAME, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.LAST_NAME_MAX_LENGTH')}`),
  country: yup.object().required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.SELECT_COUNTRY')}`),
  state: yup.object().required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.SELECT_STATE')}`),
  city: yup.object().required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.SELECT_CITY')}`),
  address: yup.string().max(AUTH_ENUMS.MAX_VALUE_ADDRESS, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.ADDRESS_MAX_LENGTH')}`),
  email: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.EMAIL_REQUIRED')}`)
    .email(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.EMAIL_INVALID')}`)
    .lowercase(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.EMAIL_LOWER_CASE')}`)
    .max(AUTH_ENUMS.MAX_VALUE_Email, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.EMAIL_MAX_LENGTH')}`),
  countryCode: yup.object().required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.SELECT_COUNTRY_CODE')}`),
  phone: yup
    .number()
    .typeError(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.MOBILE_NO_TYPE')}`)
    .required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.MOBIL_NO_REQUIRED')}`)
    .min(AUTH_ENUMS.MIN_VALUE_PHONE_NUMBER, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.MOBILE_NO_MIN_LENGTH')}`)
    .max(AUTH_ENUMS.MAX_VALUE_PHONE_NUMBER, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.MOBILE_NO_MAX_LENGTH')}`),
  dob: yup.date().test('date', `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.REGISTER_FORM.DATE_OF_BIRTH_INVALID')}`, (inputValue: Date | undefined) => {
    if (inputValue && inputValue.getFullYear() < new Date().getFullYear() && inputValue.getFullYear() > new Date().getFullYear() - 100) {
      return true
    } else if (!inputValue) {
      return true
    } else {
      return false
    }
  }),
  password: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_REQUIRED')}`)
    .min(AUTH_ENUMS.MIN_VALUE_PASSWORD, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_MIN_LENGTH')}`)
    .max(AUTH_ENUMS.MAX_VALUE_PASSWORD, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_MAX_LENGTH')}`),
  confirmPassword: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.CONFIRM_PASSWORD_REQUIRED')}`)
    .oneOf([yup.ref('password'), null], `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_SHOULD_MATCHED')}`)
    .min(AUTH_ENUMS.MIN_VALUE_PASSWORD, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_MIN_LENGTH')}`)
    .max(AUTH_ENUMS.MAX_VALUE_PASSWORD, `${i18n.t('FORMIK_ERROR.AUTH_VALIDATION.PASSWORD_MAX_LENGTH')}`)
})
