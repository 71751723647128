import React from 'react'
// import { ReactComponent as Logo } from '../../../assets/logo/logo.svg'

type Props = {
  greetingLine?: string
  greetingTitle?: string
}

const AuthHeader = (props: Props) => {
  return (
    <>
      <div className="brand-logo">
        {/* <Logo /> */}
        <h1>Colleague Cab</h1>
      </div>
      <h4>{props.greetingTitle}</h4>
      <h6 className="font-weight-light">{props.greetingLine}</h6>
    </>
  )
}

export default AuthHeader
