import i18n from '../../../i18n'
import * as yup from 'yup'
export const AddUserSchema = yup.object().shape({
  name: yup.string().required(`${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.NAME_REQUIRED')}`).max(50, `${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.NAME_MAX_LENGTH')}`),
  dob: yup.string().nullable(),
  email: yup.string().required(`${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.EMAIL_REQUIRED')}`).email(`${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.EMAIL_INVALID')}`).max(250, `${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.EMAIL_MAX_LENGTH')}`),
  mobile: yup.number().required(`${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.MOBILE_NO_REQUIRED')}`).min(100000, `${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.MOBILE_NO_MIN_LENGTH')}`)
    .max(9999999999999, `${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.MOBILE_NO_MAX_LENGTH')}`),
  countryCode: yup.string().required()
})
