import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {}

const Footer = (props: Props) => {
  const { t } = useTranslation()
  return (
    <footer className="footer">
      <div className="w-100 clearfix text-center">
        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">{`${t('FOOTER_LINE')}`}</span>
      </div>
    </footer>
  )
}

export default Footer
