import axios, { serviceFailureHandle, serviceSuccessHandle } from './AxiosConfig'

const gerDriverList = (pageDetail?: object) => {
  return axios.post('/api/driver/driverList/list', pageDetail).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const getDriverDetails = (driverId?: string) => {
  return axios.get(`/api/driver/driverList/details/${driverId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const getDriversDocument = (driverId: string) => {
  return axios.get(`/api/driver/docsVerification/docs/${driverId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const getDriverSingleDocumentDetails = (documentId: string) => {
  return axios.get(`/api/driver/docsVerification/driver-doc/details/${documentId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const getVehicleSingleDocumentDetails = (documentId: string) => {
  return axios.get(`/api/driver/docsVerification/vehicle-doc/details/${documentId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const patchEditDriverDetails = (driverId: string, driverDetails: object) => {
  return axios.patch(`/api/driver/driverList/details/${driverId}`, driverDetails).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const postAddDriver = (driverId: string, driverDetails: object) => {
  return axios.post(`/api/driver/driverList/details/${driverId}`, driverDetails).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
const getDocumentList = () => {
  return axios.get('/api/driver/docsVerification/list').then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const postDocumentCreate = (documentObject: object) => {
  return axios.post('/api/driver/docsVerification/create', documentObject).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const patchEditDocumentType = (documentObject: object, orgDriverDocId: string) => {
  return axios.patch(`/api/driver/docsVerification/update/${orgDriverDocId}`, documentObject).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const getDocumentToEdit = (orgDriverDocId: string) => {
  return axios.get(`/api/driver/docsVerification/details/${orgDriverDocId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const putUpdateDriverDocStatus = (documentId: string, payLoad: object) => {
  return axios.put(`/api/driver/docsVerification/driver-doc/update/${documentId}`, payLoad).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const putUpdateDriverStatus = (driverId: string, updatedStatus: number) => {
  return axios.put(`/api/driver/docsVerification/driver/update/document-status/${driverId}`, { status: updatedStatus }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const putBlockDriver = (driverId: string, status: number) => {
  return axios.put(`/api/driver/driverList/update/status/${driverId}`, { status }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
export {
  gerDriverList,
  getDriverDetails,
  getDriversDocument,
  getDocumentList,
  postDocumentCreate,
  patchEditDocumentType,
  getDocumentToEdit,
  patchEditDriverDetails,
  postAddDriver,
  getDriverSingleDocumentDetails,
  getVehicleSingleDocumentDetails,
  putUpdateDriverDocStatus,
  putUpdateDriverStatus,
  putBlockDriver
}
