import { FormikValues } from 'formik'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import UserCreateViewEdit from '../../../Components/Common/UserCreateViewEdit'
import UserContext from '../../../Context/UserContext'
import { getSingleUser } from '../../../Services/UserService'
import { PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../utils/utils'

type Props = {}

const ViewUser = (props: Props) => {
  const { setIsFetching, setArrayOfBreadCrumb } = useContext(UserContext)
  const params = useParams()
  const { t } = useTranslation()

  const [userName, setUserName] = useState('')
  const [userBirthDate, setUserBirthDate] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userMobile, setUserMobile] = useState('')
  const [userStatus, setUserStatus] = useState(false)
  const [userCreatedAt, setUserCreatedAt] = useState('')
  const [userCountryCode, setUserCountyCode] = useState('')
  const [userCity, setUserCity] = useState('')
  const [noDataFetched, setNoDataFetched] = useState(true)

  useEffect(() => {
    (async () => {
      setIsFetching(true)
      try {
        // @ts-ignore
        const response = await getSingleUser(params.itemId!)
        if (response.data) {
          setUserName(response.data.name)
          setUserBirthDate(response.data.dob)
          setUserEmail(response.data.email)
          setUserMobile(response.data.mobile)
          setUserStatus(!!response.data.status)
          setUserCreatedAt(response.data.createdAt)
          setUserCountyCode(response.data.countryCode)
          setUserCity(response.data.cityName)
          setIsFetching(false)
          setNoDataFetched(false)
        } else {
          throw response
        }
      } catch (error) {
        console.error('🔥 ~ error', error)
        setIsFetching(false)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.USER_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.USER_LIST_PAGE },
      { name: `${t('PAGE_NAME.VIEW_USER')}`, url: '' }
    ])
    return () => { }
  }, [])

  const setFieldValue = (formik: FormikValues) => {
    setUserName(formik.values.name)
    setUserBirthDate(formik.values.dob)
    setUserEmail(formik.values.email)
    setUserMobile(formik.values.mobile)
    setUserCreatedAt(formik.values.createdAt)
    setUserCountyCode(formik.values.countryCode)
  }
  return (
    <UserCreateViewEdit
      field={{
        name: userName,
        dob: userBirthDate,
        email: userEmail,
        mobile: userMobile,
        status: userStatus,
        createdAt: userCreatedAt,
        countryCode: userCountryCode,
        city: userCity,
        setStatus: setUserStatus
      }}
      isDisabled={true}
      cardTitle={`${t('PAGE_NAME.VIEW_USER')}`}
      submitButtonName="EDIT"
      setFieldValue={setFieldValue}
      pageName="view-user"
      paramId={params.itemId!}
      noDataFetched={noDataFetched}
    />
  )
}

export default ViewUser
