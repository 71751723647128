import React, { useContext } from 'react'
import { Button, Modal } from 'react-bootstrap'
import UserContext from '../../Context/UserContext'
import ListMedia from './ListMedia'

const MediaModel = () => {
  const { showMediaModel, setShowMediaModel, setShowMediaModelDetails } = useContext(UserContext)

  const handleHide = () => {
    setShowMediaModel(false)
    setShowMediaModelDetails({ setInputState: null, inputRef: null })
  }
  return (
    <Modal
      show={showMediaModel}
      onHide={handleHide}
      size="lg"
      fullscreen
      scrollable
      centered>
        <Modal.Header closeButton>
          <Modal.Title>Choose Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListMedia/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>
            Close
          </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default MediaModel
