import { Formik, FormikValues } from 'formik'
import _ from 'lodash'
import React, { useContext } from 'react'
import { Button, Card, Col, Form, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
import { AddDesignationSchema } from '../../Pages/AdminMenu/Designation/AddDesignation/AddDesignationSchema'
import { patchEditDesignation, postAddDesignation } from '../../Services/DesignationService'
import { MODULE_ID, PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { fetchResponseMessage } from '../../utils/utils'
import FormInputText from '../Form/FormInputText/FormInputText'
import IsAuthorized from '../IsAuthorized/IsAuthorized'

type Props = {
  field: {
    designation: string
    duty: string
    isDefault: boolean
    setDefaultState: (checkValue: boolean) => void
  }
  isDisabled?: boolean
  cardTitle: string
  paramId?: string
  redirectTo?: string
  submitButtonName: string
  setFieldValue?: (formik: FormikValues) => void
  pageName: string
}

const DesignationEditView = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isFetching, setIsFetching } = useContext(UserContext)
  const location = useLocation()
  const params = useParams()

  // formik field initial values.
  const initialInputInfo = {
    designationInput: props.field.designation,
    duty: props.field.duty,
    isDefault: props.field.isDefault
  }

  /**
   * @function editDesignationSubmit
   * @return void
   * @param value
   *@description To handle submit  after changes are made in  any Designation field
   */
  const editDesignationSubmit = async (values: FormikValues, { resetForm }: FormikValues) => {
    if (props.pageName === 'view-designation') {
      navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE}/edit/${props.paramId}`)
    } else {
      setIsFetching(true)
      const fieldValue = {
        name: values.designationInput,
        duty: values.duty,
        isDefault: props.field.isDefault
      }
      // let toastType
      try {
        let response
        const lastParamsOfPath = location.pathname.split('/')[location.pathname.split('/').length - 1]
        if (lastParamsOfPath === 'add') {
          // toastType = 'ADD_DESIGNATION'
          response = await postAddDesignation(fieldValue)
        } else {
          // toastType = 'EDIT_DESIGNATION'
          response = await patchEditDesignation(fieldValue, params.itemId!)
        }
        if (response.data) {
          const responseMessage = fetchResponseMessage(response.responseCode)
          toast.success(responseMessage)
          // toast.success(`${t(`TOAST_ERROR.${toastType}.EDIT_SUCCESS`)}`)
          setIsFetching(false)
          resetForm()
          props.field.setDefaultState(false)
          navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🚀 ~ file: AddDesignation.tsx ~ line 52 ~ addDesignationSubmitHandler ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
        // toast.error(`${t(`TOAST_ERROR.${toastType}.FIELD_TO_EDIT`)}`)
      }
    }
  }

  // handle toggle switch to set default value
  const setCheckBox = (event: React.ChangeEvent<HTMLInputElement>, formik: FormikValues) => {
    props.field.setDefaultState!(event.target.checked)
    props.setFieldValue!(formik)
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialInputInfo}
      validationSchema={() => AddDesignationSchema}
      onSubmit={(values, formik) => {
        editDesignationSubmit(values, formik)
      }}
    >
      {(formik: FormikValues) => (
        <Form onSubmit={formik.handleSubmit} className='mt-3'>
          <fieldset disabled={props.isDisabled}>
            <Row>
              <Col sm={12} md={6} lg={4} xl={3}>
                <FormInputText type="text" inputField="ADD_DESIGNATION" isRequired={false} inputName="designationInput" />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={4} xl={3}>
                <FormInputText type="text" inputField="ADD_DUTY" isRequired={false} inputName="duty" />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={4} xl={4} >
                <Card className='form-card mt-3'>
                  <Card.Body>
                    <FormGroup className='switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 w-50 text-truncate text-left'>{`${t('DESIGNATION_PAGE.IS_DEFAULT_CHECKBOX')}`}</FormLabel>
                      <Form.Check type="switch" name="isDefault" className="switch" checked={props.field.isDefault} onChange={(event) => setCheckBox(event, formik)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, User can click on the Dashboard Message.</p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </fieldset>
          <div className='d-flex justify-content-start align-items-start mt-4'>
            <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.ADMIN_DESIGNATION, rights: props.submitButtonName === 'ADD' ? 'create' : 'update' }]}>
              <Button type="submit" className='py-1 my-0 add-button'>
                {isFetching ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : t(`PROJECT_BUTTON.${props.submitButtonName}`)}
              </Button>
            </IsAuthorized>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default DesignationEditView
