import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {}

const AuthFooter = (props: Props) => {
  const { t } = useTranslation()
  return (
    <div className="col-lg-6 auth-half-bg d-flex flex-row">
      <p className="text-white font-weight-medium text-center flex-grow align-self-end">{`${t('FOOTER_LINE')}`}</p>
    </div>
  )
}

export default AuthFooter
