import React from 'react'
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export type SingleValueOfPath = {
  name: string
  url: string
  state?: string
}

type Props = {
  arrayOfPath: SingleValueOfPath[]
}

const BreadCrumb = (props: Props) => {
  const navigate = useNavigate()
  const redirectHandler = (url: string, pathState: string) => {
    navigate(url, { state: pathState })
  }
  return (
    <>
      {props.arrayOfPath && props.arrayOfPath.length > 0 && (
        <Breadcrumb className="my-3">
          {props.arrayOfPath.map((path: SingleValueOfPath, pathIndex: number) =>
            // eslint-disable-next-line multiline-ternary
            pathIndex === props.arrayOfPath.length - 1 ? (
              <BreadcrumbItem active key={`breadCrumb ${pathIndex}`}>
                {path.name}
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem onClick={() => redirectHandler(path.url, path.state!)} key={`breadCrumb ${pathIndex}`}>
                {path.name}
              </BreadcrumbItem>
            )
          )}
        </Breadcrumb>
      )}
    </>
  )
}

export default BreadCrumb
