// TODO: Low priority --- After login same page open in different tab and logout from provide page then in first page it should be throw to login page
// TODO: failed toast is coming while go to login page.
import { Formik, FormikHandlers, FormikValues } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import FormInputCheckBox from '../../Components/Form/FormInputCheckBox/FormInputCheckBox'
import FormInputText from '../../Components/Form/FormInputText/FormInputText'
import { loginFormSchema } from './LoginFormSchema'
import FormButton from '../../Components/Form/FormButton/FormButton'
import { INPUT_FIELD_ENUMS, PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { useTranslation } from 'react-i18next'
import { postLoginUser } from '../../Services/AuthService'
import UserContext from '../../Context/UserContext'
import { toast } from 'react-toastify'
import { fetchResponseMessage } from '../../utils/utils'
import _ from 'lodash'

type Props = {}
const Login = (props: Props) => {
  const { t } = useTranslation()
  const [isChecked, setIsChecked] = useState(true)
  const { setAuthToken, isFetching, setIsFetching } = useContext(UserContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const search = location.search
    if (search) {
      toast.error('expired')
    }
  }, [])
  const initialUserInfo = {
    email: '',
    password: ''
  }
  /**
   * @function checkBoxToggle
   * @return null
   * @param event
   * @description To handle check box toggle
   */
  const checkBoxToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }

  /**
   * @function handleLogin
   * @return void
   * @param values
   * @description to handle the user login
   */
  const handleLogin = async (values: FormikValues, { resetForm }: FormikValues) => {
    try {
      setIsFetching(true)
      const response = await postLoginUser({ email: values.email, password: values.password })
      if (response.statusCode === 200 && response.data.token) {
        const responseMessage = fetchResponseMessage(response.responseCode)
        setIsFetching(false)
        resetForm()
        sessionStorage.setItem('token', response.data.token)
        if (isChecked) {
          localStorage.setItem('token', response.data.token)
        }
        setAuthToken(response.data.token)
        navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE)
        setTimeout(() => {
          toast.success(responseMessage)
        }, 500)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🚀 ~ file: LoginForm.tsx ~ line 51 ~ handleLogin ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  return (
    <Formik
      initialValues={initialUserInfo}
      validationSchema={() => loginFormSchema}
      onSubmit={(values, formik) => {
        handleLogin(values, formik)
      }}
    >
      {(formik: FormikHandlers) => (
        <form className="pt-3" onSubmit={formik.handleSubmit}>
          <FormInputText
            inputField="EMAIL_ADDRESS"
            type={INPUT_FIELD_ENUMS.EMAIL_ADDRESS.INPUT_TYPE}
            inputName={INPUT_FIELD_ENUMS.EMAIL_ADDRESS.INPUT_NAME}
            isRequired={INPUT_FIELD_ENUMS.EMAIL_ADDRESS.IS_REQUIRED}
          />
          <FormInputText
            inputField="LOGIN_PASSWORD"
            type={INPUT_FIELD_ENUMS.LOGIN_PASSWORD.INPUT_TYPE}
            inputName={INPUT_FIELD_ENUMS.LOGIN_PASSWORD.INPUT_NAME}
            isRequired={INPUT_FIELD_ENUMS.LOGIN_PASSWORD.IS_REQUIRED}
          />
          <div className="my-2 d-flex justify-content-between align-items-center">
            <div className="form-check">
              <label className="form-check-label text-muted" style={{ cursor: 'pointer', userSelect: 'none' }}>
                <FormInputCheckBox checkBoxToggle={checkBoxToggle} isChecked={isChecked} />
                {`${t('FORM_PAGE_CONSTANTS.CHECKBOX_CONDITION.LOGIN_PAGE')}`}
              </label>
            </div>
            <Link to={PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.REQUEST_RESET_PASSWORD_PAGE} className="auth-link text-black">
              {`${t('FORM_PAGE_CONSTANTS.REDIRECT_LINK.FORGOT_PASSWORD')}`}
            </Link>
          </div>
          <FormButton buttonName={`${t('FORM_PAGE_CONSTANTS.BUTTON_NAME.LOGIN_PAGE')}`} isDisable={false} isFetching={isFetching} />
          {/* <div className="text-center mt-4 font-weight-light">
            {`${t('FORM_PAGE_CONSTANTS.BOTTOM_LINE.LOGIN_PAGE')}`}
            <a href={`${PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.SIGN_UP_PAGE}`} className="text-primary">
              {`${t('FORM_PAGE_CONSTANTS.BUTTON_NAME.REGISTER_PAGE')}`}
            </a>
          </div> */}
        </form>
      )}
    </Formik>
  )
}

export default Login
