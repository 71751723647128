import { FormikValues } from 'formik'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SingleValue } from 'react-select'
import { toast } from 'react-toastify'
import VehicleCreateViewEdit from '../../../Components/Common/VehicleCreateViewEdit'
import UserContext from '../../../Context/UserContext'
import { getVehicleDefaultPrice } from '../../../Services/OrganizationServices'
import { getSingleVehicle } from '../../../Services/VehicleService'
import { PROJECT_ROUTES_ENUMS, VEHICLE_TYPE } from '../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../utils/utils'
import { VehiclePriceModel } from '../../Models/CommonModel'
import { OptionDataModel, SelectDropdownModel } from '../../Register/UserRegistrationModel'

type Props = {}
const EditVehicle = (props: Props) => {
  const { t } = useTranslation()
  const params = useParams()
  const { setArrayOfBreadCrumb, setIsFetching } = useContext(UserContext)
  const [vehicleIsActiveState, setVehicleIsActiveState] = useState(false)
  const [vehicleNameState, setVehicleNameState] = useState('')
  const [vehicleDescriptionState, setVehicleDescriptionState] = useState('')
  const [vehicleTypeState, setVehicleTypeState] = useState<SingleValue<SelectDropdownModel>>()
  const [seatingCapacityState, seSeatingCapacityState] = useState<number>(0)
  const [vehicleImageState, setVehicleImageState] = useState('')
  const [vehicleMapIconState, setVehicleMapIconState] = useState('')
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([] as OptionDataModel)
  const [averageSpeedState, setAverageSpeedState] = useState(500)
  const [vehicleDefaultPrice, setVehicleDefaultPrice] = useState({} as VehiclePriceModel)
  const [vehicleId, setVehicleId] = useState('')

  useEffect(() => {
    (async () => {
      setIsFetching(true)
      try {
        const response = await getSingleVehicle(params.itemId!)
        if (response.data.vehicle) {
          setIsFetching(false)
          setVehicleIsActiveState(response.data.vehicle.isActive)
          setVehicleNameState(response.data.vehicle.name)
          setVehicleDescriptionState(response.data.vehicle.description)
          seSeatingCapacityState(response.data.vehicle.seatCapacity)
          setVehicleImageState(response.data.vehicle.vehicleIcon)
          setVehicleMapIconState(response.data.vehicle.vehicleMapIcon)
          setAverageSpeedState(response.data.vehicle.averageSpeed)
          setVehicleId(response.data.vehicle.id)
          setVehicleTypeOptions(Object.keys(VEHICLE_TYPE).map((vehicleType: any) => ({ value: vehicleType, label: vehicleType })))
          const vehicleType = Object.keys(VEHICLE_TYPE).find((type: string) => VEHICLE_TYPE[type as keyof typeof VEHICLE_TYPE] === response.data.vehicle.vehicleType)
          if (vehicleType) {
            setVehicleTypeState({ value: vehicleType, label: vehicleType })
          }
          setVehicleDefaultPrice(response.data.vehicleDefaultPrice)
          getVehiclePrice()
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🔥 ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST_EDIT')}`, url: '' }
    ])
    return () => { }
  }, [])

  const getVehiclePrice = async () => {
    setIsFetching(true)
    try {
      const response = await getVehicleDefaultPrice(params.itemId!)
      if (response.data) {
        setIsFetching(false)
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ getVehiclePrice ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }
  const setFieldValue = (formik: FormikValues) => {
    setVehicleIsActiveState(formik.values.vehicleIsActive)
    setVehicleNameState(formik.values.vehicleName)
    setVehicleDescriptionState(formik.values.vehicleDescription)
    setVehicleTypeState(formik.values.vehicleType)
    seSeatingCapacityState(formik.values.seatingCapacity)
    setVehicleMapIconState(formik.values.vehicleMapIcon)
  }

  return (
    <VehicleCreateViewEdit
      field={{
        vehicleName: vehicleNameState,
        vehicleDescription: vehicleDescriptionState,
        vehicleType: vehicleTypeState,
        seatingCapacity: seatingCapacityState,
        vehicleIsActive: vehicleIsActiveState,
        vehicleImage: vehicleImageState,
        vehicleMapIcon: vehicleMapIconState,
        option: vehicleTypeOptions,
        averageSpeed: averageSpeedState,
        setVehicleIsActiveState
      }}
      isDisabled={false}
      cardTitle={vehicleNameState || `${t('PAGE_TITLE.EDIT_VEHICLE')}`}
      submitButtonName="UPDATE"
      setFieldValue={setFieldValue}
      pageName="edit-vehicle"
      vehiclePrice={vehicleDefaultPrice}
      vehicleId={vehicleId}
    />
  )
}

export default EditVehicle
