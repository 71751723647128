import i18n from '../../../i18n'
import * as yup from 'yup'
export const AddDriverSchema = yup.object().shape({
  name: yup.string().required(`${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.NAME_REQUIRED')}`).max(50, `${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.NAME_MAX_LENGTH')}`),
  dob: yup.string().required(`${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.DATE_OF_BIRTH_REQUIRED')}`),
  email: yup.string().required(`${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.EMAIL_REQUIRED')}`).email(`${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.EMAIL_INVALID')}`).max(250, `${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.EMAIL_MAX_LENGTH')}`),
  mobile: yup.number().required(`${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.MOBILE_NO_REQUIRED')}`).min(100000, `${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.MOBILE_NO_MIN_LENGTH')}`)
    .max(9999999999999, `${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.MOBILE_NO_MAX_LENGTH')}`),
  address: yup.string().max(250, `${i18n.t('FORMIK_ERROR.GENERAL_FORMIK_ERROR.DESCRIPTION_MAX_LENGTH')}`),
  city: yup.string().required(`${i18n.t('FORMIK_ERROR.DRIVER_ADD.CITY_REQUIRED')}`),
  gender: yup.object().required(`${i18n.t('FORMIK_ERROR.DRIVER_ADD.GENDER_REQUIRED')}`)
})
