import axios, { serviceFailureHandle, serviceSuccessHandle } from './AxiosConfig'

const getVehicleList = () => {
  return axios.get('/api/vehicle/list').then(serviceSuccessHandle)
}

const postCreateVehicle = (vehicleDetails: object) => {
  return axios.post('/api/vehicle/organizationVehicle', vehicleDetails).then(serviceSuccessHandle)
}

const patchEditVehicle = (vehicleDetails: object, vehicleId: string) => {
  return axios.patch(`/api/vehicle/organizationVehicle/${vehicleId}`, vehicleDetails).then(serviceSuccessHandle)
}

const putUpdateVehiclePrice = (vehiclePriceDetails: object, vehiclePriceId: string) => {
  return axios.put(`/api/organization/vehiclePrice/${vehiclePriceId}`, vehiclePriceDetails).then(serviceSuccessHandle)
}

const getSingleVehicle = (vehicleId: string) => {
  return axios.get(`/api/vehicle/details/${vehicleId}`).then(serviceSuccessHandle)
}

/* const deleteVehicle = (vehicleId: string) => {
  return axios.delete(`api/vehicle//details/${vehicleId}`).then(serviceSuccessHandle)
} */

const getVehicleDocument = (vehicleId: string) => {
  return axios.get(`/api/vehicle/verificationDocs/list/${vehicleId}`).then(serviceSuccessHandle)
}
const postCreateVehicleDocument = (vehicleId: string, vehicleDocumentDetails: object) => {
  return axios.post(`/api/vehicle/verificationDocs/create/${vehicleId}`, vehicleDocumentDetails).then(serviceSuccessHandle)
}
const patchEditVehicleDocument = (vehicleId: string, documentId: string, vehicleDocumentDetails: object) => {
  return axios.patch(`/api/vehicle/verificationDocs/update/${vehicleId}/${documentId}`, vehicleDocumentDetails).then(serviceSuccessHandle)
}
const getVehicleDocumentDetails = (vehicleId: string, documentId: string) => {
  return axios.get(`/api/vehicle/verificationDocs/details/${vehicleId}/${documentId}`).then(serviceSuccessHandle)
}

const getVehicleDetails = (vehicleId: string) => {
  return axios.get(`/api/driver/docsVerification/vehicle/details/${vehicleId}`).then(serviceSuccessHandle)
}

const patchEditVehicleDetails = (vehicleId: string, vehicleDetails: object) => {
  return axios.patch(`/api/driver/docsVerification/vehicle/details/${vehicleId}`, vehicleDetails).then(serviceSuccessHandle)
}

const putUpdateVehicleDocStatus = (documentId: string, payLoad: object) => {
  return axios.put(`/api/driver/docsVerification/vehicle-doc/update/${documentId}`, payLoad).then(serviceSuccessHandle)
}

const getVehicleBrandList = () => {
  return axios.get('api/vehicle/brands').then(serviceSuccessHandle)
}

const postUpdateVehicleBrandList = (updatedList: Array<object>) => {
  return axios.post('api/vehicle/add/brands', { data: updatedList }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const putUpdateVehicleStatus = (vehicleId: string, updatedStatus: number) => {
  return axios.put(`api/vehicle/update/vehicleStatus/${vehicleId}`, { status: updatedStatus }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
export {
  getVehicleList,
  postCreateVehicle,
  patchEditVehicle,
  getSingleVehicle,
  // deleteVehicle,
  getVehicleDocument,
  getVehicleDocumentDetails,
  postCreateVehicleDocument,
  patchEditVehicleDocument,
  getVehicleDetails,
  putUpdateVehicleDocStatus,
  patchEditVehicleDetails,
  getVehicleBrandList,
  postUpdateVehicleBrandList,
  putUpdateVehicleStatus,
  putUpdateVehiclePrice

}
