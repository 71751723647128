import { Formik, FormikValues } from 'formik'
import _ from 'lodash'
// import _ from 'lodash'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { /* Button, */ /* Card, */ Col, Form, /* FormGroup, */ /* FormLabel, */ Row /* Spinner */ } from 'react-bootstrap'
/* import { useTranslation } from 'react-i18next' */
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
// import { useParams } from 'react-router-dom'
// import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
import { AddUserSchema } from '../../Pages/UserMenu/UserList/AddUserSchema'
import { patchEditDriverDetails, postAddDriver } from '../../Services/DriverService'
import { INPUT_FIELD_ENUMS, /* MODULE_ID, */ PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { fetchResponseMessage } from '../../utils/utils'
import FormInputText from '../Form/FormInputText/FormInputText'
/* import IsAuthorized from '../IsAuthorized/IsAuthorized' */
type Props = {
  field: {
    name: string,
    dob: string,
    email: string,
    mobile: string,
    status: boolean,
    createdAt: string,
    countryCode: string,
    city: string,
    setStatus: Dispatch<SetStateAction<boolean>>
  }
  isDisabled?: boolean
  cardTitle: string
  paramId?: string
  redirectTo?: string
  submitButtonName: string
  setFieldValue?: (formik: FormikValues) => void
  pageName: string
  noDataFetched?: boolean
}

const UserCreateViewEdit = (props: Props) => {
  const { setIsFetching /*, isFetching */ } = useContext(UserContext)
  const params = useParams()
  /*   const { t } = useTranslation() */
  const navigate = useNavigate()

  const EditUser = async (values: FormikValues, { resetForm }: FormikValues) => {
    if (props.pageName === 'view-user') {
      navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.USER_LIST_PAGE}/edit/${props.paramId}`)
    } else {
      setIsFetching(true)
      const fieldValue = {
        name: values.name,
        gender: values.gender.id,
        dob: values.dob,
        email: values.email,
        mobile: values.mobile,
        status: props.field.status,
        createdAt: values.createdAt,
        address: values.address,
        city: values.city
      }
      try {
        let response
        const lastParamsOfPath = location.pathname.split('/')[location.pathname.split('/').length - 1]
        if (lastParamsOfPath === 'add') {
          response = await postAddDriver(params.itemId!, fieldValue)
        } else {
          response = await patchEditDriverDetails(params.itemId!, fieldValue)
        }
        if (response.data) {
          const responseMessage = fetchResponseMessage(response.responseCode)
          toast.success(responseMessage)
          setIsFetching(false)
          resetForm()
          navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.USER_LIST_PAGE)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🚀 ~ file: AddDesignation.tsx ~ line 52 ~ addDesignationSubmitHandler ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
      }
    }
  }

  const initialUserInfo = {
    name: props.field.name,
    dob: props.field.dob,
    email: props.field.email,
    mobile: props.field.mobile,
    countryCode: props.field.countryCode,
    city: props.field.city
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialUserInfo}
      validationSchema={() => AddUserSchema}
      onSubmit={(values: FormikValues, formik) => {
        EditUser(values, formik)
      }}
    >
      {(formik: FormikValues) => (
        <Form className="pt-3" onSubmit={formik.handleSubmit}>
          <fieldset disabled={props.isDisabled}>
            <Row>
              <Col sm={12}>
                <Row>
                  <Col lg={4} className='my-2'>
                    <FormInputText
                      inputName={INPUT_FIELD_ENUMS.USER_NAME.INPUT_NAME}
                      type={INPUT_FIELD_ENUMS.USER_NAME.INPUT_TYPE}
                      isRequired={INPUT_FIELD_ENUMS.USER_NAME.IS_REQUIRED}
                      inputField={'USER_NAME'}
                    />
                  </Col>
                  <Col lg={4} className='my-2'>
                    <FormInputText
                      inputName={INPUT_FIELD_ENUMS.USER_EMAIL.INPUT_NAME}
                      type={INPUT_FIELD_ENUMS.USER_EMAIL.INPUT_TYPE}
                      isRequired={INPUT_FIELD_ENUMS.USER_EMAIL.IS_REQUIRED}
                      inputField={'USER_EMAIL'}
                    />
                  </Col>
                  <Col lg={4} className='my-2'>
                    <FormInputText
                      inputName={INPUT_FIELD_ENUMS.USER_MOBILE.INPUT_NAME}
                      type={INPUT_FIELD_ENUMS.USER_MOBILE.INPUT_TYPE}
                      isRequired={INPUT_FIELD_ENUMS.USER_MOBILE.IS_REQUIRED}
                      inputField={'USER_MOBILE'}
                    />
                  </Col>
                  <Col lg={4} className='my-2'>
                    <FormInputText
                      inputName={INPUT_FIELD_ENUMS.USER_DOB.INPUT_NAME}
                      type={INPUT_FIELD_ENUMS.USER_DOB.INPUT_TYPE}
                      isRequired={INPUT_FIELD_ENUMS.USER_DOB.IS_REQUIRED}
                      inputField={'USER_DOB'}
                    />
                  </Col>
                  <Col lg={4} className='my-2'>
                    <FormInputText
                      inputName={INPUT_FIELD_ENUMS.USER_COUNTRY.INPUT_NAME}
                      type={INPUT_FIELD_ENUMS.USER_COUNTRY.INPUT_TYPE}
                      isRequired={INPUT_FIELD_ENUMS.USER_COUNTRY.IS_REQUIRED}
                      inputField={'USER_COUNTRY'}
                    />
                  </Col>
                  <Col lg={4} className='my-2'>
                    <FormInputText
                      inputName={INPUT_FIELD_ENUMS.USER_CITY.INPUT_NAME}
                      type={INPUT_FIELD_ENUMS.USER_CITY.INPUT_TYPE}
                      isRequired={INPUT_FIELD_ENUMS.USER_CITY.IS_REQUIRED}
                      inputField={'USER_CITY'}
                    />
                  </Col>
                </Row>
              </Col>
              {/* <Col sm={9} md={9} lg={9} xl={3} className='my-2'>
                <Card className='form-card'>
                  <Card.Body className={'d-flex flex-column justify-content-between'}>
                    <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
                      <FormLabel className='align-self-center mb-0 mt-1' >{`${t('ORGANIZATION_SETTINGS.HAS_MULTIPLE_STOP')} :`}</FormLabel>
                      <Form.Check type="switch" name="bookingForOther" className="switch" checked={!!props.field.status} onChange={(event) => props.field.setStatus(event.target.checked)} />
                    </FormGroup>
                    <p className='text-left mt-4 text-secondary'>To enable the switch, User can create booking with Multiple stops Locations.</p>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
          </fieldset>
          {/* <FormGroup className="d-flex align-item-center justify-content-start">
            <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.USER_LIST, rights: props.submitButtonName === 'ADD' ? 'create' : 'update' }]}>
              <Button type="submit" className='m-3 mw-50' disabled={props.noDataFetched}>
                {isFetching ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : t(`PROJECT_BUTTON.${props.submitButtonName}`)}
              </Button>
            </IsAuthorized>
          </FormGroup> */}
        </Form>
      )}
    </Formik>

  )
}

export default UserCreateViewEdit
