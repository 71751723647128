import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, FormControl, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import TooltipComponent from '../../Components/Common/Tooltip'
import UserContext from '../../Context/UserContext'
import { getBookingDetail } from '../../Services/BookingService'
import { ACCOUNT_STATUS, BOOKING_STATUS, PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { fetchResponseMessage, getStatus } from '../../utils/utils'
import { BookingDetailModal, PaginationBodyObjectModel, ReactDataTable } from '../Models/CommonModel'

type Props = {}

const Bookings = (props: Props) => {
  const { setArrayOfBreadCrumb, isFetching, setIsFetching } = useContext(UserContext)
  const { t } = useTranslation()
  const [searchBar, setSearchBar] = useState('')
  const navigate = useNavigate()

  const [tableBodyState, setTableBodyState] = useState([] as BookingDetailModal[])

  const [totalRows, setTotalRows] = useState<number>(10)
  const [timeStampState, setTimeStampState] = useState<string>('')
  const [currentPageState, setCurrentPageState] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [sortedField, setSortedField] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const [isAscending, setIsAscending] = useState<boolean>(true)
  const [sortedFieldId, setSortedFieldId] = useState<number>(1)

  useEffect(() => {
    getTableData({ page: currentPageState, limit: rowsPerPage })
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.BOOKINGS')}`, url: '' }
    ])
    return () => { }
  }, [])

  // fetch content of table while you go to booking page.
  const getTableData = async (paginationInfo: PaginationBodyObjectModel) => {
    setIsFetching(true)
    try {
      const response = await getBookingDetail(paginationInfo)
      if (response.statusCode === 200) {
        console.log('🔥 ~ responseWithOrderNumber ~ response.data', response.data)
        const responseWithOrderNumber = response.data.data.map((data: BookingDetailModal, index: number) => {
          data.name === 'test' && console.log('🔥 ~ responseWithOrderNumber ~ data', data.name, data.status)
          return {
            ...data,
            status: (
              <div className={`badge badge-pill me-1 ${data.status === ACCOUNT_STATUS.ACTIVE.STATUS ? ACCOUNT_STATUS.ACTIVE.CLASS : data.status === ACCOUNT_STATUS.INACTIVE.STATUS ? ACCOUNT_STATUS.INACTIVE.CLASS : ACCOUNT_STATUS.BLOCKED.CLASS}`}>{getStatus(data.status)}</div>
            ),
            activeStatus: data.status
          }
        })
        setTotalRows(response.data.total)
        setTimeStampState(response.data.timestamps)
        setTableBodyState(responseWithOrderNumber)
        setIsFetching(false)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🚀 ~ file: Designation.tsx ~ line 73 ~ useEffect ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
      // toast.error(`${t('TOAST_ERROR.DESIGNATION.FILED_TO_LOAD')}`)
    }
  }

  const columns: ReactDataTable[] = [
    {
      name: 'pick-up',
      cell: (tableRow) => (
        <TooltipComponent toolTipMsg={tableRow.pickupLocation}>
          <div className='w-50 d-flex'>
            <p className='w-100 text-overflow-none'>{tableRow.pickupLocation}</p>
          </div>
        </TooltipComponent>
      ),
      sortable: false
    },

    {
      name: 'Drop-Location',
      cell: (tableRow) => (
        <TooltipComponent toolTipMsg={tableRow.dropLocation}>
          <div className='w-50 d-flex'>
            <p className='w-100 text-overflow-none'>{tableRow.dropLocation}</p>
          </div>
        </TooltipComponent>
      ),
      sortable: false
    },
    {
      name: 'Driver Name',
      cell: (tableRow) => (
        <Link to={`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE}/view/${tableRow.driverId}`} className='w-50 d-flex'>
          <p className='w-100 text-overflow-none'>{tableRow.driverName}</p>
        </Link>
      ),
      sortable: false
    },
    {
      name: 'User Name',
      cell: (tableRow) => (
        <Link to={`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.USER_LIST_PAGE}/view/${tableRow.userId}`} className='w-50 d-flex'>
          <p className='w-100 text-overflow-none'>{tableRow.userName}</p>
        </Link>
      ),
      sortable: false
    },
    {
      name: 'Booking Type',
      // @ts-ignore
      selector: (row: { bookingType: string }) => BOOKING_STATUS.find(opt => opt.id === row.bookingType)?.value,
      sortable: true
    }
  ]

  /**
 *@function searchBarHandler
 *@return void
 *@param event
 * @description To handle search on Designation page.
 */
  const searchBarHandler = (value: string) => {
    setSearchBar(value)
    const pagination = { page: currentPageState, limit: rowsPerPage, timestamps: timeStampState, q: (value).toLowerCase() }
    getTableData(pagination)
  }

  /**
 *@function gotoBookingDetails
 *@return void
 *@param bookingId
 * @description To handle search on Designation page.
 */
  const gotoBookingDetails = (bookingId: string) => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.BOOKING_PAGE}/view/${bookingId}`)
  }

  const handlePageChange = (changedPage: number, totalRows: number) => {
    setCurrentPageState(changedPage)
    let paginationObject
    if (sortedField !== '') {
      paginationObject = {
        page: changedPage,
        limit: rowsPerPage,
        orderBy: sortedField,
        orderDirection: sortDirection,
        timestamps: timeStampState
      }
    } else {
      paginationObject = {
        page: changedPage,
        limit: rowsPerPage,
        orderDirection: sortDirection,
        timestamps: timeStampState
      }
    }

    getTableData(paginationObject)
  }

  const handleSort = (selectedColumn: any, order: string) => {
    setSortedField(selectedColumn.name.toLowerCase())
    setSortedFieldId(selectedColumn.id)
    setSortDirection(order)
    setIsAscending(isAscending => !isAscending)
    const paginationObject = {
      page: currentPageState,
      limit: rowsPerPage,
      timestamps: timeStampState,
      orderBy: selectedColumn.name.toLowerCase(),
      orderDirection: order
    }
    getTableData(paginationObject)
  }

  const handlePerPageLimit = (rowsPerPge: number, currentPage: number) => {
    setRowsPerPage(rowsPerPge)
    setCurrentPageState(currentPage)
  }

  return (
    <>
      <div>
        <Row className='mb-4'>
          <Col sm={12} md={6} lg={4} xl={3}>
            <FormControl
              type="text"
              className="form-control ms-auto search-bar"
              placeholder={`${t('DESIGNATION_PAGE.SEARCH_BAR_PLACEHOLDER')}`}
              onChange={(event) => searchBarHandler(event.target.value)}
              value={searchBar}
            />
          </Col>
        </Row>
      </div>
      <Card className="designation-table">
        <Card.Body>
          <Row>
            <Col>
              {isFetching ? (
                <div className="loading d-block mx-auto">
                  <div className="spinner-border"></div>
                </div>
              ) : tableBodyState.length === 0 ? (
                <div className="text-center font-weight-bold">{`${t('BOOKING_PAGE.NO_DATA_FOUND')}`}</div>
              ) : (
                <DataTable
                  columns={columns}
                  data={tableBodyState}
                  className="table-with-pagination"
                  highlightOnHover
                  onRowClicked={(row) => gotoBookingDetails(row.id)}
                  pagination
                  paginationServer
                  paginationDefaultPage={currentPageState}
                  paginationTotalRows={totalRows}
                  paginationPerPage={rowsPerPage}
                  onChangePage={(page, totalRows) => handlePageChange(page, totalRows)}
                  paginationComponentOptions={{
                    rowsPerPageText: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.TABLE.ROW_PER_PAGE')}`,
                    rangeSeparatorText: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.TABLE.RANGE_SELECTION')}`
                  }}
                  sortServer
                  onSort={handleSort}
                  defaultSortAsc={isAscending}
                  onChangeRowsPerPage={handlePerPageLimit}
                  defaultSortFieldId={sortedFieldId}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default Bookings
