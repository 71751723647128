/* eslint-disable indent */
import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import UserContext from '../../../../Context/UserContext'
import { MenuDataChildren } from '../../../../Pages/Models/CommonModel'
import { SCREEN_SIZE } from '../../../../utils/enums/enums'
import SidebarAccess from '../../SidebarAccess'

type SubMenu = MenuDataChildren & {
  icon: React.ReactElement
  children?: MenuDataChildren[]
  arrayOfChildrenModuleId: number[]
}

type Props = {
  menuData: SubMenu
}

const SubMenus = ({ menuData }: Props) => {
  const { setShowSidebar } = useContext(UserContext)
  const { pathname } = useLocation()
  const handleClick = () => {
    if (window.innerWidth < SCREEN_SIZE.SCREEN_SIZE_720) {
      setShowSidebar(false)
    }
  }
  return (
    <>
      {menuData.children &&
        menuData.children.map((child, childrenIndex) => {
          return (
            <SidebarAccess moduleId={child.moduleId} key={'children' + childrenIndex} accessRights={child.accessType}>
              <li onClick={handleClick} className="nav-item">
                <NavLink to={child.url} className={({ isActive }) => (isActive && child.url === pathname ? 'active nav-link sub-menu' : 'nav-link sub-menu')}>
                  {child.name}
                </NavLink>
              </li>
            </SidebarAccess>
          )
        })}
    </>
  )
}

export default SubMenus
