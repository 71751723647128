import _ from 'lodash'
import React, { useContext } from 'react'
import UserContext from '../../Context/UserContext'
import { MODULE_ID } from '../../utils/enums/enums'

type Props = {
  moduleId?: number
  accessRights?: 'create' | 'update' | 'delete' | 'read'
  children: React.ReactElement
  arrayOfChildrenModuleId?: number[]
}

const SidebarAccess = ({
  moduleId,
  accessRights,
  children,
  arrayOfChildrenModuleId
}: Props) => {
  const { userPermissions } = useContext(UserContext)
  if (!moduleId && arrayOfChildrenModuleId) {
    if (!arrayOfChildrenModuleId.includes(MODULE_ID.DEFAULT_ACCESS)) {
      const filteredArrayByPermission = arrayOfChildrenModuleId.map(moduleId => {
        return userPermissions.find(permissionObj => permissionObj.moduleId === moduleId && permissionObj[accessRights!] === 1)
      })
      if (!filteredArrayByPermission.length) return null
    }
  } else if (moduleId && arrayOfChildrenModuleId) {
    if (!arrayOfChildrenModuleId.includes(MODULE_ID.DEFAULT_ACCESS)) {
      const filteredArrayByPermission = arrayOfChildrenModuleId.map(moduleId => {
        return userPermissions.find(permissionObj => permissionObj.moduleId === moduleId && permissionObj[accessRights!] === 1)
      })
      const moduleAccess = userPermissions.find(permissionObj => permissionObj.moduleId === moduleId)
      if (moduleAccess) {
        if (_.compact(filteredArrayByPermission).length === 0 || !moduleAccess[accessRights!]) return null
      }
    }
  } else if (moduleId && !arrayOfChildrenModuleId) {
    if (moduleId !== MODULE_ID.DEFAULT_ACCESS) {
      const moduleAccess = userPermissions.find(permissionObj => permissionObj.moduleId === moduleId)
      if (moduleAccess) {
        if (!moduleAccess[accessRights!]) return <div></div>
      }
    }
  }
  return (
    children
  )
}

export default SidebarAccess
