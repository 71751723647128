/* eslint-disable indent */
// TODO : this page is pending for review
import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Form, FormControl, FormGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { DriverListModel, PaginationBodyObjectModel, ReactDataTable, UserListModel } from '../../Models/CommonModel'
import UserContext from '../../../Context/UserContext'
import { ACCOUNT_STATUS, PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { gerDriverList, putBlockDriver } from '../../../Services/DriverService'
import { fetchResponseMessage, getStatus } from '../../../utils/utils'
import _ from 'lodash'

type Props = {}

const AdminList = (props: Props) => {
  const { t } = useTranslation()
  const [searchBar, setSearchBar] = useState('')
  const navigate = useNavigate()
  const [tableBodyState, setTableBodyState] = useState([] as DriverListModel[])
  const { setArrayOfBreadCrumb, isFetching, setIsFetching } = useContext(UserContext)
  // state for pagination
  const [totalRows, setTotalRows] = useState<number>(10)
  const [timeStampState, setTimeStampState] = useState<string>('')
  const [currentPageState, setCurrentPageState] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [sortedField, setSortedField] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<string>('asc')
  const [isAscending, setIsAscending] = useState<boolean>(true)

  // fetch content of table while you to to Designation page.
  const getTableData = async (paginationInfo: PaginationBodyObjectModel) => {
    setIsFetching(true)
    try {
      const response = await gerDriverList(paginationInfo)
      if (response.statusCode === 200) {
        const responseWithOrderNumber = response.data.data.map((data: DriverListModel) => {
          return {
            ...data,
            status: (
              <div className={`badge badge-pill me-1 ${data.status === ACCOUNT_STATUS.ACTIVE.STATUS ? ACCOUNT_STATUS.ACTIVE.CLASS : data.status === ACCOUNT_STATUS.ACTIVE.STATUS ? ACCOUNT_STATUS.INACTIVE.CLASS : ACCOUNT_STATUS.BLOCKED.CLASS}`}>{getStatus(data.status)}</div>
            ),
            availability: (<div className='availability-icon  d-flex flex-column justify-content-between mt-1 align-items-center'>
              <span className={`mdi mdi-circle d-block mx-auto text-center me-2 ${data.driverAvailability.isAvailable ? 'text-success' : 'text-danger'}`}></span>
              <p className='mb-0'>{data.driverAvailability.since}</p>
            </div>),
            activeStatus: data.status
          }
        })
        setTotalRows(response.data.total)
        setTimeStampState(response.data.timestamps)
        setTableBodyState(responseWithOrderNumber)
        setIsFetching(false)
      } else {
        throw response
      }
    } catch (error) {
      console.error('🔥 ~ getTableData ~ error', error)
      setIsFetching(false)
      console.error('🚀 ~ file: Designation.tsx ~ line 73 ~ useEffect ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
      // toast.error(`${t('TOAST_ERROR.DESIGNATION.FILED_TO_LOAD')}`)
    }
  }
  useEffect(() => {
    getTableData({ page: currentPageState, limit: rowsPerPage })
    // TODO : this should not here
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.DRIVER_LIST')}`, url: '' }
    ])
    return () => { }
  }, [rowsPerPage])

  /**
 * @function goToAddDesignationPage
 * @return void
 * @description Go to add-designation page
 */

  const gotoDriverDetailsPage = (driverId: string) => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE}/view/${driverId}`)
  }

  const gotoDriverEditPage = (driverId: string) => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_LIST_PAGE}/edit/${driverId}`)
  }
  const blockDriver = (id: string) => {
    changeDriverStatus(id, ACCOUNT_STATUS.BLOCKED.STATUS)
  }

  const activeDriver = (id: string) => {
    changeDriverStatus(id, ACCOUNT_STATUS.ACTIVE.STATUS)
  }

  const changeDriverStatus = async (driverId: string, status: number) => {
    setIsFetching(true)
    try {
      const response = await putBlockDriver(driverId, status)
      if (response.statusCode === 200) {
        setIsFetching(false)
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
        getTableData({ page: currentPageState, limit: rowsPerPage })
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ blockDriver ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  const driverSubmittedDocument = (driverId: string) => {
    const driverName = tableBodyState.find((driver: any) => driver.id === driverId)!.name
    const docStatus = tableBodyState.find(driver => driver.id === driverId)!.documentStatus
    navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_SUBMITTED_DOCUMENT_PAGE, { state: { id: driverId, name: driverName, docVerificationStatus: docStatus } })
  }

  /*   // Below part of code will render the dropdown menu as permission the admin get from server.
    const dropdownTitleArray = _.compact(['view', 'edit', 'delete', 'block', 'verification', 'active'].map((title: string) => (
      getDropdownTitle(title, MODULE_ID.DRIVER_LIST, userPermissions)
    ))) */
  // columns is total columns in table and its name
  const columns: ReactDataTable[] = [
    {
      name: `${t('USER_LIST_PAGE.USER_TABLE.NAME')}`,
      selector: (row: { name: string }) => row.name,
      sortable: true
    },

    {
      name: `${t('USER_LIST_PAGE.USER_TABLE.EMAIL')}`,
      selector: (row: { email: string }) => row.email,
      sortable: true
    },
    {
      name: 'Mobile No.',
      cell: (tableRow: UserListModel) => (
        <a className={' text-decoration-none'} href={`tel:${tableRow.countryCode + tableRow.mobile}`} >
          <div>+{tableRow.countryCode} {tableRow.mobile}</div>
        </a>
      )
      /*  selector: (row: { availability: string }) => row.availability,
       sortable: false */
    },
    {
      name: `${t('USER_LIST_PAGE.USER_TABLE.STATUS')}`,
      cell: (tableRow: UserListModel) => (
        <>
          <FormGroup className='my-2 switch-wrapper d-flex justify-content-between'>
            <Form.Check type="switch" name="bookingForOther" className="switch" checked={tableRow.activeStatus !== -1} onChange={(event) => handleInputStatusChange(event.target.checked, tableRow.id)} />
          </FormGroup>
        </>
      )
    },
    {
      // TODO: from translation
      name: 'Action',
      cell: (tableRow: UserListModel) => (
        <>
          <span onClick={() => gotoDriverEditPage(tableRow.id)} className='mdi mdi-pencil table-setting-icon mx-2 text-primary'></span>
          {/* <span onClick={() => deleteDriver(tableRow.id)} className='mdi mdi-trash-can-outline table-setting-icon mx-2 text-danger delete'></span> */}
          <span onClick={() => driverSubmittedDocument(tableRow.id)} className='mdi mdi-file-document table-setting-icon mx-2 text-primary delete'></span>
        </>
      )
    }
    // {
    //   name: 'Delete',
    //   cell: (tableRow: UserListModel) => (
    //     <>
    //       {/* <span onClick={() => gotoDriverEditPage(tableRow.id)} className='mdi mdi-pencil table-setting-icon mx-1 text-primary'></span> */}
    //       <span onClick={() => deleteDriver(tableRow.id)} className='mdi mdi-trash-can-outline table-setting-icon mx-1 text-danger delete'></span>
    //       {/* <span onClick={() => driverSubmittedDocument(tableRow.id)} className='mdi mdi-file-document table-setting-icon mx-1 text-primary delete'></span> */}
    //     </>
    //   )
    // },
    // {
    //   name: 'Document',
    //   cell: (tableRow: UserListModel) => (
    //     <>
    //       {/* <span onClick={() => gotoDriverEditPage(tableRow.id)} className='mdi mdi-pencil table-setting-icon mx-1 text-primary'></span> */}
    //       {/* <span onClick={() => deleteDriver(tableRow.id)} className='mdi mdi-trash-can-outline table-setting-icon mx-1 text-danger delete'></span> */}
    //       <span onClick={() => driverSubmittedDocument(tableRow.id)} className='mdi mdi-file-document table-setting-icon mx-1 text-primary delete'></span>
    //     </>
    //   )
    // },
  ]
  const handleInputStatusChange = (eventValue: boolean, userId: string) => {
    if (eventValue) {
      activeDriver(userId)
    } else {
      blockDriver(userId)
    }
  }

  /* const deleteDriver = (driverId: string) => {

  }
 */
  const handlePageChange = (changedPage: number, totalRows: number) => {
    setCurrentPageState(changedPage)
    let paginationObject
    if (sortedField !== '') {
      paginationObject = {
        page: changedPage,
        limit: rowsPerPage,
        orderBy: sortedField,
        orderDirection: sortDirection,
        timestamps: timeStampState
      }
    } else {
      paginationObject = {
        page: changedPage,
        limit: rowsPerPage,
        orderDirection: sortDirection,
        timestamps: timeStampState
      }
    }

    getTableData(paginationObject)
  }

  const handleSort = (selectedRow: any, order: string) => {
    setSortedField(selectedRow.name.toLowerCase())
    setSortDirection(order)
    setIsAscending(isAscending => !isAscending)
    const paginationObject = {
      page: currentPageState,
      limit: rowsPerPage,
      timestamps: timeStampState,
      orderBy: selectedRow.name.toLowerCase(),
      orderDirection: order
    }
    getTableData(paginationObject)
  }

  /**
   *@function searchBarHandler
   *@return void
   *@param event
   * @description To handle search on Designation page.
   */
  const searchBarHandler = (value: string) => {
    setSearchBar(value)

    const pagination = { page: currentPageState, limit: rowsPerPage, timestamps: timeStampState, q: (value).toLowerCase() }
    getTableData(pagination)
  }

  const handlePerPageLimit = (rowsPerPge: number, currentPage: number) => {
    setRowsPerPage(rowsPerPge)
    setCurrentPageState(currentPage)
  }

  return (
    <>
      <div className='mb-4'>
        <Row>
          {/* <Col sm={6}>
                    <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.SHIFT, rights: 'create' }]}>
                      <Button variant="light" className={'add-button my-2'} onClick={gotoAddDriverShift}>
                        {`${t('ORGANIZATION_SHIFT_SETTINGS_PAGE.ADD_SHIFT')}`}
                      </Button>
                    </IsAuthorized>
                    </Col> */}
          <Col sm={12} md={6} lg={4} xl={3}>
            <FormControl
              type="text"
              className="form-control ms-auto"
              placeholder={`${t('DESIGNATION_PAGE.SEARCH_BAR_PLACEHOLDER')}`}
              onChange={(event) => searchBarHandler(event.target.value)}
              value={searchBar}
            />
          </Col>
        </Row>
      </div>
      <Card className="designation-table">
        <Card.Body>
          <div className='d-flex align-items-center justify-content-between'>
          </div>
          <Row>
            <Col>
              <div>
                <Row>
                  <Col>
                    {isFetching ? (
                      <div className="loading d-block mx-auto">
                        <div className="spinner-border"></div>
                      </div>
                    ) : tableBodyState.length === 0 ? (
                      <div className="text-center font-weight-bold">{`${t('DRIVER_LIST_PAGE.DRIVER_TABLE.NO_DRIVER')}`}</div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={tableBodyState}
                        className="table-with-pagination"
                        onRowClicked={(row) => gotoDriverDetailsPage(row.id)}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationDefaultPage={currentPageState}
                        paginationTotalRows={totalRows}
                        paginationPerPage={rowsPerPage}
                        onChangePage={(page, totalRows) => handlePageChange(page, totalRows)}
                        paginationComponentOptions={{
                          rowsPerPageText: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.TABLE.ROW_PER_PAGE')}`,
                          rangeSeparatorText: `${t('DESIGNATION_PAGE.DESIGNATION_TABLE.TABLE.RANGE_SELECTION')}`
                        }}
                        sortServer
                        onSort={handleSort}
                        defaultSortAsc={isAscending}
                        onChangeRowsPerPage={handlePerPageLimit}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default AdminList
