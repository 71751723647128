import _ from 'lodash'
import React, { useContext } from 'react'
import UserContext from '../../Context/UserContext'

type Props = {
  moduleInfo: {
    moduleId: number,
    rights?: 'create' | 'read' | 'update' | 'delete'
  }[],
  children: any
}
const IsAuthorized = (props: Props) => {
  const { userPermissions } = useContext(UserContext)
  for (const prop of props.moduleInfo) {
    const getPermission = _.find(userPermissions, { moduleId: prop.moduleId })
    switch (prop.rights) {
      case 'create':
        if (!getPermission || !getPermission.create) {
          return <></>
        } else {
          break
        }
      case 'read':
        if (!getPermission || !getPermission.read) {
          return <></>
        } else {
          break
        }
      case 'update':
        if (!getPermission || !getPermission.update) {
          return <></>
        } else {
          break
        }
      case 'delete':
        if (!getPermission || !getPermission.delete) {
          return <></>
        } else {
          break
        }
    }
  }

  return <>{props.children}</>
}

export default IsAuthorized
