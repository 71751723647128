import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import InnerLoader from '../../../Components/Common/InnerLoader'
import IsAuthorized from '../../../Components/IsAuthorized/IsAuthorized'
import UserContext from '../../../Context/UserContext'
import { getVehicleBrandList, postUpdateVehicleBrandList } from '../../../Services/VehicleService'
import { MODULE_ID, PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../utils/utils'
import { VehicleBrandModel } from '../../Models/CommonModel'

type Props = {}

const VehicleBrand = (props: Props) => {
  const { isFetching, setArrayOfBreadCrumb, setIsFetching } = useContext(UserContext)
  const { t } = useTranslation()
  const [vehicleBrandList, setVehicleBrandList] = useState([] as VehicleBrandModel[])

  const fetchBrandList = async () => {
    setIsFetching(true)
    try {
      const response = await getVehicleBrandList()
      if (response.data) {
        setVehicleBrandList(response.data)
        setIsFetching(false)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }
  useEffect(() => {
    fetchBrandList()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_BRAND')}`, url: '' }
    ])
  }, [])

  const toggleBrand = (check: boolean, brandId: number) => {
    if (check) {
      const updatedList = vehicleBrandList.map((vehicleBrand: VehicleBrandModel) => vehicleBrand.masterBrandId === brandId ? { ...vehicleBrand, orgVehicleBrandId: brandId } : vehicleBrand)
      setVehicleBrandList(updatedList)
    } else {
      const updatedList = vehicleBrandList.map(vehicleBrand => vehicleBrand.masterBrandId === brandId ? { ...vehicleBrand, orgVehicleBrandId: null } : vehicleBrand)
      setVehicleBrandList(updatedList)
    }
  }

  const submitSelectedBrand = async () => {
    setIsFetching(true)
    try {
      const updatedList = vehicleBrandList.map(brand => {
        if (brand.orgVehicleBrandId) {
          return _.omit(brand, 'orgVehicleBrandId')
        } else {
          return null
        }
      })
      const response = await postUpdateVehicleBrandList(_.compact(updatedList))
      if (response.statusCode === 200) {
        setIsFetching(false)
        // fetchBrandList()
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
      } else {
        throw response
      }
    } catch (error) {
      setIsFetching(false)
      console.error('🔥 ~ submitSelectedBrand ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        // @ts-ignore
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  return (
    <div>
      {isFetching ? <InnerLoader /> :
        <>
          <div className='d-flex flex-wrap '>
            {vehicleBrandList.length > 0 ?
              vehicleBrandList.map((brand: VehicleBrandModel, brandIndex: number) => {
                return (
                  <React.Fragment key={brandIndex}>
                    <input type={'checkbox'} checked={!!brand.orgVehicleBrandId} onChange={(event) => toggleBrand(event.target.checked, brand.masterBrandId)} id={brand.brand + brand.masterBrandId} style={{ display: 'none' }} />
                    <label htmlFor={brand.brand + brand.masterBrandId} className={`vehicle-brand-name m-3 ${!!brand.orgVehicleBrandId && 'selected'}`}>{brand.brand}</label>
                  </React.Fragment>
                )
              })
              : <h5 className='d-block w-100 text-center'>{'Data not fetched'}</h5>}
          </div>
          <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.VEHICLE_LIST, rights: 'update' }]}>
            <Button className='d-block ms-3 mt-5' onClick={submitSelectedBrand}>
              {`${t('PROJECT_BUTTON.SUBMIT')}`}
            </Button>
          </IsAuthorized>

        </>
      }
    </div>
  )
}

export default VehicleBrand
