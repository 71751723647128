import React from 'react'

type Props = {
  checkBoxToggle: (event: React.ChangeEvent<HTMLInputElement>) => void
  isChecked: boolean
}

const FormInputCheckBox = (props: Props) => {
  return (
    <>
      <input type="checkbox" className="form-check-input" onChange={props.checkBoxToggle} checked={props.isChecked} />
      <i className="input-helper"></i>
    </>
  )
}

export default FormInputCheckBox
