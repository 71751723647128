import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'

const UnAuthorized = () => {
  const navigate = useNavigate()
  return (
    <div className="d-flex align-item-center justify-content-center mt-5">
      <div className="page-404 d-flex align-items-center flex-column">
        <h4>403</h4>
        <h5 className='text-secondary mb-4'>You Are Not Authorized To View This Page!</h5>
        <Button onClick={() => navigate(PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE)}>Back To Dashboard</Button>
      </div>
    </div>
  )
}

export default UnAuthorized
