/* eslint-disable multiline-ternary */
import React, { MouseEvent } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownToggleProps } from 'react-bootstrap/esm/DropdownToggle'
import { DropdownMenuItemObject } from '../../Pages/Models/CommonModel'

type Props = {
  dropdownNameOrBadge?: string
  dropdownDetails: DropdownMenuItemObject
  dropdownButtonIcon?: string
  dropdownItemOnClickHandler?: (event: React.MouseEvent<HTMLElement>) => void
  cssClassForStyle?: string
}

type CustomToggleProps = {
  children?: DropdownToggleProps
  onClick: (e: MouseEvent<Element, Event>) => void
}

const DropdownMenu = (props: Props) => {
  /**
   * @function notificationIconButton
   * @param children
   * @param onClick
   * @param ref
   * @description In bootstrap dropdown to make button style custom this function is used.
   */
  const notificationIconButton = React.forwardRef(function test ({ children, onClick }: CustomToggleProps, ref: React.LegacyRef<HTMLButtonElement>) {
    return (
      <button
        className="nav-link count-indicator"
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
        ref={ref}
      >
        <>
          <i className={`mdi ${props.dropdownButtonIcon} mx-0`}></i>
          {props.dropdownNameOrBadge && <span className="count">{props.dropdownNameOrBadge}</span>}
          {children}
        </>
      </button>
    )
  })

  return (
    <Dropdown className={`nav-item icon ${props.cssClassForStyle}`} align={'start'}>
      <Dropdown.Toggle bsPrefix="string" as={notificationIconButton}></Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-left navbar-dropdown preview-list">
        {props.dropdownDetails.map((dropdownItem, dropdownItemIndex) =>
          dropdownItem.isDropdownHeader ? (
            <React.Fragment key={dropdownItemIndex}>
              <Dropdown.Item>
                <h6 className="px-1 mb-0">{dropdownItem.dropdownTitle}</h6>
              </Dropdown.Item>
              <Dropdown.Divider />
            </React.Fragment>
          ) : (
            <React.Fragment key={dropdownItemIndex}>
              <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center px-1" onClick={props.dropdownItemOnClickHandler}>
                {dropdownItem.dropdownIcon !== '' && (
                  <div className="preview-thumbnail">
                    <div className="preview-icon text-center rounded-circle">
                      <i className={`mdi ${dropdownItem.dropdownIcon} text-success`}></i>
                    </div>
                  </div>
                )}

                <div className="preview-item-content">
                  <h6 className="preview-subject mb-1">{dropdownItem.dropdownTitle}</h6>
                  <p className="text-muted ellipsis mb-0">{dropdownItem.dropdownContent}</p>
                </div>
              </Dropdown.Item>
              {dropdownItemIndex === props.dropdownDetails.length - 1 ? '' : <Dropdown.Divider />}
            </React.Fragment>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropdownMenu
