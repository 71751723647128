import { FormikValues } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import VehicleDocumentCreateViewEdit from '../../../Components/Common/VehicleDocumentCreateViewEdit'
import UserContext from '../../../Context/UserContext'
import { PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'

type Props = {}

const AddVehicleDocument = (props: Props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [selectedFormat, setSelectedFormat] = useState('' as string | [] as any)
  const [doShowState, setDoShow] = useState(false)
  const [doseExpiryState, setDoseExpiry] = useState(false)
  const [isRequiredStateState, setIsRequiredState] = useState(true)
  const [isCaptureOnlyStateState, setIsCaptureOnlyState] = useState(false)
  const [documentNameState, setDocumentNameState] = useState('')
  const [documentDescriptionState, setDocumentDescriptionState] = useState('')
  const [documentSizeState, setDocumentSizeState] = useState(0)
  const [documentImageState, setDocumentImageState] = useState('')

  const { setArrayOfBreadCrumb } = useContext(UserContext)

  useEffect(() => {
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.VEHICLE_LIST')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_LIST_PAGE },
      // @ts-ignore
      { name: `${t('PAGE_NAME.VEHICLE_DOCUMENT')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.VEHICLE_DOCUMENT_PAGE, state: location.state },
      { name: `${t('PAGE_NAME.VEHICLE_DOCUMENT_ADD')}`, url: '' }
    ])
    setDocumentNameState('')
    setDocumentDescriptionState('')
    setDocumentSizeState(0)
    setDocumentImageState('')
    setSelectedFormat('')
    return () => { }
  }, [])

  const setFieldValue = (formik: FormikValues) => {
    setSelectedFormat(formik.values.documentFormat)
    setDocumentNameState(formik.values.documentName)
    setDocumentDescriptionState(formik.values.documentDescription)
    setDocumentSizeState(formik.values.documentSize)
    setDocumentImageState(formik.values.documentImage)
  }
  return (
    <VehicleDocumentCreateViewEdit
      field={{
        documentName: documentNameState,
        documentDescription: documentDescriptionState,
        documentSize: documentSizeState,
        documentImage: documentImageState,
        documentFormat: selectedFormat,
        setDoShow,
        setDoseExpiry,
        setIsRequiredState,
        setIsCaptureOnlyState,
        setSelectedFormat,
        setDocumentSizeState,
        doShow: doShowState,
        doseExpiry: doseExpiryState,
        isRequiredState: isRequiredStateState,
        isCaptureOnlyState: isCaptureOnlyStateState
      }}
      isDisabled={false}
      cardTitle={`${t('ADD_VEHICLE_DOCUMENT_PAGE.TITLE')}`}
      submitButtonName="ADD"
      setFieldValue={setFieldValue}
      pageName="create-vehicle-document"
      // @ts-ignore
      paramId={location.state}

    />
  )
}

export default AddVehicleDocument
