import i18n from '../../../i18n'
import * as yup from 'yup'
export const addDriverDocumentSchema = yup.object().shape({
  documentName: yup
    .string()
    .required(`${i18n.t('FORMIK_ERROR.DRIVER_DOCUMENT.DOC_NAME_REQUIRED')}`)
    .max(50, `${i18n.t('FORMIK_ERROR.DRIVER_DOCUMENT.DOC_NAME_MAX_LENGTH')}`),
  documentDescription: yup.string().max(250, `${i18n.t('FORMIK_ERROR.DRIVER_DOCUMENT.DOC_DISC_MAX_LENGTH')}`).required(`${i18n.t('FORMIK_ERROR.DRIVER_DOCUMENT.DOC_DISC_REQUIRED')}`),
  documentSize: yup.number().required(`${i18n.t('FORMIK_ERROR.DRIVER_DOCUMENT.DOC_SIZE_REQUIRED')}`).max(30000000, `${i18n.t('FORMIK_ERROR.DRIVER_DOCUMENT.DOC_MAX_SIZE')}`),
  documentImage: yup.string().url(),
  documentFormat: yup.array().required(`${i18n.t('FORMIK_ERROR.DRIVER_DOCUMENT.DOC_FORMAT_REQUIRED')}`)
})
