import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DRIVER_DOCUMENT_VERIFICATION_STATUS } from '../../utils/enums/enums'
import DocumentAccordion from './DocumentAccordion'

export type DocumentDetail = {
  driverVehicleDocId?: string
  driverDocId?: string
  isRequired: number
  orgVehicleDocId?: string
  orgDriverDocId?: string
  placeHolderImg: string
  status: number
  title: string
}

type Props = {
  documents: DocumentDetail[]
  documentType: string
  classes: string
  rerenderAllDocument: () => void
}

const GetSingleVehicleDocuments = (props: Props) => {
  const [approvedVehicleDocuments, setApprovedVehicleDocuments] = useState<Array<object>>([])
  const [notSubmittedVehicleDocuments, setNotSubmittedVehicleDocuments] = useState<Array<object>>([])
  const [rejectedVehicleDocuments, setRejectedVehicleDocuments] = useState<Array<object>>([])
  const [submittedVehicleDocuments, setSubmittedVehicleDocuments] = useState<Array<object>>([])
  useEffect(() => {
    const approvedVehicleDocumentsArray = [] as Array<object>
    const notSubmittedVehicleDocumentsArray = [] as Array<object>
    const rejectedVehicleDocumentsArray = [] as Array<object>
    const submittedVehicleDocumentsArray = [] as Array<object>
    props.documents.forEach((document: DocumentDetail, documentIndex: number) => {
      switch (document.status) {
        case DRIVER_DOCUMENT_VERIFICATION_STATUS.PENDING.STATUS_KEY:
          notSubmittedVehicleDocumentsArray.push(<DocumentAccordion
            documentId={document.driverVehicleDocId || document.driverDocId}
            documentTitle={document.title}
            documentType={props.documentType}
            status={DRIVER_DOCUMENT_VERIFICATION_STATUS.PENDING}
            isClickable={false}
            rerenderAllDocument={props.rerenderAllDocument}
          />)
          break
        case DRIVER_DOCUMENT_VERIFICATION_STATUS.SUBMITTED.STATUS_KEY:
          submittedVehicleDocumentsArray.push(
            <DocumentAccordion
              documentId={document.driverVehicleDocId || document.driverDocId}
              documentTitle={document.title}
              documentType={props.documentType}
              isClickable={true}
              status={DRIVER_DOCUMENT_VERIFICATION_STATUS.SUBMITTED}
              rerenderAllDocument={props.rerenderAllDocument}
            />
          )
          break
        case DRIVER_DOCUMENT_VERIFICATION_STATUS.APPROVED.STATUS_KEY:
          approvedVehicleDocumentsArray.push(<DocumentAccordion
            documentId={document.driverVehicleDocId || document.driverDocId}
            documentTitle={document.title}
            documentType={props.documentType}
            isClickable={true}
            status={DRIVER_DOCUMENT_VERIFICATION_STATUS.APPROVED}
            rerenderAllDocument={props.rerenderAllDocument}
          />)
          break
        case DRIVER_DOCUMENT_VERIFICATION_STATUS.REJECTED.STATUS_KEY:
          rejectedVehicleDocumentsArray.push(<DocumentAccordion
            documentId={document.driverVehicleDocId || document.driverDocId}
            documentTitle={document.title}
            documentType={props.documentType}
            status={DRIVER_DOCUMENT_VERIFICATION_STATUS.REJECTED}
            isClickable={true}
            rerenderAllDocument={props.rerenderAllDocument}
          />)
          break
      }
    })
    setApprovedVehicleDocuments(approvedVehicleDocumentsArray)
    setNotSubmittedVehicleDocuments(notSubmittedVehicleDocumentsArray)
    setRejectedVehicleDocuments(rejectedVehicleDocumentsArray)
    setSubmittedVehicleDocuments(submittedVehicleDocumentsArray)

    return () => { }
  }, [])
  return (
    <Row className={`mt-3 mb-3 ${props.classes}`}>
      {submittedVehicleDocuments.length > 0 &&
        <>
          {submittedVehicleDocuments.map((document: any, documentIndex: number) => {
            return (
              <Col sm={12} className={'mt-'} key={documentIndex}>
                {document}
              </Col>
            )
          })}
        </>
      }
      {approvedVehicleDocuments.length > 0 &&
        <>
          {approvedVehicleDocuments.map((document: any, documentIndex: number) => {
            return (
              <Col sm={12} className={'mt-'} key={documentIndex}>
                {document}
              </Col>
            )
          })}
        </>
      }
      {notSubmittedVehicleDocuments.length > 0 &&
        <>
          {notSubmittedVehicleDocuments.map((document: any, documentIndex: number) => {
            return (
              <Col sm={12} className={'mt-'} key={documentIndex}>
                {document}
              </Col>
            )
          })}
        </>
      }
      {rejectedVehicleDocuments.length > 0 &&
        <>
          {rejectedVehicleDocuments.map((document: any, documentIndex: number) => {
            return (
              <Col sm={12} className={'mt-'} key={documentIndex}>
                {document}
              </Col>
            )
          })}
        </>
      }
    </Row>
  )
}

export default GetSingleVehicleDocuments
