import React from 'react'
// import UserContext from '../../Context/UserContext'
import { sidebarComponents } from '../../utils/utils'
import BrandLogo from './BrandLogo/BrandLogo'
import MainMenus from './MainMenus/MainMenus'
import SidebarAccess from './SidebarAccess'

type Props = {
  showSidebar: boolean
}
const Sidebar = ({ showSidebar }: Props) => {
  // const { userPermissions } = useContext(UserContext)
  return (
    // changed last
    <nav className={`sidebar sidebar-offcanvas ${showSidebar ? 'active' : ''}`} id="sidebar">
      <li className="nav-item my-4 brand-logo">
        <BrandLogo />
      </li>
      <ul className="nav pb-3 mt-5 d-flex flex-column justify-content-between main-sidebar-ul card">
        <div className='mt-4'>
          {sidebarComponents.map((navInfo, index) => (
            <React.Fragment key={index}>
              <SidebarAccess arrayOfChildrenModuleId={navInfo.arrayOfChildrenModuleId}>
                <MainMenus navInfo={navInfo} />
              </SidebarAccess>
              {index !== sidebarComponents.length - 1 ? <div className='border-under-menu my-2 mb-4'></div> : null}
            </React.Fragment>
          ))}
        </div>
      </ul>
    </nav>
  )
}

export default Sidebar
