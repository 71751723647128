import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
// import DataTable from 'react-data-table-component'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import IsAuthorized from '../../../Components/IsAuthorized/IsAuthorized'
import UserContext from '../../../Context/UserContext'
import { getDocumentList } from '../../../Services/DriverService'
import { MODULE_ID, PROJECT_ROUTES_ENUMS } from '../../../utils/enums/enums'
import { fetchResponseMessage } from '../../../utils/utils'
import { /* , ReactDataTable */
  VehicleDocumentResponse
} from '../../Models/CommonModel'

type Props = {}

const DriverDocument = (props: Props) => {
  const { t } = useTranslation()
  const { isFetching, setArrayOfBreadCrumb, setIsFetching } = useContext(UserContext)
  const [documentArray, setDocumentArray] = useState([] as VehicleDocumentResponse[])
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      setIsFetching(true)
      try {
        const response = await getDocumentList()
        if (response.data) {
          setDocumentArray(response.data)
          setIsFetching(false)
        } else {
          throw response
        }
      } catch (error) {
        setIsFetching(true)
        console.error('🔥 ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
        // toast.error('could not fetch document list.')
      }
    })()
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.DRIVER_DOCUMENTS')}`, url: '' }
    ])
    return () => { }
  }, [])
  // const columns: ReactDataTable[] = [
  //   {
  //     name: `${t('DRIVER_DOCUMENT_PAGE.DRIVER_DOCUMENT_LIST.Document_NAME')}`,
  //     selector: (row: { title: string }) => row.title,
  //     sortable: true
  //   },
  //   {
  //     name: `${t('DRIVER_DOCUMENT_PAGE.DRIVER_DOCUMENT_LIST.EDIT')}`,
  //     cell: (selectedRow: DesignationTableRow) => (
  //       <button className='table-button' onClick={() => editDocument(selectedRow.id)} ><span className='mdi mdi-pencil'></span></button>
  //     )
  //   }/* ,
  //   {
  //     name: `${t('DRIVER_DOCUMENT_PAGE.DRIVER_DOCUMENT_LIST.VIEW')}`,
  //     cell: (selectedRow: DesignationTableRow) => (
  //       <button className='table-button' onClick={() => viewDocument(selectedRow.id)}>{`${t('PROJECT_BUTTON.VIEW')}`}</button>
  //     )
  //   } */
  // ]

  const editDocument = (id: string) => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_DOCUMENT_PAGE}/edit/${id}`)
  }

  /*   const goToCreateDocumentPage = () => {
    navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_DOCUMENT_PAGE}/add`)
  } */
  //  < DataTable
  // columns = { columns }
  // data = { tableBodyState }
  // className = "table-with-pagination"
  // highlightOnHover
  // onRowClicked = {(row) => viewDocument(row.id)}
  // />
  // const viewDocument = (id: string) => {
  //   navigate(`${PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DRIVER_DOCUMENT_PAGE}/view/${id}`)
  // }
  return (
    <Row>
      <Col>
        <div>
          <Row>
            <Col>
              {isFetching ? (
                <div className="loading d-block mx-auto">
                  <div className="spinner-border"></div>
                </div>
              ) : documentArray.length === 0 ? (
                <div className="text-center font-weight-bold">{`${t('DRIVER_DOCUMENT_PAGE.DRIVER_DOCUMENT_LIST.NO_DOCUMENT')}`}</div>
              ) : (
                <>
                  {!documentArray ?
                    <div>
                      {/* TODO: from translation */}
                      <h4>No Document Found</h4>
                    </div> :
                    <Row>
                      {documentArray.map((document, documentIndex) => {
                        return (
                          // <Col key={index} sm={12} md={6} lg={4} xl={3} className='mb-3 card-item-list'>
                          //   <Card className={'form-card card-with-image'}>
                          //     <Row className='align-items-center'>
                          //       <Card.Title className={'py-3 px-4'}>
                          //         <Col xs={12}>
                          //           <Row className='align-items-center'>
                          //             <Col xs={6}>
                          //               <p className='mb-2 font-weight-bold card-details text-truncate'>{document.title}</p>
                          //             </Col>
                          //           </Row>
                          //         </Col>
                          //       </Card.Title>
                          //       <div className='d-flex justify-content-center p-2'>
                          //         <Card.Img
                          //           src={document.placeHolderImg}
                          //           onClick={() => viewDocument(document.id)}>
                          //         </Card.Img>
                          //       </div>
                          //     </Row>
                          //   </Card>
                          // </Col>
                          // TODO: from translation
                          <Col key={`${documentIndex}-vehicle-document`} sm={12} md={6} lg={4} xl={3} className='my-2'>
                            <Card className={'form-card card-with-detail'}>
                              <Card.Title className='px-4 pt-3 d-flex justify-content-between'>
                                {document.title}
                                <IsAuthorized moduleInfo={[{ moduleId: MODULE_ID.VEHICLE_DOCUMENT, rights: 'update' }]}>
                                  <span className='mdi mdi-pencil text-primary cursor-p' onClick={() => editDocument(document.id)}></span>
                                </IsAuthorized>
                              </Card.Title>
                              <hr className='my-2'></hr>
                              {/* TODO : Renish it should be in one file */}
                              <Card.Body className={'py-0 px-4'}>
                                <Row className='align-items-center'>
                                  <Col xs={12} className='my-2'>
                                    <p className='mb-2 card-details title-name text-line-2'>{document.description}</p>
                                  </Col>
                                  <Col xs={12} className='d-flex justify-content-between my-1'>
                                    <p className='mb-2 font-weight-bold card-details title-name'>Format:</p>
                                    <p className='mb-2 font-weight-bold card-details title-value'>{document.contentType}</p>
                                  </Col>
                                  <Col xs={12} className='d-flex justify-content-between my-1'>
                                    <p className='mb-2 font-weight-bold card-details title-name'>Max Size:</p>
                                    <p className='mb-2 font-weight-bold card-details title-value'>{document.maxSize / 1000000} MB</p>
                                  </Col>
                                  <Col xs={12} className='d-flex justify-content-between my-1'>
                                    <p className='mb-2 font-weight-bold card-details title-name'>Doc Number:</p>
                                    <p className='mb-2 font-weight-bold card-details title-value'>{document.doShowDocumentNumber ? 'Yes' : 'No'}</p>
                                  </Col>
                                  <Col xs={12} className='d-flex justify-content-between my-1'>
                                    <p className='mb-2 font-weight-bold card-details title-name'>Show Expiry:</p>
                                    <p className='mb-2 font-weight-bold card-details title-value'>{document.doShowDocumentExpiryDate ? 'Yes' : 'No'}</p>
                                  </Col>
                                  <Col xs={12} className='d-flex justify-content-between my-1'>
                                    <p className='mb-2 font-weight-bold card-details title-name'>Required:</p>
                                    <p className='mb-2 font-weight-bold card-details title-value'>{document.isRequired ? 'Yes' : 'No'}</p>
                                  </Col>
                                  <Col xs={12} className='d-flex justify-content-between my-1'>
                                    <p className='mb-2 font-weight-bold card-details title-name'>Captured Only:</p>
                                    <p className='mb-2 font-weight-bold card-details title-value'>{document.isCaptureOnly ? 'Yes' : 'No'}</p>
                                  </Col>
                                </Row>

                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      })}

                    </Row>
                  }
                </>
              )}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
}

export default DriverDocument
