import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

type Props = {
  buttonName: string
  isDisable?: boolean
  className?: string
  isFetching?: boolean
}

const FormButton = (props: Props) => {
  return (
    <div className="mt-3">
      <Button
        type="submit"
        className={`btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn ${props.className && props.className}`}
        disabled={props.isDisable || props.isFetching}
      >
        {props.isFetching ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : props.buttonName}
      </Button>
    </div>
  )
}

export default FormButton
