// import { FormikValues/* , useFormik */ } from 'formik'
// import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Button, Col, Form, FormControl, FormGroup, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
// import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
import { VehiclePriceModel } from '../../Pages/Models/CommonModel'
// import { OrganizationShiftPriceSchema } from '../../Pages/OrganizationMenu/ShiftSettings/OrganizationShiftPrice/OrganizationShiftPriceSchema'
// import { patchUpdateVehicleShiftPrice } from '../../Services/OrganizationServices'
// import { fetchResponseMessage, RateKmToMeters/* , RateMetersToKm */ } from '../../utils/utils'

type Props = {
  vehicleImage?: string
  vehicleName?: string
  vehicleId: string
  eventKey: number
  vehiclePrice: VehiclePriceModel
  handleSubmit: (formValue: VehiclePriceModel) => void
}

// TODO: What do you mean by shit price accordion?
const ShitPriceAccordion = (props: Props) => {
  const { /* setIsFetching, */ isFetching } = useContext(UserContext)
  const { t } = useTranslation()
  const [formValue, setFormValue] = useState({} as VehiclePriceModel)
  const arrayOfPriceLabel = [
    { label: `${t('INPUT_FIELD_ENUMS.ADDITIONAL_PERSON_CHARGE.LABEL_NAME')}`, keyName: 'additionalPersonCharge', placeholder: `${t('INPUT_FIELD_ENUMS.ADDITIONAL_PERSON_CHARGE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.BASE_RATE.LABEL_NAME')}`, keyName: 'baseRate', placeholder: `${t('INPUT_FIELD_ENUMS.BASE_RATE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.CANCEL_RATE.LABEL_NAME')}`, keyName: 'cancelRate', placeholder: `${t('INPUT_FIELD_ENUMS.CANCEL_RATE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.DISTANCE_RATE.LABEL_NAME')}`, keyName: 'distanceRate', placeholder: `${t('INPUT_FIELD_ENUMS.DISTANCE_RATE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.DURATION_RATE.LABEL_NAME')}`, keyName: 'durationRate', placeholder: `${t('INPUT_FIELD_ENUMS.DURATION_RATE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.INITIAL_DISTANCE.LABEL_NAME')}`, keyName: 'initialDistance', placeholder: `${t('INPUT_FIELD_ENUMS.INITIAL_DISTANCE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.INITIAL_DURATION.LABEL_NAME')}`, keyName: 'initialDuration', placeholder: `${t('INPUT_FIELD_ENUMS.INITIAL_DURATION.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.INITIAL_PICKUP_DISTANCE.LABEL_NAME')}`, keyName: 'initialPickupDistance', placeholder: `${t('INPUT_FIELD_ENUMS.INITIAL_PICKUP_DISTANCE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.INITIAL_WAIT_TIME.LABEL_NAME')}`, keyName: 'initialWaitTime', placeholder: `${t('INPUT_FIELD_ENUMS.INITIAL_WAIT_TIME.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.LUGGAGE_CHARGE.LABEL_NAME')}`, keyName: 'luggageCharge', placeholder: `${t('INPUT_FIELD_ENUMS.LUGGAGE_CHARGE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.PICKUP_DISTANCE_RATE.LABEL_NAME')}`, keyName: 'pickupDistanceRate', placeholder: `${t('INPUT_FIELD_ENUMS.PICKUP_DISTANCE_RATE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.STOP_RATE.LABEL_NAME')}`, keyName: 'stopRate', placeholder: `${t('INPUT_FIELD_ENUMS.STOP_RATE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.SUR_CHARGE.LABEL_NAME')}`, keyName: 'surCharge', placeholder: `${t('INPUT_FIELD_ENUMS.SUR_CHARGE.PLACEHOLDER')}` },
    { label: `${t('INPUT_FIELD_ENUMS.WAIT_CHARGE.LABEL_NAME')}`, keyName: 'waitRate', placeholder: `${t('INPUT_FIELD_ENUMS.WAIT_CHARGE.PLACEHOLDER')}` }
  ]

  // const handleUpdate = async (values: FormikValues, { resetForm }: FormikValues) => {
  //   setIsFetching(true)
  //   try {
  //     values = { ...values, distanceRate: RateKmToMeters(values.distanceRate), pickupDistanceRate: RateKmToMeters(values.pickupDistanceRate) }
  //     console.log('🔥 ~ handleUpdate ~ values', values)
  //     const response = await patchUpdateVehicleShiftPrice(props.vehiclePrice.id, props.shiftId ? Object.assign(values, { orgShiftId: props.shiftId, orgVehicleDetailId: props.vehicleId }) : {})
  //     if (response.statusCode === 200) {
  //       setIsFetching(false)
  //       const responseMessage = fetchResponseMessage(response.responseCode)
  //       toast.success(responseMessage)
  //       setTimeout(() => {
  //         window.location.reload()
  //       }, 2000)
  //     }
  //   } catch (error) {
  //     setIsFetching(false)
  //     console.error('🔥 ~ handleUpdate ~ error', error)
  //     if (error && _.has(error, 'response.data.responseCode')) {
  //       // @ts-ignore
  //       const responseMessage = fetchResponseMessage(error.response.data.responseCode)
  //       toast.error(responseMessage)
  //     }
  //   }
  // }
  // const initialVehiclePrice = {
  //   additionalPersonCharge: props.vehiclePrice.additionalPersonCharge,
  //   baseRate: props.vehiclePrice.baseRate,
  //   cancelRate: props.vehiclePrice.cancelRate,
  //   distanceRate: RateMetersToKm(props.vehiclePrice.distanceRate),
  //   durationRate: props.vehiclePrice.durationRate,
  //   initialDistance: props.vehiclePrice.initialDistance,
  //   initialDuration: props.vehiclePrice.initialDuration,
  //   initialPickupDistance: props.vehiclePrice.initialPickupDistance,
  //   initialWaitTime: props.vehiclePrice.initialWaitTime,
  //   luggageCharge: props.vehiclePrice.luggageCharge,
  //   pickupDistanceRate: RateMetersToKm(props.vehiclePrice.pickupDistanceRate),
  //   stopRate: props.vehiclePrice.stopRate,
  //   surCharge: props.vehiclePrice.surCharge,
  //   waitRate: props.vehiclePrice.waitRate
  // }
  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: initialVehiclePrice,
  //   validationSchema: () => OrganizationShiftPriceSchema,
  //   onSubmit: (values: FormikValues, formik) => {
  //     handleUpdate(values, formik)
  //   }
  // })

  // const handleInputChange = (value: string, inputName: string) => {
  //   formik.setFieldValue(inputName, value)
  // }

  useEffect(() => {
    if (Object.keys(props.vehiclePrice).length) {
      setFormValue(props.vehiclePrice)
    }
  }, [Object.keys(props.vehiclePrice).length])
  return (
    // <Form onSubmit={formik.handleSubmit}>
    <>
      {Object.keys(formValue).length > 0 ?
        <Accordion className='mb-3'>
          <Accordion.Item eventKey={`${props.eventKey}`}>
            <Accordion.Header>
              <p><strong>{props.vehicleName || 'Set Price For This Vehicle'}</strong></p>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <small className="text-muted mb-2">
                  <b>Note : </b> All the distance are in <b>Meter</b> and Duration or Time is in <b>Minutes</b>. So distance is factor is <b>Meter/Minute</b>
                </small>
                {Object.keys(props.vehiclePrice).length > 0 && Object.keys(formValue).length > 0 ? arrayOfPriceLabel.map((priceType: { label: string, keyName: string, placeholder: string }, index: number) => {
                  return (<Col sm={6} className={'my-2'} key={index}>
                    <FormGroup>
                      <>
                        <Form.Label className='d-inline-block text-truncate'>{priceType.label}</Form.Label>
                        <FormControl
                          type='number'
                          step={0.01}
                          name={priceType.keyName}
                          value={formValue[priceType.keyName as keyof VehiclePriceModel]}
                          onChange={(event) => setFormValue({ ...formValue, [priceType.keyName]: event.target.value })}
                          placeholder={priceType.placeholder}
                        >
                        </FormControl>
                        {/* {formik.errors[priceType.keyName] ? <div className='text-danger'>{`${formik.errors[priceType.keyName]}`}</div> : null} */}
                      </>
                    </FormGroup>
                  </Col>
                  )
                })
                  : null}
                <FormGroup className={'d-flex justify-content-center'}>
                  <Button className='my-3 d-block me-auto' onClick={() => props.handleSubmit(formValue)}>
                    {isFetching ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> :
                      `${t('PROJECT_BUTTON.UPDATE')} Rate`
                    }
                  </Button>
                </FormGroup>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        : null}
    </>
    // </Form>
  )
}

export default ShitPriceAccordion
