import { FormikValues } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DesignationEditView from '../../../../Components/Common/DesignationEditView'
import UserContext from '../../../../Context/UserContext'
import { PROJECT_ROUTES_ENUMS } from '../../../../utils/enums/enums'

type Props = {}
const AddDesignation = (props: Props) => {
  const { t } = useTranslation()
  const { setArrayOfBreadCrumb } = useContext(UserContext)
  const [isDefaultState, setDefaultState] = useState(false)
  const [designationState, setDesignationState] = useState('')
  const [dutyState, setDutyState] = useState('')

  useEffect(() => {
    setArrayOfBreadCrumb([
      { name: `${t('PAGE_NAME.HOME')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE },
      { name: `${t('PAGE_NAME.DESIGNATION')}`, url: PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.DESIGNATION_PAGE },
      { name: `${t('PAGE_NAME.ADD_DESIGNATION')}`, url: '' }
    ])
    setDesignationState('')
    setDutyState('')
    return () => {}
  }, [])

  const setFieldValue = (formik: FormikValues) => {
    setDesignationState(formik.values.designationInput)
    setDutyState(formik.values.duty)
  }

  return (
    <DesignationEditView
      field={{ designation: designationState, duty: dutyState, isDefault: isDefaultState, setDefaultState }}
      isDisabled={false}
      cardTitle={`${t('PAGE_TITLE.ADD_DESIGNATION')}`}
      submitButtonName="ADD"
      setFieldValue={setFieldValue}
      pageName="add-designation"
    />
  )
}

export default AddDesignation
