import axios, { versionInstance, serviceSuccessHandle, serviceFailureHandle } from './AxiosConfig'
import Axios from 'axios'
// TODO Need to review the code below
export const logoutHandler = () => {
  return axios.delete('/auth/logout').then(serviceSuccessHandle).catch(serviceFailureHandle)
}
export const getPermission = () => {
  return axios.get('/api/me/access').then(serviceSuccessHandle).catch(serviceFailureHandle)
}
export const getAdminDetails = () => {
  return axios.get('/api/me').then(serviceSuccessHandle).catch(serviceFailureHandle)
}
export const getCountries = () => {
  return versionInstance.get('/location/countries').then(serviceSuccessHandle).catch(serviceFailureHandle)
}
export const getStates = (countryId: number) => {
  return versionInstance.get(`/location/states/${countryId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
export const getCities = (stateId: number) => {
  return versionInstance.get(`/location/cities/${stateId}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
export const getPresignedUrl = (urlCount: Array<object>) => {
  return axios.post('/api/organization/media/public/presignedUrl', { data: urlCount }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
export const awsUploadMedia = (url: string, multiPartFormData: any, file: any) => {
  const formData = new FormData()
  for (const key in multiPartFormData) {
    formData.append(key, multiPartFormData[key])
  }
  formData.append('file', file)
  return Axios.post(url, formData, {
    headers: {}
  }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
export const setPresignedUrl = (reqBody: any) => {
  return axios.post('/api/organization/media/public/', reqBody).then(serviceSuccessHandle).catch(serviceFailureHandle)
}
