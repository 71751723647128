import React, { useContext } from 'react'
import AuthFooter from '../Components/Auth/AuthFooter/AuthFooter'
import AuthHeader from '../Components/Auth/AuthHeader/AuthHeader'
import ToastMsg from '../Components/Common/ToastMsg'
import LanguageOptionButton from '../Components/LanguageOptionButton/LanguageOptionButton'
import UserContext from '../Context/UserContext'

type Props = {
  greetingLine?: string
  greetingTitle?: string,
  children?:React.ReactNode
}

const SidePanel = ({ greetingLine, greetingTitle, children }: Props) => {
  const { darkMode } = useContext(UserContext)

  return (
    <div className={`container-scroller position-relative ${darkMode && 'dark-theme'}`}>
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
          <div className="row flex-grow">
            <ToastMsg />
            <div className="col-lg-6 d-flex align-items-center justify-content-center flex-column">
              <LanguageOptionButton className="nav-item language-selector align-self-end dropdown mt-2" />
              <div className="auth-form-transparent text-left p-3">
                <AuthHeader greetingTitle={greetingTitle} greetingLine={greetingLine} />
                {children}
              </div>
            </div>
            <AuthFooter />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidePanel
