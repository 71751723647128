import axios, { AxiosError, AxiosResponse } from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 4000
})

export const versionInstance = axios.create({
  baseURL: process.env.REACT_APP_VERSION_API_URL,
  timeout: 4000
})

export const serviceSuccessHandle = async (res: AxiosResponse) => {
  try {
    console.log('🚀 ~ file: AxiosConfig.tsx:15 ~ serviceSuccessHandle ~ res', res)
    if (res.status < 300) {
      return res.data
    }
    // TODO: Handle other res.status errors
  } catch (error) {
    console.error('🚀 ~ file: AxiosConfig.tsx:21 ~ serviceSuccessHandle ~ error', error)
  }
}

export const serviceFailureHandle = async (res: AxiosError) => {
  console.log('🔥 ~ serviceFailureHandle ~ res', res)
  return res
  /* if (res.response!.status >= 400 && res.response!.status < 500) {
    switch (res.response?.status) {
      case 401:
        setTimeout(() => {
          localStorage.clear()
          sessionStorage.clear()
          window.location.pathname = '/auth/login?hasErrors=true'
        }, 5000)
        break
      case 404:
        console.log('🔥 ~ serviceFailureHandle ~ 404')
        window.location.pathname = '/page-not-found'
        break
      default:
        return res
    }
  } else if (res.response!.status >= 500 && res.response!.status < 600) {
    console.log('🔥 ~ serviceFailureHandle ~ res.response!.status', res.response!.status)
    window.location.pathname = '/under-management'
  } */
}

export default instance
