import axios, { serviceFailureHandle, serviceSuccessHandle } from './AxiosConfig'
import { UserRegistrationModel } from '../Pages/Register/UserRegistrationModel'
import { UserLoginModel } from '../Pages/Login/UserLoginModel'

const postSignUpUser = (singUpInfo: UserRegistrationModel | object) => {
  return axios.post('/auth/signup', singUpInfo).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const postLoginUser = (loginInfo: UserLoginModel) => {
  return axios.post('/auth/login', loginInfo).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const postRequestResetPassword = (resetPasswordBody: object) => {
  return axios.post('/auth/request/resetpassword', resetPasswordBody).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const getResetPasswordVerify = (token: string) => {
  return axios.get(`/auth/verify/resetToken/${token}`).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

const postResetPassword = (password: string, resetToken: string) => {
  return axios.put('/auth/password', { password, resetToken }).then(serviceSuccessHandle).catch(serviceFailureHandle)
}

export { postSignUpUser, postLoginUser, postRequestResetPassword, getResetPasswordVerify, postResetPassword }
