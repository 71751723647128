/* eslint-disable indent */
import React, { JSXElementConstructor, ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { PermissionsObjectModel } from '../../Pages/Models/CommonModel'
import AppLayout from '../../Layout/AppLayout'

type AccessControlProps = {
  allowedPermissionKey: ('create' | 'read' | 'update' | 'delete')[],
  moduleId: number,
  userPermissions: PermissionsObjectModel[],
  renderNoAccess?: ReactElement,
  children?: ReactElement<any, string | JSXElementConstructor<any>>
}

const AccessControl = ({
  allowedPermissionKey,
  moduleId,
  userPermissions,
  renderNoAccess,
  children
}: AccessControlProps): ReactElement => {
  let isAuthorized: Boolean = true

  const checkPermission = ({ userPermissions, allowedPermissionKey, moduleId }: { userPermissions: PermissionsObjectModel[], allowedPermissionKey: ('create' | 'read' | 'update' | 'delete')[], moduleId: number }): Boolean => {
    const modulePermission = userPermissions.find(permissions => permissions.moduleId === moduleId)
    if (!modulePermission) return false
    const permissionArrayLength = allowedPermissionKey.map(permission => modulePermission[permission] === 1).length
    if (modulePermission && allowedPermissionKey.length === permissionArrayLength) return true

    return false
  }

  if (userPermissions.length > 0) {
    /* check if the current user is authorized  */

    if (!checkPermission({ allowedPermissionKey, moduleId, userPermissions })) isAuthorized = false

    if (!isAuthorized) return <Navigate to={PROJECT_ROUTES_ENUMS.PRIVATE_ROUTES.HOME_PAGE} />

    if (renderNoAccess) return renderNoAccess
  }

  return (
    userPermissions.length > 0
      ? (
        <AppLayout>
          {children}
        </AppLayout>
      )
      :
      <></>
  )
}

export default AccessControl
