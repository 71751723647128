import { Formik, FormikHandlers, FormikValues } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { INPUT_FIELD_ENUMS, PROJECT_ROUTES_ENUMS } from '../../utils/enums/enums'
import { ResetPasswordSchema } from '../../Pages/ResetPassword/ResetPasswordSchema'
import { useTranslation } from 'react-i18next'
import { getResetPasswordVerify, postResetPassword } from '../../Services/AuthService'
import UserContext from '../../Context/UserContext'
import FormInputText from '../../Components/Form/FormInputText/FormInputText'
import FormButton from '../../Components/Form/FormButton/FormButton'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { fetchResponseMessage } from '../../utils/utils'
import _ from 'lodash'

type Props = {}
// This link come fromm email
const ResetPassword = (props: Props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { setIsFetching, isFetching } = useContext(UserContext)
  const [isResetPasswordRequestTokenValid, setIsResetPasswordRequestTokenValid] = useState(false)
  const [token, setToken] = useState('')
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const initialUserInfo = {
    password: '',
    confirmPassword: ''
  }
  useEffect(() => {
    ; (async () => {
      setIsFetching(true)
      try {
        const arrayOfPathParams = location.pathname.split('/')
        const token = arrayOfPathParams[arrayOfPathParams.length - 1]
        const response = await getResetPasswordVerify(token)
        if (response.statusCode === 200) {
          setIsResetPasswordRequestTokenValid(true)
          setToken(token)
          setIsFetching(false)
        } else {
          setIsResetPasswordRequestTokenValid(false)
          throw response
        }
      } catch (error) {
        setIsFetching(false)
        console.error('🚀 ~ file: ResetPasswordForm.tsx ~ line 37 ~ error', error)
        if (error && _.has(error, 'response.data.responseCode')) {
          // @ts-ignore
          const responseMessage = fetchResponseMessage(error.response.data.responseCode)
          toast.error(responseMessage)
        }
        // toast.error(`${t('TOAST_ERROR.RESET_PASSWORD.LINK_EXPIRED')}`)
      }
    })()
    return () => { }
  }, [])

  /**
   *@function handleResetPassword
   * @param values
   * @description Will send post request to change old password to new one.
   */
  const handleResetPassword = async (values: FormikValues) => {
    setIsSubmitting(true)
    setIsFetching(false)
    setIsResetPasswordRequestTokenValid(true)
    try {
      const response = await postResetPassword(values.password, token)
      if (response.statusCode === 200) {
        const responseMessage = fetchResponseMessage(response.responseCode)
        toast.success(responseMessage)
        // setIsFetching(false)
        navigate(PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.LOGIN_PAGE)
        setIsResetPasswordRequestTokenValid(false)
        setIsSubmitting(false)
      } else {
        throw response
      }
    } catch (error: any) {
      // TODO : Need to handle from interceptors
      setIsFetching(false)
      console.error('🚀 ~ file: ResetPasswordForm.tsx ~ line 37 ~ error', error)
      if (error && _.has(error, 'response.data.responseCode')) {
        const responseMessage = fetchResponseMessage(error.response.data.responseCode)
        toast.error(responseMessage)
      }
    }
  }

  return (
    <>
      {!isResetPasswordRequestTokenValid && isFetching ? (
        <Spinner animation="border" variant="primary" />
      ) : isResetPasswordRequestTokenValid && !isFetching ? (
        <Formik
          initialValues={initialUserInfo}
          validationSchema={() => ResetPasswordSchema}
          onSubmit={(values) => {
            handleResetPassword(values)
          }}
        >
          {(formik: FormikHandlers) => (
            <form className="pt-3" onSubmit={formik.handleSubmit}>
              <FormInputText
                inputField="LOGIN_PASSWORD"
                type={INPUT_FIELD_ENUMS.LOGIN_PASSWORD.INPUT_TYPE}
                inputName={INPUT_FIELD_ENUMS.LOGIN_PASSWORD.INPUT_NAME}
                isRequired={INPUT_FIELD_ENUMS.LOGIN_PASSWORD.IS_REQUIRED}
              />
              <FormInputText
                inputField="CONFIRM_PASSWORD"
                type={INPUT_FIELD_ENUMS.CONFIRM_PASSWORD.INPUT_TYPE}
                inputName={INPUT_FIELD_ENUMS.CONFIRM_PASSWORD.INPUT_NAME}
                isRequired={INPUT_FIELD_ENUMS.CONFIRM_PASSWORD.IS_REQUIRED}
              />
              <FormButton buttonName={'Reset Password'} isFetching={isSubmitting} />
              <div className="text-center mt-4 font-weight-light">
                {`${`${t('FORM_PAGE_CONSTANTS.BOTTOM_LINE.RESET_PASSWORD_PAGE')}`}`} &nbsp;
                <Link to={PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.LOGIN_PAGE} className="text-primary">
                  {`${t('FORM_PAGE_CONSTANTS.BUTTON_NAME.LOGIN_PAGE')}`}
                </Link>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <div>
          <h1 className="mb-3">{t('RESET_PASSWORD_PAGE.LINK_EXPIRED')}</h1>
          <p>
            <a href={`${PROJECT_ROUTES_ENUMS.PUBLIC_ROUTES.REQUEST_RESET_PASSWORD_PAGE}`}>{t('RESET_PASSWORD_PAGE.REDIRECT_LINK')}</a>
            {t('RESET_PASSWORD_PAGE.REDIRECT_LINK_LINE')}
          </p>
        </div>
      )}
    </>
  )
}

export default ResetPassword
