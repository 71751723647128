export const FRENCH_TRANSLATION = {
  INPUT_FIELD_ENUMS: {
    FIRST_NAME: {
      PLACEHOLDER: 'Prénom',
      LABEL_NAME: 'Prénom'
    },
    LAST_NAME: {
      PLACEHOLDER: 'Nom de famille',
      LABEL_NAME: 'Nom de famille'
    },
    MIDDLE_NAME: {
      PLACEHOLDER: 'Deuxième nom',
      LABEL_NAME: 'Deuxième nom'
    },
    COUNTRY: {
      PLACEHOLDER: 'Pays',
      LABEL_NAME: 'Pays'
    },
    STATE: {
      PLACEHOLDER: 'État',
      LABEL_NAME: 'État'
    },
    CITY: {
      PLACEHOLDER: 'Ville',
      LABEL_NAME: 'Ville'
    },
    ADDRESS: {
      PLACEHOLDER: 'Entrez votre adresse',
      LABEL_NAME: 'Adresse'
    },
    EMAIL_ADDRESS: {
      PLACEHOLDER: 'Adresse e-mail',
      LABEL_NAME: 'Adresse e-mail'
    },
    COUNTRY_CODE: {
      PLACEHOLDER: '+91',
      LABEL_NAME: 'Code postal'
    },
    PHONE_NUMBER: {
      PLACEHOLDER: 'Numéro de téléphone',
      LABEL_NAME: 'Numéro de téléphone'
    },
    DATE_OF_BIRTH: {
      PLACEHOLDER: 'Date de naissance',
      LABEL_NAME: 'Date de naissance'
    },
    PASSWORD: {
      PLACEHOLDER: 'Définir le mot de passe',
      LABEL_NAME: 'Mot de passe'
    },
    CONFIRM_PASSWORD: {
      PLACEHOLDER: 'Confirmez le mot de passe',
      LABEL_NAME: 'Confirmez le mot de passe'
    },
    LOGIN_EMAIL_OR_PHONE_NUMBER: {
      PLACEHOLDER: 'Entrez votre email ou numéro de téléphone',
      LABEL_NAME: 'Courriel ou numéro de téléphone'
    },
    LOGIN_PASSWORD: {
      PLACEHOLDER: 'Tapez votre mot de passe',
      LABEL_NAME: 'Mot de passe'
    },
    ADD_DESIGNATION: {
      PLACEHOLDER: 'La désignation',
      LABEL_NAME: 'Entrez la désignation'
    },
    ADD_DUTY: {
      PLACEHOLDER: 'Devoir',
      LABEL_NAME: 'Attribuer le devoir de la désignation ci-dessus'
    }
  },
  FORM_PAGE_CONSTANTS: {
    GREETING_TITLE: {
      REGISTER_PAGE: 'Bonjour!',
      LOGIN_PAGE: 'Content de te revoir!'
    },
    GREETING_LINE: {
      REGISTER_PAGE: 'Bienvenue!',
      LOGIN_PAGE: 'Content de te revoir!'
    },
    CHECKBOX_CONDITION: {
      REGISTER_PAGE: 'Jaccepte tous les ',
      LOGIN_PAGE: 'Gardez-moi connecté'
    },
    BUTTON_NAME: {
      REGISTER_PAGE: 'INSCRIVEZ-VOUS',
      LOGIN_PAGE: 'CONNEXION',
      REQUEST_RESET_PASSWORD: 'Request to reset password',
      RESET_PASSWORD_PAGE: 'Reset Password'
    },
    BOTTOM_LINE: {
      REGISTER_PAGE: 'Vous avez déjà un compte? ',
      LOGIN_PAGE: 'Vous navez pas de compte ? ',
      REQUEST_RESET_PASSWORD: 'Go back to',
      RESET_PASSWORD_PAGE: 'Go back to',
      REGISTER_PAGE_SUBMITTED: 'You have registered successfully click here to'
    },
    REDIRECT_LINK: {
      TERMS_CONDITION: 'termes et conditions',
      FORGOT_PASSWORD: 'Mot de passe oublié',
      REQUEST_RESET_PASSWORD: 'Reset password',
      RESET_PASSWORD_PAGE: 'Login'
    }
  },
  PROJECT_BUTTON: {
    ADD: 'Ajouter',
    CANCEL: 'Annuler',
    CLOSE: 'proche',
    ENTER: 'Entrer',
    DELETE: 'Effacer',
    SUBMIT: 'Submit',
    EDIT: 'Edit'
  },
  DESIGNATION_PAGE: {
    TABLE_TITLE: 'Tableau de désignation',
    ADD_DESIGNATION_BUTTON: 'Ajouter une désignation',
    SEARCH_BAR_PLACEHOLDER: 'Chercher',
    IS_DEFAULT_CHECKBOX: 'Set To Default',
    DESIGNATION_TABLE: {
      ORDER: 'Ordre',
      DESIGNATION: 'La désignation',
      DUTY: 'Devoir',
      ACTION: 'Action',
      DEFAULT: 'Default',
      NO_DESIGNATION: 'No Designation To show',
      ACTION_DROPDOWN: {
        EDIT: 'Éditer',
        DELETE: 'Effacer',
        VIEW: 'Voir'
      },
      TABLE: {
        ROW_PER_PAGE: 'Row per page',
        RANGE_SELECTION: 'Per'
      }
    },
    MODAL: {
      MODAL_TITLE: 'Supprimer le champ de',
      MODAL_MESSAGE: 'Voulez-vous vraiment supprimer ce champ de désignation ?'
    }
  },
  DESIGNATION_RIGHT_PAGE: {
    TITLE: 'Désignation Droit',
    NO_DESIGNATION: 'No Rights to show for this designation.',
    RIGHTS_NAME: {
      CREATE: 'Create :',
      READ: 'Read :',
      UPDATE: 'Update :',
      DELETE: 'Delete :'
    }
  },
  PAGE_TITLE: {
    DESIGNATION: 'Tableau de désignation',
    ADD_DESIGNATION: 'Ajouter une désignation',
    EDIT_DESIGNATION: 'Modifier la désignation',
    VIEW_DESIGNATION: 'Afficher la désignation',
    DESIGNATION_RIGHTS: 'Désignation Droit'
  },
  PAGE_NAME: {
    HOME: 'Maison',
    DESIGNATION: 'La désignation',
    ADD_DESIGNATION: 'Ajouter-Désignation',
    EDIT_DESIGNATION: 'Edit-Désignation',
    VIEW_DESIGNATION: 'Vue-Désignation',
    DESIGNATION_RIGHTS: 'Désignation Droit',
    ADMIN_LIST: 'Admin List',
    USER_LIST: 'User List',
    DRIVER_LIST: 'Driver List'
  },
  TOAST_ERROR: {
    HEADER_TYPE: {
      ERROR: 'Erreur',
      SUCCESS: 'Succès'
    },
    DESIGNATION: {
      FILED_TO_LOAD: 'Impossible de récupérer les détails de la désignation.',
      FILED_TO_DELETE: "Une erreur s'est produite, l'entrée n'a pas encore été supprimée. Veuillez réessayer.",
      DELETE_SUCCESS: 'Entrée supprimée avec succès.'
    },
    ADD_DESIGNATION: {
      FIELD_TO_LOAD: "Vous n'avez pas obtenu la désignation à laquelle vous essayez d'accéder.",
      FIELD_TO_EDIT: 'Entrée non ajoutée.',
      EDIT_SUCCESS: 'Entrée ajoutée avec succès.'
    },
    EDIT_DESIGNATION: {
      FIELD_TO_LOAD: "Vous n'avez pas obtenu la désignation à laquelle vous essayez d'accéder.",
      FIELD_TO_EDIT: 'Entrée non modifiée.',
      EDIT_SUCCESS: 'Entrée modifiée avec succès.'
    },
    VIEW_DESIGNATION: {
      FIELD_TO_LOAD: "Vous n'avez pas obtenu la désignation à laquelle vous essayez d'accéder."
    },
    DESIGNATION_RIGHTS: {
      FIELD_TO_LOAD_DESIGNATION_RIGHTS: 'Impossible de récupérer les données Désignation Droit.',
      FIELD_TO_LOAD_DESIGNATION_MENU: 'Impossible de récupérer les données de désignation pour le menu de sélection.'
    },
    REGISTER_FORM: {
      SUBMIT_SUCCESS: 'Vous vous êtes bien inscrit',
      SUBMIT_FIELD: "quelque chose s'est mal passé",
      COUNTRY_FETCH_FIELD: 'impossible de récupérer le pays',
      STATE_FETCH_FIELD: "Impossible de récupérer l'état",
      CITY_FETCH_FIELD: 'ne peut pas récupérer la ville'
    },
    LOGIN_FORM: {
      LOGIN_FIELD: "Quelque chose s'est mal passé. Veuillez réessayer.",
      LOGIN_SUCCESS: 'Logged in  successfully.'
    },
    ADMIN_LIST: {
      FIELD_TO_LOAD: 'Can not fetch Admin list.'
    },
    USER_LIST: {
      FILED_TO_LOAD: 'Can not fetch User list.'
    },
    DRIVER_LIST: {
      FILED_TO_LOAD: 'Can not fetch Driver list.'
    },
    REQUEST_RESET_PASSWORD: {
      FILED_TO_LOAD: 'Something went wrong!',
      REQUEST_SUCCESS: 'Your request has been send successfully please check you mail.'
    },
    RESET_PASSWORD: {
      LINK_EXPIRED: 'Your password reset link has expired',
      FIELD_TO_RESET: 'Your password has been not reset please try again.',
      RESET_SUCCESS: 'Your request has been reset successfully.'
    },
    AUTH_ERROR: {
      EMAIL_EXIST: 'This email id is already in use-fr.',
      LOGIN_SUCCESS: 'You have logged in successfully.',
      ACCOUNT_CREATED: 'Your account has been created successfully.',
      INVALID_PASSWORD: 'Please make sure you have entered a valid password-fr.',
      ACCOUNT_NOT_FOUND: 'There is no account with this email address. create an account first.',
      PASSWORD_RESET_SUCCESS: 'Your password has been reset successfully.',
      LOGOUT_SUCCESS: 'You have logged out successfully.',
      LOGOUT_FAILED: 'Logout failed Please try again.',
      RESET_PASSWORD_LINK_EXPIRED: 'This link has expired. Please try again to reset you password.',
      RESET_PASSWORD_LINK_SENT: 'Please check your mail to reset your password.'
    },
    DEFAULT_ERROR: 'Something went wrong. Please try again.'
  },
  SIDEBAR: {
    LOGO_TAG_LINE: '',
    MENU: {
      MAIN_MENU: 'Main Menuu',
      DASHBOARD: 'Dashboard',
      WIDGETS: 'Widgets',
      APP_PAGE: {
        MENU_TITLE: 'App Pages',
        PAGE: 'Pages'
      },
      USER_MENU: 'User Menu',
      ADMIN: {
        MENU_TITLE: 'Admin',
        DESIGNATION: 'Designation',
        DESIGNATION_RIGHTS: 'Designation Rights',
        ADMIN_LIST: 'Admin List'
      },
      USERS: {
        MENU_TITLE: 'User',
        USER_LIST: 'User List'
      },
      DRIVER: {
        MENU_TITLE: 'Driver',
        DRIVER_LIST: 'Driver List'
      }
    }
  },
  NAVBAR: {
    GREETING_LINE: 'Salut! content de te revoir!',
    SEARCH_PLACEHOLDER: 'Chercher',
    NOTIFICATION_MENU: {
      MENU_TITLE: 'Avis',
      EVENT_TODAY: {
        MENU_TITLE: "Événement aujourd'hui",
        MENU_DETAIL: "Juste un rappel que vous avez un événement aujourd'hui"
      },
      SETTING: {
        MENU_TITLE: 'Paramètre',
        MENU_DETAIL: 'Mettre à jour le tableau de bord'
      },
      NOTIFICATION: {
        MENU_TITLE: 'Notification',
        MENU_DETAIL: 'Nouvel administrateur wow !'
      }
    },
    USER_MENU: {
      MENU_TITLE: 'Utilisatrice',
      LOGOUT: {
        MENU_TITLE: 'Se déconnecter',
        MENU_DETAIL: ''
      }
    }
  },
  ADMIN_LIST_PAGE: {
    TABLE_TITLE: 'Admin List',
    ADD_DESIGNATION_BUTTON: 'Add Designation',
    SEARCH_BAR_PLACEHOLDER: 'Search',
    ADMIN_TABLE: {
      ORDER: 'Order',
      NAME: 'Id',
      SURNAME: 'Name',
      EMAIL: 'Email',
      STATUS: 'Mobile No.',
      DETAILS: 'Designation',
      DESIGNATION_NAME: 'Designation'
    },
    MODAL: {
      MODAL_TITLE: 'Delete Designation Field',
      MODAL_MESSAGE: 'Are you sure you want to delete this designation field?'
    }
  },
  ADMIN_DETAILS_PAGE: {
    TITLE: 'Admin Details'
  },
  USER_LIST_PAGE: {
    TABLE_TITLE: 'User List',
    ADD_DESIGNATION_BUTTON: 'Add Designation',
    SEARCH_BAR_PLACEHOLDER: 'Search',
    USER_TABLE: {
      ORDER: 'Order',
      ID: 'Id',
      NAME: 'Name',
      EMAIL: 'Email',
      MOBILE_NO: 'Mobile No.',
      DESIGNATION: 'Designation'
    },
    MODAL: {
      MODAL_TITLE: 'Delete Designation Field',
      MODAL_MESSAGE: 'Are you sure you want to delete this designation field?'
    }
  },
  DRIVER_LIST_PAGE: {
    TABLE_TITLE: 'Driver List',
    ADD_DESIGNATION_BUTTON: 'Add Designation',
    SEARCH_BAR_PLACEHOLDER: 'Search',
    DRIVER_TABLE: {
      ORDER: 'Order',
      ID: 'Id',
      NAME: 'Name',
      EMAIL: 'Email',
      MOBILE_NO: 'Mobile No.',
      DESIGNATION: 'Designation'
    },
    MODAL: {
      MODAL_TITLE: 'Delete Designation Field',
      MODAL_MESSAGE: 'Are you sure you want to delete this designation field?'
    }
  },
  RESET_PASSWORD_PAGE: {
    LINK_EXPIRED: 'Link is expired',
    REDIRECT_LINK_LINE: 'to request again for change password',
    REDIRECT_LINK: 'Click here '
  },
  FORMIK_ERROR: {
    ADD_DESIGNATION: {
      DESIGNATION_REQUIRED: 'Please enter Designation you want to assign',
      DUTY_REQUIRED: 'Please assign the Duty to the Designation'
    },
    AUTH_VALIDATION: {
      EMAIL_REQUIRED: 'Email is required',
      EMAIL_MAX_LENGTH: 'Email is required',
      EMAIL_INVALID: 'Please enter a valid email address',
      EMAIL_LOWER_CASE: 'only lowercase letters allowed',
      PASSWORD_REQUIRED: 'Please set your password',
      CONFIRM_PASSWORD_REQUIRED: 'Please confirm your password',
      PASSWORD_MAX_LENGTH: 'Password must not have more than 18 characters',
      PASSWORD_MIN_LENGTH: 'Provide least 6 characters',
      PASSWORD_SHOULD_MATCHED: 'Password must match',
      REGISTER_FORM: {
        FIRST_NAME_REQUIRED: 'First name is required',
        FIRST_NAME_MAX_LENGTH: 'First name must be at most 15 characters',
        MIDDLE_NAME_MAX_LENGTH: 'Middle name must be at most 15 characters',
        LAST_NAME_REQUIRED: 'Last name is required',
        LAST_NAME_MAX_LENGTH: 'Last name must be at most 15 characters',
        SELECT_COUNTRY: 'Please select a country',
        SELECT_STATE: 'Please select a state',
        SELECT_CITY: 'Please select a city',
        SELECT_COUNTRY_CODE: 'Country code',
        ADDRESS_MAX_LENGTH: 'Address must be at most 500 characters',
        DATE_OF_BIRTH_INVALID: 'Date is invalid',
        MOBIL_NO_REQUIRED: 'Mobile number is required',
        MOBILE_NO_TYPE: 'Mobile should be a number value.',
        MOBILE_NO_MIN_LENGTH: 'number should be at least 6 characters long',
        MOBILE_NO_MAX_LENGTH: 'number should be at most 12 characters long'
      }
    }
  },
  FOOTER_LINE: `Copyright © ${new Date().getFullYear()} All rights reserved.`
}
