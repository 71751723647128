import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './assets/css/style.css'
import './assets/css/dark-theme-style.css'
import UserContext, { UserContextProvider } from './Context/UserContext'
import './web.config'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <UserContextProvider>
    <UserContext.Consumer>{(context) => <App />}</UserContext.Consumer>
  </UserContextProvider>
  // </React.StrictMode>
)

reportWebVitals()
