import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { MenuData } from '../../../Pages/Models/CommonModel'
import SidebarAccess from '../SidebarAccess'
import MainMenuComponent from './MainMenuComponent'

type Props = {
  navInfo: {
    menuName: string
    menuData: MenuData[]
    arrayOfChildrenModuleId: number[]
  }
}
const MainMenus = ({ navInfo }: Props) => {
  const { pathname } = useLocation()
  return (
    <>
      <li className="nav-item mb-2">
        <div className="menu-heading mt-0">{navInfo.menuName}</div>
      </li>
      {navInfo.menuData.map((menuData, menuDataIndex) => {
        if (menuData.children) {
          const id = '#' + menuData.name.toLowerCase().replace(/\s/g, '-')
          return (
            <SidebarAccess key={`mainMenus ${menuDataIndex}`} arrayOfChildrenModuleId={menuData.arrayOfChildrenModuleId} moduleId={menuData.moduleId} accessRights={menuData.accessType}>
              <MainMenuComponent
                menuData={menuData}
                isMainActive={pathname.toString().toLowerCase().includes(`/${menuData.name.toString().toLowerCase()}/`)}
                index={`${menuDataIndex}.${menuData.name}`}
                id={id}
              />
            </SidebarAccess>
          )
        } else if (!menuData.children) {
          return (
            <SidebarAccess key={menuDataIndex} moduleId={menuData.moduleId} accessRights={menuData.accessType}>
              <li className="nav-item">
                <NavLink to={menuData.url} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                  {menuData.icon}
                  <span className="menu-title">{menuData.name}</span>
                </NavLink>
              </li>
            </SidebarAccess>
          )
        } else {
          return null
        }
      })}
    </>
  )
}

export default MainMenus
