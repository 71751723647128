import React from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownMenuItemObject } from '../../../../Pages/Models/CommonModel'
import DropdownMenu from '../../../DropdownMenu/DropdownMenu'
const NotificationIcon = () => {
  const { t } = useTranslation()
  const dropdownDetails: DropdownMenuItemObject = [
    {
      dropdownTitle: `${t('NAVBAR.NOTIFICATION_MENU.MENU_TITLE')}`,
      dropdownIcon: '',
      dropdownContent: '',
      isDropdownHeader: true
    },
    {
      dropdownTitle: `${t('NAVBAR.NOTIFICATION_MENU.EVENT_TODAY.MENU_TITLE')}`,
      dropdownIcon: 'mdi-calendar',
      dropdownContent: `${t('NAVBAR.NOTIFICATION_MENU.EVENT_TODAY.MENU_DETAIL')}`,
      isDropdownHeader: false
    },
    {
      dropdownTitle: `${t('NAVBAR.NOTIFICATION_MENU.SETTING.MENU_TITLE')}`,
      dropdownIcon: 'mdi-settings',
      dropdownContent: `${t('NAVBAR.NOTIFICATION_MENU.SETTING.MENU_DETAIL')}`,
      isDropdownHeader: false
    },
    {
      dropdownTitle: `${t('NAVBAR.NOTIFICATION_MENU.NOTIFICATION.MENU_TITLE')}`,
      dropdownIcon: 'mdi-link-variant',
      dropdownContent: `${t('NAVBAR.NOTIFICATION_MENU.NOTIFICATION.MENU_DETAIL')}`,
      isDropdownHeader: false
    }
  ]

  return <DropdownMenu dropdownDetails={dropdownDetails} dropdownButtonIcon={'mdi-bell'} dropdownNameOrBadge={'2'} />
}

export default NotificationIcon
